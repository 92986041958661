import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './Header.module.scss'
import cn from 'classnames'

const Header = (props) => {
    const [isSticky, setIsSticky] = useState(false)

    useEffect(() => {
        const handleScroll = () => setIsSticky(window.scrollY > 50)
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <header className={cn(props.className, styles.wrapper, { [styles.sticky]: isSticky })} style={props.style}>
            <div className='flex'>
                <a href='#'>
                    <img className='h-14 w-auto sm:h-14' src='/images/logo.png' alt='MPlus Online' />
                </a>
            </div>
        </header>
    )
}

Header.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}

Header.defaultProps = {
}

export default Header
