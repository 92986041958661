import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useHistory, useParams } from 'react-router-dom'
import AdminLayout from '../../../../../modules/admin/components/templates/layout'
import FormContainer from '../../../../../components/form/container'
import CompleteFinancialProfileForm from '../../../../../modules/application/financial-profile/complete/components/form'
import StepBar from '../../../../../modules/application/components/step-bar'
import { getAdminStepLink } from '../../../../../helpers/StepLink'

const AdminApplicationFinancialProfilePage = inject('store')(observer(({ store }) => {
    const history = useHistory()
    const { id } = useParams()

    const handleNext = () => {
        history.push(`/admin/application/${id}/taxation-and-declarations`)
    }

    return (
        <AdminLayout>
            <div className='flex flex-col items-center flex-grow'>
                <h1 className='text-4xl oxygen-font-weight-bold text-gray-500 tracking-tight mb-4 my-16'>
                    Financial Profile
                </h1>

                <StepBar
                    current={3} onChange={(e) => {
                        const step = e + 1
                        if (step > store.completedStep) return
                        const link = getAdminStepLink(step, id)
                        history.push(link)
                    }}
                />

                <FormContainer>
                    <CompleteFinancialProfileForm onNext={handleNext} isLocked />
                </FormContainer>
            </div>
        </AdminLayout>
    )
}))

export default AdminApplicationFinancialProfilePage
