
const industry = [
    'Financial Institution authorized by SC/BNM',
    'Government/Military/Public Service',
    'Accounting',
    'Admin/Human Resource',
    'Advertising/Marketing/PR',
    'Aerospace/Aviation',
    'Agricultural/Chemical/Forest Product',
    'Architecture',
    'Automotive/Manufacturing/Production',
    'Building/Construction',
    'Computers/Electronics',
    'Convenience Store',
    'Electronic Wholesaler/Retailer',
    'Education/Training',
    'Finance/Banking/Insurance',
    'Food and Beverages',
    'Hospitality/Tourism',
    'Investment',
    'Legal Services',
    'Medical/Health Services/Pharmaceuticals',
    'Recreational/Sports',
    'Shipping/Freight/Transportation/Logistics',
    'Telecommunications/Networking',
    'Trusts, Charities, NGO',
    'Others',
    'Art and Antique Dealer/Auctioneer',
    'Business/Employment type not furnished',
    'Entertainment',
    'Gambling/Gaming',
    'Jewellery/Precious Metal Dealer',
    'Leather Goods Store',
    'Liquor Store',
    'Mining',
    'Money Service Business',
    'Real Estate/Property'
]

export default industry
