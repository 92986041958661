export const createPaymentEndpointPath = '/payment/create'

const PaymentService = (api) => ({
    async create (accessToken, data) {
        api.setAuthToken(accessToken)
        const res = await api.post(createPaymentEndpointPath, data)
        return res
    }
})

export default PaymentService
