const summaryFieds = {
    signature: {
        label: 'Signature',
        inputType: 'SIGNATURE'
    },
    signatureAgreement: {
        label: '',
        inputType: 'CHECKBOX'
    }
}

export default summaryFieds
