import { string, object } from 'yup'

const schema = object().shape({
    signaturePath: string()
        .nullable()
        .required('Signature is a required field'),
    signatureAgreement: string()
        .nullable()
        .required('This field is a required field')
        .oneOf(['1'], 'This field is a required field')
})

export default schema
