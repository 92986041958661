import { useState, useEffect } from 'react'
import qs from 'qs'
import { inject } from 'mobx-react'
import { Spin, Modal } from 'antd'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Layout from '../modules/application/components/templates/layout'
import styles from '../modules/application/components/status/ApplicationStatus.module.scss'
import detectIsMobile from '../helpers/detectIsMobile'
import config from 'config'

const EkycStatusPage = props => {
    const history = useHistory()
    const location = useLocation()
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    const isLoadedFromIframe = window !== window.parent
    const isMobile = detectIsMobile()
    const [verifying, setVerifying] = useState(true)
    const [isValid, setIsValid] = useState(false)
    const [data, setData] = useState(null)

    if (!query?.refId) {
        history.replace('/')
        return (<div />)
    }

    useEffect(() => {
    }, [])

    useEffect(() => {
        if (query?.refId) {
            getEkycStatus(query.refId)
        }
    }, [query?.refId])

    const getEkycStatus = async (refId) => {
        try {
            setVerifying(true)
            const res = await props.services.applicationService.getApplicationEkycStatus(refId)

            if (res.ok) {
                setIsValid(true)
                setData(res.data?.data)
            } else {
                setIsValid(false)
                Modal.info({
                    title: 'Invalid Link',
                    content: (
                        <p>
                            Looks like the ekyc status link you clicked on is invalid.
                        </p>
                    ),
                    onOk: () => {
                        history.push('/')
                    }
                })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setVerifying(false)
        }
    }

    return (
        <>
            {verifying &&
            <div className='flex flex-col w-full h-screen justify-center content-center place-content-center'>
                <Spin size='large' />
                <p className='text-lg mt-2 text-center text-blue-400'>Verifying ...</p>
            </div>}

            {!verifying && isValid &&
            <Layout hideBtnNewApplication>
                <div className='container mx-auto text-center'>
                    <div className={styles.wrapper}>
                        {(data.ekycStatus === 'NEW' ||
                        data.ekycStatus === 'PENDING' ||
                        data.ekycStatus === 'DOCUMENT_SUBMITTED' ||
                        data.ekycStatus === 'SELFIE_SUBMITTED') &&
                        <>
                            <div className={styles.title}>
                                Your identity verification is still in progress
                            </div>
                        </>}

                        {data?.ekycStatus === 'COMPLETED' &&
                        <>
                            <div className={styles.title}>
                                You have completed the identity verification
                            </div>

                            <p className={styles.description}>
                                Thank you for completing the identity verification, our team is working around the clock to get your trading and CDS account open, so fasten your seatbelt and get ready for the ride soon.
                            </p>

                            {!isLoadedFromIframe && isMobile &&
                            <p className={styles.description}>
                                Please return to your desktop & <br /> Press on <b className='text-light-blue'>"I've Completed Verification"</b> button.
                            </p>}

                            {isLoadedFromIframe &&
                            <>
                                <p className={styles.description}>
                                    You can now close the verification by clicking the <b className='text-light-blue'>"Close"</b> button now
                                </p>

                                <button
                                    type='button'
                                    className='btn-primary-full px-4 py-2 rounded font-weight-bold block mt-3 mx-auto'
                                    onClick={() => {
                                        const targetOrigin = config.app.appUrl

                                        window.parent.postMessage({
                                            source: 'btn-close-click'
                                        }, targetOrigin)
                                    }}
                                >
                                    Close
                                </button>
                            </>}
                        </>}

                        {data?.ekycStatus === 'FAILED' &&
                        <>
                            <div className={styles.title}>
                                Failed eKYC (Identity Verification)
                            </div>

                            <p className={styles.description}>
                                We’re sorry to inform you that your eKYC (Identity Verification) was not successful.
                            </p>

                            {!isLoadedFromIframe && isMobile &&
                            <p className={styles.description}>
                                Please return to your desktop & <br /> Press on <b className='text-light-blue'>"X"</b> button.
                            </p>}

                            {isLoadedFromIframe &&
                            <>
                                <p className={styles.description}>
                                    You can now close the verification by clicking the <b className='text-light-blue'>"Close"</b> button now
                                </p>

                                <button
                                    type='button'
                                    className='btn-primary-full px-4 py-2 rounded font-weight-bold block mt-3 mx-auto'
                                    onClick={() => {
                                        const targetOrigin = config.app.appUrl

                                        window.parent.postMessage({
                                            source: 'btn-close-click'
                                        }, targetOrigin)
                                    }}
                                >
                                    Close
                                </button>
                            </>}
                        </>}
                    </div>
                </div>
            </Layout>}
        </>
    )
}

export default inject('services')(EkycStatusPage)
