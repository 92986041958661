import { useState, useEffect } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styles from '../ApplicationStatus.module.scss'

const ApplicationStatusAmendWithDocument = props => {
    useEffect(() => {
    }, [])

    return (
        <div className={cn(styles.wrapper, props.className)}>
            <div className={styles.title}>
                We’re almost there. Just some final adjustments
            </div>
            <p className={styles.description}>
                Kindly refer to your registered email for amendments that are required.
            </p>

            <div className={styles.callToAction}>
                <div className={styles.message}>
                    Click the button below to edit your details
                </div>
                <Link className='btn-primary-full px-4 py-2 rounded font-weight-bold' to='/cds-trading-account-registration'>
                    Go to Form
                </Link>
            </div>
        </div>
    )
}

ApplicationStatusAmendWithDocument.propTypes = {
    className: PropTypes.string
}

ApplicationStatusAmendWithDocument.defaultProps = {
}

export default ApplicationStatusAmendWithDocument
