import { useState, useEffect } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Spin } from 'antd'
import _ from 'lodash'

const AdminPrivateRoute = ({ children, adminStore, services, ...restProps }) => {
    const location = useLocation()
    const [isAuthed, setIsAuthed] = useState(false)
    const [checking, setChecking] = useState(true)

    useEffect(async () => {
        if (adminStore?.initiated) {
            if (adminStore?.accessToken) {
                validateAccessToken(adminStore?.accessToken)
            } else {
                setChecking(false)
                setIsAuthed(false)
            }
        }
    }, [location, adminStore.initiated])

    const validateAccessToken = async (accessToken) => {
        try {
            setChecking(true)
            const res = await services.adminService.validateAccessToken(accessToken)

            if (res.ok) {
                adminStore.setData({
                    fullName: res.data?.fullName,
                    accessToken: accessToken
                })
                setIsAuthed(true)
            } else {
                setIsAuthed(false)
            }
        } catch (error) {
            setIsAuthed(false)
            console.error('Failed to verify admin access token', error)
        } finally {
            setChecking(false)
        }
    }

    return (
        <Route
            {...restProps}
            render={() => {
                if (checking) {
                    return (
                        <div className='flex flex-col w-full h-screen justify-center content-center place-content-center'>
                            <Spin size='large' />
                        </div>
                    )
                } else {
                    if (isAuthed) {
                        return children
                    } else {
                        return <Redirect to='/' />
                    }
                }
            }}
        />
    )
}

AdminPrivateRoute.propTypes = {
}

AdminPrivateRoute.defaultProps = {
}

export default inject('adminStore', 'services')(observer(AdminPrivateRoute))
