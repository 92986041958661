import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import SelectInput from './SelectInput'

const SelectInputWithController = React.forwardRef((props, ref) => {
    const [disabled, setDisabled] = useState(props.disabled)
    const { unregister, setValue } = useFormContext()

    useEffect(() => {
        return () => {
            unregister(props.name)
        }
    }, [])

    useEffect(() => {
        setDisabled(props.disabled)
    }, [props.disabled])

    useEffect(() => {
        if (!disabled) {
            // setValue(props.name, props.defaultValue)
        } else {
            unregister(props.name)
        }
    }, [disabled])

    const defaultValue = useMemo(() => {
        let defaultValue = props.defaultValue

        if (props.onSetDefaultValue) {
            defaultValue = props.onSetDefaultValue(defaultValue)
        }

        return defaultValue
    }, [props.defaultValue])

    return (
        <>
            {!disabled &&
            <Controller
                name={props.name}
                defaultValue={defaultValue}
                render={({ field: { value, onChange, ref } }) => {
                    return (
                        <SelectInput
                            ref={ref}
                            label={props.label}
                            placeholder={props.placeholder}
                            disabled={disabled}
                            disabledBtnEdit={props.disabledBtnEdit}
                            disabledBtnCancel={props.disabledBtnCancel}
                            ableToToggleDisabled={props.ableToToggleDisabled}
                            onToggleDisabled={(disabled) => {
                                if (disabled) {
                                    setDisabled(true)
                                }
                                props.onToggleDisabled(disabled)
                            }}
                            onChange={(e) => {
                                onChange(e)
                                props.onChange(e)
                            }}
                            value={value}
                            error={props.error}
                        >
                            {props.children}
                        </SelectInput>
                    )
                }}
            />}

            {disabled &&
            <SelectInput
                label={props.label}
                disabled={disabled}
                ableToToggleDisabled={props.ableToToggleDisabled}
                onToggleDisabled={(disabled) => {
                    setDisabled(false)
                    props.onToggleDisabled(false)
                }}
                value={defaultValue}
            >
                {props.children}
            </SelectInput>}
        </>
    )
})

SelectInputWithController.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    name: PropTypes.string,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.object,
    onChange: PropTypes.func,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    disabledBtnEdit: PropTypes.bool,
    disabledBtnCancel: PropTypes.bool,
    ableToToggleDisabled: PropTypes.bool,
    onToggleDisabled: PropTypes.func,
    onSetDefaultValue: PropTypes.func
}

SelectInputWithController.defaultProps = {
    disabled: false,
    disableBtnEdit: false,
    disableBtnCancel: false,
    ableToToggleDisabled: false,
    onToggleDisabled: () => {},
    onChange: () => {}
}

export default SelectInputWithController
