import { useState, useEffect } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import styles from './Checklist.module.scss'

const Checklist = props => {
    return (
        <ul className={cn(styles.wrapper, props.className)}>
            {props.data.map((item, index) =>
                <li key={index}>
                    {item}
                </li>
            )}
        </ul>
    )
}

Checklist.propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.string)
}

Checklist.defaultProps = {
}

export default Checklist
