import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import TextInput from './TextInput'

const TextInputWithController = React.forwardRef((props, ref) => {
    const [disabled, setDisabled] = useState(props.disabled)
    const { unregister, setValue } = useFormContext()

    useEffect(() => {
        return () => {
            unregister(props.name)
        }
    }, [])

    useEffect(() => {
        setDisabled(props.disabled)
    }, [props.disabled])

    useEffect(() => {
        if (!disabled) {
            setValue(props.name, props.defaultValue)
        } else {
            unregister(props.name)
        }
    }, [disabled])

    return (
        <>
            {!disabled &&
            <Controller
                name={props.name}
                defaultValue={props.defaultValue}
                render={({ field: { value, onChange, ref } }) => {
                    return (
                        <TextInput
                            ref={ref}
                            className={props.className}
                            label={props.label}
                            placeholder={props.placeholder}
                            disabled={disabled}
                            disabledBtnEdit={props.disabledBtnEdit}
                            disabledBtnCancel={props.disabledBtnCancel}
                            ableToToggleDisabled={props.ableToToggleDisabled}
                            onToggleDisabled={(disabled) => {
                                if (disabled) {
                                    setDisabled(true)
                                }
                                props.onToggleDisabled(disabled)
                            }}
                            onChange={(e) => {
                                onChange(e.target.value)
                                props.onChange(e)
                            }}
                            value={value}
                            error={props.error}
                            addOn={props.addOn}
                        />
                    )
                }}
            />}

            {disabled &&
            <TextInput
                label={props.label}
                className={props.className}
                disabled={disabled}
                ableToToggleDisabled={props.ableToToggleDisabled}
                onToggleDisabled={(disabled) => {
                    setDisabled(false)
                    props.onToggleDisabled(false)
                }}
                value={props.defaultValue}
                error={props.error}
                addOn={props.addOn}
            />}
        </>
    )
})

TextInputWithController.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.string,
    error: PropTypes.object,
    disabled: PropTypes.bool,
    disabledBtnEdit: PropTypes.bool,
    disabledBtnCancel: PropTypes.bool,
    ableToToggleDisabled: PropTypes.bool,
    onToggleDisabled: PropTypes.func,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    defaultValue: PropTypes.string
}

TextInputWithController.defaultProps = {
    disabled: false,
    disableBtnEdit: false,
    disableBtnCancel: false,
    ableToToggleDisabled: false,
    onToggleDisabled: () => {},
    onChange: () => {}
}

export default React.memo(TextInputWithController)
