import './App.css'
import { HashRouter, BrowserRouter, Switch, Route, useLocation } from 'react-router-dom'
import ApplicationPublicRoute from './modules/application/components/route/public'
import ApplicationPrivateRoute from './modules/application/components/route/private'
import HomePage from './pages/HomePage'
import EmailSentPage from './pages/EmailSentPage'
import EmailVerificationPage from './pages/EmailVerificationPage'
import StatusPage from './pages/StatusPage'
import ResumePage from './pages/ResumePage'
import CompletePersonalProfilePage from './pages/CompletePersonalProfilePage'
import CompleteEmploymentAndSpouseProfilePage from './pages/CompleteEmploymentAndSpouseProfilePage'
import CompleteFinancialProfilePage from './pages/CompleteFinancialProfilePage'
import CompleteTaxationAndDeclarationProfilePage from './pages/CompleteTaxationAndDeclarationProfilePage'
import ConfirmSummaryPage from './pages/ConfirmSummaryPage'
import AdminApplicationPage from './pages/admin/application'
import EkycStatusPage from './pages/EkycStatusPage'
import AsyncStorage from '@callstack/async-storage'
import { types, onPatch, onSnapshot, applySnapshot } from 'mobx-state-tree'
import { Provider, observer, inject } from 'mobx-react'
import { asReduxStore, connectReduxDevtools } from 'mst-middlewares'
import { RootStore } from './models/root'
import { useEffect } from 'react'
import * as _ from 'lodash'
import config from 'config'
import TagManager from 'react-gtm-module'
import DiligencePage from './pages/DiligencePage'
import { closeAllSystemNotice, displaySystemNotice } from './helpers/SystemNotice'
import ProfileUpdateRequestEmailSentPage from './pages/profile/update/request/ProfileUpdateRequestEmailSentPage'
import ProfileUpdateRequestPage from './pages/profile/update/request/ProfileUpdateRequestPage'
import SendProfileUpdateRequestPage from './pages/profile/update/request/SendProfileUpdateRequestPage'
import ProfileUpdateRequestEkycStatusPage from './pages/profile/update/request/ProfileUpdateRequestEkycStatusPage'
import generateAppContainer from './container'

TagManager.initialize({
    gtmId: config.app.googleTag.id,
    auth: config.app.googleTag.auth,
    preview: config.app.googleTag.preview
})

// connectReduxDevtools(require('remotedev'), store)

generateAppContainer.then((appContainer) => {
    onSnapshot(appContainer.store, snapshot => {
        if (snapshot.initiated) {
            const obj = { ...snapshot }
            obj.initiated = false
            if (!config.app.isProduction && !config.app.isTesting) {
                console.log('Write Data', obj)
            }
            AsyncStorage.setItem('rootStore', JSON.stringify(obj))
        }
    })

    onSnapshot(appContainer.adminStore, snapshot => {
        if (snapshot.initiated) {
            if (!config.app.isProduction && !config.app.isTesting) {
                console.log('Write Admin Data', snapshot)
            }
            AsyncStorage.setItem('adminStore', JSON.stringify(snapshot))
        }
    })
})

const App = (props) => {
    const location = useLocation()

    useEffect(async () => {
        const jsonStr = await AsyncStorage.getItem('rootStore')
        if (jsonStr) {
            // console.log('loadFromLocalStore', jsonStr)
            const obj = JSON.parse(jsonStr)
            const obj2 = _.clone(obj)
            delete obj.step1
            delete obj.step2
            delete obj.step3
            delete obj.step4
            delete obj.ekyc
            if (obj.partner) {
                delete obj.partner
            }

            const loadedStore = RootStore.create(obj)

            if (loadedStore) {
                applySnapshot(props.container.store, loadedStore)
                props.container.store.setStep1Data(obj2.step1)
                props.container.store.setStep2Data(obj2.step2)
                props.container.store.setStep3Data(obj2.step3)
                props.container.store.setStep4Data(obj2.step4)
                props.container.store.setEkyc(obj2.ekyc)
                if (obj2.partner) {
                    props.container.store.setPartner(obj2.partner)
                }
                props.container.store.init()
            }
        } else {
            props.container.store.init()
        }

        const adminJsonStr = await AsyncStorage.getItem('adminStore')
        if (adminJsonStr) {
            const admin = JSON.parse(adminJsonStr)
            applySnapshot(props.container.adminStore, admin)
            props.container.adminStore.init()
        } else {
            props.container.adminStore.init()
        }

        closeAllSystemNotice()
        displaySystemNotice()
    }, [])

    useEffect(() => {
        const hasAdminApplicationPath = location.hash.includes('admin/application')
        if (!hasAdminApplicationPath) {
            window.open('https://global.mplusonline.com/mplus/passport/login?_scnl=HSKE', '_self')
        }
    }, [location])

    return (
        <Provider store={props.container.store} adminStore={props.container.adminStore} services={props.container.services}>
            <BrowserRouter>
                <Switch>
                    <Route exact path='/'>
                        <HashRouter>
                            <Switch>
                                <ApplicationPublicRoute restricted exact path='/' key='home'>
                                    <HomePage />
                                </ApplicationPublicRoute>
                                <ApplicationPublicRoute restricted exact path='/email-sent' key='email-sent'>
                                    <EmailSentPage />
                                </ApplicationPublicRoute>
                                <ApplicationPublicRoute exact path='/email-verification' key='email-verification'>
                                    <EmailVerificationPage />
                                </ApplicationPublicRoute>
                                <ApplicationPublicRoute exact path='/profile-update-request-email-sent' key='profile-update-request-email-sent'>
                                    <ProfileUpdateRequestEmailSentPage />
                                </ApplicationPublicRoute>
                                <ApplicationPublicRoute exact path='/send-profile-update-request' key='send-profile-update-request'>
                                    <SendProfileUpdateRequestPage />
                                </ApplicationPublicRoute>
                                <ApplicationPublicRoute exact path='/profile-update-request' key='profile-update-request'>
                                    <ProfileUpdateRequestPage />
                                </ApplicationPublicRoute>
                                <ApplicationPublicRoute exact path='/profile-update-request/ekyc-status' key='profile-update-request-ekyc-status'>
                                    <ProfileUpdateRequestEkycStatusPage />
                                </ApplicationPublicRoute>
                                <ApplicationPublicRoute exact path='/diligence' key='diligence'>
                                    <DiligencePage />
                                </ApplicationPublicRoute>
                                <ApplicationPublicRoute exact path='/resume' key='resume'>
                                    <ResumePage />
                                </ApplicationPublicRoute>
                                <ApplicationPrivateRoute exact path='/status' key='status'>
                                    <StatusPage />
                                </ApplicationPrivateRoute>
                                <ApplicationPublicRoute exact path='/ekyc-status' key='ekyc-status'>
                                    <EkycStatusPage />
                                </ApplicationPublicRoute>
                                <ApplicationPrivateRoute exact path='/cds-trading-account-registration'>
                                    <CompletePersonalProfilePage />
                                </ApplicationPrivateRoute>
                                <ApplicationPrivateRoute exact path='/employment-and-spouse-details' key='employment-and-spouse-details'>
                                    <CompleteEmploymentAndSpouseProfilePage />
                                </ApplicationPrivateRoute>
                                <ApplicationPrivateRoute exact path='/financial-profile' key='financial-profile'>
                                    <CompleteFinancialProfilePage />
                                </ApplicationPrivateRoute>
                                <ApplicationPrivateRoute exact path='/taxation-and-declarations' key='taxation-and-declarations'>
                                    <CompleteTaxationAndDeclarationProfilePage />
                                </ApplicationPrivateRoute>
                                <ApplicationPrivateRoute exact path='/summary' key='summary'>
                                    <ConfirmSummaryPage />
                                </ApplicationPrivateRoute>
                                <Route path='/admin/application'>
                                    <AdminApplicationPage />
                                </Route>
                            </Switch>
                        </HashRouter>
                    </Route>
                </Switch>
            </BrowserRouter>
        </Provider>
    )
}

export default App
