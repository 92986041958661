import { useState, useEffect } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styles from '../EKYCStatus.module.scss'

const EKYCStatusCompleted = props => {
    useEffect(() => {
    }, [])

    return (
        <div className={cn(styles.wrapper, props.className, '')}>
            <div className={styles.title}>
                You have completed the identity verification
            </div>

            <p className='text-lg oxygen-font-weight-bold mb-3'>
                Dear {props.fullName}
            </p>

            <p className={styles.description}>
                Thank you for completing the identity verification, our team is working around the clock to get your trading and CDS account open, so fasten your seatbelt and get ready for the ride soon.
            </p>

            <p className={styles.description}>
                Upon successful application, you will receive an email with your account details and instructions to get the account activated from <a className='text-blue-400' href='mailto:support@mplusonline.com.my'>support@mplusonline.com.my</a>
            </p>

            <p className={styles.description}>
                If you do require any assistance, you can visit our Website, Facebook Page or have a chat with SITI (our ChatBot).
            </p>

            <p className={styles.description}>
                Thank you once again for choosing M+ Online.
            </p>

            <Link className='btn-primary-full px-4 py-2 rounded font-weight-bold' to='/cds-trading-account-registration'>
                View Submitted Info
            </Link>
        </div>
    )
}

EKYCStatusCompleted.propTypes = {
    className: PropTypes.string,
    fullName: PropTypes.string.isRequired
}

EKYCStatusCompleted.defaultProps = {
}

export default EKYCStatusCompleted
