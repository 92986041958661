export const fetchPartnerEndpointPath = '/partner/fetch'

const PartnerService = (api) => ({
    async getById (partnerId) {
        const res = await api.post(fetchPartnerEndpointPath, {
            partnerId
        })
        return res
    }
})

export default PartnerService
