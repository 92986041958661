import { string, object, ref } from 'yup'

const schema = object().shape({
    otp: string()
        .nullable()
        .length(6, 'OTP characters must equal to 6')
        .required('OTP is a required field')
})

export default schema
