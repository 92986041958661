import { useState, useEffect, useRef } from 'react'
import { Col, DatePicker, Form, Input, Radio, Row, Select, Switch, TreeSelect, message } from 'antd'
import CountryPhoneInput, { ConfigProvider, defaultAreas } from 'antd-country-phone-input'
import en from 'world_countries_lists/data/en/world.json'
import { inject, observer } from 'mobx-react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import SubmitButton from '../../../../../../components/button/submit'
import Button from '../../../../../../components/button'
import { raceListExcludeOtherBumi } from '../../../../../../models/step1'
import countries, { highRiskCountryCodes, nonHighRiskCountries } from '../../../../../../models/countries'
import nationalities, { highRiskNationalityCodes, nonHighRiskNationalities } from '../../../../../../models/nationalities'
import malaysiaStates from '../../../../../../constants/malaysiaStates'
import FormError from '../../../../../../components/form/error'
import { drTooltipTitle, drCodeTooltipTitle, mrCodeTooltipTitle, referralCodeTooltipTitle } from '../../../../components/tooltip'
import FileUploader from '../../../../../../components/file/uploader'
import focusErrorInput from '../../../../../../helpers/focusErrorInput'
import schema from './schema'

const { Option } = Select

const CompletePersonalProfileForm = props => {
    const refFormWrapper = useRef(null)
    const step = props.store.step1
    const isAllFieldsDisabled = (props.store?.applicationStatus !== 'EMAIL_VERIFIED' && props.store?.applicationStatus !== 'AMEND' && props.store?.applicationStatus !== 'AMEND_WITH_DOCUMENT') || props.isLocked
    const [submitting, setSubmitting] = useState(false)
    const [apiErrorMessages, setApiErrorMessages] = useState([])
    const phoneCountryCode = (!step?.contactPrefix) ? 'MY' : defaultAreas.filter((itm) => itm.phoneCode.toString() === step?.contactPrefix)[0].short
    const { handleSubmit, setValue, setFocus, setError, control, trigger, watch, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        context: {
            sameAddress: step?.sameAddress,
            applicationStatus: props.store?.applicationStatus
        },
        defaultValues: step.formData
    })

    useEffect(() => {
    }, [])

    const onSubmit = async () => {
        try {
            setSubmitting(true)
            setApiErrorMessages([])
            const res = await step?.submit(props.store.accessToken)

            if (res.ok) {
                if (props.onSuccessSubmit) props.onSuccessSubmit()
            } else if (res.status === 422) {
                if (res.data?.errors) {
                    const inputFields = Object.keys(watch())
                    const errors = res.data.errors
                    const { nonErrorFieldMessages } = focusErrorInput(inputFields, errors, setFocus, setError, ['contactPrefix', 'contactNumber'])
                    setApiErrorMessages(nonErrorFieldMessages)
                    if (nonErrorFieldMessages.length !== 0) refFormWrapper.current.scrollIntoView({ behavior: 'smooth' })
                }
            } else {
                setApiErrorMessages(['Something went wrong, please try again later'])
                refFormWrapper.current.scrollIntoView({ behavior: 'smooth' })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setSubmitting(false)
        }
    }

    const handleDocumentBeforeUpload = (file) => {
        const fileFormatOk = file.type === 'image/jpeg' || file.type === 'image/png'
        if (!fileFormatOk) {
            message.error('You can only upload JPG/PNG file!')
        }
        const maxFileSize = (file.type === 'image/jpeg') ? 1 : 0.7 // MB
        const isSizeValid = file.size / 1024 / 1024 < maxFileSize
        const fileSizeErrorMessage = `Document must be smaller than ${maxFileSize}MB!`
        if (!isSizeValid) {
            message.error(fileSizeErrorMessage)
        }
        return fileFormatOk && isSizeValid
    }

    const uploadAmendDocument = async (options, isBack) => {
        const { onSuccess, onError, file } = options
        const formData = new FormData()
        formData.append('path', file)
        formData.append('isBack', isBack)
        try {
            const res = await step?.uploadAmendDocument(props.store.accessToken, formData)

            if (res.ok) {
                console.log(res)
                onSuccess(res.data)
            } else {
                onError()
            }
        } catch (error) {
            console.error(error)
            onError()
        }
    }

    return (
        <div ref={refFormWrapper}>
            <Form
                className={cn(props.className, 'w-full complete-personal-profile-form')}
                layout='vertical'
                onFinish={handleSubmit(onSubmit)}
            >
                {apiErrorMessages.length !== 0 &&
                <FormError className='mb-5' messages={apiErrorMessages} />}

                <fieldset disabled={isAllFieldsDisabled}>
                    <Row gutter={24}>
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='mb-4 quesTitle'
                                label='Do you have a CDS account on Bursa Anywhere?'
                                validateStatus={(errors?.hasBaAccount?.message) ? 'error' : '-'}
                                help={errors?.hasBaAccount?.message}
                            >
                                <Controller
                                    name='hasBaAccount'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Radio.Group
                                            name='hasBaAccount'
                                            onChange={(e) => {
                                                onChange(e.target.value)
                                                step?.setHasBaAccount(e.target.value === 1)
                                            }}
                                            value={value}
                                        >
                                            <Radio value={1}>Yes</Radio>
                                            <Radio value={0}>No</Radio>
                                        </Radio.Group>
                                    )}
                                />
                            </Form.Item>
                        </Col>

                        {step?.hasBaAccount &&
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='mb-4 quesTitle'
                                label='Did you select Malacca Securities as the ADA (Broker)?'
                                validateStatus={(errors?.hasBaMsecAccount?.message) ? 'error' : '-'}
                                help={errors?.hasBaMsecAccount?.message}
                            >
                                <Controller
                                    name='hasBaMsecAccount'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Radio.Group
                                            onChange={(e) => {
                                                onChange(e.target.value)
                                                step?.setHasBaMsecAccount(e.target.value === 1)
                                            }}
                                            value={value}
                                        >
                                            <Radio value={1}>Yes</Radio>
                                            <Radio value={0}>No</Radio>
                                        </Radio.Group>
                                    )}
                                />
                            </Form.Item>
                        </Col>}
                    </Row>

                    <h2 className='text-xl my-5 font-weight-bold'>
                        Account Details
                    </h2>

                    <Form.Item
                        className='mb-4 quesTitle'
                        label='Account Type'
                        validateStatus={(errors?.accountType?.message) ? 'error' : '-'}
                        help={errors?.accountType?.message}
                    >
                        <Controller
                            name='accountType'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <Radio.Group
                                    name='accountType'
                                    onChange={(e) => {
                                        onChange(e.target.value)
                                        step?.setData({ accountType: e.target.value })
                                        if (e.target.value === 'COLLATERISED') {
                                            setValue('nationality', 'MY')
                                            step.setNationality('MY')
                                        }
                                    }}
                                    value={value}
                                >
                                    <Radio value='CASH_UPFRONT'>Cash Upfront Account</Radio>
                                    <Radio value='ISLAMIC_CASH_UPFRONT'>Islamic Cash Upfront Account</Radio>
                                    <Radio value='COLLATERISED'>Collaterised Account</Radio>
                                </Radio.Group>
                            )}
                        />
                    </Form.Item>

                    <Form.Item
                        className='mb-4 quesTitle'
                        label='Username'
                        validateStatus={(errors?.username?.message) ? 'error' : '-'}
                        help={errors?.username?.message}
                    >
                        <Controller
                            name='username'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <Input
                                    ref={ref}
                                    name='username'
                                    className='input-text-full'
                                    onChange={(e) => {
                                        onChange(e.target.value)
                                        step?.setData({ username: e.target.value })
                                    }}
                                    value={value}
                                />
                            )}
                        />
                    </Form.Item>

                    <Form.Item
                        className='mb-4 quesTitle'
                        label="Do you have a preferred Dealer's Representative or Remiser?"
                        validateStatus={(errors?.hasPreferDR?.message) ? 'error' : '-'}
                        help={errors?.hasPreferDR?.message}
                        tooltip={{
                            title: drTooltipTitle,
                            placement: 'bottom'
                        }}
                    >
                        <Controller
                            name='hasPreferDR'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <Radio.Group
                                    name='hasPreferDR'
                                    onChange={(e) => {
                                        onChange(e.target.value)
                                        step?.setHasPreferDR(e.target.value === 1)
                                        if (e.target.value !== 1) {
                                            setValue('drCode', null)
                                            setValue('mrCode', null)
                                        }
                                    }}
                                    value={value}
                                >
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={0}>No</Radio>
                                </Radio.Group>
                            )}
                        />
                    </Form.Item>

                    {step?.hasPreferDR &&
                    <Form.Item
                        className='mb-4 quesTitle'
                        label='DR Code'
                        validateStatus={(errors?.drCode?.message) ? 'error' : '-'}
                        help={errors?.drCode?.message}
                        tooltip={{
                            title: drCodeTooltipTitle,
                            placement: 'bottom'
                        }}
                    >
                        <Controller
                            name='drCode'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <Input
                                    ref={ref}
                                    name='drCode'
                                    className='input-text-full'
                                    onChange={(e) => {
                                        onChange(e.target.value.toUpperCase())
                                        step?.setData({ drCode: e.target.value.toUpperCase() })
                                    }}
                                    value={value}
                                />
                            )}
                        />
                    </Form.Item>}

                    <Row gutter={24}>
                        {step?.hasPreferDR &&
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='mb-4 quesTitle'
                                label='MR Code (Optional)'
                                validateStatus={(errors?.mrCode?.message) ? 'error' : '-'}
                                help={errors?.mrCode?.message}
                                tooltip={{
                                    title: mrCodeTooltipTitle,
                                    placement: 'bottom'
                                }}
                            >
                                <Controller
                                    name='mrCode'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Input
                                            ref={ref}
                                            name='mrCode'
                                            className='input-text-full'
                                            onChange={(e) => {
                                                onChange(e.target.value.toUpperCase())
                                                step?.setData({ mrCode: e.target.value.toUpperCase() })
                                            }}
                                            value={value}
                                        />
                                    )}
                                />
                            </Form.Item>
                        </Col>}
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='mb-4 quesTitle'
                                label='Referral Code (Optional)'
                                validateStatus={(errors?.referralCode?.message) ? 'error' : '-'}
                                help={errors?.referralCode?.message}
                                tooltip={{
                                    title: referralCodeTooltipTitle,
                                    placement: 'bottom'
                                }}
                            >
                                <Controller
                                    name='referralCode'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Input
                                            ref={ref}
                                            name='referralCode'
                                            className='input-text-full'
                                            onChange={(e) => {
                                                onChange(e.target.value)
                                                step?.setData({ referralCode: e.target.value })
                                            }}
                                            value={value}
                                        />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <h2 className='text-xl my-5 font-weight-bold'>
                        Personal Particulars
                    </h2>

                    <Form.Item
                        className='mb-4 quesTitle'
                        label='Full Name (As Per NRIC)'
                        validateStatus={(errors?.fullName?.message) ? 'error' : '-'}
                        help={errors?.fullName?.message}
                    >
                        <Controller
                            name='fullName'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <Input
                                    ref={ref}
                                    name='fullName'
                                    className='input-text-full'
                                    onChange={(e) => {
                                        onChange(e.target.value)
                                        step?.setData({ fullName: e.target.value })
                                    }}
                                    value={value}
                                />
                            )}
                        />
                    </Form.Item>

                    <Row gutter={16} className=''>
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='mb-4 quesTitle'
                                label='Nationality'
                                validateStatus={(errors?.nationality?.message) ? 'error' : '-'}
                                help={errors?.nationality?.message}
                            >
                                <Controller
                                    name='nationality'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => {
                                        const isHighRiskNationality = highRiskNationalityCodes.includes(value)
                                        let highRiskNationality = null

                                        if (isHighRiskNationality) {
                                            highRiskNationality = _.find(nationalities, { code: value })
                                        }

                                        return (
                                            <Select
                                                ref={ref}
                                                name='nationality'
                                                dropdownClassName='nationality-dropdown'
                                                disabled={isAllFieldsDisabled || step?.accountType === 'COLLATERISED'}
                                                placeholder='Search to Select'
                                                optionFilterProp='children'
                                                showSearch
                                                value={(!isHighRiskNationality) ? value : highRiskNationality?.name}
                                                onChange={(e) => {
                                                    onChange(e)
                                                    if (e !== 'MY' || e !== 'MYPR') {
                                                        setValue('documentExpiry', null)
                                                    }
                                                    if (e === 'MYPR') {
                                                        setValue('race', 'OTHERS')
                                                        setValue('raceOther', 'Foreigner')
                                                        step?.setData({
                                                            race: 'OTHERS',
                                                            raceOther: 'Foreigner'
                                                        })
                                                    } else {
                                                        setValue('race', null)
                                                        setValue('raceOther', null)
                                                        step?.setData({
                                                            race: null,
                                                            raceOther: null
                                                        })
                                                    }
                                                    setValue('ekycDocumentType', null)
                                                    step.setData({ ekycDocumentType: null })
                                                    step?.setNationality(e)
                                                    trigger(['documentNumber', 'documentExpiry', 'race', 'raceOther', 'ekycDocumentType'])
                                                }}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                            >
                                                {nonHighRiskNationalities.map((itm) => <Option value={itm.code} key={itm.code}>{itm.name}</Option>)}
                                            </Select>
                                        )
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='mb-4 quesTitle'
                                label='Gender'
                                validateStatus={(errors?.gender?.message) ? 'error' : '-'}
                                help={errors?.gender?.message}
                            >
                                <Controller
                                    name='gender'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Select
                                            ref={ref}
                                            name='gender'
                                            dropdownClassName='gender-dropdown'
                                            disabled={isAllFieldsDisabled}
                                            placeholder='Select Gender'
                                            onChange={(e) => {
                                                onChange(e)
                                                step?.setData({ gender: e })
                                            }}
                                            value={value}
                                        >
                                            <Option value='MALE' key='gender-1'>Male</Option>
                                            <Option value='FEMALE' key='gender-2'>Female</Option>
                                        </Select>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='mb-4 quesTitle'
                                label='NRIC/Passport'
                                validateStatus={(errors?.documentNumber?.message) ? 'error' : '-'}
                                help={errors?.documentNumber?.message}
                            >
                                <Controller
                                    name='documentNumber'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Input
                                            ref={ref}
                                            name='documentNumber'
                                            className='input-text-full'
                                            onChange={(e) => {
                                                onChange(e)
                                                step?.setData({ documentNumber: e.target.value })
                                            }}
                                            value={value}
                                        />
                                    )}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='mb-4 quesTitle'
                                label='Document Type'
                                validateStatus={(errors?.ekycDocumentType?.message) ? 'error' : '-'}
                                help={errors?.ekycDocumentType?.message}
                            >
                                <Controller
                                    name='ekycDocumentType'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => {
                                        return (
                                            <Select
                                                ref={ref}
                                                name='ekycDocumentType'
                                                dropdownClassName='ekyc-document-type-dropdown'
                                                disabled={isAllFieldsDisabled}
                                                placeholder='Select Document Type'
                                                onChange={(e) => {
                                                    onChange(e)
                                                    step?.setData({ ekycDocumentType: e })
                                                }}
                                                value={value}
                                            >
                                                {(step?.nationality === 'MY' || step?.nationality === 'MYPR' || !step.nationality) &&
                                                <>
                                                    <Option value={1} key='mykad'>MyKad/NRIC</Option>
                                                    <Option value={2} key='mytentera'>MyTentera/ARMY</Option>
                                                    <Option value={5} key='mypr'>MyPR/PR of Malaysia</Option>
                                                    <Option value={6} key='mykas'>MyKas</Option>
                                                </>}

                                                {(step.nationality && step?.nationality !== 'MY' && step?.nationality !== 'MYPR') &&
                                                <>
                                                    <Option value={3} key='passport'>Passport</Option>
                                                </>}
                                            </Select>
                                        )
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        {(step?.nationality !== 'MY' && step?.nationality !== 'MYPR') &&
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='mb-4 quesTitle'
                                label='Passport Expiry Date'
                                validateStatus={(errors?.documentExpiry?.message) ? 'error' : '-'}
                                help={errors?.documentExpiry?.message}
                            >
                                <Controller
                                    name='documentExpiry'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <DatePicker
                                            ref={ref}
                                            className='input-text-full'
                                            format='DD-MM-YYYY'
                                            disabled={step?.nationality === 'MY' || isAllFieldsDisabled}
                                            onChange={(e) => {
                                                onChange(e)
                                                let documentExpiry = null
                                                if (e) documentExpiry = new Date(e.year(), e.month(), e.date())
                                                step?.setData({ documentExpiry })
                                            }}
                                            value={value}
                                        />
                                    )}
                                />
                            </Form.Item>
                        </Col>}

                        {(step?.nationality === 'MY' || step?.nationality === 'MYPR') &&
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='mb-4 quesTitle'
                                label='Old NRIC'
                                validateStatus={(errors?.otherDocumentNumber?.message) ? 'error' : '-'}
                                help={errors?.otherDocumentNumber?.message}
                            >
                                <Controller
                                    name='otherDocumentNumber'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Input
                                            ref={ref}
                                            name='otherDocumentNumber'
                                            disabled={step?.nationality === 'MYPR'}
                                            className='input-text-full'
                                            onChange={(e) => {
                                                onChange(e)
                                                step?.setData({ otherDocumentNumber: e.target.value })
                                            }}
                                            value={value}
                                        />
                                    )}
                                />
                            </Form.Item>
                        </Col>}
                    </Row>

                    {(props.store?.applicationStatus === 'AMEND_WITH_DOCUMENT' || step?.uploadedDocumentFrontPath || step?.uploadedDocumentBackPath) &&
                    <Row gutter={16}>
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='quesTitle'
                                label='Front Photo of NRIC/Passport'
                                validateStatus={(errors?.uploadedDocumentFrontPath?.message) ? 'error' : '-'}
                                help={errors?.uploadedDocumentFrontPath?.message}
                            >
                                <Controller
                                    name='uploadedDocumentFrontPath'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <FileUploader
                                            ref={ref}
                                            style={{ width: '100%' }}
                                            disabled={isAllFieldsDisabled}
                                            name='uploadedDocumentFrontPath'
                                            accept='image/png, image/jpeg'
                                            listType='picture-card'
                                            showUploadList={false}
                                            uploadedDocumentSrc={value}
                                            previewTitle='Front Photo of NRIC/Passport'
                                            customRequest={(options) => uploadAmendDocument(options, false)}
                                            beforeUpload={handleDocumentBeforeUpload}
                                            onChange={(info) => {
                                                const { status } = info.file
                                                if (status === 'done') {
                                                    const { url } = info.file.response
                                                    onChange(url)
                                                }
                                            }}
                                            onRemove={() => {
                                                onChange(null)
                                            }}
                                        />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='quesTitle'
                                label='Back Photo of NRIC/Passport'
                                validateStatus={(errors?.uploadedDocumentBackPath?.message) ? 'error' : '-'}
                                help={errors?.uploadedDocumentBackPath?.message}
                            >
                                <Controller
                                    name='uploadedDocumentBackPath'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <FileUploader
                                            ref={ref}
                                            style={{ width: '100%' }}
                                            disabled={isAllFieldsDisabled}
                                            name='uploadedDocumentBackPath'
                                            accept='image/png, image/jpeg'
                                            listType='picture-card'
                                            showUploadList={false}
                                            uploadedDocumentSrc={value}
                                            previewTitle='Back Photo of NRIC/Passport'
                                            customRequest={(options) => uploadAmendDocument(options, true)}
                                            beforeUpload={handleDocumentBeforeUpload}
                                            onChange={(info) => {
                                                const { status } = info.file
                                                if (status === 'done') {
                                                    const { url } = info.file.response
                                                    onChange(url)
                                                }
                                            }}
                                            onRemove={() => {
                                                onChange(null)
                                            }}
                                        />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>}

                    <Row gutter={16}>
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='mb-4 quesTitle'
                                label='Date of Birth'
                                validateStatus={(errors?.birthDate?.message) ? 'error' : '-'}
                                help={errors?.birthDate?.message}
                            >
                                <Controller
                                    name='birthDate'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <DatePicker
                                            ref={ref}
                                            name='birthDate'
                                            disabled={isAllFieldsDisabled}
                                            className='input-text-full'
                                            format='DD-MM-YYYY'
                                            onChange={(e) => {
                                                onChange(e)
                                                let birthDate = null
                                                if (e) birthDate = new Date(e.year(), e.month(), e.date())
                                                step?.setData({ birthDate })
                                                trigger(['otherDocumentNumber'])
                                            }}
                                            value={value}
                                        />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='mb-4 quesTitle'
                                label='Marital Status'
                                validateStatus={(errors?.maritalStatus?.message) ? 'error' : '-'}
                                help={errors?.maritalStatus?.message}
                            >
                                <Controller
                                    name='maritalStatus'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Select
                                            ref={ref}
                                            name='maritalStatus'
                                            dropdownClassName='martial-status-dropdown'
                                            disabled={isAllFieldsDisabled}
                                            placeholder='Select Marital Status'
                                            onChange={(e) => {
                                                onChange(e)
                                                step?.setData({ maritalStatus: e })
                                            }}
                                            value={value}
                                        >
                                            <Option value='SINGLE' key='marital-1'>Single</Option>
                                            <Option value='MARRIED' key='marital-2'>Married</Option>
                                            <Option value='DIVORCED' key='marital-3'>Divorced</Option>
                                            <Option value='WIDOWED' key='marital-4'>Widowed</Option>
                                        </Select>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {(step?.nationality === 'MY' || step?.nationality === 'MYPR') &&
                    <Row gutter={16}>
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='mb-4 quesTitle'
                                label='Race'
                                validateStatus={(errors?.race?.message) ? 'error' : '-'}
                                help={errors?.race?.message}
                            >
                                <Controller
                                    name='race'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => {
                                        return (
                                            <TreeSelect
                                                ref={ref}
                                                name='race'
                                                dropdownClassName='race-dropdown'
                                                disabled={isAllFieldsDisabled || step?.nationality === 'MYPR'}
                                                treeData={raceListExcludeOtherBumi}
                                                placeholder='Select Race'
                                                treeDefaultExpandAll
                                                onChange={(e) => {
                                                    onChange(e)
                                                    if (e !== 'OTHERS') {
                                                        setValue('raceOther', null)
                                                    }
                                                    step?.setRace(e)
                                                }}
                                                value={(value !== 'OTHER_BUMIPUTRA') ? value : 'Other Bumiputra'}
                                            />
                                        )
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='quesTitle'
                                label='Others'
                                validateStatus={(errors?.raceOther?.message) ? 'error' : '-'}
                                help={errors?.raceOther?.message}
                            >
                                <Controller
                                    name='raceOther'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Input
                                            ref={ref}
                                            name='raceOther'
                                            className='input-text-full'
                                            disabled={step?.race !== 'OTHERS' || isAllFieldsDisabled || step?.nationality === 'MYPR'}
                                            onChange={(e) => {
                                                onChange(e)
                                                step?.setRaceOther(e.target.value)
                                            }}
                                            value={value}
                                        />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>}

                    <Row gutter={16}>
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='mb-4 quesTitle'
                                label='Mobile Number'
                                validateStatus={(errors?.contactNumber?.message) ? 'error' : '-'}
                                help={errors?.contactNumber?.message}
                            >
                                <Controller
                                    name='contactNumber'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <ConfigProvider
                                            locale={en}
                                            areaMapper={(area) => {
                                                return {
                                                    ...area,
                                                    emoji: <span className={`fp ${area.short.toLowerCase()}`} />
                                                }
                                            }}
                                        >
                                            <CountryPhoneInput
                                                disabled={isAllFieldsDisabled}
                                                className='contact'
                                                onChange={(e) => {
                                                    onChange(e.phone)
                                                    step?.setData({ contactNumber: e.phone, contactPrefix: e.code.toString() })
                                                }}
                                                value={{ short: phoneCountryCode, phone: step?.contactNumber }}
                                            />
                                        </ConfigProvider>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        className='mb-4 quesTitle'
                        label='Registered Address (as per NRIC)'
                        validateStatus={(errors?.address1?.message) ? 'error' : '-'}
                        help={errors?.address1?.message}
                    >
                        <Controller
                            name='address1'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <Input
                                    ref={ref}
                                    name='address1'
                                    className='input-text-full'
                                    onChange={(e) => {
                                        onChange(e.target.value)
                                        step?.setData({ address1: e.target.value })
                                    }}
                                    value={value}
                                />
                            )}
                        />
                    </Form.Item>

                    <Form.Item
                        className='mb-4 quesTitle'
                        validateStatus={(errors?.address2?.message) ? 'error' : '-'}
                        help={errors?.address2?.message}
                    >
                        <Controller
                            name='address2'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <Input
                                    ref={ref}
                                    name='address2'
                                    className='input-text-full'
                                    onChange={(e) => {
                                        onChange(e.target.value)
                                        step?.setData({ address2: e.target.value })
                                    }}
                                    value={value}
                                />
                            )}
                        />
                    </Form.Item>

                    <Form.Item
                        className='mb-4 quesTitle'
                        validateStatus={(errors?.address3?.message) ? 'error' : '-'}
                        help={errors?.address3?.message}
                    >
                        <Controller
                            name='address3'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <Input
                                    ref={ref}
                                    name='address3'
                                    className='input-text-full'
                                    onChange={(e) => {
                                        onChange(e.target.value)
                                        step?.setData({ address3: e.target.value })
                                    }}
                                    value={value}
                                />
                            )}
                        />
                    </Form.Item>

                    <Row gutter={16}>
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='mb-4 quesTitle'
                                label='Country'
                                validateStatus={(errors?.country?.message) ? 'error' : '-'}
                                help={errors?.country?.message}
                            >
                                <Controller
                                    name='country'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => {
                                        const isHighRiskCountry = highRiskCountryCodes.includes(value)
                                        let highRiskCountry = null

                                        if (isHighRiskCountry) {
                                            highRiskCountry = _.find(countries, { code: value })
                                        }

                                        return (
                                            <Select
                                                ref={ref}
                                                name='country'
                                                dropdownClassName='country-dropdown'
                                                disabled={isAllFieldsDisabled}
                                                placeholder='Search to Select'
                                                optionFilterProp='children'
                                                showSearch
                                                onChange={(e) => {
                                                    onChange(e)
                                                    step?.setCountry(e)
                                                    trigger(['state', 'city', 'postcode'])
                                                }}
                                                value={(!isHighRiskCountry) ? value : highRiskCountry?.name}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                            >
                                                {nonHighRiskCountries.map((itm) => <Option value={itm.code} key={itm.code}>{itm.name}</Option>)}
                                            </Select>
                                        )
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        {(step?.country === 'MY' || !step?.country) &&
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='quesTitle'
                                label='State'
                                validateStatus={(errors?.state?.message) ? 'error' : '-'}
                                help={errors?.state?.message}
                            >
                                <Controller
                                    name='state'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Select
                                            ref={ref}
                                            name='state'
                                            dropdownClassName='state-dropdown'
                                            disabled={isAllFieldsDisabled}
                                            placeholder='Search to Select'
                                            optionFilterProp='children'
                                            showSearch
                                            onChange={(e) => {
                                                onChange(e)
                                                step?.setState(e)
                                            }}
                                            value={value}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                        >
                                            {malaysiaStates.map((itm) => <Option value={itm} key={itm}>{itm}</Option>)}
                                        </Select>
                                    )}
                                />
                            </Form.Item>
                        </Col>}
                    </Row>

                    {(step?.country === 'MY' || !step?.country) &&
                    <Row gutter={16}>
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='mb-4 quesTitle'
                                label='City/Town'
                                validateStatus={(errors?.city?.message) ? 'error' : '-'}
                                help={errors?.city?.message}
                            >
                                <Controller
                                    name='city'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Input
                                            ref={ref}
                                            name='city'
                                            className='input-text-full'
                                            onChange={(e) => {
                                                onChange(e.target.value)
                                                step?.setData({ city: e.target.value })
                                            }}
                                            value={value}
                                        />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='mb-4 quesTitle'
                                label='Postcode'
                                validateStatus={(errors?.postcode?.message) ? 'error' : '-'}
                                help={errors?.postcode?.message}
                            >
                                <Controller
                                    name='postcode'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Input
                                            ref={ref}
                                            name='postcode'
                                            className='input-text-full'
                                            onChange={(e) => {
                                                onChange(e.target.value)
                                                step?.setData({ postcode: e.target.value })
                                            }}
                                            value={value}
                                        />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>}

                    <Row className='mb-4'>
                        <Col xs={24} span={24}>
                            <Switch
                                id='same-address-switch'
                                disabled={isAllFieldsDisabled}
                                defaultChecked={step?.sameAddress}
                                onChange={(checked) => step?.setSameAddress(checked)}
                                style={{ marginRight: 10 }}
                                size='small'
                            />
                            Correspondence/Mailing address is same as above
                        </Col>
                    </Row>

                    {!step?.sameAddress &&
                    <>
                        <Form.Item
                            className='mb-4 quesTitle'
                            label='Correspondence Address'
                            validateStatus={(errors?.mailingAddress1?.message) ? 'error' : '-'}
                            help={errors?.mailingAddress1?.message}
                        >
                            <Controller
                                name='mailingAddress1'
                                control={control}
                                render={({ field: { value, onChange, ref } }) => (
                                    <Input
                                        ref={ref}
                                        name='mailingAddress1'
                                        className='input-text-full'
                                        onChange={(e) => {
                                            onChange(e.target.value)
                                            step?.setData({ mailingAddress1: e.target.value })
                                        }}
                                        value={value}
                                    />
                                )}
                            />
                        </Form.Item>

                        <Form.Item
                            className='mb-4 quesTitle'
                            validateStatus={(errors?.mailingAddress2?.message) ? 'error' : '-'}
                            help={errors?.mailingAddress2?.message}
                        >
                            <Controller
                                name='mailingAddress2'
                                control={control}
                                render={({ field: { value, onChange, ref } }) => (
                                    <Input
                                        ref={ref}
                                        name='mailingAddress2'
                                        className='input-text-full'
                                        onChange={(e) => {
                                            onChange(e.target.value)
                                            step?.setData({ mailingAddress2: e.target.value })
                                        }}
                                        value={value}
                                    />
                                )}
                            />
                        </Form.Item>

                        <Form.Item
                            className='mb-4 quesTitle'
                            validateStatus={(errors?.mailingAddress3?.message) ? 'error' : '-'}
                            help={errors?.mailingAddress3?.message}
                        >
                            <Controller
                                name='mailingAddress3'
                                control={control}
                                render={({ field: { value, onChange, ref } }) => (
                                    <Input
                                        ref={ref}
                                        name='mailingAddress3'
                                        className='input-text-full'
                                        onChange={(e) => {
                                            onChange(e.target.value)
                                            step?.setData({ mailingAddress3: e.target.value })
                                        }}
                                        value={value}
                                    />
                                )}
                            />
                        </Form.Item>

                        <Row gutter={16}>
                            <Col xs={24} md={12} lg={12} span={12}>
                                <Form.Item
                                    className='mb-4 quesTitle'
                                    label='Country'
                                    validateStatus={(errors?.mailingCountry?.message) ? 'error' : '-'}
                                    help={errors?.mailingCountry?.message}
                                >
                                    <Controller
                                        name='mailingCountry'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => {
                                            const isHighRiskCountry = highRiskCountryCodes.includes(value)
                                            let highRiskCountry = null

                                            if (isHighRiskCountry) {
                                                highRiskCountry = _.find(countries, { code: value })
                                            }

                                            return (
                                                <Select
                                                    ref={ref}
                                                    name='mailingCountry'
                                                    dropdownClassName='mailing-country-dropdown'
                                                    disabled={isAllFieldsDisabled}
                                                    placeholder='Search to Select'
                                                    optionFilterProp='children'
                                                    showSearch
                                                    onChange={(e) => {
                                                        onChange(e)
                                                        step?.setMailingCountry(e)
                                                        trigger(['mailingState', 'mailingCity', 'mailingPostcode'])
                                                    }}
                                                    value={value}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                                >
                                                    {nonHighRiskCountries.map((itm) => <Option value={itm.code} key={itm.code}>{itm.name}</Option>)}
                                                </Select>
                                            )
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            {(step?.mailingCountry === 'MY' || !step?.mailingCountry) &&
                            <Col xs={24} md={12} lg={12} span={12}>
                                <Form.Item
                                    className='quesTitle'
                                    label='State'
                                    validateStatus={(errors?.mailingState?.message) ? 'error' : '-'}
                                    help={errors?.mailingState?.message}
                                >
                                    <Controller
                                        name='mailingState'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Select
                                                ref={ref}
                                                name='mailingState'
                                                dropdownClassName='mailing-state-dropdown'
                                                disabled={isAllFieldsDisabled}
                                                placeholder='Search to Select'
                                                optionFilterProp='children'
                                                showSearch
                                                onChange={(e) => {
                                                    onChange(e)
                                                    step?.setMailingState(e)
                                                }}
                                                value={value}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                            >
                                                {malaysiaStates.map((itm) => <Option value={itm} key={itm}>{itm}</Option>)}
                                            </Select>
                                        )}
                                    />
                                </Form.Item>
                            </Col>}
                        </Row>

                        {(step?.mailingCountry === 'MY' || !step?.mailingCountry) &&
                        <Row gutter={16}>
                            <Col xs={24} md={12} lg={12} span={12}>
                                <Form.Item
                                    className='mb-4 quesTitle'
                                    label='City/Town'
                                    validateStatus={(errors?.mailingCity?.message) ? 'error' : '-'}
                                    help={errors?.mailingCity?.message}
                                >
                                    <Controller
                                        name='mailingCity'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Input
                                                ref={ref}
                                                name='mailingCity'
                                                className='input-text-full'
                                                onChange={(e) => {
                                                    onChange(e.target.value)
                                                    step?.setData({ mailingCity: e.target.value })
                                                }}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={12} span={12}>
                                <Form.Item
                                    className='mb-4 quesTitle'
                                    label='Postcode'
                                    validateStatus={(errors?.mailingPostcode?.message) ? 'error' : '-'}
                                    help={errors?.mailingPostcode?.message}
                                >
                                    <Controller
                                        name='mailingPostcode'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Input
                                                ref={ref}
                                                name='mailingPostcode'
                                                className='input-text-full'
                                                onChange={(e) => {
                                                    onChange(e.target.value)
                                                    step?.setData({ mailingPostcode: e.target.value })
                                                }}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>}
                    </>}

                    {!isAllFieldsDisabled &&
                    <>
                        <SubmitButton
                            className='w-full mt-5 submit-btn'
                            label='Submit'
                            submitting={submitting}
                        />

                        <div className='flex justify-center gray my-3'>
                            By clicking continue I agree that all information provided are accurate.
                        </div>
                    </>}
                </fieldset>

                {isAllFieldsDisabled &&
                <Button
                    className='w-full mt-5'
                    label='Next'
                    onClick={props.onNext}
                />}
            </Form>
        </div>
    )
}

CompletePersonalProfileForm.propTypes = {
    className: PropTypes.string,
    onSuccessSubmit: PropTypes.func,
    onNext: PropTypes.func,
    isLocked: PropTypes.bool
}

CompletePersonalProfileForm.defaultProps = {
    isLocked: false
}

export default inject('store')(observer(CompletePersonalProfileForm))
