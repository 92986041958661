import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ApplicationStatusNew from '../../../../components/status/new'

const ApplicationCreatedEmailVerificationSentNotice = props => {
    useEffect(() => {
    }, [])

    return (
        <ApplicationStatusNew
            className={props.className}
            fullName={props.fullName}
            email={props.email}
        />
    )
}

ApplicationCreatedEmailVerificationSentNotice.propTypes = {
    className: PropTypes.string,
    fullName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
}

ApplicationCreatedEmailVerificationSentNotice.defaultProps = {
}

export default ApplicationCreatedEmailVerificationSentNotice
