import React, { useState, useEffect, useCallback } from 'react'
import { Form, Radio } from 'antd'
import { EditTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import cn from 'classnames'
import PropTypes from 'prop-types'
import inputStyles from '../Input.module.scss'

const RadioGroup = React.forwardRef((props, ref) => {
    const [disabled, setDisabled] = useState(props.disabled)

    useEffect(() => {
        setDisabled(props.disabled)
    }, [props.disabled])

    const handleBtnEditClick = useCallback(() => {
        if (props.disabledBtnEdit) return
        setDisabled(false)
        props.onToggleDisabled(false)
    })

    const handleBtnCancelEditClick = useCallback(() => {
        if (props.disabledBtnCancel) return
        setDisabled(true)
        props.onToggleDisabled(true)
    })

    return (
        <Form.Item
            className={cn('mb-4 quesTitle', inputStyles.wrapper, props.className)}
            style={props.style}
            label={
                (props.label || props.ableToToggleDisabled)
                    /* eslint-disable react/jsx-closing-tag-location */
                    ? (<div className='flex justify-between w-full'>
                        <span>{props.label}</span>

                        {props.ableToToggleDisabled && disabled &&
                        <a
                            type='button'
                            className={inputStyles.btnEdit}
                            disabled={props.disabledBtnEdit}
                            onClick={handleBtnEditClick}
                        >
                            <EditTwoTone className='mr-1' twoToneColor={(props.disabledBtnEdit) ? 'grey' : ''} />
                            Edit
                        </a>}

                        {props.ableToToggleDisabled && !disabled &&
                        <a
                            type='button'
                            className={inputStyles.btnCancel}
                            disabled={props.disabledBtnCancel}
                            onClick={handleBtnCancelEditClick}
                        >
                            <CloseCircleTwoTone twoToneColor={(props.disabledBtnCancel) ? 'grey' : '#ef4444'} className='mr-1' />
                            Cancel
                        </a>}
                    </div>)
                    : null
                    /* eslint-enable react/jsx-closing-tag-location */
            }
            validateStatus={(props.error?.message) ? 'error' : '-'}
            help={props.error?.message}
        >
            <Radio.Group
                ref={ref}
                style={{ width: '100%' }}
                defaultValue={props.defaultValue}
                disabled={props.disabled}
                name={props.name}
                options={props.options}
                value={props.value}
                onChange={props.onChange}
            >
                {props.children}
            </Radio.Group>
        </Form.Item>
    )
})

RadioGroup.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.string,
    error: PropTypes.object,
    disabled: PropTypes.bool,
    disabledBtnEdit: PropTypes.bool,
    disabledBtnCancel: PropTypes.bool,
    ableToToggleDisabled: PropTypes.bool,
    onToggleDisabled: PropTypes.func,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string,
    options: PropTypes.array,
    optionType: PropTypes.string,
    value: PropTypes.any
}

RadioGroup.defaultProps = {
    disabled: false,
    disableBtnEdit: false,
    disableBtnCancel: false,
    ableToToggleDisabled: false,
    onToggleDisabled: () => {},
    onChange: () => {},
    options: [],
    optionType: 'default'
}

export default RadioGroup
