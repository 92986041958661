import { useState, useEffect, useMemo } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { inject, observer } from 'mobx-react'
import qs from 'qs'

const ApplicationPublicRoute = ({ children, store, services, restricted, ...restProps }) => {
    const [isAuthed, setIsAuthed] = useState(false)
    const [checking, setChecking] = useState(true)
    const location = useLocation()
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })

    const redirectTo = useMemo(() => {
        if (query?.exclude && query?.exclude === 'payment') {
            return {
                pathname: restProps.redirectTo.pathname,
                search: '?exclude=payment'
            }
        } else {
            return restProps.redirectTo
        }
    }, [query, restProps.redirectTo])

    useEffect(async () => {
        if (store?.initiated) {
            if (store?.accessToken) {
                validateAccessToken(store?.accessToken)
            } else {
                setChecking(false)
                setIsAuthed(false)
            }
        }
    }, [store.initiated])

    const validateAccessToken = async (accessToken) => {
        try {
            setChecking(true)
            const res = await services.applicationService.validateAccessToken(accessToken)

            if (res.ok) {
                setIsAuthed(true)
            } else {
                setIsAuthed(false)
            }
        } catch (error) {
            setIsAuthed(false)
            console.error('Failed to verify access token', error)
        } finally {
            setChecking(false)
        }
    }

    return (
        <Route
            {...restProps}
            render={() => {
                if (checking) {
                    return (
                        <div className='flex flex-col w-full h-screen justify-center content-center place-content-center'>
                            <Spin size='large' />
                        </div>
                    )
                } else {
                    if (isAuthed && restricted) {
                        return <Redirect to={redirectTo} />
                    } else {
                        return children
                    }
                }
            }}
        />
    )
}

ApplicationPublicRoute.propTypes = {
    restricted: PropTypes.bool,
    redirectTo: PropTypes.object
}

ApplicationPublicRoute.defaultProps = {
    restricted: false,
    redirectTo: {
        pathname: '/status'
    }
}

export default inject('store', 'services')(observer(ApplicationPublicRoute))
