import { string, object, number, array } from 'yup'
import moment from 'moment'

const isEmployed = (type) => {
    return type === 'EMPLOYED' || type === 'SELF_EMPLOYED'
}

const schema = object().shape({
    employmentType: string()
        .nullable()
        .required('Employment status is a required field'),
    industry: string()
        .when('employmentType', (employmentType, schema) => {
            if (isEmployed(employmentType)) {
                return string()
                    .nullable()
                    .required('Employment industry is a required field')
            }
        }).nullable(),
    occupation: string()
        .when('employmentType', (employmentType, schema) => {
            if (isEmployed(employmentType)) {
                return string()
                    .nullable()
                    .required('Occupation is a required field')
            }
        }).nullable(),
    companyName: string()
        .when('employmentType', (employmentType, schema) => {
            if (isEmployed(employmentType)) {
                return string()
                    .nullable()
                    .required('Company name is a required field')
            }
        }).nullable(),
    yearsOfEmployment: number()
        .when('employmentType', (employmentType, schema) => {
            if (isEmployed(employmentType)) {
                return number()
                    .min(1, 'Year(s) of employment must be at least 1 year')
                    .integer('Year(s) of employment only accept integer number')
                    .nullable()
                    .required('Year(s) of employment is a required field')
            }
        }).nullable(),
    officeNumber: string()
        .when('employmentType', (employmentType, schema) => {
            if (isEmployed(employmentType)) {
                return string()
                    .nullable()
                    .required('Office number is a required field')
            }
        }).nullable(),
    annualIncome: string()
        .nullable()
        .required('Annual income is a required field'),
    netWorth: string()
        .nullable()
        .required('Annual income is a required field'),
    sourceOfInvestment: array()
        .of(string()
            .when('employmentType', (employmentType, schema) => {
                if (isEmployed(employmentType)) {
                    return string()
                        .nullable()
                        .required('Source of investment is a required field')
                }
            }).nullable()
        ),
    sourceOfInvestmentOther: string()
        .when('sourceOfInvestment', (sourceOfInvestment) => {
            if (sourceOfInvestment.includes('OTHERS')) {
                return string()
                    .nullable()
                    .required('Source of investment other is a required field')
            }
        }).nullable(),
    incomeStatementPath: string()
        .when(['$accountType', 'employmentType'], (accountType, employmentType, schema) => {
            if (accountType === 'COLLATERISED') {
                if (isEmployed(employmentType)) {
                    return string()
                        .nullable()
                        .required('This is a required field')
                } else {
                    return string()
                        .nullable()
                        .required('This is a required field')
                }
            }
        }).nullable(),
    additionalIncomeStatementPath: string()
        .nullable(),
    spouseFullName: string()
        .when('$hasSpouse', (hasSpouse) => {
            if (hasSpouse) {
                return string()
                    .max(100)
                    .nullable()
                    .required('Spouse name is a required field')
            }
        }).nullable(),
    spouseNationality: string()
        .when('$hasSpouse', (hasSpouse) => {
            if (hasSpouse) {
                return string()
                    .nullable()
                    .required('Spouse nationality is a required field')
            }
        }).nullable(),
    spouseContactNumber: string()
        .when('$hasSpouse', (hasSpouse) => {
            if (hasSpouse) {
                return string()
                    .nullable()
                    .required('Spouse mobile number is a required field')
                    .matches(/^\d+$/, 'Invalid mobile number')
            }
        }).nullable(),
    spouseDocumentNumber: string()
        .when('$hasSpouse', (hasSpouse) => {
            if (hasSpouse) {
                return string()
                    .nullable()
                    .required('Spouse document number is a required field')
            }
        }).nullable(),
    spouseDocumentExpiry: string()
        .when('$hasSpouse', (hasSpouse) => {
            if (hasSpouse) {
                return string()
                    .when('spouseNationality', (spouseNationality) => {
                        if (spouseNationality !== 'MY' && spouseNationality !== 'MYPR') {
                            return string()
                                .nullable()
                                .required('Spouse passport expiry date is a required field')
                                .test(
                                    'is-valid',
                                    'Invalid datetime format',
                                    (value, context) => {
                                        if (value === null || value === '') {
                                            return false
                                        }
                                        return moment(value).isValid()
                                    }
                                )
                        }
                    }).nullable()
            }
        }).nullable(),
    spouseEmploymentType: string()
        .when('$hasSpouse', (hasSpouse) => {
            if (hasSpouse) {
                return string()
                    .nullable()
                    .required('Spouse employment status is a required field')
            }
        }).nullable(),
    spouseIndustry: string()
        .when('spouseEmploymentType', (spouseEmploymentType) => {
            if (isEmployed(spouseEmploymentType)) {
                return string()
                    .nullable()
                    .required('Spouse employment industry is a required field')
            }
        }).nullable(),
    spouseOccupation: string()
        .when('spouseEmploymentType', (spouseEmploymentType) => {
            if (isEmployed(spouseEmploymentType)) {
                return string()
                    .nullable()
                    .required('Spouse occupation is a required field')
            }
        }).nullable(),
    spouseCompanyName: string()
        .when('spouseEmploymentType', (spouseEmploymentType) => {
            if (isEmployed(spouseEmploymentType)) {
                return string()
                    .nullable()
                    .required('Spouse company name is a required field')
            }
        }).nullable(),
    spouseAnnualIncome: string()
        .when('hasSpouse', (hasSpouse) => {
            if (isEmployed(hasSpouse)) {
                return string()
                    .nullable()
                    .required('Spouse annual income is a required field')
            }
        }).nullable()
})

export default schema
