import { types } from 'mobx-state-tree'

export const Partner = types.model('Partner', {
    id: types.optional(types.integer, 0),
    partnerId: types.maybeNull(types.string),
    fullName: types.maybeNull(types.string),
    logoUrl: types.maybeNull(types.string),
    showLogo: types.optional(types.boolean, false),
    websiteUrl: types.maybeNull(types.string),
    redirectionUrl: types.maybeNull(types.string)
})
    .actions((self) => ({
        setData (data) {
            for (const key in data) {
                if (!Object.prototype.hasOwnProperty.call(data, key)) {
                    console.error(`Unavailable key ${key}`)
                } else {
                    self[key] = data[key]
                }
            }
        }
    }))
