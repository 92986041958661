const appiumIOS = {
    host: process.env.REACT_APP_APPIUM_HOST || 'localhost',
    port: parseInt(process.env.REACT_APP_APPIUM_PORT) || 4723,
    logLevel: process.env.REACT_APP_APPIUM_LOGLEVEL || 'info',
    path: process.env.REACT_APP_APPIUM_PATH || '/wd/hub',
    capabilities: {
        platformName: process.env.REACT_APP_APPIUM_PLATFORM || 'iOS',
        platformVersion: process.env.REACT_APP_APPIUM_PLATFORM_VERSION || '15.5',
        automationName: process.env.REACT_APP_APPIUM_AUTOMATION_NAME || 'XCUITest',
        deviceName: process.env.REACT_APP_APPIUM_IOS_DEVICE_NAME || 'iPhone SE',
        udid: process.env.REACT_APP_APPIUM_IOS_DEVICE_ID || 'B6FA019E-BAC4-4E3C-8BEE-7D7A9DE8F06D',
        browserName: process.env.REACT_APP_APPIUM_BROSWER_NAME || 'Safari',
        safariAllowPopups: true
    }
}

const appiumAndroid = {
    host: process.env.REACT_APP_APPIUM_HOST || 'localhost',
    port: parseInt(process.env.REACT_APP_APPIUM_PORT) || 4723,
    logLevel: process.env.REACT_APP_APPIUM_LOGLEVEL || 'info',
    path: process.env.REACT_APP_APPIUM_PATH || '/wd/hub',
    capabilities: {
        platformName: 'Android',
        platformVersion: '11.0',
        automationName: 'UIAutomator2',
        deviceName: 'emulator-5554',
        udid: 'emulator-5554',
        browserName: 'Chrome'
    }
}

const mailtrap = {
    url: process.env.REACT_APP_MAILTRAP_URL || 'https://mailtrap.io',
    apiToken: process.env.REACT_APP_MAILTRAP_API_TOKEN,
    inboxId: process.env.REACT_APP_MAILTRAP_INBOX_ID || '1781875'
}

const testConfig = {
    appium: appiumIOS,
    mailtrap,
    webdriverio: {
        automationProtocol: 'devtools',
        capabilities: {
            browserName: 'chrome',
            'goog:chromeOptions': {
                args: ['--single-process', '--no-zygote', '--headless', '--no-sandbox']
            }
        }
    }
}

export default testConfig
