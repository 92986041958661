export const fetchDiligenceEndpointPath = '/diligence/fetch'
export const diligenceVerificationEndpointPath = '/diligence/verification'
export const completeDiligenceEndpointPath = '/diligence/upload-signature'

const DiligenceService = (api) => ({
    async getByUUID (uuid) {
        const res = await api.get(fetchDiligenceEndpointPath, {
            uuid
        })
        return res
    },

    async verify (uuid, nric) {
        const res = await api.post(diligenceVerificationEndpointPath, {
            uuid,
            nric
        })
        return res
    },

    async complete (uuid, signatureBase64) {
        const res = await api.post(completeDiligenceEndpointPath, {
            uuid,
            content: signatureBase64
        })
        return res
    }
})

export default DiligenceService
