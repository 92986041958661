import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import SingleFileInput from './SingleFileInput'

const SingleFileInputWithController = React.forwardRef((props, ref) => {
    const [disabled, setDisabled] = useState(props.disabled)
    const { unregister, setValue } = useFormContext()

    useEffect(() => {
        return () => {
            unregister(props.name)
        }
    }, [])

    useEffect(() => {
        setDisabled(props.disabled)
    }, [props.disabled])

    useEffect(() => {
        if (!disabled) {
            setValue(props.name, props.defaultValue)
        } else {
            unregister(props.name)
        }
    }, [disabled])

    return (
        <>
            {!disabled &&
            <Controller
                name={props.name}
                render={({ field: { value, onChange, ref } }) => {
                    let selectedFile = null
                    if (value) {
                        selectedFile = value
                    }

                    return (
                        <SingleFileInput
                            ref={ref}
                            className={props.className}
                            label={props.label}
                            accept={props.accept}
                            sampleUrl={props.sampleUrl}
                            previewTitle={props.previewTitle}
                            selectedFile={selectedFile}
                            disabled={disabled}
                            disabledBtnEdit={props.disabledBtnEdit}
                            disabledBtnCancel={props.disabledBtnCancel}
                            ableToToggleDisabled={props.ableToToggleDisabled}
                            onToggleDisabled={(disabled) => {
                                if (disabled) {
                                    setDisabled(true)
                                    unregister(props.name)
                                }
                            }}
                            onChange={(e) => {
                                const { file } = e
                                onChange(file)
                                props.onChange(e)
                            }}
                            error={props.error}
                        />
                    )
                }}
            />}

            {disabled &&
            <SingleFileInput
                className={props.className}
                label={props.label}
                accept={props.accept}
                sampleUrl={props.sampleUrl}
                previewTitle={props.previewTitle}
                selectedFile={props.selectedFile}
                disabled={disabled}
                ableToToggleDisabled={props.ableToToggleDisabled}
                onToggleDisabled={(disabled) => {
                    setDisabled(false)
                }}
                error={props.error}
            />}
        </>
    )
})

SingleFileInputWithController.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    name: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.object,
    disabled: PropTypes.bool,
    disabledBtnEdit: PropTypes.bool,
    disabledBtnCancel: PropTypes.bool,
    ableToToggleDisabled: PropTypes.bool,
    onToggleDisabled: PropTypes.func,
    previewTitle: PropTypes.string,
    selectedFile: PropTypes.object,
    accept: PropTypes.string,
    onChange: PropTypes.func
}

SingleFileInputWithController.defaultProps = {
    defaultValue: null,
    disabled: false,
    disableBtnEdit: false,
    disableBtnCancel: false,
    ableToToggleDisabled: false,
    onToggleDisabled: () => {},
    selectedFile: null,
    onChange: () => {}
}

export default SingleFileInputWithController
