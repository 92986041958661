export const getStepLink = (step, appendString = null) => {
    let link
    switch (step) {
        case 1:
            link = '/cds-trading-account-registration'
            break
        case 2:
            link = '/employment-and-spouse-details'
            break
        case 3:
            link = '/financial-profile'
            break
        case 4:
            link = '/taxation-and-declarations'
            break
        case 5:
            link = '/summary'
            break
    }
    if (appendString) {
        link += appendString
    }
    return link
}

export const getAdminStepLink = (step, id) => {
    let link
    switch (step) {
        case 1:
            link = `/admin/application/${id}/cds-trading-account`
            break
        case 2:
            link = `/admin/application/${id}/employment-and-spouse-details`
            break
        case 3:
            link = `/admin/application/${id}/financial-profile`
            break
        case 4:
            link = `/admin/application/${id}/taxation-and-declarations`
            break
        case 5:
            link = `/admin/application/${id}/summary`
            break
    }
    return link
}
