import { useState, useEffect, useMemo } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Spin, Modal } from 'antd'
import { applySnapshot } from 'mobx-state-tree'
import _ from 'lodash'
import AsyncStorage from '@callstack/async-storage'
import TagManager from 'react-gtm-module'
import qs from 'qs'

const ApplicationPrivateRoute = ({ children, store, services, ...restProps }) => {
    const location = useLocation()
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    const [isAuthed, setIsAuthed] = useState(restProps?.isAuthed)
    const [checking, setChecking] = useState(true)

    const redirectTo = useMemo(() => {
        if (query?.exclude && query?.exclude === 'payment') {
            return {
                pathname: restProps.redirectTo.pathname,
                search: '?exclude=payment'
            }
        } else {
            return restProps.redirectTo
        }
    }, [query, restProps.redirectTo])

    useEffect(() => {
    }, [])

    useEffect(() => {
        if (store?.initiated) {
            if (store?.accessToken) {
                getApplication(store?.accessToken)
            } else {
                setChecking(false)
                setIsAuthed(false)
            }
        }
    }, [location, store.initiated])

    const getApplication = async (accessToken) => {
        try {
            setChecking(true)
            const res = await services.applicationService.fetch(accessToken)

            if (res.ok && res.data) {
                const application = res.data.data
                const jsonStr = await AsyncStorage.getItem('rootStore')
                const obj = JSON.parse(jsonStr)

                if (application.ekyc?.method === 'CTOS' && application.ekycStatus === 'COMPLETED' && store.ekycStatus !== 'COMPLETED') {
                    const tagManagerArgs = {
                        dataLayer: {
                            event: 'complete-registration'
                        }
                    }

                    TagManager.dataLayer(tagManagerArgs)
                }

                if (application.createdAt) {
                    application.createdAt = new Date(application.createdAt)
                }
                if (application.formData1) {
                    application.fullName = application.formData1.fullName
                }

                applySnapshot(store, {
                    ...application,
                    initiated: true,
                    accessToken: accessToken
                })

                // Step 1
                if (application.formData1 && application.completedStep > 1) {
                    const step1 = application.formData1
                    if (step1.birthDate) {
                        step1.birthDate = new Date(`${step1.birthDate}T00:00:00`)
                    }
                    if (step1.documentExpiry) {
                        step1.documentExpiry = new Date(`${step1.documentExpiry}T00:00:00`)
                    }
                    if (step1.isPrMalaysia) {
                        step1.nationality = 'MYPR'
                    }

                    if (!step1.ekycDocumentType) {
                        if (step1.documentExpiry) {
                            step1.ekycDocumentType = 3 // Passport
                        } else {
                            step1.ekycDocumentType = 1 // MyKad
                        }
                    }

                    store.setStep1Data(step1)
                } else {
                    if (obj) {
                        const step1 = application.formData1
                        if (step1?.drCode && step1?.hasPreferDR && obj?.step1) {
                            obj.step1.drCode = step1.drCode
                            obj.step1.hasPreferDR = step1.hasPreferDR
                        }

                        if (application.completedStep === 1 && obj?.step1 && !obj.step1?.fullName) {
                            obj.step1.fullName = application.formData1?.fullName
                        }

                        store.setStep1Data(obj.step1)
                    }
                }

                // Step 2
                if (application.formData2 && application.completedStep > 2) {
                    const step2 = { ...application.formData2.employment }
                    for (const [key, value] of Object.entries(application.formData2.spouse)) {
                        step2[`spouse${_.upperFirst(key)}`] = value
                    }
                    if (step2.spouseDocumentExpiry) {
                        step2.spouseDocumentExpiry = new Date(`${step2.spouseDocumentExpiry}T00:00:00`)
                    }
                    if (step2.spouseIsPrMalaysia) {
                        step2.spouseNationality = 'MYPR'
                    }

                    store.setStep2Data(step2)
                } else {
                    if (obj) {
                        store.setStep2Data(obj.step2)
                    }
                }

                // Step 3
                if (application.formData3 && application.completedStep > 3) {
                    const step3 = { ...application.formData3.customerBank, ...application.formData3.riskProfile }
                    if (step3.investmentTerm) {
                        let investmentTerm

                        switch (step3.investmentTerm) {
                            case 'SHORT_TERM':
                                investmentTerm = 1
                                break
                            case 'MID_TERM':
                                investmentTerm = 2
                                break
                            case 'LONG_TERM':
                                investmentTerm = 3
                                break
                        }

                        step3.investmentTerm = investmentTerm
                    }
                    if (step3.riskTolerance) {
                        let riskTolerance

                        switch (step3.riskTolerance) {
                            case 'CONSERVATIVE':
                                riskTolerance = 1
                                break
                            case 'MODERATE':
                                riskTolerance = 2
                                break
                            case 'AGGRESSIVE':
                                riskTolerance = 3
                                break
                        }

                        step3.riskTolerance = riskTolerance
                    }

                    store.setStep3Data(step3)
                } else {
                    if (obj) {
                        store.setStep3Data(obj.step3)
                    }
                }

                // Step 4
                if (application.formData4 && application.completedStep > 4) {
                    const step4 = application.formData4.declaration
                    if (step4?.isMalaysiaResident) {
                        step4.countryOfResidence = 'MY'
                    }
                    store.setStep4Data(step4)
                } else {
                    if (obj) {
                        store.setStep4Data(obj.step4)
                    }
                }

                // Ekyc
                if (application.ekyc) {
                    const ekyc = { ...application.ekyc }
                    ekyc.url = ekyc.ekycUrl
                    ekyc.status = application.ekycStatus
                    store.setEkyc(ekyc)
                }

                // Partner
                if (application.partner) {
                    const partner = { ...application.partner }
                    store.setPartner(partner)
                }
                setIsAuthed(true)
            } else {
                store.reset()
                setIsAuthed(false)
                Modal.info({
                    title: 'Error',
                    content: (
                        <p>
                            Something went wrong, please try again later
                        </p>
                    )
                })
            }
        } catch (error) {
            store.reset()
            setIsAuthed(false)
            Modal.info({
                title: 'Error',
                content: (
                    <p>
                        Something went wrong, please try again later
                    </p>
                )
            })
            console.error(error)
        } finally {
            setChecking(false)
        }
    }

    return (
        <Route
            {...restProps}
            render={() => {
                if (checking) {
                    return (
                        <div className='flex flex-col w-full h-screen justify-center content-center place-content-center'>
                            <Spin size='large' />
                        </div>
                    )
                } else {
                    if (isAuthed) {
                        return children
                    } else {
                        return <Redirect to={redirectTo} />
                    }
                }
            }}
        />
    )
}

ApplicationPrivateRoute.propTypes = {
    redirectTo: PropTypes.object
}

ApplicationPrivateRoute.defaultProps = {
    redirectTo: {
        pathname: '/'
    }
}

export default inject('store', 'services')(observer(ApplicationPrivateRoute))
