import { useState, useEffect } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import styles from '../EKYCStatus.module.scss'

const EKYCStatusFailed = props => {
    useEffect(() => {
    }, [])

    return (
        <div className={cn(styles.wrapper, props.className)}>
            <div className={styles.title}>
                You have failed the identity verification
            </div>

            <p className='text-lg oxygen-font-weight-bold mb-3'>
                Dear {props.fullName}
            </p>

            <p className={styles.description}>
                Thank you for registering with us.
            </p>

            <p className={styles.description}>
                Unfortunately, we would like to inform you that your identity verification failed.
            </p>

            <p className={styles.description}>
                The account opening fee paid will be refunded to your bank account.
            </p>

            <p className={styles.description}>
                Meanwhile, please resubmit your application.
            </p>

            <p className={styles.description}>
                If you do require any assistance, you can visit our Website, Facebook Page or have a chat with SITI (our ChatBot).
            </p>

            <p className={styles.description}>
                Thank you and hope to hear from you soon!
            </p>

            <p className={styles.description}>
                M+ Online
            </p>
        </div>
    )
}

EKYCStatusFailed.propTypes = {
    className: PropTypes.string,
    fullName: PropTypes.string.isRequired
}

EKYCStatusFailed.defaultProps = {
}

export default EKYCStatusFailed
