import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import Radio from './Radio'

const RadioWithController = React.forwardRef((props, ref) => {
    const [disabled, setDisabled] = useState(props.disabled)
    const { unregister, setValue } = useFormContext()

    useEffect(() => {
        return () => {
            unregister(props.name)
        }
    }, [])

    useEffect(() => {
        setDisabled(props.disabled)
    }, [props.disabled])

    useEffect(() => {
        if (!disabled) {
            setValue(props.name, props.defaultValue)
        } else {
            unregister(props.name)
        }
    }, [disabled])

    return (
        <>
            {!disabled &&
            <Controller
                name={props.name}
                defaultValue={props.defaultValue}
                render={({ field: { value, onChange, ref } }) => (
                    <Radio.Group
                        ref={ref}
                        label={props.label}
                        defaultValue={props.defaultValue}
                        disabled={disabled}
                        ableToToggleDisabled={props.ableToToggleDisabled}
                        onToggleDisabled={(disabled) => {
                            if (disabled) {
                                setDisabled(true)
                                unregister(props.name)
                            }
                        }}
                        onChange={(e) => {
                            onChange(e.target.value)
                            props.onChange(e)
                        }}
                        value={value}
                        error={props.error}
                    >
                        {props.children}
                    </Radio.Group>
                )}
            />}

            {disabled &&
            <Radio.Group
                label={props.label}
                disabled={disabled}
                ableToToggleDisabled={props.ableToToggleDisabled}
                onToggleDisabled={(disabled) => {
                    setDisabled(false)
                }}
                value={props.defaultValue}
                error={props.error}
            >
                {props.children}
            </Radio.Group>}
        </>
    )
})

RadioWithController.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.string,
    error: PropTypes.object,
    disabled: PropTypes.bool,
    disabledBtnEdit: PropTypes.bool,
    disabledBtnCancel: PropTypes.bool,
    ableToToggleDisabled: PropTypes.bool,
    onToggleDisabled: PropTypes.func,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string,
    options: PropTypes.array,
    optionType: PropTypes.string,
    value: PropTypes.any
}

RadioWithController.defaultProps = {
    disabled: false,
    disableBtnEdit: false,
    disableBtnCancel: false,
    ableToToggleDisabled: false,
    onToggleDisabled: () => {},
    onChange: () => {},
    options: [],
    optionType: 'default'
}

export default RadioWithController
