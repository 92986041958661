
const occupations = [
    'Accountant',
    'Actor',
    'Adjusters',
    'Administrator',
    'Advisor',
    'Agent',
    'Air Hostess',
    'Analyst / Researcher',
    'Aquaculture',
    'Architect',
    'Army Officer',
    'Artist',
    'Assistant',
    'Assistant Director',
    'Assistant Manager',
    'Astronaut / Astronomer / Astrologer',
    'Athlete',
    'Auctioner',
    'Auditor',
    'Author',
    'Babysitter',
    'Baker',
    'Bank Officer',
    'Beautician',
    'Bell Boy',
    'Biographer',
    'Broker',
    'Business Partner',
    'Businessman / Businesswoman',
    'Butcher',
    'CEO',
    'Carpenter',
    'Cashier',
    'Catering',
    'Chairman',
    'Chargeman',
    'Chef / Cook',
    'Chemist, Physicist',
    'Chief Accountant',
    'Chief Auditor',
    'Choreographer / Dancer',
    'Circus performer',
    'Civil Servant',
    'Clergy',
    'Clerk',
    'Co-Director',
    'Coach',
    'Composer',
    'Consultant',
    'Contractor',
    'Coordinator',
    'Copywriter / Script Writer',
    'Dealer / Distributor',
    'Decorator',
    'Dentist',
    'Designer',
    'Despatch',
    'Detective',
    'Developer',
    'Diplomat',
    'Direct Sales',
    'Director',
    'Doctor',
    'Driver',
    'Economist',
    'Editor',
    'Electrician',
    'Engineer',
    'Entertainer',
    'Executive',
    'Executive Director',
    'Exporter',
    'Farmer / Agronomist',
    'Financial Controller',
    'Fireman',
    'Fisherman',
    'Fishmonger',
    'Florist',
    'Foreman',
    'Forestry',
    'Freight Forwarding Services',
    'Gardener',
    'General Manager',
    'General Worker',
    'Goldsmith',
    'Government',
    'Grocer',
    'Hairdresser',
    'Hawker',
    'Homemaker',
    'Housewife',
    'Hunter',
    'Instructor',
    'Investor',
    'Jeweller',
    'Journalist',
    'Judge',
    'Labourer',
    'Launderer / Dry Cleaner',
    'Lawyer / Barrister / Solicitor',
    'Lender',
    'Librarian / Curator',
    'Locksmith',
    'Magician',
    'Maid / Cleaner',
    'Manager',
    'Managing Director',
    'Manufacturer',
    'Mechanic',
    'Model',
    'Money Lender',
    'Money-Changer',
    'NGO Staff / Volunteer',
    'Newscaster',
    'Nurse / Midwife',
    'Nursery Worker & Gardaner',
    'Officer',
    'Operator',
    'Optometrist / Optician',
    'others',
    'Owner / Co-Owner',
    'Painter',
    'Partner',
    'Pastor',
    'Pharmacist',
    'Photographer',
    'Physician',
    'Pilot',
    'Plumber',
    'Police Officer',
    'Politician',
    'Postman',
    'President',
    'Priest / Nun',
    'Principal',
    'Programmer',
    'Promoter',
    'Proof Reader',
    'Psychiatrist',
    'Psychologist',
    'Quantity Surveyor',
    'Remisier',
    'Retailer',
    'Retiree',
    'Sailor',
    'Salesman / Salesgirl / Marketing',
    'Secretary',
    'Security / Body Guard',
    'Self-Employed',
    'Shop Assistant',
    'Singer',
    'Smallholder',
    'Social Worker',
    'Sole Proprietor',
    'Statistician / Acturarist',
    'Stockbroker',
    'Store-Keeper',
    'Student',
    'Sub-contractor',
    'Supervisor',
    'Supplier',
    'Tailor / Dressmaker',
    'Teacher',
    'Technician',
    'Tourist Guide',
    'Town Planner',
    'Trader',
    'Translator / interpreter',
    'Unemployed',
    'Unknown',
    'Uphoisterer',
    'Valuer',
    'Vice President',
    'Waiter / Waitress / Barista / Bartender',
    'Warden',
    'Welder',
    'Wholesaler'
]

export default occupations
