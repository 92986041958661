import { types } from 'mobx-state-tree'

export const AdminStore = types
    .model('AdminStore', {
        initiated: types.optional(types.boolean, false),
        fullName: types.maybeNull(types.string),
        accessToken: types.maybeNull(types.string)
    })
    .actions((self) => ({
        setData (data) {
            for (const key in data) {
                if (!Object.prototype.hasOwnProperty.call(data, key)) {
                    console.error(`Unavailable key ${key}`)
                } else {
                    self[key] = data[key]
                }
            }
        },
        init () {
            self.initiated = true
        }
    }))

let _store

export const createAdminStore = (data, env = null) => {
    if (!_store) {
        _store = AdminStore.create(data, env)
    }
    return _store
}
