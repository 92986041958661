import { useState, useEffect } from 'react'
import { Form, Input } from 'antd'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { string, object } from 'yup'
import { inject } from 'mobx-react'
import SubmitButton from '../../../../../components/button/submit'
import FormError from '../../../../../components/form/error'
import focusErrorInput from '../../../../../helpers/focusErrorInput'

const schema = object().shape({
    email: string()
        .email('Email must be a valid email')
        .required('Email is a required field')
})

const ResumeApplicationForm = props => {
    const [submitting, setSubmitting] = useState(false)
    const [apiErrorMessages, setApiErrorMessages] = useState([])
    const { handleSubmit, setError, setValue, control, reset, watch, setFocus, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    })

    useEffect(() => {
    }, [])

    useEffect(() => {
        for (const [key, value] of Object.entries(props.defaultValues)) {
            setValue(key, value)
        }
    }, [props.defaultValues])

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            setApiErrorMessages([])

            const res = await props.services.applicationService.resume(
                data.fullName,
                data.email.toLowerCase(),
                data.isValidateByOTP,
                data.exclude
            )

            if (res.ok) {
                reset()
                if (props.onSuccessSubmit) props.onSuccessSubmit(data)
            } else if (res.status === 422) {
                if (res.data?.errors) {
                    const inputFields = Object.keys(watch())
                    const errors = res.data.errors
                    const { nonErrorFieldMessages } = focusErrorInput(inputFields, errors, setFocus, setError)
                    setApiErrorMessages(nonErrorFieldMessages)
                }
            } else {
                setApiErrorMessages(['Something went wrong'])
            }
        } catch (error) {
            console.error(error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Form
            className={cn(props.className, 'w-full')}
            layout='vertical'
            onFinish={handleSubmit(onSubmit)}
        >
            {apiErrorMessages.length !== 0 &&
            <FormError className='mb-3' messages={apiErrorMessages} />}

            <Form.Item
                className='mb-4 quesTitle'
                label='Email'
                validateStatus={(errors?.email?.message) ? 'error' : '-'}
                help={errors?.email?.message}
            >
                <Controller
                    name='email'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Input
                            ref={ref}
                            className='input-text-full'
                            placeholder='Email'
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <SubmitButton
                label='Resume'
                submitting={submitting}
            />
        </Form>
    )
}

ResumeApplicationForm.propTypes = {
    className: PropTypes.string,
    onSuccessSubmit: PropTypes.func,
    defaultValues: PropTypes.shape({
        email: PropTypes.string,
        isValidateByOTP: PropTypes.bool,
        exclude: PropTypes.string
    })
}

ResumeApplicationForm.defaultProps = {
    defaultValues: {
        email: '',
        isValidateByOTP: false,
        exclude: null
    }
}

export default inject('services')(ResumeApplicationForm)
