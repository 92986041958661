import { string, object } from 'yup'

const schema = object().shape({
    documentNumber: string()
        .nullable()
        .min(3, 'NRIC/Passport must be at least 3 characters')
        .max(25, 'NRIC/Passport must be at most 25 characters')
        .required('NRIC/Passport is a required field')
})

export default schema
