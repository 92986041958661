import { useState, useEffect } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import mailSentImage from '../../../../../../assests/images/mail-sent.png'
import ResendEmailButton from '../../resend/components/button'
import styles from './ApplicationResumeEmailVerificationSentNotice.module.scss'

const ApplicationResumeEmailVerificationSentNotice = props => {
    const location = useLocation()
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })

    useEffect(() => {
    }, [])

    return (
        <div className={cn(styles.wrapper, props.className)}>
            <div className='text-light-blue oxygen-font-weight-bold text-2xl mb-3'>
                We're halfway there!
            </div>

            <p className='text-black mb-4'>
                Welcome back to M+ CDS Trading Account Registration Journey, to continue your application please click on the request button below. An email will be sent to your registered email address for you to continue the registration.
            </p>

            <p className='text-black'>
                An email titled <span className='font-weight-bold'>“MPLUS Online - Resume Account Opening Registration”</span> has been sent to {props.email}. Please check your email Inbox.
            </p>

            <h1 className='oxygen-font-weight-bold text-2xl text-gray-500 mt-8'>
                Check your inbox in order to resume your account opening
            </h1>

            <img
                className='m-auto'
                src={mailSentImage}
                alt='Mail Sent'
                width={160}
                height={160}
            />

            <hr className='mb-5' style={{ color: '#C8C8C8' }} />

            <p className='text-lg text-black mb-3 font-weight-bold'>
                Didn't receive the email?
            </p>

            <p className='text-gray-500 mb-5'>
                P.S: Sometimes the email may fall into Junk/Spam categories. Don’t forget to check if you could not locate the email in your inbox. If you do not received the email after 1 minute, you may click on the “Resend Email Button”
            </p>

            <ResendEmailButton
                defaultValues={{
                    email: props.email,
                    isValidateByOTP: false,
                    exclude: query?.exclude
                }}
            />
        </div>
    )
}

ApplicationResumeEmailVerificationSentNotice.propTypes = {
    className: PropTypes.string,
    email: PropTypes.string.isRequired
}

ApplicationResumeEmailVerificationSentNotice.defaultProps = {
}

export default ApplicationResumeEmailVerificationSentNotice
