import config from './config'
import MockAdapter from 'axios-mock-adapter'
import mockEndpoints from './helpers/mock/mockEndpoints'
import { createStore, RootStore } from './models/root'
import { createAdminStore } from './models/admin'
import HttpClientWrapper from './utils/HttpClientWrapper'
import ApplicationService from './modules/application/ApplicationService'
import DiligenceService from './modules/diligence/DiligenceService'
import CustomerService from './modules/customer/CustomerService'
import AdminService from './modules/admin/AdminService'
import PartnerService from './modules/partner/PartnerService'
import PaymentService from './modules/application/payment/PaymentService'

const generateAppContainer = async () => {
    const toMockAPIWithoutServer = (config.app.mockApi && !config.app.mockApiWithServer)
    const httpClientOptions = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        timeout: 30000
    }
    if (!toMockAPIWithoutServer) httpClientOptions.baseURL = config.app.apiUrl
    const httpClientWrapper = new HttpClientWrapper(httpClientOptions)

    // Mock API results without server/network call
    if (toMockAPIWithoutServer) {
        const mockModule = await import('api-mock/src/mock')
        const endpoints = mockModule.default
        const mockAdapter = new MockAdapter(httpClientWrapper.httpClient.axiosInstance)
        mockEndpoints(mockAdapter, endpoints)
    }

    const services = {}
    services.applicationService = ApplicationService(httpClientWrapper)
    services.diligenceService = DiligenceService(httpClientWrapper)
    services.customerService = CustomerService(httpClientWrapper)
    services.adminService = AdminService(httpClientWrapper)
    services.partnerService = PartnerService(httpClientWrapper)
    services.paymentService = PaymentService(httpClientWrapper)

    const store = createStore({
        applicationService: services.applicationService
    })
    const adminStore = createAdminStore(
        {
            fullName: null,
            accessToken: null
        },
        {
            adminService: services.adminService
        }
    )

    return { store, adminStore, services }
}

export default (async () => {
    const appContainer = await generateAppContainer()
    return appContainer
})()
