import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import Layout from '../modules/application/components/templates/layout'
import ApplicationCreatedEmailVerificationSentNotice from '../modules/application/create/email-verification/components/sent-notice'
import ApplicationResumeEmailVerificationSentNotice from '../modules/application/resume/email-verification/components/sent-notice'

const EmaiLSentPage = props => {
    const history = useHistory()

    if (!props.store?.email) {
        history.push('/')
        return (<div />)
    }

    if (props.store?.applicationStatus !== 'NEW' && props.store?.applicationStatus !== null) {
        history.push('/status')
        return (<div />)
    }

    return (
        <Layout>
            {!props.store?.isResumeApplication &&
            <ApplicationCreatedEmailVerificationSentNotice
                fullName={props.store?.fullName}
                email={props.store?.email}
            />}

            {props.store?.isResumeApplication &&
            <ApplicationResumeEmailVerificationSentNotice
                email={props.store?.email}
            />}
        </Layout>
    )
}

export default inject('store')(observer(EmaiLSentPage))
