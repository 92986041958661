import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './ApplicationLayout.module.scss'
import Header from './header'

const ApplicationLayout = (props) => {
    return (
        <div className={styles.wrapper}>
            <Header hideBtnNewApplication={props.hideBtnNewApplication} />

            <div className={styles.contentWrapper}>
                {props.children}
            </div>
        </div>
    )
}

ApplicationLayout.propTypes = {
    hideBtnNewApplication: PropTypes.bool
}

ApplicationLayout.defaultProps = {
    hideBtnNewApplication: false
}

export default ApplicationLayout
