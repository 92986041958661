import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button as AntButton } from 'antd'
import SignatureCanvas from 'react-signature-canvas'
import { inject } from 'mobx-react'
import styles from './SignatureUploader.module.scss'

const SignatureUploader = props => {
    const [uploadedSignatureSrc, setUploadedSignatureSrc] = useState(null)
    const [uploading, setUploading] = useState(false)

    useEffect(() => {
    }, [])

    useEffect(() => {
        setUploadedSignatureSrc(props.uploadedSignatureSrc)
    }, [props.uploadedSignatureSrc])

    let refSign = null
    const clearSignature = () => {
        if (refSign) refSign.clear()
        if (props.onClear) props.onClear()
        setUploadedSignatureSrc(null)
    }

    const saveSignature = async () => {
        try {
            if (refSign.isEmpty()) {
                Modal.error({
                    title: 'Error',
                    content: (
                        <p>Signature cannot be empty</p>
                    )
                })
                return
            }

            setUploading(true)
            const base64 = refSign.toDataURL()
            const res = await props.services.applicationService.uploadSignature(props.store.accessToken, base64)

            if (res.ok) {
                const uploadedSignature = {
                    url: res.data?.url
                }

                props.onUpload(uploadedSignature)
                setUploadedSignatureSrc(uploadedSignature.url)
                Modal.success({
                    className: 'upload-signature-success-modal',
                    title: 'Signature Uploaded Successfully',
                    content: (
                        <p>
                            Your signature has been uploaded successfully
                        </p>
                    )
                })
            } else if (res.status === 422) {
                if (res.data?.errors) {
                    const messages = []
                    for (const key in res.data.errors) {
                        messages.push(...res.data.errors[key])
                    }
                    Modal.error({
                        title: 'Error',
                        content: (
                            <ul>
                                {messages.map((message, index) =>
                                    <li key={index}>{message}</li>
                                )}
                            </ul>
                        )
                    })
                } else {
                    Modal.error({
                        title: 'Something went wrong',
                        content: (
                            <p>Please try again later</p>
                        )
                    })
                }
            } else {
                Modal.error({
                    title: 'Something went wrong',
                    content: (
                        <p>Please try again later</p>
                    )
                })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setUploading(false)
        }
    }

    return (
        <div className={props.className}>
            {!uploadedSignatureSrc && !props.isCanvasDisabled &&
            <div className={styles.canvasWrapper}>
                <SignatureCanvas
                    ref={(ref) => { refSign = ref }}
                    penColor='black'
                    canvasProps={{ width: 340, height: 300 }}
                />
            </div>}

            {(uploadedSignatureSrc || props.isCanvasDisabled) &&
            <div className={styles.imageWrapper}>
                <img src={uploadedSignatureSrc} />
            </div>}

            <AntButton
                type='primary'
                style={{ width: 100, marginTop: 10 }}
                className='btn-save'
                loading={uploading}
                disabled={uploading || uploadedSignatureSrc || props.isUploadBtnDisabled}
                onClick={saveSignature}
            >
                Save
            </AntButton>

            <AntButton className='btn-clear' style={{ width: 100, marginTop: 10 }} disabled={uploading || props.isClearBtnDisabled} onClick={clearSignature}>
                Clear
            </AntButton>
        </div>
    )
}

SignatureUploader.propTypes = {
    className: PropTypes.string,
    onUpload: PropTypes.func,
    onCancel: PropTypes.func,
    uploadedSignatureSrc: PropTypes.string,
    isUploadBtnDisabled: PropTypes.bool,
    isClearBtnDisabled: PropTypes.bool,
    isCanvasDisabled: PropTypes.bool
}

SignatureUploader.defaultProps = {
    isUploadBtnDisabled: false,
    isClearBtnDisabled: false,
    isCanvasDisabled: false
}

export default inject('store', 'services')(SignatureUploader)
