import { types } from 'mobx-state-tree'

export const Ekyc = types.model('Ekyc', {
    referenceId: types.maybeNull(types.string),
    accountId: types.maybeNull(types.string),
    templateId: types.maybeNull(types.string),
    inquiryId: types.maybeNull(types.string),
    environment: types.maybeNull(types.string),
    accessToken: types.maybeNull(types.string),
    resumeable: types.maybeNull(types.boolean),
    url: types.maybeNull(types.string),
    method: types.maybeNull(
        types.enumeration('method', [
            'CTOS',
            'PERSONA'
        ])
    ),
    status: types.maybeNull(
        types.enumeration('status', [
            'NEW',
            'PENDING',
            'DOCUMENT_SUBMITTED',
            'SELFIE_SUBMITTED',
            'COMPLETED',
            'FAILED'
        ])
    )
})
    .actions((self) => ({
        setData (data) {
            for (const key in data) {
                if (!Object.prototype.hasOwnProperty.call(data, key)) {
                    console.error(`Unavailable key ${key}`)
                } else {
                    self[key] = data[key]
                }
            }
        }
    }))
