import React, { useState, useEffect, useImperativeHandle } from 'react'
import { Form } from 'antd'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useForm, Controller, FormProvider, useFormContext } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { inject } from 'mobx-react'
import focusErrorInput from '../../../../../helpers/focusErrorInput'
import FormError from '../../../../../components/form/error'
import SubmitButton from '../../../../../components/button/submit'
import OtpInput from '../../../../../components/input/otp/OtpInput'
import schema from './schema'

const CustomerVerificationForm = inject('services')((props) => {
    const [form] = Form.useForm()
    const [submitting, setSubmitting] = useState(false)
    const [apiErrorMessages, setApiErrorMessages] = useState([])
    const { handleSubmit, setValue, getValues, setFocus, setError, reset, watch, control, formState: { errors } } = useFormContext()

    useEffect(() => {
        reset()
    }, [props.defaultValues.email])

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            setApiErrorMessages([])
            const res = await props.services.customerService.verify(props.accessToken, props.defaultValues.email, data.otp)

            if (res.ok) {
                const payload = res.data
                if (props.onSuccessSubmit) props.onSuccessSubmit(payload)
            } else if (res.status === 422) {
                const inputFields = Object.keys(watch())
                const errors = res.data.errors
                const { nonErrorFieldMessages } = focusErrorInput(inputFields, errors, setFocus, setError, ['email', 'otp'])
                setApiErrorMessages(nonErrorFieldMessages)
            } else {
                setApiErrorMessages(['Something went wrong'])
            }
        } catch (error) {
            console.error(error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Form
            className={cn(props.className, 'w-full')}
            style={props.style}
            layout='vertical'
            onFinish={handleSubmit(onSubmit)}
            form={form}
        >
            {apiErrorMessages.length !== 0 &&
            <FormError className='mt-3' messages={apiErrorMessages} />}

            <Form.Item
                className='quesTitle'
                validateStatus={(errors?.otp?.message) ? 'error' : '-'}
                help={<div className={cn('flex items-center justify-center', { 'mt-3': errors?.otp })}>{errors?.otp?.message}</div>}
            >
                <Controller
                    name='otp'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <OtpInput
                            ref={ref}
                            className='flex items-center justify-center mt-10'
                            numInputs={6}
                            value={value}
                            isInputNum
                            onChange={(otp) => {
                                onChange(otp)
                            }}
                        />
                    )}
                />
            </Form.Item>

            <SubmitButton
                className='w-full mt-1'
                label='Verify'
                labelSubmitting='Verifying'
                submitting={submitting}
            />
        </Form>
    )
})

CustomerVerificationForm.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onSuccessSubmit: PropTypes.func,
    accessToken: PropTypes.string
}

CustomerVerificationForm.defaultProps = {
    onSuccessSubmit: () => {}
}

export default React.forwardRef((props, ref) => {
    const reactHookFormMethods = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: props.defaultValues
    })

    useImperativeHandle(ref, () => ({
        reset: () => {
            reactHookFormMethods.reset()
        }
    }))

    return (
        <FormProvider {...reactHookFormMethods}>
            <CustomerVerificationForm {...props} />
        </FormProvider>
    )
})
