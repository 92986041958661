import { useState, useEffect } from 'react'
import { Form, Input } from 'antd'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { inject } from 'mobx-react'
import FormError from '../../../../../../../../components/form/error'
import TextInput from '../../../../../../../../components/input/text'
import Button from '../../../../../../../../components/button'
import ProfileUpdateRequirementModal from '../../../components/requirement/modal/ProfileUpdateRequirementModal'
import focusErrorInput from '../../../../../../../../helpers/focusErrorInput'
import schema from './schema'

const SendProfileUpdateRequestForm = props => {
    const [form] = Form.useForm()
    const [isProfileUpdateRequirementModalVisible, setIsProfileUpdateRequirementModalVisible] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [apiErrorMessages, setApiErrorMessages] = useState([])
    const { handleSubmit, setError, control, reset, watch, setFocus, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    })

    useEffect(() => {
    }, [])

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            setApiErrorMessages([])
            const res = await props.services.customerService.sendProfileUpdateRequest(data.documentNumber)

            if (res.ok) {
                reset()
                if (props.onSuccessSubmit) props.onSuccessSubmit(res.data)
            } else if (res.status === 422) {
                if (res.data?.errors) {
                    if (isProfileUpdateRequirementModalVisible) setIsProfileUpdateRequirementModalVisible(false)
                    const inputFields = Object.keys(watch())
                    const errors = res.data.errors
                    const { nonErrorFieldMessages } = focusErrorInput(inputFields, errors, setFocus, setError)
                    setApiErrorMessages(nonErrorFieldMessages)
                }
            } else {
                setApiErrorMessages(['Something went wrong'])
            }
        } catch (error) {
            console.error(error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Form
            className={cn(props.className, 'w-full')}
            layout='vertical'
            onFinish={handleSubmit(onSubmit)}
            form={form}
        >
            {apiErrorMessages.length !== 0 &&
            <FormError className='mb-3' messages={apiErrorMessages} />}

            <Controller
                name='documentNumber'
                control={control}
                render={({ field: { value, onChange, ref } }) => (
                    <TextInput
                        ref={ref}
                        label='NRIC/Passport'
                        placeholder='NRIC/Passport'
                        onChange={onChange}
                        value={value}
                        error={errors.documentNumber}
                    />
                )}
            />

            <Button
                label='Submit'
                onClick={async () => {
                    const result = await trigger()
                    if (result) {
                        setIsProfileUpdateRequirementModalVisible(true)
                    }
                }}
            />

            <ProfileUpdateRequirementModal
                visible={isProfileUpdateRequirementModalVisible}
                isBtnProceedLoading={submitting}
                btnProceedOnClick={() => {
                    form.submit()
                }}
                onCancel={() => setIsProfileUpdateRequirementModalVisible(false)}
            />
        </Form>
    )
}

SendProfileUpdateRequestForm.propTypes = {
    className: PropTypes.string,
    onSuccessSubmit: PropTypes.func,
    defaultValues: PropTypes.shape({
        email: PropTypes.string
    })
}

SendProfileUpdateRequestForm.defaultProps = {
}

export default inject('services')(SendProfileUpdateRequestForm)
