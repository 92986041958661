import { useState, useEffect, useRef } from 'react'
import { Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select, Spin, message, Upload } from 'antd'
import CountryPhoneInput, { ConfigProvider, defaultAreas } from 'antd-country-phone-input'
import en from 'world_countries_lists/data/en/world.json'
import { inject, observer } from 'mobx-react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import SubmitButton from '../../../../../../components/button/submit'
import Button from '../../../../../../components/button'
import nationalities from '../../../../../../models/nationalities'
import FormError from '../../../../../../components/form/error'
import industry from '../../../../../../models/industry'
import occupations from '../../../../../../models/occupations'
import FileUploader from '../../../../../../components/file/uploader'
import schema from './schema'
import config from 'config'
import focusErrorInput from '../../../../../../helpers/focusErrorInput'

const employmentTypeOptions = [
    { label: 'Employed', value: 'EMPLOYED' },
    { label: 'Self-Employed/Freelance', value: 'SELF_EMPLOYED' },
    { label: 'Unemployed', value: 'UNEMPLOYED' },
    { label: 'Student', value: 'STUDENT' },
    { label: 'Retiree', value: 'RETIREE' },
    { label: 'Homemaker', value: 'HOMEMAKER' }
]

const employedEmploymentTypeOptions = employmentTypeOptions.filter((option, index) => {
    return option.value !== 'STUDENT' && option.value !== 'UNEMPLOYED'
})

const { Option } = Select

const CompleteEmploymentAndSpouseProfileForm = props => {
    const refFormWrapper = useRef(null)
    const step = props.store.step2
    const isAllFieldsDisabled = (props.store?.applicationStatus !== 'EMAIL_VERIFIED' && props.store?.applicationStatus !== 'AMEND' && props.store?.applicationStatus !== 'AMEND_WITH_DOCUMENT') || props.isLocked
    const [apiErrorMessages, setApiErrorMessages] = useState([])

    const spousePhoneCountryCode = (!step?.spouseContactPrefix) ? 'MY' : defaultAreas.filter((itm) => itm.phoneCode.toString() === step?.spouseContactPrefix)[0].short
    const officePhoneCountryCode = (!step?.officeNumberPrefix) ? 'MY' : defaultAreas.filter((itm) => itm.phoneCode.toString() === step?.officeNumberPrefix)[0].short
    const [submitting, setSubmitting] = useState(false)

    const { handleSubmit, setValue, getValues, setFocus, setError, control, trigger, watch, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        context: { hasSpouse: props.store.step1.hasSpouse, accountType: props.store.step1.accountType },
        defaultValues: step.formData
    })

    useEffect(() => {
    }, [])

    const handleDocumentBeforeUpload = (file) => {
        const isFormatValid = file.type === 'application/pdf'
        if (!isFormatValid) {
            message.error('You can only upload PDF file!')
        }
        const isFileSizeValid = file.size / 1024 / 1024 < 50
        if (!isFileSizeValid) {
            message.error('Document must be smaller than 50MB!')
        }
        return (isFormatValid && isFileSizeValid) || Upload.LIST_IGNORE
    }

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            setApiErrorMessages([])
            const res = await step?.submit(props.store.accessToken)

            if (res.ok) {
                if (props.onSuccessSubmit) props.onSuccessSubmit()
            } else if (res.status === 422) {
                if (res.data?.errors) {
                    const inputFields = Object.keys(watch())
                    const errors = res.data.errors
                    const { nonErrorFieldMessages } = focusErrorInput(inputFields, errors, setFocus, setError, ['officeNumber', 'spouseContactPrefix', 'spouseContactNumber'])
                    setApiErrorMessages(nonErrorFieldMessages)
                    if (nonErrorFieldMessages.length !== 0) refFormWrapper.current.scrollIntoView({ behavior: 'smooth' })
                }
            } else {
                setApiErrorMessages(['Something went wrong'])
                refFormWrapper.current.scrollIntoView({ behavior: 'smooth' })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <div ref={refFormWrapper}>
            <Form
                className={cn(props.className, 'w-full complete-employment-and-spouse-profile-form')}
                layout='vertical'
                onFinish={handleSubmit(onSubmit)}
            >
                {apiErrorMessages.length !== 0 &&
                <FormError className='mb-5' messages={apiErrorMessages} />}

                <h2 className='text-2xl mb-3'>
                    Employment Particulars
                </h2>

                <div className='gray mb-6 font-italic'>
                    Kindly ensure that the details in the financial particulars section are filled out accurately, as the trading limit approval is subject to the information provided
                </div>

                <fieldset disabled={isAllFieldsDisabled}>
                    <Row gutter={24}>
                        <Col xs={24} md={8} lg={8} span={8}>
                            <Form.Item
                                className='quesTitle'
                                label='Employment Status'
                                validateStatus={(errors?.employmentType?.message) ? 'error' : '-'}
                                help={errors?.employmentType?.message}
                            >
                                <Controller
                                    name='employmentType'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Select
                                            ref={ref}
                                            name='employmentType'
                                            dropdownClassName='employment-type-dropdown'
                                            disabled={isAllFieldsDisabled}
                                            placeholder='Select Type'
                                            dropdownMatchSelectWidth={190}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e)
                                                step?.setEmploymentType(e)
                                                trigger(['industry', 'occupation', 'companyName', 'yearsOfEmployment', 'officeNumber', 'incomeStatementPath'])
                                                if (e !== 'EMPLOYED' && e !== 'SELF_EMPLOYED') {
                                                    setValue('industry', null)
                                                    setValue('occupation', null)
                                                    setValue('companyName', null)
                                                    setValue('yearsOfEmployment', 0)
                                                    setValue('officeNumber', null)
                                                    setValue('incomeStatementPath', null)
                                                    setValue('additionalIncomeStatementPath', null)
                                                }
                                            }}
                                        >
                                            {props.store.step1.accountType !== 'COLLATERISED' && employmentTypeOptions.map((item, index) => {
                                                return (
                                                    <Option value={item.value} key={index}>
                                                        {item.label}
                                                    </Option>
                                                )
                                            })}

                                            {props.store.step1.accountType === 'COLLATERISED' && employedEmploymentTypeOptions.map((item, index) => {
                                                return (
                                                    <Option value={item.value} key={index}>
                                                        {item.label}
                                                    </Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8} lg={8} span={8}>
                            <Form.Item
                                className='quesTitle'
                                label='Industry'
                                validateStatus={(errors?.industry?.message) ? 'error' : '-'}
                                help={errors?.industry?.message}
                            >
                                <Controller
                                    name='industry'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Select
                                            ref={ref}
                                            name='industry'
                                            dropdownClassName='industry-dropdown'
                                            placeholder='Search to Select'
                                            optionFilterProp='children'
                                            dropdownMatchSelectWidth={266}
                                            showSearch
                                            value={value}
                                            disabled={!step?.hasJob || isAllFieldsDisabled}
                                            onChange={(e) => {
                                                onChange(e)
                                                step?.setData({ industry: e })
                                            }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                        >
                                            {industry.map((itm, idx) => <Option value={itm} key={`industry-${idx}`}>{itm}</Option>)}
                                        </Select>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8} lg={8} span={8}>
                            <Form.Item
                                className='quesTitle'
                                label='Occupation'
                                validateStatus={(errors?.occupation?.message) ? 'error' : '-'}
                                help={errors?.occupation?.message}
                            >
                                <Controller
                                    name='occupation'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Select
                                            ref={ref}
                                            name='occupation'
                                            dropdownClassName='occupation-dropdown'
                                            placeholder='Search to Select'
                                            optionFilterProp='children'
                                            dropdownMatchSelectWidth={266}
                                            showSearch
                                            disabled={!step?.hasJob || isAllFieldsDisabled}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e)
                                                step?.setData({ occupation: e })
                                            }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                        >
                                            {occupations.map((itm, idx) => <Option value={itm} key={`occupation-${idx}`}>{itm}</Option>)}
                                        </Select>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xs={24} md={17} lg={17} span={17}>
                            <Form.Item
                                className='quesTitle'
                                label='Company Name'
                                validateStatus={(errors?.companyName?.message) ? 'error' : '-'}
                                help={errors?.companyName?.message}
                            >
                                <Controller
                                    name='companyName'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Input
                                            ref={ref}
                                            name='companyName'
                                            className='input-text-full'
                                            disabled={!step?.hasJob || isAllFieldsDisabled}
                                            onChange={(e) => {
                                                onChange(e.target.value)
                                                step?.setData({ companyName: e.target.value })
                                            }}
                                            value={value}
                                        />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={7} lg={7} span={7}>
                            <Form.Item
                                className='quesTitle'
                                label='Year(s) of Employment'
                                name='yearsOfEmployment'
                                validateStatus={(errors?.yearsOfEmployment?.message) ? 'error' : '-'}
                                help={errors?.yearsOfEmployment?.message}
                            >
                                <Controller
                                    name='yearsOfEmployment'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <InputNumber
                                            ref={ref}
                                            name='yearsOfEmployment'
                                            min={0}
                                            max={100}
                                            precision={0}
                                            disabled={!step?.hasJob || isAllFieldsDisabled}
                                            onChange={(e) => {
                                                onChange(e)
                                                if (Number.isInteger(e)) step?.setData({ yearsOfEmployment: Number(e) })
                                            }}
                                            value={value}
                                        />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='quesTitle'
                                label='Office Number'
                                name='officeNumber'
                                validateStatus={(errors?.officeNumber?.message) ? 'error' : '-'}
                                help={errors?.officeNumber?.message}
                            >
                                <Controller
                                    name='officeNumber'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <ConfigProvider
                                            locale={en}
                                            areaMapper={(area) => {
                                                return {
                                                    ...area,
                                                    emoji: <span className={`fp ${area.short.toLowerCase()}`} />
                                                }
                                            }}
                                        >
                                            <CountryPhoneInput
                                                className='office-contact'
                                                disabled={!step?.hasJob || isAllFieldsDisabled}
                                                onChange={(e) => {
                                                    onChange(e.phone)
                                                    step?.setData({ officeNumber: e.phone, officeNumberPrefix: e.code.toString() })
                                                }}
                                                value={{ short: officePhoneCountryCode, phone: step?.officeNumber }}
                                            />
                                        </ConfigProvider>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='quesTitle'
                                label='Gross Annual Income(RM)'
                                name='annualIncome'
                                validateStatus={(errors?.annualIncome?.message) ? 'error' : '-'}
                                help={errors?.annualIncome?.message}
                            >
                                <Controller
                                    name='annualIncome'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Select
                                            ref={ref}
                                            name='annualIncome'
                                            dropdownClassName='annual-income-dropdown'
                                            disabled={isAllFieldsDisabled}
                                            placeholder='Select Range'
                                            dropdownMatchSelectWidth={190}
                                            onChange={(e) => {
                                                onChange(e)
                                                step?.setData({ annualIncome: e })
                                            }}
                                            value={value}
                                        >
                                            <Option value='_0_TO_50000' key='annualIncome-1'>0 - 50,000</Option>
                                            <Option value='_50001_TO_100000' key='annualIncome-2'>50,001 - 100,000</Option>
                                            <Option value='_100001_TO_250000' key='annualIncome-3'>100,001 - 250,000</Option>
                                            <Option value='_250001_TO_500000' key='annualIncome-4'>250,001 - 500,000</Option>
                                            <Option value='_500001_TO_1000000' key='annualIncome-5'>500,001 - 1,000,000</Option>
                                            <Option value='_ABOVE_1000000' key='annualIncome-6'>{'>'}1,000,000</Option>
                                        </Select>
                                    )}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='quesTitle'
                                label='Estimated Net Worth(RM)'
                                name='netWorth'
                                validateStatus={(errors?.netWorth?.message) ? 'error' : '-'}
                                help={errors?.netWorth?.message}
                            >
                                <Controller
                                    name='netWorth'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Select
                                            ref={ref}
                                            name='netWorth'
                                            dropdownClassName='net-worth-dropdown'
                                            disabled={isAllFieldsDisabled}
                                            placeholder='Select Range'
                                            dropdownMatchSelectWidth={190}
                                            onChange={(e) => {
                                                onChange(e)
                                                step?.setData({ netWorth: e })
                                            }}
                                            value={value}
                                        >
                                            <Option value='_0_TO_250000' key='netWorth-1'>0 - 250,000</Option>
                                            <Option value='_250001_TO_500000' key='netWorth-2'>250,001 - 500,000</Option>
                                            <Option value='_500001_TO_1000000' key='netWorth-3'>500,001 - 1,000,000</Option>
                                            <Option value='_1000001_TO_5000000' key='netWorth-4'>1,000,001 - 5,000,000</Option>
                                            <Option value='_ABOVE_5000000' key='netWorth-6'>{'>'}5,000,000</Option>
                                        </Select>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        className='quesTitle'
                        label='Source of Investment'
                        validateStatus={(errors?.sourceOfInvestment?.message) ? 'error' : '-'}
                        help={errors?.sourceOfInvestment?.message}
                    >
                        <Controller
                            name='sourceOfInvestment'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <Checkbox.Group
                                    ref={ref}
                                    name='sourceOfInvestment'
                                    style={{ width: '100%' }}
                                    onChange={(e) => {
                                        onChange(e)
                                        step?.setData({ sourceOfInvestment: e })
                                        if (!e.includes('OTHERS')) {
                                            setValue('sourceOfInvestmentOther', null)
                                            step?.setData({ sourceOfInvestmentOther: null })
                                        }
                                        trigger('sourceOfInvestmentOther')
                                    }}
                                    value={value}
                                >
                                    <Row>
                                        <Col xs={24} md={8} lg={8} span={8}>
                                            <Checkbox value='SAVINGS'>Savings</Checkbox>
                                        </Col>
                                        <Col xs={24} md={8} lg={8} span={8}>
                                            <Checkbox value='SALARY'>Salary</Checkbox>
                                        </Col>
                                        <Col xs={24} md={8} lg={8} span={8}>
                                            <Checkbox value='BUSINESS_INCOME'>Business Income</Checkbox>
                                        </Col>
                                        <Col xs={24} span={24}>
                                            <div className='flex flex-col md:flex-row md:mt-6'>
                                                <Checkbox className='w-full md:w-2/12 mb-2' value='OTHERS'>Others</Checkbox>

                                                <Form.Item
                                                    className='quesTitle mb-0 w-full md:w-10/12 md:-mt-2'
                                                    validateStatus={(errors?.sourceOfInvestmentOther?.message) ? 'error' : '-'}
                                                    help={errors?.sourceOfInvestmentOther?.message}
                                                >
                                                    <Controller
                                                        name='sourceOfInvestmentOther'
                                                        control={control}
                                                        render={({ field: { value, onChange, ref } }) => (
                                                            <Input
                                                                name='sourceOfInvestmentOther'
                                                                ref={ref}
                                                                className='input-text-full'
                                                                disabled={!getValues('sourceOfInvestment').includes('OTHERS')}
                                                                onChange={(e) => {
                                                                    onChange(e.target.value)
                                                                    step?.setData({ sourceOfInvestmentOther: e.target.value })
                                                                }}
                                                                value={value}
                                                            />
                                                        )}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            )}
                        />
                    </Form.Item>

                    {props.store.step1.accountType === 'COLLATERISED' && step.employmentType &&
                    <Row gutter={24}>
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='quesTitle'
                                label={
                                    <div className='flex justify-between w-full'>
                                        {(step.employmentType === 'EMPLOYED' || step.employmentType === 'SELF_EMPLOYED') &&
                                        <span>Latest 3 Months Payslip/B or BE Form/EPF Statement</span>}

                                        {(step.employmentType === 'RETIREE' || step.employmentType === 'HOMEMAKER') &&
                                        <span>Latest 3 Months Bank Statements</span>}
                                    </div>
                                }
                                validateStatus={(errors?.incomeStatementPath?.message) ? 'error' : '-'}
                                help={errors?.incomeStatementPath?.message}
                            >
                                <Controller
                                    name='incomeStatementPath'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <FileUploader
                                            disabled={isAllFieldsDisabled}
                                            style={{ width: '100%' }}
                                            name='incomeStatementPath'
                                            accept='application/pdf'
                                            listType='picture-card'
                                            className='avatar-uploader'
                                            showUploadList={false}
                                            uploadedDocumentSrc={value}
                                            action={`${config.app.apiUrl}/document/upload-income-statement`}
                                            headers={{ Authorization: `Bearer ${props.store.accessToken}` }}
                                            beforeUpload={handleDocumentBeforeUpload}
                                            onChange={(info) => {
                                                const { status } = info.file
                                                if (status === 'done') {
                                                    const { url } = info.file.response
                                                    info.file.name = url
                                                    info.file.url = url
                                                    onChange(url)
                                                    step?.setData({ incomeStatementPath: url })
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='quesTitle'
                                label={
                                    <div className='flex justify-between w-full'>
                                        <span>Additional Income Supporting Document</span>
                                    </div>
                                }
                                validateStatus={(errors?.additionalIncomeStatementPath?.message) ? 'error' : '-'}
                                help={errors?.additionalIncomeStatementPath?.message}
                            >
                                <Controller
                                    name='additionalIncomeStatementPath'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <FileUploader
                                            disabled={isAllFieldsDisabled}
                                            style={{ width: '100%' }}
                                            name='additionalIncomeStatementPath'
                                            accept='application/pdf'
                                            listType='picture-card'
                                            className='avatar-uploader'
                                            showUploadList={false}
                                            uploadedDocumentSrc={value}
                                            action={`${config.app.apiUrl}/document/upload-additional-income-statement`}
                                            headers={{ Authorization: `Bearer ${props.store.accessToken}` }}
                                            beforeUpload={handleDocumentBeforeUpload}
                                            onChange={(info) => {
                                                const { status } = info.file
                                                if (status === 'done') {
                                                    const { url } = info.file.response
                                                    info.file.name = url
                                                    info.file.url = url
                                                    onChange(url)
                                                    step?.setData({ additionalIncomeStatementPath: url })
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>}

                    {props.store.step1.hasSpouse &&
                    <>
                        <h2 className='text-2xl mb-5 mt-8'>
                            Spouse Details
                        </h2>

                        <Form.Item
                            className='quesTitle'
                            label='Full Name (As Per NRIC)'
                            validateStatus={(errors?.spouseFullName?.message) ? 'error' : '-'}
                            help={errors?.spouseFullName?.message}
                        >
                            <Controller
                                name='spouseFullName'
                                control={control}
                                render={({ field: { value, onChange, ref } }) => (
                                    <Input
                                        ref={ref}
                                        name='spouseFullName'
                                        className='input-text-full'
                                        onChange={(e) => {
                                            onChange(e.target.value)
                                            step?.setData({ spouseFullName: e.target.value })
                                        }}
                                        value={value}
                                    />
                                )}
                            />
                        </Form.Item>

                        <Row gutter={16}>
                            <Col xs={24} md={12} lg={12} span={12}>
                                <Form.Item
                                    className='quesTitle'
                                    label='Nationality'
                                    validateStatus={(errors?.spouseNationality?.message) ? 'error' : '-'}
                                    help={errors?.spouseNationality?.message}
                                >
                                    <Controller
                                        name='spouseNationality'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Select
                                                ref={ref}
                                                name='spouseNationality'
                                                dropdownClassName='spouse-nationality-dropdown'
                                                disabled={isAllFieldsDisabled}
                                                placeholder='Search to Select'
                                                optionFilterProp='children'
                                                showSearch
                                                onChange={(e) => {
                                                    onChange(e)
                                                    if (e === 'MY' || e !== 'MYPR') {
                                                        setValue('spouseDocumentExpiry', null)
                                                    }
                                                    step?.setSpouseNationality(e)
                                                    trigger('spouseDocumentExpiry')
                                                }}
                                                value={value}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                            >
                                                {nationalities.map((itm) =>
                                                    <Option
                                                        value={itm.code}
                                                        key={itm.code}
                                                    >
                                                        {itm.name}
                                                    </Option>
                                                )}
                                            </Select>
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={12} span={12}>
                                <Form.Item
                                    className='quesTitle'
                                    label='Mobile Number'
                                    validateStatus={(errors?.spouseContactNumber?.message) ? 'error' : '-'}
                                    help={errors?.spouseContactNumber?.message}
                                >
                                    <Controller
                                        name='spouseContactNumber'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <ConfigProvider
                                                locale={en}
                                                areaMapper={(area) => {
                                                    return {
                                                        ...area,
                                                        emoji: <span className={`fp ${area.short.toLowerCase()}`} />
                                                    }
                                                }}
                                            >
                                                <CountryPhoneInput
                                                    className='spouse-contact'
                                                    disabled={isAllFieldsDisabled}
                                                    onChange={(e) => {
                                                        onChange(e.phone)
                                                        step?.setData({ spouseContactNumber: e.phone, spouseContactPrefix: e.code.toString() })
                                                    }}
                                                    value={{ short: spousePhoneCountryCode, phone: step?.spouseContactNumber }}
                                                />
                                            </ConfigProvider>
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col xs={24} md={12} lg={12} span={12}>
                                <Form.Item
                                    className='quesTitle'
                                    label='NRIC/Passport'
                                    validateStatus={(errors?.spouseDocumentNumber?.message) ? 'error' : '-'}
                                    help={errors?.spouseDocumentNumber?.message}
                                >
                                    <Controller
                                        name='spouseDocumentNumber'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Input
                                                ref={ref}
                                                name='spouseDocumentNumber'
                                                className='input-text-full'
                                                onChange={(e) => {
                                                    onChange(e.target.value)
                                                    step?.setData({ spouseDocumentNumber: e.target.value })
                                                }}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>
                            </Col>

                            {(step?.spouseNationality !== 'MY' && step?.spouseNationality !== 'MYPR') &&
                            <Col xs={24} md={12} lg={12} span={12}>
                                <Form.Item
                                    className='quesTitle'
                                    label='Passport Expiry Date'
                                    validateStatus={(errors?.spouseDocumentExpiry?.message) ? 'error' : '-'}
                                    help={errors?.spouseDocumentExpiry?.message}
                                >
                                    <Controller
                                        name='spouseDocumentExpiry'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <DatePicker
                                                ref={ref}
                                                className='input-text-full'
                                                format='DD-MM-YYYY'
                                                disabled={step?.spouseNationality === 'MY' || isAllFieldsDisabled}
                                                onChange={(e) => {
                                                    onChange(e)
                                                    let spouseDocumentExpiry = null
                                                    if (e) spouseDocumentExpiry = new Date(e.year(), e.month(), e.date())
                                                    step?.setData({ spouseDocumentExpiry })
                                                }}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>
                            </Col>}
                        </Row>

                        <Row gutter={24}>
                            <Col xs={24} md={8} lg={8} span={8}>
                                <Form.Item
                                    className='quesTitle'
                                    label='Employment Status'
                                    validateStatus={(errors?.spouseEmploymentType?.message) ? 'error' : '-'}
                                    help={errors?.spouseEmploymentType?.message}
                                >
                                    <Controller
                                        name='spouseEmploymentType'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Select
                                                ref={ref}
                                                name='spouseEmploymentType'
                                                dropdownClassName='spouse-employment-type-dropdown'
                                                disabled={isAllFieldsDisabled}
                                                placeholder='Select Type'
                                                dropdownMatchSelectWidth={190}
                                                onChange={(e) => {
                                                    onChange(e)
                                                    step?.setData({ spouseEmploymentType: e })
                                                    step?.setSpouseEmploymentType(e)
                                                    trigger(['spouseIndustry', 'spouseOccupation', 'spouseCompanyName'])
                                                    if (e !== 'EMPLOYED' && e !== 'SELF_EMPLOYED') {
                                                        setValue('spouseIndustry', null)
                                                        setValue('spouseOccupation', null)
                                                        setValue('spouseCompanyName', null)
                                                    }
                                                }}
                                                value={value}
                                            >
                                                <Option value='EMPLOYED' key='spouseEmpType-1'>Employed</Option>
                                                <Option value='SELF_EMPLOYED' key='spouseEmpType-2'>Self-Employed/Freelance</Option>
                                                <Option value='UNEMPLOYED' key='spouseEmpType-3'>Unemployed</Option>
                                                <Option value='STUDENT' key='spouseEmpType-4'>Student</Option>
                                                <Option value='RETIREE' key='spouseEmpType-5'>Retiree</Option>
                                                <Option value='HOMEMAKER' key='spouseEmpType-6'>Homemaker</Option>
                                            </Select>
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8} lg={8} span={8}>
                                <Form.Item
                                    className='quesTitle'
                                    label='Industry'
                                    validateStatus={(errors?.spouseIndustry?.message) ? 'error' : '-'}
                                    help={errors?.spouseIndustry?.message}
                                >
                                    <Controller
                                        name='spouseIndustry'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Select
                                                ref={ref}
                                                name='spouseIndustry'
                                                dropdownClassName='spouse-industry-dropdown'
                                                placeholder='Search to Select'
                                                optionFilterProp='children'
                                                dropdownMatchSelectWidth={266}
                                                showSearch
                                                value={value}
                                                disabled={!step?.spouseHasJob || isAllFieldsDisabled}
                                                onChange={(e) => {
                                                    onChange(e)
                                                    step?.setData({ spouseIndustry: e })
                                                }}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                            >
                                                {industry.map((itm, idx) => <Option value={itm} key={idx}>{itm}</Option>)}
                                            </Select>
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8} lg={8} span={8}>
                                <Form.Item
                                    className='quesTitle'
                                    label='Occupation'
                                    validateStatus={(errors?.spouseOccupation?.message) ? 'error' : '-'}
                                    help={errors?.spouseOccupation?.message}
                                >
                                    <Controller
                                        name='spouseOccupation'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Select
                                                ref={ref}
                                                name='spouseOccupation'
                                                dropdownClassName='spouse-occupation-dropdown'
                                                placeholder='Search to Select'
                                                optionFilterProp='children'
                                                dropdownMatchSelectWidth={266}
                                                showSearch
                                                disabled={!step?.spouseHasJob || isAllFieldsDisabled}
                                                onChange={(e) => {
                                                    onChange(e)
                                                    step?.setData({ spouseOccupation: e })
                                                }}
                                                value={value}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                            >
                                                {occupations.map((itm, idx) =>
                                                    <Option
                                                        value={itm}
                                                        key={`spouse-occupation-${idx}`}
                                                    >
                                                        {itm}
                                                    </Option>
                                                )}
                                            </Select>
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item
                            className='quesTitle'
                            label='Company Name'
                            validateStatus={(errors?.spouseCompanyName?.message) ? 'error' : '-'}
                            help={errors?.spouseCompanyName?.message}
                        >
                            <Controller
                                name='spouseCompanyName'
                                control={control}
                                render={({ field: { value, onChange, ref } }) => (
                                    <Input
                                        ref={ref}
                                        name='spouseCompanyName'
                                        className='input-text-full'
                                        disabled={!step?.spouseHasJob || isAllFieldsDisabled}
                                        onChange={(e) => {
                                            onChange(e.target.value)
                                            step?.setData({ spouseCompanyName: e.target.value })
                                        }}
                                        value={value}
                                    />
                                )}
                            />
                        </Form.Item>

                        <Row gutter={24}>
                            <Col xs={24} md={12} lg={12} span={12}>
                                <Form.Item
                                    className='quesTitle'
                                    label='Gross Annual Income(RM)'
                                    validateStatus={(errors?.spouseAnnualIncome?.message) ? 'error' : '-'}
                                    help={errors?.spouseAnnualIncome?.message}
                                >
                                    <Controller
                                        name='spouseAnnualIncome'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Select
                                                ref={ref}
                                                name='spouseAnnualIncome'
                                                dropdownClassName='spouse-annual-income-dropdown'
                                                disabled={isAllFieldsDisabled}
                                                placeholder='Select Range'
                                                dropdownMatchSelectWidth={190}
                                                onChange={(e) => {
                                                    onChange(e)
                                                    step?.setData({ spouseAnnualIncome: e })
                                                }}
                                                value={value}
                                            >
                                                <Option value='_0_TO_50000' key='spouseIncome-1'>0 - 50,000</Option>
                                                <Option value='_50001_TO_100000' key='spouseIncome-2'>50,001 - 100,000</Option>
                                                <Option value='_100001_TO_250000' key='spouseIncome-3'>100,001 - 250,000</Option>
                                                <Option value='_250001_TO_500000' key='spouseIncome-4'>250,001 - 500,000</Option>
                                                <Option value='_500001_TO_1000000' key='spouseIncome-5'>500,001 - 1,000,000</Option>
                                                <Option value='_ABOVE_1000000' key='spouseIncome-6'>{'>'}1,000,000</Option>
                                            </Select>
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>}

                    {!isAllFieldsDisabled &&
                    <>
                        <SubmitButton
                            className='w-full mt-5 submit-btn'
                            label='Submit'
                            submitting={submitting}
                        />

                        <div className='flex justify-center gray my-3'>
                            By clicking continue I agree that all information provided are accurate.
                        </div>
                    </>}
                </fieldset>

                {isAllFieldsDisabled &&
                <Button
                    className='w-full mt-5 submit-btn'
                    label='Next'
                    onClick={props.onNext}
                />}
            </Form>
        </div>
    )
}

CompleteEmploymentAndSpouseProfileForm.propTypes = {
    className: PropTypes.string,
    onSuccessSubmit: PropTypes.func,
    onNext: PropTypes.func,
    isLocked: PropTypes.bool
}

CompleteEmploymentAndSpouseProfileForm.defaultProps = {
    isLocked: false
}

export default inject('store')(observer(CompleteEmploymentAndSpouseProfileForm))
