import { useState, useEffect } from 'react'
import { Form, Input } from 'antd'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { inject } from 'mobx-react'
import FormError from '../../../../../components/form/error'
import SubmitButton from '../../../../../components/button/submit'
import focusErrorInput from '../../../../../helpers/focusErrorInput'
import schema from './schema'

const DiligenceVerificationForm = props => {
    const [form] = Form.useForm()
    const [submitting, setSubmitting] = useState(false)
    const [apiErrorMessages, setApiErrorMessages] = useState([])
    const { handleSubmit, setError, control, reset, watch, setFocus, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: props.defaultValues
    })

    useEffect(() => {
    }, [])

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            setApiErrorMessages([])

            const res = await props.services.diligenceService.verify(props.code, data.documentNumber)

            if (res.ok) {
                const payload = res.data
                if (payload.data.verification) {
                    if (props.onSuccessSubmit) props.onSuccessSubmit(payload)
                } else {
                    setApiErrorMessages([
                        'Verification failed'
                    ])
                }
            } else if (res.status === 422) {
                if (res.data?.errors) {
                    const inputFields = Object.keys(watch())
                    const errors = res.data.errors
                    const { nonErrorFieldMessages } = focusErrorInput(inputFields, errors, setFocus, setError)
                    setApiErrorMessages(nonErrorFieldMessages)
                }
            } else {
                setApiErrorMessages(['Something went wrong'])
            }
        } catch (error) {
            console.error(error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Form
            className={cn(props.className, 'w-full')}
            layout='vertical'
            onFinish={handleSubmit(onSubmit)}
            form={form}
        >
            {apiErrorMessages.length !== 0 &&
            <FormError className='mb-3' messages={apiErrorMessages} />}

            <Form.Item
                className='mb-3 quesTitle'
                label='NRIC/Passport'
                validateStatus={(errors?.documentNumber?.message) ? 'error' : '-'}
                help={errors?.documentNumber?.message}
            >
                <Controller
                    name='documentNumber'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Input
                            ref={ref}
                            className='input-text-full'
                            placeholder='NRIC/Passport'
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <SubmitButton
                className='w-full mt-3'
                label='Verify'
                labelSubmitting='Verifying'
                submitting={submitting}
            />
        </Form>
    )
}

DiligenceVerificationForm.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    code: PropTypes.string.isRequired,
    onSuccessSubmit: PropTypes.func
}

DiligenceVerificationForm.defaultProps = {
    onSuccessSubmit: () => {}
}

export default inject('services')(DiligenceVerificationForm)
