export const createApplicationEndpointPath = '/application/create'
export const resumeApplicationEndpointPath = '/application/resume'
export const resendEmailApplicationEndpointPath = '/application/resend'
export const verifyByOTPEndpointPath = '/application/verify-otp'
export const checkApplicationEndpointPath = '/application/check-token'
export const fetchApplicationEndpointPath = '/application/fetch'
export const verifyApplicationEndpointPath = '/application/verify-code'
export const completePersonalProfileEndpointPath = '/application/create-step1'
export const completeEmploymentAndSpouseProfileEndpointPath = '/application/create-step2'
export const completeFinancialProfileEndpointPath = '/application/create-step3'
export const completeTaxationAndDeclarationProfileEndpointPath = '/application/create-step4'
export const uploadSignatureEndpointPath = '/document/upload-signature'
export const uploadAmendDocumentEndpointPath = '/document/upload-amend-document'
export const submitAmendEndpointPath = '/application/amend-submit'
export const getApplicationEkycStatusEndpointPath = '/application/by-ctos'

const ApplicationService = (api, authToken) => ({
    async create (fullName, email, drCode = null, partnerId = null, partnerMemberId = null, isValidateByOTP = false, exclude) {
        const data = {
            fullName,
            email,
            mobile: isValidateByOTP
        }

        if (drCode) {
            data.drCode = drCode
        }
        if (partnerId) {
            data.partnerId = partnerId
        }
        if (partnerMemberId) {
            data.partnerMemberId = partnerMemberId
        }
        if (exclude) {
            data.exclude = exclude
        }

        const res = await api.post(createApplicationEndpointPath, data)
        return res
    },

    async resume (fullName, email, isValidateByOTP = false, exclude) {
        const data = {
            fullName,
            email,
            mobile: isValidateByOTP
        }
        if (exclude) {
            data.exclude = exclude
        }

        const res = await api.post(resumeApplicationEndpointPath, data)
        return res
    },

    async resendEmail (email, isValidateByOTP = false, exclude) {
        const data = {
            email,
            mobile: isValidateByOTP
        }
        if (exclude) {
            data.exclude = exclude
        }

        const res = await api.post(resendEmailApplicationEndpointPath, data)
        return res
    },

    async verifyByOTP (otp, email, exclude) {
        const data = {
            pin: otp,
            email
        }
        if (exclude) {
            data.exclude = exclude
        }

        const res = await api.post(verifyByOTPEndpointPath, data)
        return res
    },

    async resendOTP (email, exclude) {
        const data = {
            email,
            mobile: true
        }
        if (exclude) {
            data.exclude = exclude
        }

        const res = await api.post(resendEmailApplicationEndpointPath, data)
        return res
    },

    async validateAccessToken (accessToken) {
        api.setAuthToken(accessToken)
        const res = await api.get(checkApplicationEndpointPath)
        return res
    },

    async fetch (accessToken) {
        api.setAuthToken(accessToken)
        const res = await api.get(fetchApplicationEndpointPath)
        return res
    },

    async verify (code) {
        const res = await api.post(verifyApplicationEndpointPath, {
            code
        })
        return res
    },

    async completePersonalProfile (accessToken, data) {
        api.setAuthToken(accessToken)
        const res = await api.post(completePersonalProfileEndpointPath, data)
        return res
    },

    async completeEmploymentAndSpouseProfile (accessToken, data) {
        api.setAuthToken(accessToken)
        const res = await api.post(completeEmploymentAndSpouseProfileEndpointPath, data)
        return res
    },

    async completeFinancialProfile (accessToken, data) {
        api.setAuthToken(accessToken)
        const res = await api.post(completeFinancialProfileEndpointPath, data)
        return res
    },

    async completeTaxationAndDeclarationProfile (accessToken, data) {
        api.setAuthToken(accessToken)
        const res = await api.post(completeTaxationAndDeclarationProfileEndpointPath, data)
        return res
    },

    async uploadSignature (accessToken, base64) {
        api.setAuthToken(accessToken)
        const res = await api.post(uploadSignatureEndpointPath, {
            content: base64
        })
        return res
    },

    async uploadAmendDocument (accessToken, formData) {
        api.setAuthToken(accessToken)
        const res = await api.post(uploadAmendDocumentEndpointPath, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        return res
    },

    async submitAmend (accessToken) {
        api.setAuthToken(accessToken)
        const res = await api.post(submitAmendEndpointPath)
        return res
    },

    async getApplicationEkycStatus (referenceId) {
        const res = await api.post(getApplicationEkycStatusEndpointPath, {
            referenceId
        })
        return res
    }
})

export default ApplicationService
