import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Row, Col } from 'antd'
import { useFormContext, Controller } from 'react-hook-form'
import cn from 'classnames'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { nonIslamicBankList, nonIslamicBankListDev, islamicBankList, brokerList } from '../../../../../../../../models/institutions'
import financialFields from '../../../../../../../../constants/financialFields'
import SelectInput, { SelectInputWithController } from '../../../../../../../../components/input/select'
import Radio, { RadioGroupWithController } from '../../../../../../../../components/input/radio'
import { TextInputWithController } from '../../../../../../../../components/input/text'
import { SingleFileInputWithController } from '../../../../../../../../components/input/file/single'
import config from 'config'

const { Option } = SelectInput

const nonIslamicBankOptions = (config.app.isProduction) ? nonIslamicBankList : nonIslamicBankListDev

const FinancialInfoFieldset = props => {
    const { setValue, watch, formState: { errors } } = useFormContext()

    useEffect(() => {
    }, [])

    const bankHidden = useMemo(() => {
        const value = props.defaultValues.bankName
        let bankHidden = _.find(islamicBankList, { key: value, hidden: true })
        if (!props.defaultValues.isIslamicBank) {
            bankHidden = _.find(nonIslamicBankOptions, { key: value, hidden: true })
        }
        return bankHidden
    }, [props.defaultValues.bankName])

    return (
        <fieldset className={cn(props.className)} style={props.style}>
            <Row gutter={16}>
                <Col xs={24} span={24}>
                    <RadioGroupWithController
                        name='isIslamicBank'
                        label={financialFields.isIslamicBank?.label}
                        disabled={watch('bankName') === undefined}
                        ableToToggleDisabled
                        defaultValue={(props.defaultValues.isIslamicBank) ? 1 : 0}
                        error={errors?.isIslamicBank}
                        onChange={(e) => {
                            setValue('bankName', null)
                            setValue('bankAccountNumber', null)
                            setValue('bankStatement', null)
                        }}
                    >
                        <Radio value={1}>Yes</Radio>
                        <Radio value={0}>No</Radio>
                    </RadioGroupWithController>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col xs={24} md={12} lg={12} span={12}>
                    <SelectInputWithController
                        name='bankName'
                        label={financialFields.bankName?.label}
                        disabled={watch('isIslamicBank') === undefined}
                        disabledBtnCancel={(watch('isIslamicBank') !== undefined)}
                        ableToToggleDisabled
                        defaultValue={(!bankHidden) ? props.defaultValues.bankName : bankHidden?.name}
                        error={errors?.bankName}
                    >
                        {((watch('isIslamicBank') === undefined) ? props.defaultValues.isIslamicBank : watch('isIslamicBank') === 1) &&
                        <>
                            {islamicBankList.map((itm, idx) => {
                                if (itm.hidden) return null
                                return (
                                    <Option value={itm.key} key={`islamic-bank-name-${idx}`}>{itm.name}</Option>
                                )
                            })}
                        </>}

                        {((watch('isIslamicBank') === undefined) ? !props.defaultValues.isIslamicBank : watch('isIslamicBank') === 0) &&
                        <>
                            {nonIslamicBankOptions.map((itm, idx) => {
                                if (itm.hidden) return null
                                return (
                                    <Option value={itm.key} key={`non-islamic-bank-name-${idx}`}>{itm.name}</Option>
                                )
                            })}
                        </>}
                    </SelectInputWithController>
                </Col>

                <Col xs={24} md={12} lg={12} span={12}>
                    <TextInputWithController
                        name='bankAccountNumber'
                        label={financialFields.bankAccountNumber?.label}
                        disabled={watch('isIslamicBank') === undefined && watch('bankName') === undefined}
                        ableToToggleDisabled
                        disabledBtnCancel={(watch('isIslamicBank') !== undefined || watch('bankName') !== undefined)}
                        defaultValue={props.defaultValues.bankAccountNumber}
                        error={errors?.bankAccountNumber}
                    />
                </Col>
            </Row>

            <Row gutter={16}>
                <Col xs={24} md={12} lg={12} span={12}>
                    <SingleFileInputWithController
                        name='bankStatement'
                        label={financialFields.bankStatement?.label}
                        accept='image/png, image/jpeg'
                        sampleUrl='/images/bank-statement-sample.png'
                        previewTitle='Bank Statement'
                        disabled={watch('isIslamicBank') === undefined && watch('bankName') === undefined && watch('bankAccountNumber') === undefined}
                        ableToToggleDisabled
                        disabledBtnCancel={(watch('isIslamicBank') !== undefined || watch('bankName') !== undefined || watch('bankAccountNumber') !== undefined)}
                        error={errors?.bankStatement}
                    />
                </Col>
            </Row>
        </fieldset>
    )
}

FinancialInfoFieldset.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}

FinancialInfoFieldset.defaultProps = {
}

export default FinancialInfoFieldset
