import { useState, useEffect, useRef } from 'react'
import { Col, Form, Input, Radio, Row, Select, Button as AntButton, Spin, Checkbox } from 'antd'
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import cn from 'classnames'
import _ from 'lodash'
import SubmitButton from '../../../../../../components/button/submit'
import Button from '../../../../../../components/button'
import schema from './schema'
import FormError from '../../../../../../components/form/error'
import countries, { highRiskCountryCodes, nonHighRiskCountries } from '../../../../../../models/countries'

const { Option } = Select

const CompleteTaxationAndDeclarationProfileForm = props => {
    const refFormWrapper = useRef(null)
    const step = props.store.step4
    const isAllFieldsDisabled = (props.store?.applicationStatus !== 'EMAIL_VERIFIED' && props.store?.applicationStatus !== 'AMEND' && props.store?.applicationStatus !== 'AMEND_WITH_DOCUMENT') || props.isLocked
    const [submitting, setSubmitting] = useState(false)
    const [apiErrorMessages, setApiErrorMessages] = useState([])

    const { handleSubmit, setValue, getValues, setFocus, setError, control, trigger, watch, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: step.formData
    })

    const {
        fields: relatedMsecStaffFields,
        append: appendRelatedMsecStaff,
        remove: removeRelatedMsecStaff
    } = useFieldArray({ control, name: 'relatedMsecStaff' })

    const {
        fields: relatedMsecTraderFields,
        append: appendRelatedMsecTrader,
        remove: removeRelatedMsecTrader
    } = useFieldArray({ control, name: 'relatedMsecTrader' })

    const {
        fields: relatedPepFields,
        append: appendRelatedPep,
        remove: removeRelatedPep
    } = useFieldArray({ control, name: 'relatedPep' })

    // useEffect(() => {
    //     if (step?.countryOfResidence === 'MY') {
    //         setError('countryOfResidence', {
    //             type: 'manual',
    //             message: 'Kindly select YES for the above question Are you a Resident of Malaysia'
    //         })
    //     }
    // }, [step?.isMalaysiaResident])

    useEffect(() => {
        if (step?.taxCountry === 'MY') {
            setError('taxCountry', {
                type: 'manual',
                message: 'Kindly select Country of Tax Residence outside Malaysia'
            })
        }
    }, [step?.taxCountry])

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            setApiErrorMessages([])
            const res = await step?.submit(props.store.accessToken)

            if (res.ok) {
                if (props.onSuccessSubmit) props.onSuccessSubmit()
            } else if (res.status === 422) {
                if (res.data?.errors) {
                    const nonErrorFieldMessages = []
                    const errors = Object.keys(res.data.errors)
                    const inputFields = Object.keys(watch())

                    errors.forEach((key, index) => {
                        const firstErrorMsg = res.data.errors[key][0]
                        if (inputFields.includes(key)) {
                            if (typeof firstErrorMsg === 'object') {
                                res.data.errors[key].forEach((item, j) => {
                                    for (const field in item) {
                                        const keyStr = `${key}[${j}].${field}`
                                        const errorMsg = item[field][0]

                                        if (j === 0 && index === 0) {
                                            setFocus(keyStr)
                                        }
                                        setError(keyStr, {
                                            type: 'manual',
                                            message: errorMsg
                                        })
                                    }
                                })
                            } else {
                                if (index === 0) {
                                    setFocus(key)
                                }
                                setError(key, {
                                    type: 'manual',
                                    message: firstErrorMsg
                                })
                            }
                        } else {
                            nonErrorFieldMessages.push(firstErrorMsg)
                        }
                    })

                    setApiErrorMessages(nonErrorFieldMessages)
                    if (nonErrorFieldMessages.length !== 0) refFormWrapper.current.scrollIntoView({ behavior: 'smooth' })
                }
            } else {
                setApiErrorMessages(['Something went wrong'])
                refFormWrapper.current.scrollIntoView({ behavior: 'smooth' })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <div ref={refFormWrapper}>
            <Form
                className={cn(props.className, 'w-full complete-taxation-and-declaration-form')}
                layout='vertical'
                onFinish={handleSubmit(onSubmit)}
            >
                {apiErrorMessages.length !== 0 &&
                <FormError className='mb-5' messages={apiErrorMessages} />}

                <fieldset disabled={isAllFieldsDisabled}>
                    <h2 className='text-2xl mb-5'>
                        Tax Residency
                    </h2>

                    <Row gutter={24}>
                        <Col xs={24} span={24}>
                            <Form.Item
                                className='quesTitle'
                                label='Are you a tax resident of another country(s) outside Malaysia?'
                                validateStatus={(errors?.hasForeignTax?.message) ? 'error' : '-'}
                                help={errors?.hasForeignTax?.message}
                            >
                                <Controller
                                    name='hasForeignTax'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Radio.Group
                                            name='hasForeignTax'
                                            onChange={(e) => {
                                                onChange(e.target.value)
                                                if (e.target.value !== 1) {
                                                    setValue('birthCountry', null)
                                                    setValue('birthCity', null)
                                                    setValue('taxCountry', null)
                                                    setValue('hasTin', null)
                                                    setValue('reasonForNoTin', null)
                                                    setValue('reasonForUnableToObtainTin', null)
                                                }
                                                step?.setHasForeignTax(e.target.value === 1)
                                            }}
                                            value={value}
                                        >
                                            <Radio value={0}>No</Radio>
                                            <Radio value={1}>Yes</Radio>
                                        </Radio.Group>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {step?.hasForeignTax &&
                    <>
                        <Row gutter={16}>
                            <Col xs={24} md={6} lg={6} span={6}>
                                <Form.Item
                                    className='quesTitle'
                                    label='Country of Birth'
                                    validateStatus={(errors?.birthCountry?.message) ? 'error' : '-'}
                                    help={errors?.birthCountry?.message}
                                >
                                    <Controller
                                        name='birthCountry'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => {
                                            const isHighRiskCountry = highRiskCountryCodes.includes(value)
                                            let highRiskCountry = null

                                            if (isHighRiskCountry) {
                                                highRiskCountry = _.find(countries, { code: value })
                                            }

                                            return (
                                                <Select
                                                    ref={ref}
                                                    name='birthCountry'
                                                    dropdownClassName='birth-country-dropdown'
                                                    disabled={isAllFieldsDisabled}
                                                    placeholder='Search to Select'
                                                    optionFilterProp='children'
                                                    showSearch
                                                    onChange={(e) => {
                                                        onChange(e)
                                                        step?.setData({ birthCountry: e })
                                                    }}
                                                    value={(!isHighRiskCountry) ? value : highRiskCountry?.name}
                                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                                >
                                                    {nonHighRiskCountries.map((itm) => <Option value={itm.code} key={itm.code}>{itm.name}</Option>)}
                                                </Select>
                                            )
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={6} lg={6} span={6}>
                                <Form.Item
                                    className='quesTitle'
                                    label='City of Birth'
                                    validateStatus={(errors?.birthCity?.message) ? 'error' : '-'}
                                    help={errors?.birthCity?.message}
                                >
                                    <Controller
                                        name='birthCity'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Input
                                                ref={ref}
                                                name='birthCity'
                                                className='input-text-full'
                                                onChange={(e) => {
                                                    onChange(e.target.value)
                                                    step?.setData({ birthCity: e.target.value })
                                                }}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={6} lg={6} span={6}>
                                <Form.Item
                                    className='quesTitleShort'
                                    label='Country of Tax Residence'
                                    validateStatus={(errors?.taxCountry?.message) ? 'error' : '-'}
                                    help={errors?.taxCountry?.message}
                                >
                                    <Controller
                                        name='taxCountry'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => {
                                            const isHighRiskCountry = highRiskCountryCodes.includes(value)
                                            let highRiskCountry = null

                                            if (isHighRiskCountry) {
                                                highRiskCountry = _.find(countries, { code: value })
                                            }

                                            return (
                                                <Select
                                                    ref={ref}
                                                    name='taxCountry'
                                                    dropdownClassName='tax-country-dropdown'
                                                    disabled={isAllFieldsDisabled}
                                                    placeholder='Search to Select'
                                                    optionFilterProp='children'
                                                    showSearch
                                                    onChange={(e) => {
                                                        onChange(e)
                                                        step?.setData({ taxCountry: e })
                                                    }}
                                                    value={(!isHighRiskCountry) ? ((value === 'MY') ? 'Malaysia' : value) : highRiskCountry?.name}
                                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                                >
                                                    {nonHighRiskCountries.map((itm) => {
                                                        if (itm.code === 'MY') return null
                                                        return (
                                                            <Option value={itm.code} key={itm.code}>{itm.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            )
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={6} lg={6} span={6}>
                                <Form.Item
                                    className='quesTitleShort'
                                    label='City of Tax Residence'
                                    validateStatus={(errors?.taxCity?.message) ? 'error' : '-'}
                                    help={errors?.taxCity?.message}
                                >
                                    <Controller
                                        name='taxCity'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Input
                                                ref={ref}
                                                name='taxCity'
                                                className='input-text-full'
                                                onChange={(e) => {
                                                    onChange(e.target.value)
                                                    step?.setData({ taxCity: e.target.value })
                                                }}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col xs={24} span={24}>
                                <Form.Item
                                    className='quesTitle'
                                    label='Do you have a Tax Identification Number (TIN)?'
                                    validateStatus={(errors?.hasTin?.message) ? 'error' : '-'}
                                    help={errors?.hasTin?.message}
                                >
                                    <Controller
                                        name='hasTin'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Radio.Group
                                                name='hasTin'
                                                onChange={(e) => {
                                                    onChange(e.target.value)
                                                    if (e.target.value !== 1) {
                                                        setValue('tinNumber', null)
                                                    }
                                                    step?.setHasTin(e.target.value === 1)
                                                }}
                                                value={value}
                                            >
                                                <Row style={{ marginTop: 10 }}>
                                                    <Col xs={24} md={6} lg={6} span={6}>
                                                        <Radio value={0}>No</Radio>
                                                    </Col>
                                                    <Col xs={6} md={6} lg={6} span={6} className='mt-4 md:mt-0'>
                                                        <Radio value={1}>Yes</Radio>
                                                    </Col>
                                                    <Col xs={18} md={12} lg={12} span={12} className='mt-2 md:mt-0 -mt-0 md:-mt-2'>
                                                        <Form.Item
                                                            validateStatus={(errors?.tinNumber?.message) ? 'error' : '-'}
                                                            help={errors?.tinNumber?.message}
                                                        >
                                                            <Controller
                                                                name='tinNumber'
                                                                control={control}
                                                                render={({ field: { value, onChange, ref } }) => (
                                                                    <Input
                                                                        ref={ref}
                                                                        name='tinNumber'
                                                                        className='input-text-full'
                                                                        disabled={!step?.hasTin}
                                                                        onChange={(e) => {
                                                                            onChange(e)
                                                                            step?.setData({ tinNumber: e.target.value })
                                                                        }}
                                                                        value={value}
                                                                    />
                                                                )}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Radio.Group>
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        {step?.hasTin === false &&
                        <Row gutter={24}>
                            <Col xs={24} span={24}>
                                <Form.Item
                                    className='quesTitle'
                                    label='If you do not have a TIN, kindly select one of the options below'
                                    validateStatus={(errors?.reasonForNoTin?.message) ? 'error' : '-'}
                                    help={errors?.reasonForNoTin?.message}
                                >
                                    <Controller
                                        name='reasonForNoTin'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Radio.Group
                                                name='reasonForNoTin'
                                                onChange={(e) => {
                                                    onChange(e.target.value)
                                                    step?.setData({ reasonForNoTin: e.target.value })
                                                }}
                                                value={value}
                                            >
                                                <Row>
                                                    <Col xs={24} span={24}>
                                                        <Radio value='TIN_NOT_ISSUED'>
                                                            The country where the Account Holder is liable to pay tax does not issue TINs to its residents.
                                                        </Radio>
                                                    </Col>
                                                </Row>

                                                <Row style={{ marginTop: 10 }}>
                                                    <Col xs={24} span={24}>
                                                        <Radio value='UNABLE_OBTAIN_TIN'>
                                                            The Account Holder is otherwise unable to obtain a TIN or equivalent number (Please explain why you are unable to obtain a TIN in the below table if you have selected reason)
                                                        </Radio>
                                                    </Col>

                                                    {step?.reasonForNoTin === 'UNABLE_OBTAIN_TIN' &&
                                                    <Col xs={24} span={24}>
                                                        <Form.Item
                                                            className='quesTitle my-3 mb-2'
                                                            validateStatus={(errors?.reasonForUnableToObtainTin?.message) ? 'error' : '-'}
                                                            help={errors?.reasonForUnableToObtainTin?.message}
                                                        >
                                                            <Controller
                                                                name='reasonForUnableToObtainTin'
                                                                control={control}
                                                                render={({ field: { value, onChange, ref } }) => (
                                                                    <Select
                                                                        name='reasonForUnableToObtainTin'
                                                                        dropdownClassName='reason-unable-obtain-tin-dropdown'
                                                                        ref={ref}
                                                                        disabled={isAllFieldsDisabled}
                                                                        placeholder='Select a reason'
                                                                        optionFilterProp='children'
                                                                        showSearch
                                                                        onChange={(e) => {
                                                                            onChange(e)
                                                                            step?.setData({ reasonForUnableToObtainTin: e })
                                                                        }}
                                                                        value={value}
                                                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                                                    >
                                                                        <Option value='TIN_APPLICATION_IN_PROGRESS'>TIN application in progress</Option>
                                                                        <Option value='NOT_REQUIRED_TO_REGISTER_TAX_FILE'>Not required to register tax file</Option>
                                                                        <Option value='HOUSEWIFE'>Housewife</Option>
                                                                        <Option value='HOUSEHUSBAND'>Househusband</Option>
                                                                        <Option value='MINOR'>Minor</Option>
                                                                        <Option value='STUDENT'>Student</Option>
                                                                        <Option value='RETIREE'>Retiree</Option>
                                                                        <Option value='FOREIGN_DIPLOMAT_IN_EMBASSY'>Foreign diplomat in embassy</Option>
                                                                    </Select>
                                                                )}
                                                            />
                                                        </Form.Item>
                                                    </Col>}
                                                </Row>

                                                <Row style={{ marginTop: 10 }}>
                                                    <Col xs={24} span={24}>
                                                        <Radio value='TIN_NOT_REQUIRED'>
                                                            No TIN is required. (Note: Only select this reason if the authorities of the country of tax resident entered below do not require the TIN to be disclosed)
                                                        </Radio>
                                                    </Col>
                                                </Row>
                                            </Radio.Group>
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>}
                    </>}

                    <h2 className='text-2xl mb-5 my-8'>
                        FEA Rules
                    </h2>

                    {/* <Row gutter={24}>
                        <Col xs={24} span={24}>
                            <Form.Item
                                className='quesTitle'
                                label='Are you a Resident of Malaysia?'
                                validateStatus={(errors?.isMalaysiaResident?.message) ? 'error' : '-'}
                                help={errors?.isMalaysiaResident?.message}
                            >
                                <Controller
                                    name='isMalaysiaResident'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Radio.Group
                                            onChange={(e) => {
                                                onChange(e.target.value)
                                                if (e.target.value !== 1) {
                                                    setValue('feaRule', null)
                                                }
                                                step?.setIsMalaysiaResident(e.target.value === 1)
                                            }}
                                            value={value}
                                        >
                                            <Radio value={0}>No</Radio>
                                            <Radio value={1}>Yes</Radio>
                                        </Radio.Group>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row> */}

                    <Row gutter={16}>
                        <Col xs={24} md={8} lg={8} span={8}>
                            <Form.Item
                                className='quesTitle'
                                label='Kindly, select the country of residence'
                                validateStatus={(errors?.countryOfResidence?.message) ? 'error' : '-'}
                                help={errors?.countryOfResidence?.message}
                            >
                                <Controller
                                    name='countryOfResidence'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => {
                                        const isHighRiskCountry = highRiskCountryCodes.includes(value)
                                        let highRiskCountry = null

                                        if (isHighRiskCountry) {
                                            highRiskCountry = _.find(countries, { code: value })
                                        }

                                        return (
                                            <Select
                                                ref={ref}
                                                name='countryOfResidence'
                                                dropdownClassName='country-residence-dropdown'
                                                disabled={isAllFieldsDisabled}
                                                placeholder='Search to Select'
                                                optionFilterProp='children'
                                                showSearch
                                                onChange={(e) => {
                                                    onChange(e)
                                                    if (e === 'MY') {
                                                        step?.setIsMalaysiaResident(true)
                                                    } else {
                                                        setValue('feaRule', null)
                                                        step?.setIsMalaysiaResident(false)
                                                    }
                                                    step?.setData({ countryOfResidence: e })
                                                }}
                                                value={(!isHighRiskCountry) ? value : highRiskCountry?.name}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                            >
                                                {nonHighRiskCountries.map((itm) => {
                                                    return (
                                                        <Option value={itm.code} key={itm.code}>{itm.name}</Option>
                                                    )
                                                })}
                                            </Select>
                                        )
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {step?.countryOfResidence === 'MY' &&
                    <Row gutter={24}>
                        <Col xs={24} span={24}>
                            <Form.Item
                                className='quesTitle'
                                label='Pursuant to the FEA Rules, kindly declare the below'
                                validateStatus={(errors?.feaRule?.message) ? 'error' : '-'}
                                help={errors?.feaRule?.message}
                            >
                                <Controller
                                    name='feaRule'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Radio.Group
                                            name='feaRule'
                                            onChange={(e) => {
                                                onChange(e.target.value)
                                                step?.setData({ feaRule: e.target.value })
                                            }}
                                            value={value}
                                        >
                                            <Row>
                                                <Col xs={24} span={24}>
                                                    <Radio value='NO_DOMESTIC_BORROWING'>
                                                        I do not have domestic ringgit borrowings and thus allowed to convert any and I undertake to inform MSSB immediately should I subsequently obtain domestic ringgit borrowings or upon request by MSSB
                                                    </Radio>
                                                </Col>
                                            </Row>

                                            <Row style={{ marginTop: 10 }}>
                                                <Col xs={24} span={24}>
                                                    <Radio value='DOMESTIC_BORROWING'>
                                                        I have domestic ringgit borrowings and thus are allowed to convert up to RM1,000,000 in aggregate per of my investment abroad which I have/I will have with MSSB and others, are to be taken into account for the purpose of FEA rules. I hereby undertake to inform MSSB immediately once my investment amount has reached the maximum allowable limit.
                                                    </Radio>
                                                </Col>
                                            </Row>
                                        </Radio.Group>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>}

                    <h2 className='text-2xl mb-5 my-10'>
                        Declarations
                    </h2>

                    <Row gutter={24}>
                        <Col xs={24} span={24}>
                            <Form.Item
                                className='quesTitle'
                                label='Do you have any spouse/relatives working in Malacca Securities Sdn Bhd?'
                                validateStatus={(errors?.hasRelatedMsecStaff?.message) ? 'error' : '-'}
                                help={errors?.hasRelatedMsecStaff?.message}
                            >
                                <Controller
                                    name='hasRelatedMsecStaff'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Radio.Group
                                            name='hasRelatedMsecStaff'
                                            onChange={(e) => {
                                                onChange(e.target.value)
                                                const b = e.target.value === 1
                                                step?.setHasRelatedMsecStaff(b)
                                                if (b) {
                                                    step?.addRelatedMsecStaff()
                                                    setValue('relatedMsecStaff', [{
                                                        relationship: null,
                                                        name: null
                                                    }])
                                                } else {
                                                    step?.setData({ relatedMsecStaff: [] })
                                                    setValue('relatedMsecStaff', [])
                                                }
                                            }}
                                            value={value}
                                        >
                                            <Radio value={0}>No</Radio>
                                            <Radio value={1}>Yes</Radio>
                                        </Radio.Group>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {relatedMsecStaffFields.map((itm, idx) => {
                        let error

                        if (errors.relatedMsecStaff) {
                            error = errors.relatedMsecStaff[idx]
                        }

                        return (
                            <Row gutter={16} key={itm.id} className='border-b mb-3 md:border-0 md:mb-0' style={(idx === 0) ? { marginTop: -15 } : null}>
                                <Col xs={24} md={8} lg={8} span={8}>
                                    <Form.Item
                                        label='Relationship'
                                        validateStatus={(error?.relationship?.message) ? 'error' : '-'}
                                        help={error?.relationship?.message}
                                    >
                                        <Controller
                                            name={`relatedMsecStaff.${idx}.relationship`}
                                            control={control}
                                            render={({ field: { value, onChange, ref } }) => (
                                                <Select
                                                    ref={ref}
                                                    name={`relatedMsecStaff.${idx}.relationship`}
                                                    dropdownClassName={`related-msec-staff-${idx}-relationship-dropdown`}
                                                    disabled={isAllFieldsDisabled}
                                                    placeholder='Select one'
                                                    onChange={(e) => {
                                                        onChange(e)
                                                        step?.relatedMsecStaff[idx].setData({ relationship: e })
                                                    }}
                                                    value={value}
                                                >
                                                    <Option value='SPOUSE' key={`relatedStaff-${idx}-1`}>Spouse</Option>
                                                    <Option value='SIBLINGS' key={`relatedStaff-${idx}-2`}>Siblings</Option>
                                                    <Option value='PARENTS' key={`relatedStaff-${idx}-3`}>Parents</Option>
                                                    <Option value='RELATIVES' key={`relatedStaff-${idx}-4`}>Relatives</Option>
                                                </Select>
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={22} md={14} lg={14} span={14}>
                                    <Form.Item
                                        label='Name'
                                        validateStatus={(error?.name?.message) ? 'error' : '-'}
                                        help={error?.name?.message}
                                    >
                                        <Controller
                                            name={`relatedMsecStaff.${idx}.name`}
                                            control={control}
                                            render={({ field: { value, onChange, ref } }) => (
                                                <Input
                                                    ref={ref}
                                                    name={`relatedMsecStaff.${idx}.name`}
                                                    className='input-text-full'
                                                    onChange={(e) => {
                                                        onChange(e.target.value)
                                                        step?.relatedMsecStaff[idx].setData({ name: e.target.value })
                                                    }}
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={2} md={2} lg={2} span={2}>
                                    <AntButton
                                        disabled={isAllFieldsDisabled}
                                        type='primary' shape='circle' size={12}
                                        style={{ marginTop: 36 }}
                                        icon={<div style={{ height: 30, marginTop: -3 }}><DeleteOutlined /></div>}
                                        onClick={() => {
                                            removeRelatedMsecStaff(idx)
                                            step?.removeRelatedMsecStaff(idx)
                                            if (relatedMsecStaffFields.length === 1) {
                                                setValue('hasRelatedMsecStaff', 0)
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        )
                    })}

                    {(step?.relatedMsecStaff && step?.relatedMsecStaff.length > 0) &&
                    <button
                        disabled={isAllFieldsDisabled}
                        type='button'
                        style={{ marginBottom: 24, fontStyle: 'italic', opacity: 0.7 }}
                        onClick={() => {
                            appendRelatedMsecStaff({
                                name: null,
                                relationship: null
                            })
                            step?.addRelatedMsecStaff()
                        }}
                    >
                        Add more?
                    </button>}

                    <Row gutter={24}>
                        <Col xs={24} span={24}>
                            <Form.Item
                                className='quesTitle'
                                label='Is/Are there any trading account holder(s) of Malacca Securities Sdn Bhd who/whom is/are related to you?'
                                validateStatus={(errors?.hasRelatedMsecTrader?.message) ? 'error' : '-'}
                                help={errors?.hasRelatedMsecTrader?.message}
                            >
                                <Controller
                                    name='hasRelatedMsecTrader'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Radio.Group
                                            name='hasRelatedMsecTrader'
                                            onChange={(e) => {
                                                onChange(e.target.value)
                                                const b = e.target.value === 1
                                                step?.setHasRelatedMsecTrader(b)
                                                if (b) {
                                                    step?.addRelatedMsecTrader()
                                                    setValue('relatedMsecTrader', [{
                                                        relationship: null,
                                                        name: null,
                                                        clientCode: null
                                                    }])
                                                } else {
                                                    step?.setData({ relatedMsecTrader: [] })
                                                    setValue('relatedMsecTrader', [])
                                                }
                                            }} value={(step?.hasRelatedMsecTrader ? 1 : (step?.hasRelatedMsecTrader === null ? null : 0))}
                                        >
                                            <Radio value={0}>No</Radio>
                                            <Radio value={1}>Yes</Radio>
                                        </Radio.Group>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {relatedMsecTraderFields.map((itm, idx) => {
                        let error

                        if (errors.relatedMsecTrader) {
                            error = errors.relatedMsecTrader[idx]
                        }

                        return (
                            <Row gutter={16} key={itm.id} className='border-b mb-3 md:border-0 md:mb-0' style={(idx === 0) ? { marginTop: -15 } : null}>
                                <Col xs={24} md={8} lg={8} span={8}>
                                    <Form.Item
                                        label='Relationship'
                                        validateStatus={(error?.relationship?.message) ? 'error' : '-'}
                                        help={error?.relationship?.message}
                                    >
                                        <Controller
                                            name={`relatedMsecTrader.${idx}.relationship`}
                                            control={control}
                                            render={({ field: { value, onChange, ref } }) => (
                                                <Select
                                                    name={`relatedMsecTrader.${idx}.relationship`}
                                                    dropdownClassName={`related-msec-trader-${idx}-relationship-dropdown`}
                                                    ref={ref}
                                                    disabled={isAllFieldsDisabled}
                                                    placeholder='Select one'
                                                    onChange={(e) => {
                                                        onChange(e)
                                                        step?.relatedMsecTrader[idx].setData({ relationship: e })
                                                    }}
                                                    value={value}
                                                >
                                                    <Option value='SPOUSE' key={`relatedStaff-${idx}-1`}>Spouse</Option>
                                                    <Option value='SIBLINGS' key={`relatedStaff-${idx}-2`}>Siblings</Option>
                                                    <Option value='PARENTS' key={`relatedStaff-${idx}-3`}>Parents</Option>
                                                    <Option value='RELATIVES' key={`relatedStaff-${idx}-4`}>Relatives</Option>
                                                </Select>
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8} lg={8} span={8}>
                                    <Form.Item
                                        label='Name'
                                        validateStatus={(error?.name?.message) ? 'error' : '-'}
                                        help={error?.name?.message}
                                    >
                                        <Controller
                                            name={`relatedMsecTrader.${idx}.name`}
                                            control={control}
                                            render={({ field: { value, onChange, ref } }) => (
                                                <Input
                                                    name={`relatedMsecTrader.${idx}.name`}
                                                    ref={ref}
                                                    className='input-text-full'
                                                    onChange={(e) => {
                                                        onChange(e.target.value)
                                                        step?.relatedMsecTrader[idx].setData({ name: e.target.value })
                                                    }}
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={22} md={6} lg={6} span={6}>
                                    <Form.Item
                                        label='Client Code'
                                        validateStatus={(error?.clientCode?.message) ? 'error' : '-'}
                                        help={error?.clientCode?.message}
                                    >
                                        <Controller
                                            name={`relatedMsecTrader.${idx}.clientCode`}
                                            control={control}
                                            render={({ field: { value, onChange, ref } }) => (
                                                <Input
                                                    name={`relatedMsecTrader.${idx}.clientCode`}
                                                    ref={ref}
                                                    className='input-text-full'
                                                    onChange={(e) => {
                                                        onChange(e.target.value)
                                                        step?.relatedMsecTrader[idx].setData({ clientCode: e.target.value })
                                                    }}
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={2} md={2} lg={2} span={2}>
                                    <AntButton
                                        disabled={isAllFieldsDisabled}
                                        type='primary' shape='circle' size={12}
                                        style={{ marginTop: 36 }}
                                        icon={<div style={{ height: 30, marginTop: -3 }}><DeleteOutlined /></div>}
                                        onClick={() => {
                                            removeRelatedMsecTrader(idx)
                                            step?.removeRelatedMsecTrader(idx)
                                            if (relatedMsecTraderFields.length === 1) {
                                                setValue('hasRelatedMsecTrader', 0)
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        )
                    })}

                    {(step?.relatedMsecTrader && step?.relatedMsecTrader.length > 0) &&
                    <button
                        disabled={isAllFieldsDisabled}
                        style={{ marginBottom: 24, fontStyle: 'italic', opacity: 0.7 }}
                        onClick={() => {
                            appendRelatedMsecTrader({
                                name: null,
                                relationship: null,
                                clientCode: null
                            })
                            step?.addRelatedMsecTrader()
                        }}
                    >
                        Add more?
                    </button>}

                    <Row gutter={24}>
                        <Col xs={24} span={24}>
                            <Form.Item
                                className='quesTitle'
                                label={
                                    <div>
                                        <span>Declaration of Politically Exposed Person (PEP).</span><br />
                                        <span>Are you, your family member or any close associate currently or have been entrusted with a prominent public function in Malaysia or Foreign country?</span>
                                    </div>
                                }
                                validateStatus={(errors?.hasRelatedPep?.message) ? 'error' : '-'}
                                help={errors?.hasRelatedPep?.message}
                            >
                                <Controller
                                    name='hasRelatedPep'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Radio.Group
                                            name='hasRelatedPep'
                                            onChange={(e) => {
                                                onChange(e)
                                                const b = e.target.value === 1
                                                step?.setHasRelatedPep(b)
                                                if (b) {
                                                    step?.addRelatedPep()
                                                    setValue('relatedPep', [{
                                                        relationship: null,
                                                        name: null,
                                                        occupation: null,
                                                        organisation: null,
                                                        country: null
                                                    }])
                                                } else {
                                                    step?.setData({ relatedPep: [] })
                                                    setValue('relatedPep', [])
                                                }
                                            }}
                                            value={value}
                                        >
                                            <Radio value={0}>No</Radio>
                                            <Radio value={1}>Yes</Radio>
                                        </Radio.Group>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {relatedPepFields.map((itm, idx) => {
                        let error
                        let borderStyle = {}

                        if (errors.relatedPep) {
                            error = errors.relatedPep[idx]
                        }
                        if (idx > 0) {
                            borderStyle = { borderTopStyle: 'solid', borderColor: '#C2C2C2', borderTopWidth: 1, paddingTop: 15 }
                        }

                        return (
                            <Row gutter={16} key={itm.id} style={(idx === 0) ? { marginTop: -15 } : borderStyle}>
                                <Col xs={24} md={8} lg={8} span={8}>
                                    <Form.Item
                                        label='Relationship'
                                        validateStatus={(error?.relationship?.message) ? 'error' : '-'}
                                        help={error?.relationship?.message}
                                    >
                                        <Controller
                                            name={`relatedPep.${idx}.relationship`}
                                            control={control}
                                            render={({ field: { value, onChange, ref } }) => (
                                                <Select
                                                    ref={ref}
                                                    name={`relatedPep.${idx}.relationship`}
                                                    dropdownClassName={`related-pep-${idx}-relationship-dropdown`}
                                                    disabled={isAllFieldsDisabled}
                                                    placeholder='Select one'
                                                    onChange={(e) => {
                                                        onChange(e)
                                                        step?.relatedPep[idx].setData({ relationship: e })
                                                    }}
                                                    value={value}
                                                >
                                                    <Option value='SPOUSE' key={`relatedTrader-${idx}-1`}>Spouse</Option>
                                                    <Option value='SIBLINGS' key={`relatedTrader-${idx}-2`}>Siblings</Option>
                                                    <Option value='PARENTS' key={`relatedTrader-${idx}-3`}>Parents</Option>
                                                    <Option value='RELATIVES' key={`relatedTrader-${idx}-4`}>Relatives</Option>
                                                </Select>
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8} lg={8} span={8}>
                                    <Form.Item
                                        label='Name'
                                        validateStatus={(error?.name?.message) ? 'error' : '-'}
                                        help={error?.name?.message}
                                    >
                                        <Controller
                                            name={`relatedPep.${idx}.name`}
                                            control={control}
                                            render={({ field: { value, onChange, ref } }) => (
                                                <Input
                                                    ref={ref}
                                                    name={`relatedPep.${idx}.name`}
                                                    className='input-text-full'
                                                    onChange={(e) => {
                                                        onChange(e.target.value)
                                                        step?.relatedPep[idx].setData({ name: e.target.value })
                                                    }}
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={22} md={6} lg={6} span={6}>
                                    <Form.Item
                                        label='Occupation'
                                        validateStatus={(error?.occupation?.message) ? 'error' : '-'}
                                        help={error?.occupation?.message}
                                    >
                                        <Controller
                                            name={`relatedPep.${idx}.occupation`}
                                            control={control}
                                            render={({ field: { value, onChange, ref } }) => (
                                                <Input
                                                    ref={ref}
                                                    name={`relatedPep.${idx}.occupation`}
                                                    className='input-text-full'
                                                    onChange={(e) => {
                                                        onChange(e.target.value)
                                                        step?.relatedPep[idx].setData({ occupation: e.target.value })
                                                    }}
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={2} md={2} lg={2} span={2}>
                                    <AntButton
                                        disabled={isAllFieldsDisabled}
                                        type='primary' shape='circle' size={12}
                                        style={{ marginTop: 36 }}
                                        icon={<div style={{ height: 30, marginTop: -3 }}><DeleteOutlined /></div>}
                                        onClick={() => {
                                            removeRelatedPep(idx)
                                            step?.removeRelatedPep(idx)
                                            if (relatedPepFields.length === 1) {
                                                setValue('hasRelatedPep', 0)
                                            }
                                        }}
                                    />
                                </Col>
                                <Col xs={24} md={14} lg={14} span={14}>
                                    <Form.Item
                                        label='Employer/Organisation'
                                        style={{ marginTop: 22 }}
                                        validateStatus={(error?.organisation?.message) ? 'error' : '-'}
                                        help={error?.organisation?.message}
                                    >
                                        <Controller
                                            name={`relatedPep.${idx}.organisation`}
                                            control={control}
                                            render={({ field: { value, onChange, ref } }) => (
                                                <Input
                                                    name={`relatedPep.${idx}.organisation`}
                                                    ref={ref}
                                                    className='input-text-full'
                                                    onChange={(e) => {
                                                        onChange(e.target.value)
                                                        step?.relatedPep[idx].setData({ organisation: e.target.value })
                                                    }}
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8} lg={8} span={8}>
                                    <Form.Item
                                        label='Country of entrusted with a prominent public function'
                                        validateStatus={(error?.country?.message) ? 'error' : '-'}
                                        help={error?.country?.message}
                                    >
                                        <Controller
                                            name={`relatedPep.${idx}.country`}
                                            control={control}
                                            render={({ field: { value, onChange, ref } }) => (
                                                <Select
                                                    ref={ref}
                                                    name={`relatedPep.${idx}.country`}
                                                    dropdownClassName={`related-pep-${idx}-country-dropdown`}
                                                    disabled={isAllFieldsDisabled}
                                                    placeholder='Search to Select'
                                                    optionFilterProp='children'
                                                    showSearch
                                                    onChange={(e) => {
                                                        onChange(e)
                                                        step?.relatedPep[idx].setData({ country: e })
                                                    }}
                                                    value={value}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                                >
                                                    {countries.map((itm) => <Option value={itm.code} key={itm.code}>{itm.name}</Option>)}
                                                </Select>
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )
                    })}

                    {(step?.relatedPep && step?.relatedPep.length > 0) &&
                    <button
                        disabled={isAllFieldsDisabled}
                        style={{ marginBottom: 24, fontStyle: 'italic', opacity: 0.7 }}
                        onClick={() => {
                            appendRelatedPep({
                                name: null,
                                relationship: null,
                                occupation: null,
                                organisation: null,
                                country: null
                            })
                            step?.addRelatedPep()
                        }}
                    >
                        Add more?
                    </button>}

                    <Row gutter={24}>
                        <Col xs={24} span={24}>
                            <Form.Item
                                className='quesTitle mt-5'
                                label='Declaration of Principal Account Holder'
                                validateStatus={(errors?.isAccountHolder?.message) ? 'error' : '-'}
                                help={errors?.isAccountHolder?.message}
                            >
                                <Controller
                                    name='isAccountHolder'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Checkbox
                                            ref={ref}
                                            name='isAccountHolder'
                                            style={{ width: '100%' }}
                                            onChange={(e) => {
                                                onChange((e.target.checked) ? 1 : 0)
                                                step?.setData({ isAccountHolder: e.target.checked })
                                            }}
                                            checked={value}
                                        >
                                            I solemnly declare that the dealings in securities in respect of my trading account(s) shall be conducted for me as principal.
                                        </Checkbox>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {!isAllFieldsDisabled &&
                    <>
                        <SubmitButton
                            className='w-full mt-5 submit-btn'
                            label='Submit'
                            submitting={submitting}
                        />

                        <div className='flex justify-center gray my-3'>
                            By clicking continue I agree that all information provided are accurate.
                        </div>
                    </>}
                </fieldset>

                {isAllFieldsDisabled &&
                <Button
                    className='w-full mt-5 submit-btn'
                    label='Next'
                    onClick={props.onNext}
                />}
            </Form>
        </div>
    )
}

CompleteTaxationAndDeclarationProfileForm.propTypes = {
    className: PropTypes.string,
    onSuccessSubmit: PropTypes.func,
    onNext: PropTypes.func,
    isLocked: PropTypes.bool
}

CompleteTaxationAndDeclarationProfileForm.defaultProps = {
    isLocked: false
}

export default inject('store')(observer(CompleteTaxationAndDeclarationProfileForm))
