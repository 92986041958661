import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'
import styles from './CustomerHeader.module.scss'

const CustomerHeader = (props) => {
    const history = useHistory()

    useEffect(() => {
    }, [])

    const createNewApplication = () => {
        props.store.reset()
        history.push('/')
    }

    return (
        <header className={cn(styles.header, props.className)} style={props.style}>
            <div className={cn(styles.container, 'container')}>
                <a href='#' className={styles.logoWrapper}>
                    <img src='/images/logo.png' alt='MPlus Online' />
                </a>
            </div>
        </header>
    )
}

CustomerHeader.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    hideBtnNewApplication: PropTypes.bool
}

CustomerHeader.defaultProps = {
    hideBtnNewApplication: false
}

export default CustomerHeader
