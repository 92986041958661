import { useState, useEffect } from 'react'
import qs from 'qs'
import { inject } from 'mobx-react'
import { Spin, Modal } from 'antd'
import { Link, useHistory, useLocation } from 'react-router-dom'
import CustomerLayout from '../../../../modules/customer/components/template/layout'
import styles from '../../../../modules/application/components/status/ApplicationStatus.module.scss'
import detectIsMobile from '../../../../helpers/detectIsMobile'
import config from 'config'

const ProfileUpdateRequestEkycStatusPage = props => {
    const history = useHistory()
    const location = useLocation()
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    const isLoadedFromIframe = window !== window.parent
    const isMobile = detectIsMobile()
    const [verifying, setVerifying] = useState(true)
    const [isValid, setIsValid] = useState(false)
    const [data, setData] = useState(null)

    if (!query?.refId) {
        history.replace('/')
        return (<div />)
    }

    useEffect(() => {
    }, [])

    useEffect(() => {
        if (query?.refId) {
            getEkycStatus(query.refId)
        }
    }, [query?.refId])

    const getEkycStatus = async (refId) => {
        try {
            setVerifying(true)
            const res = await props.services.customerService.getProfileUpdateRequestEkycStatus(refId)

            if (res.ok) {
                if (res.data.data.status === 'APPROVED') {
                    history.replace('/')
                    return (<div />)
                } else {
                    setIsValid(true)
                    setData(res.data?.data)
                }
            } else {
                setIsValid(false)
                Modal.info({
                    title: 'Invalid Link',
                    content: (
                        <p>
                            Looks like the link you clicked on is invalid.
                        </p>
                    ),
                    onOk: () => {
                        history.push('/')
                    }
                })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setVerifying(false)
        }
    }

    return (
        <>
            {verifying &&
            <div className='flex flex-col w-full h-screen justify-center content-center place-content-center'>
                <Spin size='large' />
                <p className='text-lg mt-2 text-center text-blue-400'>Verifying ...</p>
            </div>}

            {!verifying && isValid &&
            <CustomerLayout hideBtnNewApplication>
                <div className='container mx-auto text-center'>
                    <div className={styles.wrapper}>
                        {data.status === 'NEW' &&
                        <>
                            <div className={styles.title}>
                                Your identity verification is still pending for you to complete.
                            </div>
                        </>}

                        {data?.status === 'PENDING' &&
                        <>
                            <div className={styles.title}>
                                You have completed the identity verification
                            </div>

                            <p className={styles.description}>
                                Your request to update your profile has been submitted to us. If you do require any assistance, you can visit our Website, Facebook Page or have a chat with SITI (our ChatBot).
                            </p>

                            {!isLoadedFromIframe && isMobile &&
                            <p className={styles.description}>
                                Please return to your desktop & <br /> Press on <b className='text-light-blue'>"I've Completed Verification"</b> button.
                            </p>}

                            {isLoadedFromIframe &&
                            <>
                                <p className={styles.description}>
                                    You can now close the verification by clicking the <b className='text-light-blue'>"Close"</b> button now
                                </p>

                                <button
                                    type='button'
                                    className='btn-primary-full px-4 py-2 rounded font-weight-bold block mt-3 mx-auto'
                                    onClick={() => {
                                        const targetOrigin = config.app.appUrl

                                        window.parent.postMessage({
                                            source: 'btn-close-click'
                                        }, targetOrigin)
                                    }}
                                >
                                    Close
                                </button>
                            </>}
                        </>}

                        {data?.status === 'REJECTED' &&
                        <>
                            <div className={styles.title}>
                                Failed eKYC (Identity Verification)
                            </div>

                            <p className={styles.description}>
                                We’re sorry to inform you that your eKYC (Identity Verification) was not successful.
                            </p>

                            {!isLoadedFromIframe && isMobile &&
                            <p className={styles.description}>
                                Please return to your desktop & <br /> Press on <b className='text-light-blue'>"X"</b> button.
                            </p>}

                            {isLoadedFromIframe &&
                            <>
                                <p className={styles.description}>
                                    You can now close the verification by clicking the <b className='text-light-blue'>"Close"</b> button now
                                </p>

                                <button
                                    type='button'
                                    className='btn-primary-full px-4 py-2 rounded font-weight-bold block mt-3 mx-auto'
                                    onClick={() => {
                                        const targetOrigin = config.app.appUrl

                                        window.parent.postMessage({
                                            source: 'btn-close-click'
                                        }, targetOrigin)
                                    }}
                                >
                                    Close
                                </button>
                            </>}
                        </>}
                    </div>
                </div>
            </CustomerLayout>}
        </>
    )
}

export default inject('services')(ProfileUpdateRequestEkycStatusPage)
