import { string, object, addMethod, shape, mixed } from 'yup'
import moment from 'moment'
import countries, { nonHighRiskCountries } from '../../../../../../../models/countries'

const nonHighRiskCountryCodes = nonHighRiskCountries.map((country) => {
    return country.code
})

addMethod(string, 'requiredWhenDefined', function requiredWhenDefined (message = 'Required when not undefined') {
    return this.test({
        message: message,
        name: 'requiredWhenDefined',
        test (value) {
            if (value === undefined) {
                return true
            }

            if (value === null || value.length === 0) {
                return false
            }

            return true
        }
    })
})

addMethod(mixed, 'requiredWhenDefined', function requiredWhenDefined (message = 'Required when not undefined') {
    return this.test({
        message: message,
        name: 'requiredWhenDefined',
        test (value) {
            if (value === undefined) {
                return true
            }

            if (value === null || value.length === 0) {
                return false
            }

            return true
        }
    })
})

const schema = object().shape({
    email: string()
        .email('Email must be a valid email')
        .requiredWhenDefined('Email is a required field')
        .test(
            'is-email-verified',
            'Email is not verified',
            (value, context) => {
                if (value === undefined) return true
                const { isEmailVerified } = context.options.context
                return isEmailVerified
            }
        ),
    fullName: string()
        .max(100, 'Full name must be at most 100 characters')
        .nullable()
        .requiredWhenDefined('Full name is a required field'),
    documentNumber: string()
        .when('$nationality', (nationality) => {
            if (nationality === 'MY' || nationality === 'MYPR') {
                return string()
                    .nullable()
                    .requiredWhenDefined('NRIC/Passport is a required field')
                    .matches(/^\d{12}$/, 'NRIC must be 12 digit number')
            } else {
                return string()
                    .nullable()
                    .requiredWhenDefined('NRIC/Passport is a required field')
            }
        }),
    // gender: string()
    //     .nullable()
    //     .requiredWhenDefined('Gender is a required field')
    //     .oneOf(['MALE', 'FEMALE'], 'Gender is a required field'),
    // birthDate: string()
    //     .nullable()
    //     .requiredWhenDefined('Birth date is a required field')
    //     .test(
    //         'is-valid',
    //         'Invalid datetime format',
    //         (value, context) => {
    //             if (value === null || value === '') {
    //                 return false
    //             }
    //             return moment(value).isValid()
    //         }
    //     ),
    // maritalStatus: string()
    //     .nullable()
    //     .requiredWhenDefined('Martial status is a required field')
    //     .oneOf(['SINGLE', 'MARRIED', 'DIVORCED', 'WIDOWED'], 'Martial status is a required field'),
    // race: string()
    //     .when('$nationality', (nationality, schema) => {
    //         if (nationality === 'MY' || nationality === 'MYPR') {
    //             return string()
    //                 .nullable()
    //                 .requiredWhenDefined('Race is a required field')
    //         }
    //     }).nullable(),
    // raceOther: string()
    //     .nullable()
    //     .requiredWhenDefined('Other race is a required field'),
    // contact: object().shape({
    //     prefix: string()
    //         .requiredWhenDefined('Mobile code is a required field')
    //         .matches(/^\d+$/, 'Invalid mobile code'),
    //     number: string()
    //         .min(7, 'Mobile number must be at least 7 characters')
    //         .max(10, 'Mobile number must be at most 10 characters')
    //         .requiredWhenDefined('Mobile number is a required field')
    //         .matches(/^\d+$/, 'Invalid mobile number')
    // }).nullable(true).default(undefined),
    address1: string()
        .nullable()
        .requiredWhenDefined('Address is a required field'),
    address2: string()
        .nullable(),
    country: string()
        .nullable()
        .oneOf(nonHighRiskCountryCodes, 'Invalid country')
        .requiredWhenDefined('Country is a required field'),
    state: string()
        .nullable()
        .when('country', (country, schema) => {
            if (country === 'MY') {
                return string()
                    .nullable()
                    .requiredWhenDefined('State is a required field')
            }
        }),
    city: string()
        .nullable()
        .when('country', (country, schema) => {
            if (country === 'MY') {
                return string()
                    .nullable()
                    .requiredWhenDefined('City is a required field')
            }
        }),
    postcode: string()
        .nullable()
        .when('country', (country, schema) => {
            if (country === 'MY') {
                return string()
                    .nullable()
                    .requiredWhenDefined('Postcode is a required field')
                    .matches(/^\d+$/, 'Postcode only accepts numeric')
            }
        }),
    mailingAddress1: string()
        .nullable()
        .when('sameAddress', (sameAddress, schema) => {
            if (sameAddress === false) {
                return string()
                    .nullable()
                    .required('Mailing address is a required field')
            }
        }),
    mailingAddress2: string()
        .nullable()
        .when('sameAddress', (sameAddress, schema) => {
            if (sameAddress === false) {
                return string()
                    .nullable()
            }
        }),
    mailingCountry: string()
        .nullable()
        .oneOf(nonHighRiskCountryCodes, 'Invalid country')
        .when('sameAddress', (sameAddress, schema) => {
            if (sameAddress === false) {
                return string()
                    .nullable()
                    .required('Mailing country is a required field')
            }
        }),
    mailingState: string()
        .nullable()
        .when(['sameAddress', 'mailingCountry'], (sameAddress, mailingCountry) => {
            if (mailingCountry === 'MY' && sameAddress === false) {
                return string()
                    .nullable()
                    .required('State is a required field')
            }
        }),
    mailingCity: string()
        .nullable()
        .when(['sameAddress', 'mailingCountry'], (sameAddress, mailingCountry) => {
            if (mailingCountry === 'MY' && sameAddress === false) {
                return string()
                    .nullable()
                    .required('Mailing city is a required field')
            }
        }),
    mailingPostcode: string()
        .nullable()
        .when(['sameAddress', 'mailingCountry'], (sameAddress, mailingCountry) => {
            if (mailingCountry === 'MY' && sameAddress === false) {
                return string()
                    .nullable()
                    .required('Mailing postcode is a required field')
                    .matches(/^\d+$/, 'Mailing postcode only accepts numeric')
            }
        }),
    isIslamicBank: string()
        .nullable()
        .requiredWhenDefined('This field is a required field')
        .oneOf(['1', '0'], 'This field is a required field'),
    bankName: string()
        .nullable()
        .requiredWhenDefined('Bank name is a required field'),
    bankAccountNumber: string()
        .nullable()
        .requiredWhenDefined('Bank account number is a required field')
        .matches(/^\d+$/, 'Bank account number only accepts numeric'),
    bankStatement: mixed()
        .nullable()
        .requiredWhenDefined('Bank statement is a required field'),
    signature: string()
        .nullable()
        .required('Signature is a required field'),
    signatureAgreement: string()
        .nullable()
        .required('This field is a required field')
        .oneOf(['1'], 'This field is a required field')
})

export default schema
