import { useState, useEffect } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { inject } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import styles from '../ApplicationStatus.module.scss'

const StatusRejected = props => {
    const history = useHistory()

    useEffect(() => {
    }, [])

    const resume = () => {
        props.store.reset()
        history.push('/')
    }

    return (
        <div className={cn(styles.wrapper, props.className)}>
            <div className={styles.title}>
                Oh no, your application has been rejected
            </div>
            <p className={styles.description}>
                Kindly refer to your registered email for the reason of rejection. If you wish to proceed with opening a Trading Account, you will be required to resubmit your application
            </p>

            <div className={styles.callToAction}>
                <div className={styles.message}>
                    Click the link below to resubmit your application
                </div>
                <button className='btn-primary-full px-4 py-2 rounded font-weight-bold' onClick={resume}>
                    Resubmit Application
                </button>
            </div>
        </div>
    )
}

StatusRejected.propTypes = {
    className: PropTypes.string
}

StatusRejected.defaultProps = {
}

export default inject('store')(StatusRejected)
