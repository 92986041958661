import { useEffect, useMemo, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useHistory, useLocation } from 'react-router-dom'
import Layout from '../modules/application/components/templates/layout'
import FormContainer from '../components/form/container'
import CompletePersonalProfileForm from '../modules/application/personal-profile/complete/components/form'
import StepBar from '../modules/application/components/step-bar'
import { getStepLink } from '../helpers/StepLink'
import qs from 'qs'

const CompletePersonalProfilePage = inject('store')(observer(({ store }) => {
    const history = useHistory()
    const location = useLocation()
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    const appendedQueryString = (query.exclude === 'payment') ? '?exclude=payment' : null

    const nextPageLink = useMemo(() => {
        let nextPageLink = '/employment-and-spouse-details'
        if (query?.exclude === 'payment') {
            nextPageLink += '?exclude=payment'
        }
        return nextPageLink
    }, [query])

    const handleSuccessSubmit = () => {
        history.push(nextPageLink)
    }

    const handleNext = () => {
        history.push(nextPageLink)
    }

    if (store?.completedStep < 1) {
        const link = getStepLink(store.completedStep, appendedQueryString)
        history.push(link)
        return (<div />)
    }

    return (
        <Layout>
            <div className='flex flex-col items-center w-full'>
                <h1 className='text-2xl md:text-4xl oxygen-font-weight-bold text-gray-500 tracking-tight mb-4 md:mb-0 my-4 md:my-12 text-center'>
                    CDS Trading Account Registration
                </h1>

                <StepBar
                    current={1} onChange={(e) => {
                        const step = e + 1
                        if (step > store.completedStep) return
                        const link = getStepLink(step, appendedQueryString)
                        history.push(link)
                    }}
                />

                <FormContainer>
                    <CompletePersonalProfileForm onSuccessSubmit={handleSuccessSubmit} onNext={handleNext} />
                </FormContainer>
            </div>
        </Layout>
    )
}))

export default CompletePersonalProfilePage
