import { useState, useEffect } from 'react'
import cn from 'classnames'
import Persona from 'persona'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { detectIncognito } from 'detect-incognito'
import styles from './ProfileUpdateRequestEKYCVerificiation.module.scss'
import { displaySystemNotice } from '../../../../../../../../helpers/SystemNotice'
import CtosModal from '../../../../../../../application/ekyc/ctos/components/modal'

const EKYCVerification = props => {
    const history = useHistory()
    let personaClient = null
    const [isCtosModalVisible, setIsCtosModalVisibile] = useState(false)
    const [unavailableToUseCTOS, setUnavailableToUseCTOS] = useState(false)
    const [browserInfo, setBrowserInfo] = useState(null)

    useEffect(() => {
        detectIncognito().then((result) => {
            setUnavailableToUseCTOS(result.browserName === 'Chrome' && result.isPrivate && props.method === 'CTOS')
            setBrowserInfo({
                name: result.browserName,
                isPrivate: result.isPrivate
            })
        })

        if (props.method !== 'PERSONA') return

        personaClient = new Persona.Client({
            referenceId: props.referenceId,
            inquiryId: props.inquiryId,
            sessionToken: props.accessToken,
            environment: props.environment,
            onStart: (inquiryId) => console.log(inquiryId),
            onComplete: (inquiryId) => {
                props.onComplete()
            }
        })

        displaySystemNotice(['EKYC'])
        beginVerification()
    }, [])

    const beginVerification = () => {
        if (props.method === 'PERSONA') {
            personaClient.open()
        } else if (props.method === 'CTOS') {
            setIsCtosModalVisibile(true)
        }
    }

    const reload = () => {
        history.go(0)
    }

    return (
        <div className={cn(styles.wrapper, props.className)}>
            <div className={styles.title}>
                Profile Request Update - Identity Verification
            </div>

            {(!unavailableToUseCTOS || props.method === 'PERSONA') &&
            <>
                <p className={styles.description}>
                    You're are at the final step, click the button below to proceed to verify your identity
                </p>

                <button type='button' className='btn-primary-full px-4 py-2 rounded font-weight-bold' onClick={beginVerification}>
                    Verify your IC/Passport
                </button>

                <CtosModal
                    visible={isCtosModalVisible}
                    url={props.url}
                    onCancel={reload}
                    handleDoneVerification={reload}
                />
            </>}

            {(unavailableToUseCTOS) &&
            <>
                <p className={styles.description}>
                    You're are at the final step, but you are using <b>{browserInfo?.name}</b> in an <b>incognito</b> mode where the identity verification system is not supported. <br />
                    Please use <b>non-incognito</b> mode to complete your identity verification.
                </p>
            </>}
        </div>
    )
}

EKYCVerification.propTypes = {
    className: PropTypes.string,
    referenceId: PropTypes.string,
    inquiryId: PropTypes.string,
    accessToken: PropTypes.string,
    environment: PropTypes.string,
    onComplete: PropTypes.func,
    url: PropTypes.string,
    method: PropTypes.oneOf(['CTOS', 'PERSONA'])
}

EKYCVerification.defaultProps = {
    method: 'PERSONA',
    onComplete: () => {}
}

export default EKYCVerification
