import { useState, useEffect } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import ProfileUpdateRequestForm from '../ProfileUpdateRequestForm'
import styles from './ProfileUpdateRequestFormWrapper.module.scss'

const ProfileUpdateRequestFormWrapper = props => {
    return (
        <div className={cn(styles.wrapper, props.className)} style={props.style}>
            <h1 className={styles.title}>Profile Update Request</h1>

            <ProfileUpdateRequestForm
                className={styles.form}
                accessToken={props.accessToken}
                defaultValues={props.defaultValues}
                onSuccessSubmit={props.onSuccessSubmit}
            />
        </div>
    )
}

ProfileUpdateRequestFormWrapper.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    defaultValues: PropTypes.object,
    onSuccessSubmit: PropTypes.func
}

ProfileUpdateRequestFormWrapper.defaultProps = {
    onSuccessSubmit: () => {}
}

export default ProfileUpdateRequestFormWrapper
