import React, { useState, useEffect, useCallback } from 'react'
import { Form, Checkbox } from 'antd'
import { EditTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import cn from 'classnames'
import PropTypes from 'prop-types'

const CheckboxGroup = React.forwardRef((props, ref) => {
    const [disabled, setDisabled] = useState(props.disabled)

    useEffect(() => {
        setDisabled(props.disabled)
    }, [props.disabled])

    const handleBtnEditClick = useCallback(() => {
        setDisabled(false)
        props.onToggleDisabled(false)
    })

    const handleBtnCancelEditClick = useCallback(() => {
        setDisabled(true)
        props.onToggleDisabled(true)
    })

    return (
        <Form.Item
            className={cn('mb-4 quesTitle', props.className)}
            style={props.style}
            label={
                (props.label)
                    /* eslint-disable react/jsx-closing-tag-location */
                    ? (<div className='flex justify-between w-full'>
                        <span>{props.label}</span>

                        {props.ableToToggleDisabled && disabled &&
                        <a type='button' className='text-blue-500 flex items-center' onClick={handleBtnEditClick}>
                            <EditTwoTone className='mr-1' />
                            Edit
                        </a>}

                        {props.ableToToggleDisabled && !disabled &&
                        <a type='button' className='text-red-500 hover:text-red-500 flex items-center' onClick={handleBtnCancelEditClick}>
                            <CloseCircleTwoTone twoToneColor='#ef4444' className='mr-1' />
                            Cancel
                        </a>}
                    </div>)
                    : null
                    /* eslint-enable react/jsx-closing-tag-location */
            }
            validateStatus={(props.error?.message) ? 'error' : '-'}
            help={props.error?.message}
        >
            <Checkbox.Group
                ref={ref}
                style={{ width: '100%' }}
                defaultValue={props.defaultValue}
                disabled={props.disabled}
                name={props.name}
                options={props.options}
                value={props.value}
                onChange={props.onChange}
            >
                {props.children}
            </Checkbox.Group>
        </Form.Item>
    )
})

CheckboxGroup.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    defaultValue: PropTypes.array,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.array,
    onChange: PropTypes.func,
    error: PropTypes.object,
    ableToToggleDisabled: PropTypes.bool,
    onToggleDisabled: PropTypes.func
}

CheckboxGroup.defaultProps = {
    defaultValue: [],
    disabled: false,
    options: [],
    value: [],
    onChange: () => {},
    ableToToggleDisabled: false,
    onToggleDisabled: () => {}
}

export default CheckboxGroup
