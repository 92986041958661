import { types, flow, getEnv } from 'mobx-state-tree'

const RelatedStaff = types.model('RelatedStaff', {
    name: types.maybeNull(types.string),
    relationship: types.maybeNull(types.string)
})
    .actions((self) => ({
        setData (data) {
            for (const key in data) {
                if (!Object.prototype.hasOwnProperty.call(data, key)) {
                    console.error(`Unavailable key ${key}`)
                } else {
                    self[key] = data[key]
                }
            }
        }
    }))

const RelatedMsecTrader = types.model('RelatedMsecTrader', {
    name: types.maybeNull(types.string),
    relationship: types.maybeNull(types.string),
    clientCode: types.maybeNull(types.string)
})
    .actions((self) => ({
        setData (data) {
            for (const key in data) {
                if (!Object.prototype.hasOwnProperty.call(data, key)) {
                    console.error(`Unavailable key ${key}`)
                } else {
                    self[key] = data[key]
                }
            }
        }
    }))

const RelatedPep = types.model('RelatedPep', {
    name: types.maybeNull(types.string),
    relationship: types.maybeNull(types.string),
    occupation: types.maybeNull(types.string),
    organisation: types.maybeNull(types.string),
    country: types.maybeNull(types.string)
})
    .actions((self) => ({
        setData (data) {
            for (const key in data) {
                if (!Object.prototype.hasOwnProperty.call(data, key)) {
                    console.error(`Unavailable key ${key}`)
                } else {
                    self[key] = data[key]
                }
            }
        }
    }))

export const Step4 = types
    .model('Step4', {
        hasForeignTax: types.maybeNull(types.boolean),
        birthCountry: types.maybeNull(types.string),
        birthCity: types.maybeNull(types.string),
        taxCountry: types.maybeNull(types.string),
        taxCity: types.maybeNull(types.string),
        hasTin: types.maybeNull(types.boolean),
        tinNumber: types.maybeNull(types.string),
        reasonForNoTin: types.maybeNull(types.string),
        reasonForUnableToObtainTin: types.maybeNull(types.string),
        isMalaysiaResident: types.maybeNull(types.boolean),
        feaRule: types.maybeNull(types.string),
        countryOfResidence: types.maybeNull(types.string),
        hasRelatedMsecStaff: types.maybeNull(types.boolean),
        hasRelatedMsecTrader: types.maybeNull(types.boolean),
        hasRelatedPep: types.maybeNull(types.boolean),
        relatedMsecStaff: types.optional(types.array(RelatedStaff), []),
        relatedMsecTrader: types.optional(types.array(RelatedMsecTrader), []),
        relatedPep: types.optional(types.array(RelatedPep), []),
        isAccountHolder: types.maybeNull(types.boolean),
        signaturePath: types.maybeNull(types.string)
    })
    .views((self) => {
        return {
            get formData () {
                const data = { ...self }
                if (data.relatedMsecStaff) {
                    data.relatedMsecStaff = data.relatedMsecStaff.toJSON()
                }
                if (data.relatedMsecTrader) {
                    data.relatedMsecTrader = data.relatedMsecTrader.toJSON()
                }
                if (data.relatedPep) {
                    data.relatedPep = data.relatedPep.toJSON()
                }
                data.hasForeignTax = data.hasForeignTax ? 1 : (data.hasForeignTax === null ? null : 0)
                data.hasTin = data.hasTin ? 1 : (data.hasTin === null ? null : 0)
                data.isMalaysiaResident = data.isMalaysiaResident ? 1 : (data.isMalaysiaResident === null ? null : 0)
                data.hasRelatedMsecStaff = data.hasRelatedMsecStaff ? 1 : (data.hasRelatedMsecStaff === null ? null : 0)
                data.hasRelatedMsecTrader = data.hasRelatedMsecTrader ? 1 : (data.hasRelatedMsecTrader === null ? null : 0)
                data.hasRelatedPep = data.hasRelatedPep ? 1 : (data.hasRelatedPep === null ? null : 0)
                data.isAccountHolder = data.isAccountHolder ? 1 : (data.isAccountHolder === null ? null : 0)
                return data
            }
        }
    })
    .actions((self) => ({
        setData (data) {
            for (const key in data) {
                if (!Object.prototype.hasOwnProperty.call(data, key)) {
                    console.error(`Unavailable key ${key}`)
                } else {
                    self[key] = data[key]
                }
            }
        },
        setHasForeignTax (b) {
            if (!b) {
                self.birthCountry = null
                self.birthCity = null
                self.taxCountry = null
                self.taxCity = null
                self.hasTin = null
                self.reasonForNoTin = null
                self.reasonForUnableToObtainTin = null
            }
            self.hasForeignTax = b
        },
        setHasTin (b) {
            if (!b) {
                self.tinNumber = null
            }
            self.hasTin = b
        },
        setIsMalaysiaResident (b) {
            if (!b) {
                self.feaRule = null
            }
            self.isMalaysiaResident = b
        },
        setHasRelatedMsecStaff (b) {
            if (!b) {
                self.relatedMsecStaff = []
            }
            self.hasRelatedMsecStaff = b
        },
        addRelatedMsecStaff () {
            if (self.relatedMsecStaff === null) {
                self.relatedMsecStaff = []
            }
            self.relatedMsecStaff.push({
                name: null,
                relationship: null
            })
        },
        removeRelatedMsecStaff (idx) {
            const cloned = self.relatedMsecStaff.slice()
            cloned.splice(idx, 1)
            self.relatedMsecStaff = cloned
            self.hasRelatedMsecStaff = cloned.length > 0
        },
        setHasRelatedMsecTrader (b) {
            if (!b) {
                self.relatedMsecTrader = []
            }
            self.hasRelatedMsecTrader = b
        },
        addRelatedMsecTrader () {
            if (self.relatedMsecTrader === null) {
                self.relatedMsecTrader = []
            }
            self.relatedMsecTrader.push({
                name: null,
                relationship: null
            })
        },
        removeRelatedMsecTrader (idx) {
            const cloned = self.relatedMsecTrader.slice()
            cloned.splice(idx, 1)
            self.relatedMsecTrader = cloned
            self.hasRelatedMsecTrader = cloned.length > 0
        },
        setHasRelatedPep (b) {
            if (!b) {
                self.relatedPep = []
            }
            self.hasRelatedPep = b
        },
        addRelatedPep () {
            if (self.relatedPep === null) {
                self.relatedPep = []
            }
            self.relatedPep.push({
                name: null,
                relationship: null
            })
        },
        removeRelatedPep (idx) {
            const cloned = self.relatedPep.slice()
            cloned.splice(idx, 1)
            self.relatedPep = cloned
            self.hasRelatedPep = cloned.length > 0
        },
        submit: flow(function * submit (accessToken) {
            const data = { ...self }
            if (data.relatedMsecStaff) {
                data.relatedMsecStaff = JSON.stringify(data.relatedMsecStaff.toJSON())
            }
            if (data.relatedMsecTrader) {
                data.relatedMsecTrader = JSON.stringify(data.relatedMsecTrader.toJSON())
            }
            if (data.relatedPep) {
                data.relatedPep = JSON.stringify(data.relatedPep.toJSON())
            }
            if (data.isMalaysiaResident) {
                data.countryOfResidence = null
            } else {
                data.feaRule = null
            }

            const service = getEnv(self).applicationService
            const res = yield service.completeTaxationAndDeclarationProfile(accessToken, data)
            return res
        })
    }))
