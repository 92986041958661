import { Steps } from 'antd'
import PropTypes from 'prop-types'

const { Step } = Steps

const StepBar = (props) => (
    <div className='stepbar-cont flex items-center w-full lg:w-2/3 m-4 md:m-8'>
        <Steps
            current={props.current - 1} progressDot direction='horizontal'
            onChange={(e) => {
                if (props.onChange) props.onChange(e)
            }}
        >
            <Step title='' />
            <Step title='' />
            <Step title='' />
            <Step title='' />
            <Step title='' />
        </Steps>
    </div>
)

StepBar.propTypes = {
    current: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
}

StepBar.defaultProps = {
    current: 1
}

export default StepBar
