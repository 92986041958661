import { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'
import { inject } from 'mobx-react'
import styles from './ApplicationHeader.module.scss'

const ApplicationHeader = (props) => {
    const history = useHistory()
    const location = useLocation()
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })

    const homeLink = useMemo(() => {
        if (query?.exclude) {
            return '#?exclude=payment'
        } else {
            return '#'
        }
    }, [query])

    useEffect(() => {
    }, [])

    const createNewApplication = () => {
        props.store.reset()
        if (query?.exclude) {
            history.push({
                pathname: '/',
                search: '?exclude=payment'
            })
        } else {
            history.push('/')
        }
    }

    return (
        <header className={cn(styles.header, props.className)} style={props.style}>
            <div className={cn(styles.container, 'container')}>
                <a href={homeLink} className={styles.logoWrapper}>
                    <img src='/images/logo.png' alt='MPlus Online' />
                </a>

                {!props.hideBtnNewApplication &&
                <button className={styles.btnNewApplication} onClick={createNewApplication}>
                    New Application
                </button>}
            </div>
        </header>
    )
}

ApplicationHeader.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    hideBtnNewApplication: PropTypes.bool
}

ApplicationHeader.defaultProps = {
    hideBtnNewApplication: false
}

export default inject('store')(ApplicationHeader)
