import React, { useState, useEffect } from 'react'
import { Upload, Modal, message } from 'antd'
import { LoadingOutlined, PlusOutlined, EyeOutlined, UploadOutlined, FileTwoTone } from '@ant-design/icons'
import PropTypes from 'prop-types'
import styles from './Uploader.module.scss'
import cn from 'classnames'
import getFileTypeFromUrl from '../../../helpers/getFileTypeFromUrl'

const FileUploader = (props) => {
    const [loading, setLoading] = useState(false)
    const [uploadedDocumentSrc, setuploadedDocumentSrc] = useState(props.uploadedDocumentSrc)
    const [isPreviewImageModalVisible, setIsPreviewImageModalVisible] = useState(false)

    useEffect(() => {
        setuploadedDocumentSrc(props.uploadedDocumentSrc)
    }, [props.uploadedDocumentSrc])

    const UploadButton = () => {
        return (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined style={{ fontSize: 80, color: '#D4D4D4' }} />}
                <div style={{ marginTop: 8 }}>Choose a file or drag it here</div>
            </div>
        )
    }

    const Thumbnail = (props) => {
        const fileType = getFileTypeFromUrl(props.uploadedDocumentSrc)
        const isImageFile = ['jpg', 'jpeg', 'png'].includes(fileType)

        return (
            <div className={styles.thumbnailWrapper}>
                {isImageFile &&
                <img src={props.uploadedDocumentSrc} />}

                {!isImageFile &&
                <div className={styles.nonImgWrapper}>
                    <FileTwoTone style={{ fontSize: 30 }} />
                    <div className={styles.filename}>{props.uploadedDocumentSrc}</div>
                </div>}

                <div className={styles.actionWrapper}>
                    {isImageFile &&
                    <div
                        className={styles.action}
                        onClick={(e) => {
                            e.stopPropagation()
                            if (!isImageFile) return
                            setIsPreviewImageModalVisible(true)
                        }}
                    >
                        <EyeOutlined />
                    </div>}

                    {!isImageFile &&
                    <a
                        href={props.uploadedDocumentSrc}
                        target='_blank'
                        rel='noreferrer'
                        className={styles.action}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                    >
                        <EyeOutlined />
                    </a>}

                    <div
                        className={styles.action}
                    >
                        <UploadOutlined />
                    </div>
                </div>
            </div>
        )
    }

    const handleChange = (info) => {
        const { status } = info.file
        setLoading(status === 'uploading')
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`)
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`)
        }
        if (props.onChange) props.onChange(info)
    }

    return (
        <div className={cn(styles.wrapper)} id={props.id}>
            <Upload
                {...props}
                disabled={loading || props.disabled}
                onChange={handleChange}
            >
                {uploadedDocumentSrc ? <Thumbnail uploadedDocumentSrc={uploadedDocumentSrc} /> : <UploadButton />}
            </Upload>

            <Modal
                visible={isPreviewImageModalVisible}
                title={props.previewTitle}
                footer={null}
                onCancel={() => setIsPreviewImageModalVisible(false)}
            >
                <img src={uploadedDocumentSrc} style={{ width: '100' }} />
            </Modal>
        </div>
    )
}

FileUploader.propTypes = {
    title: PropTypes.string
}

FileUploader.defaultProps = {
}

export default FileUploader
