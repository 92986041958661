import { types, flow, getEnv } from 'mobx-state-tree'
import moment from 'moment'

export const Step2 = types
    .model('Step2', {
        employmentType: types.maybeNull(types.string),
        industry: types.maybeNull(types.string),
        occupation: types.maybeNull(types.string),
        companyName: types.maybeNull(types.string),
        yearsOfEmployment: types.optional(types.integer, 0),
        officeNumber: types.maybeNull(types.string),
        officeNumberPrefix: types.maybeNull(types.string),
        sourceOfInvestment: types.array(types.string),
        sourceOfInvestmentOther: types.maybeNull(types.string),
        incomeStatementPath: types.maybeNull(types.string),
        additionalIncomeStatementPath: types.maybeNull(types.string),
        annualIncome: types.maybeNull(types.string),
        netWorth: types.maybeNull(types.string),
        spouseFullName: types.maybeNull(types.string),
        spouseDocumentNumber: types.maybeNull(types.string),
        spouseDocumentExpiry: types.maybeNull(types.Date),
        spouseNationality: types.maybeNull(types.string),
        spouseIsPrMalaysia: types.maybeNull(types.boolean),
        spouseContactNumber: types.maybeNull(types.string),
        spouseContactPrefix: types.maybeNull(types.string),
        spouseEmploymentType: types.maybeNull(types.string),
        spouseIndustry: types.maybeNull(types.string),
        spouseOccupation: types.maybeNull(types.string),
        spouseCompanyName: types.maybeNull(types.string),
        spouseAnnualIncome: types.maybeNull(types.string)
    })
    .views((self) => {
        return {
            get formData () {
                const data = { ...self }
                if (data.spouseDocumentExpiry) {
                    data.spouseDocumentExpiry = moment(data.spouseDocumentExpiry)
                }
                if (data.spouseIsPrMalaysia) {
                    data.spouseNationality = 'MYPR'
                }
                return data
            },
            get hasJob () {
                return self.employmentType === 'EMPLOYED' || self.employmentType === 'SELF_EMPLOYED'
            },
            get spouseHasJob () {
                return self.spouseEmploymentType === 'EMPLOYED' || self.spouseEmploymentType === 'SELF_EMPLOYED'
            }
        }
    })
    .actions((self) => ({
        setData (data) {
            for (const key in data) {
                if (!Object.prototype.hasOwnProperty.call(data, key)) {
                    console.error(`Unavailable key ${key}`)
                } else {
                    self[key] = data[key]
                }
            }
        },
        setEmploymentType (value) {
            if (value !== 'EMPLOYED' && value !== 'SELF_EMPLOYED') {
                self.industry = null
                self.occupation = null
                self.companyName = null
                self.yearsOfEmployment = 0
                self.officeNumberPrefix = null
                self.officeNumber = null
            }
            self.employmentType = value
        },
        setSpouseEmploymentType (value) {
            if (value !== 'EMPLOYED' && value !== 'SELF_EMPLOYED') {
                self.spouseIndustry = null
                self.spouseOccupation = null
                self.spouseCompanyName = null
            }
            self.spouseEmploymentType = value
        },
        setSpouseNationality (value) {
            self.spouseNationality = value
            if (value === 'MY') {
                self.spouseDocumentExpiry = null
            }
            if (value === 'MYPR') {
                self.spouseIsPrMalaysia = true
            } else {
                self.spouseIsPrMalaysia = false
            }
        },
        setSpouseDocumentExpiry (value) {
            if (typeof value === 'string') {
                self.spouseDocumentExpiry = new Date(value)
            } else {
                self.spouseDocumentExpiry = new Date(value)
            }
        },
        submit: flow(function * submit (accessToken) {
            if (self.employmentType !== 'EMPLOYED' && self.employmentType !== 'SELF_EMPLOYED') {
                self.industry = null
                self.occupation = null
                self.companyName = null
                self.yearsOfEmployment = 0
                self.officeNumberPrefix = null
                self.officeNumber = null
            }

            const data = {
                ...self,
                spouseDocumentExpiry: self.spouseDocumentExpiry ? moment(self.spouseDocumentExpiry).format('YYYY-MM-DD') : null,
                sourceOfInvestment: JSON.stringify(self.sourceOfInvestment.toJSON())
            }

            if (data.spouseNationality === 'MYPR') {
                data.spouseNationality = 'MY'
                data.spouseIsPrMalaysia = true
            }

            const service = getEnv(self).applicationService
            const res = yield service.completeEmploymentAndSpouseProfile(accessToken, data)
            return res
        })
    }))
