import moment from 'moment'

const appConfig = {
    isProduction: process.env.REACT_APP_APP_ENV === 'production' || false,
    isTesting: process.env.REACT_APP_APP_ENV === 'test' || false,
    appEnv: process.env.REACT_APP_APP_ENV || 'local',
    appName: process.env.REACT_APP_APP_NAME,
    appUrl: process.env.REACT_APP_APP_URL || 'http://localhost:3000',
    apiUrl: (process.env.REACT_APP_MOCK_API === 'true' && process.env.REACT_APP_MOCK_API_WITH_SERVER === 'true') ? process.env.REACT_APP_MOCK_API_URL : process.env.REACT_APP_API_URL || 'http://localhost:3001',
    mockApi: process.env.REACT_APP_MOCK_API === 'true' || false,
    mockApiWithServer: process.env.REACT_APP_MOCK_API_WITH_SERVER === 'true' || false,
    ableToResendDuration: process.env.REACT_APP_ABLE_TO_RESEND_DURATION || 60,
    googleTag: {
        id: process.env.REACT_APP_GOOGLE_TAG_ID || 'GTM-KMSGRVQ',
        auth: process.env.REACT_APP_GOOGLE_TAG_AUTH || 'qIGVHXBGKGoVCWevYpQHrA',
        preview: process.env.REACT_APP_GOOGLE_TAG_PREVIEW || 'env-12'
    },
    systemNotices: [
        {
            title: 'System Notice',
            description: `<p>Our online identity verification system is currently under maintenance from <b>${moment.utc('2022-03-28T07:00:00Z').local().format('Do MMM YYYY, h:mm a')}</b> to <b>${moment.utc('2022-03-28T08:00:00Z').local().format('Do MMM YYYY,  h:mm a')}</b>. Please retry the verification process later if you experience any difficulty during this period.</p>`,
            // startAt: '2022-03-28T07:00:00Z',
            // endAt: '2022-03-28T08:00:00Z'
            // startAt: '2022-03-21T04:00:00Z',
            // endAt: '2022-03-21T05:00:00Z',
            toDisplay: false,
            duration: 0,
            category: 'EKYC'
        },
        {
            title: 'System Notice Testing',
            description: `<p>Our online identity verification system is currently under maintenance from <b>${moment.utc('2022-03-28T07:00:00Z').local().format('Do MMM YYYY, h:mm a')}</b> to <b>${moment.utc('2022-03-28T08:00:00Z').local().format('Do MMM YYYY,  h:mm a')}</b>. Please retry the verification process later if you experience any difficulty during this period.</p>`,
            startAt: '2022-03-28T00:30:00Z',
            endAt: '2022-03-28T01:30:00Z',
            toDisplay: true,
            duration: 0,
            category: 'EKYC'
        }
    ]
}

export default appConfig
