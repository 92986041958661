import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import SwitchInput from './SwitchInput'

const SwitchInputWithController = React.forwardRef((props, ref) => {
    const [disabled, setDisabled] = useState(props.disabled)
    const { unregister, setValue } = useFormContext()

    useEffect(() => {
        return () => {
            unregister(props.name)
        }
    }, [])

    useEffect(() => {
        setDisabled(props.disabled)
    }, [props.disabled])

    useEffect(() => {
        if (!disabled) {
            setValue(props.name, props.checked)
        } else {
            unregister(props.name)
        }
    }, [disabled])

    return (
        <>
            {!disabled &&
            <Controller
                name={props.name}
                render={({ field: { value, onChange, ref } }) => {
                    return (
                        <SwitchInput
                            label={props.label}
                            disabled={disabled}
                            ableToToggleDisabled={props.ableToToggleDisabled}
                            onToggleDisabled={(disabled) => {
                                if (disabled) {
                                    setDisabled(true)
                                    unregister(props.name)
                                }
                            }}
                            onChange={(checked) => {
                                onChange(checked)
                                props.onChange(checked)
                            }}
                            checked={value}
                            error={props.error}
                        />
                    )
                }}
            />}

            {disabled &&
            <SwitchInput
                label={props.label}
                disabled={disabled}
                ableToToggleDisabled={props.ableToToggleDisabled}
                onToggleDisabled={(disabled) => {
                    setDisabled(false)
                }}
                checked={props.checked}
            />}
        </>
    )
})

SwitchInputWithController.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.string,
    error: PropTypes.object,
    disabled: PropTypes.bool,
    disabledBtnEdit: PropTypes.bool,
    disabledBtnCancel: PropTypes.bool,
    ableToToggleDisabled: PropTypes.bool,
    onToggleDisabled: PropTypes.func,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    defaultChecked: PropTypes.bool,
    checked: PropTypes.bool,
    size: PropTypes.string
}

SwitchInputWithController.defaultProps = {
    disabled: false,
    disableBtnEdit: false,
    disableBtnCancel: false,
    ableToToggleDisabled: false,
    onToggleDisabled: () => {},
    onChange: () => {},
    defaultChecked: false,
    checked: false,
    size: 'default'
}

export default SwitchInputWithController
