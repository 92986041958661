import { useState, useEffect } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const antIcon = <LoadingOutlined style={{ color: 'white' }} spin />

const Button = props => {
    return (
        <button
            type={props.type}
            className={cn(props.className, 'px-4 py-2 rounded font-weight-bold', { 'btn-primary-outline': props.outline, 'btn-primary-full': !props.outline })}
            disabled={props.loading || props.disabled}
            style={props.style}
            onClick={props.onClick}
        >
            {!props.loading &&
            <span>{props.label}</span>}

            {props.loading &&
            <div className='d-flex items-center'>
                <Spin indicator={antIcon} size={props.loadingIndicatorSize} style={{ marginTop: '-7px' }} />
                <span className='ml-2 font-weight-bold'>{props.labelLoading}</span>
            </div>}
        </button>
    )
}

Button.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    labelLoading: PropTypes.string,
    loading: PropTypes.bool,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    outline: PropTypes.bool,
    loadingIndicatorSize: PropTypes.string
}

Button.defaultProps = {
    type: 'button',
    loading: false,
    disabled: false,
    labelLoading: 'loading',
    outline: false,
    loadingIndicatorSize: 'default'
}

export default Button
