import { useState, useEffect } from 'react'
import { Checkbox, Modal } from 'antd'
import PropTypes from 'prop-types'
import styles from './ProfileUpdateRequirementModal.module.scss'
import Button from '../../../../../../../../components/button'

const ProfileUpdateRequirementModal = (props) => {
    const [isAgreed, setIsAgreed] = useState(false)
    const [isBtnProceedLoading, setIsBtnProceedLoading] = useState(props.isBtnProceedLoading)

    useEffect(() => {
        setIsAgreed(false)
    }, [props.visible])

    useEffect(() => {
        setIsBtnProceedLoading(props.isBtnProceedLoading)
    }, [props.isBtnProceedLoading])

    const handleCheckboxChange = (e) => {
        setIsAgreed(e.target.checked)
    }

    return (
        <Modal
            visible={props.visible}
            title=''
            footer={null}
            className={styles.wrapper}
            onCancel={props.onCancel}
        >
            <div className={styles.title}>Updating of Client Particulars</div>

            <p className='mb-5'>
                The “Updating of Client Particulars" request form is solely for registered clients of Malacca Securities Sdn Bhd to update their account particulars.
            </p>

            <p className='mb-2 font-weight-bold'>Please take note of the following terms:</p>

            <ol>
                <li>
                    This updating request is only applicable to existing Malacca Securities Sdn Bhd clients that have successfully opened their accounts via the online portal.
                </li>
                <li>
                    Updating of account particulars via this form will apply to both the client's Trading Account and Bursa Malaysia CDS Account maintained with Malacca Securities Sdn Bhd.
                </li>
                <li className='font-weight-bold'>
                    Supporting documents required when requesting to update account particulars:
                    <ul className='list-lower-latin ml-5'>
                        <li className='font-weight-bold'>
                            Original NRIC or Passport
                        </li>
                        <li className='font-weight-bold'>
                            Supporting documents i.e. bank statement that captures the client’s name, banking account number and the bank name <br />
                            (Applicable to clients who wish to update their Registered Bank Account details)
                        </li>
                    </ul>
                </li>
            </ol>

            <p className='my-5'>
                <b>
                    The updating of account particulars process may take up to one (1) week to week to ensure accuracy of verification and to prevent fraud or misuse of the client’s trading and CDS account maintained with Malacca Securities Sdn Bhd.
                </b>
            </p>

            <Checkbox checked={isAgreed} onChange={handleCheckboxChange}>
                <b>
                    I have read and fully understood the above-mentioned requirements and information regarding the request of updating of my registered account personal particulars for my Trading Account and Bursa Malaysia CDS Account maintained with Malacca Securities Sdn Bhd.
                </b>
            </Checkbox>

            <Button
                label='I am an existing Malacca Securities Sdn Bhd Trading & CDS Account holder'
                labelLoading='Submitting'
                disabled={!isAgreed}
                className='mx-auto my-10 block'
                loading={isBtnProceedLoading}
                onClick={() => props.btnProceedOnClick()}
            />
        </Modal>
    )
}

ProfileUpdateRequirementModal.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    visible: PropTypes.bool,
    isBtnProceedLoading: PropTypes.bool,
    btnProceedOnClick: PropTypes.func.isRequired,
    onCancel: PropTypes.func
}

ProfileUpdateRequirementModal.defaultProps = {
    visible: false,
    isBtnProceedLoading: false
}

export default ProfileUpdateRequirementModal
