import { useState, useEffect } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import EKYCVerification from '../../../ekyc/components/verification'
import EKYCStatusCompleted from '../../../ekyc/components/status/completed'
import EKYCStatusFailed from '../../../ekyc/components/status/failed'

const AccountApplicationStatusPaid = props => {
    useEffect(() => {
    }, [])

    return (
        <div className={cn('container mx-auto text-center', props.className)}>
            {(props.ekyc.status === 'NEW' ||
            props.ekyc.status === 'PENDING' ||
            props.ekyc.status === 'DOCUMENT_SUBMITTED' ||
            props.ekyc.status === 'SELFIE_SUBMITTED') &&
            <EKYCVerification
                referenceId={props.ekyc?.referenceId}
                accountId={props.ekyc?.accountId}
                templateId={props.ekyc?.templateId}
                inquiryId={props.ekyc?.inquiryId}
                accessToken={props.ekyc?.accessToken}
                environment={props.ekyc?.environment}
                url={props.ekyc?.url}
                method={props.ekyc?.method}
            />}

            {props.ekyc.status === 'COMPLETED' &&
            <EKYCStatusCompleted fullName={props.fullName} />}

            {props.ekyc.status === 'FAILED' &&
            <EKYCStatusFailed fullName={props.fullName} />}
        </div>
    )
}

AccountApplicationStatusPaid.propTypes = {
    className: PropTypes.string,
    fullName: PropTypes.string.isRequired,
    ekyc: PropTypes.shape({
        referenceId: PropTypes.string,
        inquiryId: PropTypes.string,
        accessToken: PropTypes.string,
        environment: PropTypes.string,
        status: PropTypes.string.isRequired
    })
}

AccountApplicationStatusPaid.defaultProps = {
}

export default AccountApplicationStatusPaid
