export const checkApplicationEndpointPath = '/admin/check-token'
export const fetchApplicationEndpointPath = '/admin/fetch'

const AdminService = (api) => ({
    async validateAccessToken (accessToken) {
        api.setAuthToken(accessToken)
        const res = await api.get(checkApplicationEndpointPath)
        return res
    },

    async getApplicationById (accessToken, applicationId) {
        api.setAuthToken(accessToken)
        const res = await api.get(fetchApplicationEndpointPath, {
            id: applicationId
        })
        return res
    }
})

export default AdminService
