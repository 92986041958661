import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Form } from 'antd'
import { useFormContext, Controller } from 'react-hook-form'
import cn from 'classnames'
import PropTypes from 'prop-types'
import TextInput from '../../../../../../../../components/input/text'
import SelectInput from '../../../../../../../../components/input/select'
import PhoneInput from '../../../../../../../../components/input/phone'
import DatePicker from '../../../../../../../../components/input/datepicker'
import TreeSelectInput from '../../../../../../../../components/input/treeselect'
import SwitchInput from '../../../../../../../../components/input/switch'
import Radio, { RadioGroup } from '../../../../../../../../components/input/radio'
import SignatureInput from '../../../../../../../../components/input/signature'
import Checkbox from '../../../../../../../../components/input/checkbox'
import SingleFileInput from '../../../../../../../../components/input/file/single'
import moment from 'moment'
import { raceList } from '../../../../../../../../models/step1'
import countries from '../../../../../../../../models/countries'
import { nonIslamicBankList, nonIslamicBankListDev, islamicBankList, brokerList } from '../../../../../../../../models/institutions'
import malaysiaStates from '../../../../../../../../constants/malaysiaStates'
import personalParticularFields from '../../../../../../../../constants/personalParticularFields'
import financialFields from '../../../../../../../../constants/financialFields'
import config from 'config'

const { Option } = SelectInput

const nonIslamicBankOptions = (config.app.isProduction) ? nonIslamicBankList : nonIslamicBankListDev

const SummaryInfoFieldset = props => {
    const { setValue, setFocus, setError, getValues, control, trigger, watch, formState: { errors } } = useFormContext()
    const [hasEditedPersonalParticularFields, setHasEditedPersonalParticularFields] = useState(false)
    const [hasEditedFinancialFields, setHasEditedFinancialFields] = useState(false)

    useEffect(() => {
    }, [])

    useEffect(() => {
        const editedFieldNames = Object.keys(watch())
        const personalParticularFieldNames = Object.keys(personalParticularFields)
        const financialFieldNames = Object.keys(financialFields)
        const hasEditedPersonalParticularFields = editedFieldNames.some(item => personalParticularFieldNames.indexOf(item) !== -1)
        const hasEditedFinancialFields = editedFieldNames.some(item => financialFieldNames.indexOf(item) !== -1)
        setHasEditedPersonalParticularFields(hasEditedPersonalParticularFields)
        setHasEditedFinancialFields(hasEditedFinancialFields)
    }, [watch()])

    return (
        <fieldset className={cn(props.className)} style={props.style}>
            {hasEditedPersonalParticularFields &&
            <>
                <div className='text-lg text-blue-500 mb-5'>
                    Edited Personal Particulars Fields
                </div>

                {(watch('email') !== null && watch('email') !== undefined) &&
                <TextInput
                    label={personalParticularFields.email?.label}
                    disabled
                    value={watch('email')}
                />}

                {(watch('fullName') !== null && watch('fullName') !== undefined) &&
                <TextInput
                    label={personalParticularFields.fullName?.label}
                    disabled
                    value={watch('fullName')}
                />}

                <Row gutter={16}>
                    {watch('documentNumber') &&
                    <Col xs={24} md={12} lg={12} span={12}>
                        <TextInput
                            label={personalParticularFields.documentNumber?.label}
                            disabled
                            value={watch('documentNumber')}
                        />
                    </Col>}

                    {watch('gender') &&
                    <Col xs={24} md={12} lg={12} span={12}>
                        <SelectInput
                            label={personalParticularFields.gender?.label}
                            disabled
                            value={watch('gender')}
                        >
                            <Option value='MALE' key='gender-1'>Male</Option>
                            <Option value='FEMALE' key='gender-2'>Female</Option>
                        </SelectInput>
                    </Col>}
                </Row>

                <Row gutter={16}>
                    {watch('birthDate') &&
                    <Col xs={24} md={12} lg={12} span={12}>
                        <DatePicker
                            label={personalParticularFields.birthDate?.label}
                            disabled
                            format='DD-MM-YYYY'
                            value={moment(watch('birthDate'))}
                        />
                    </Col>}

                    {watch('maritalStatus') &&
                    <Col xs={24} md={12} lg={12} span={12}>
                        <SelectInput
                            label={personalParticularFields.maritalStatus?.label}
                            disabled
                            value={watch('maritalStatus')}
                        >
                            <Option value='SINGLE' key='marital-1'>Single</Option>
                            <Option value='MARRIED' key='marital-2'>Married</Option>
                            <Option value='DIVORCED' key='marital-3'>Divorced</Option>
                            <Option value='WIDOWED' key='marital-4'>Widowed</Option>
                        </SelectInput>
                    </Col>}
                </Row>

                <Row gutter={16}>
                    {watch('race') &&
                    <Col xs={24} md={12} lg={12} span={12}>
                        <TreeSelectInput
                            label={personalParticularFields.race?.label}
                            disabled
                            treeData={raceList}
                            value={watch('race')}
                        />
                    </Col>}

                    {watch('raceOther') &&
                    <Col xs={24} md={12} lg={12} span={12}>
                        <TextInput
                            label={personalParticularFields.raceOther?.label}
                            disabled
                            value={watch('raceOther')}
                        />
                    </Col>}
                </Row>

                <Row gutter={16}>
                    {watch('contact') &&
                    <Col xs={24} md={12} lg={12} span={12}>
                        <PhoneInput
                            label={personalParticularFields.contact?.label}
                            disabled
                            value={{
                                prefix: watch('contact.prefix'),
                                number: watch('contact.number')
                            }}
                        />
                    </Col>}
                </Row>

                {watch('address1') &&
                <TextInput
                    label={personalParticularFields.address1?.label}
                    disabled
                    value={watch('address1')}
                />}

                {watch('address2') &&
                <TextInput
                    label={personalParticularFields.address2?.label}
                    disabled
                    value={watch('address2')}
                />}

                {watch('address3') &&
                <TextInput
                    label={personalParticularFields.address3?.label}
                    disabled
                    value={watch('address3')}
                />}

                <Row gutter={16}>
                    {watch('country') &&
                    <Col xs={24} md={12} lg={12} span={12}>
                        <SelectInput
                            label={personalParticularFields.country?.label}
                            disabled
                            value={watch('country')}
                        >
                            {countries.map((itm) => <Option value={itm.code} key={itm.code}>{itm.name}</Option>)}
                        </SelectInput>
                    </Col>}

                    {watch('state') &&
                    <Col xs={24} md={12} lg={12} span={12}>
                        <SelectInput
                            label={personalParticularFields.state?.label}
                            disabled
                            value={watch('state')}
                        >
                            {malaysiaStates.map((itm) => <Option value={itm} key={itm}>{itm}</Option>)}
                        </SelectInput>
                    </Col>}
                </Row>

                <Row gutter={16}>
                    {watch('city') &&
                    <Col xs={24} md={12} lg={12} span={12}>
                        <TextInput
                            label={personalParticularFields.city?.label}
                            disabled
                            value={watch('city')}
                        />
                    </Col>}

                    {watch('postcode') &&
                    <Col xs={24} md={12} lg={12} span={12}>
                        <TextInput
                            label={personalParticularFields.postcode?.label}
                            disabled
                            value={watch('postcode')}
                        />
                    </Col>}
                </Row>

                <Row className='mb-4'>
                    {(watch('sameAddress') !== null && watch('sameAddress') !== undefined) &&
                    <Col xs={24} span={24}>
                        <SwitchInput
                            label={personalParticularFields.sameAddress?.label}
                            disabled
                            checked={watch('sameAddress')}
                        />
                    </Col>}
                </Row>

                {watch('mailingAddress1') &&
                <TextInput
                    label={personalParticularFields.mailingAddress1?.label}
                    disabled
                    value={watch('mailingAddress1')}
                />}

                {watch('mailingAddress2') &&
                <TextInput
                    label={personalParticularFields.mailingAddress2?.label}
                    disabled
                    value={watch('mailingAddress2')}
                />}

                {watch('mailingAddress3') &&
                <TextInput
                    label={personalParticularFields.mailingAddress3?.label}
                    disabled
                    value={watch('mailingAddress3')}
                />}

                <Row gutter={16}>
                    {watch('mailingCountry') &&
                    <Col xs={24} md={12} lg={12} span={12}>
                        <SelectInput
                            label={personalParticularFields.mailingCountry?.label}
                            disabled
                            value={watch('mailingCountry')}
                        >
                            {countries.map((itm) => <Option value={itm.code} key={itm.code}>{itm.name}</Option>)}
                        </SelectInput>
                    </Col>}

                    {watch('mailingState') &&
                    <Col xs={24} md={12} lg={12} span={12}>
                        <SelectInput
                            label={personalParticularFields.mailingState?.label}
                            disabled
                            value={watch('mailingState')}
                        >
                            {malaysiaStates.map((itm) => <Option value={itm} key={itm}>{itm}</Option>)}
                        </SelectInput>
                    </Col>}
                </Row>

                <Row gutter={16}>
                    {watch('mailingCity') &&
                    <Col xs={24} md={12} lg={12} span={12}>
                        <TextInput
                            label={personalParticularFields.mailingCity?.label}
                            disabled
                            value={watch('mailingCity')}
                        />
                    </Col>}

                    {watch('mailingPostcode') &&
                    <Col xs={24} md={12} lg={12} span={12}>
                        <TextInput
                            label={personalParticularFields.mailingPostcode?.label}
                            disabled
                            value={watch('mailingPostcode')}
                        />
                    </Col>}
                </Row>
            </>}

            {hasEditedFinancialFields &&
            <>
                <div className='text-lg text-blue-500 mt-3 mb-5'>
                    Edited Financial Info Fields
                </div>

                <Row gutter={16}>
                    {(watch('isIslamicBank') !== null && watch('isIslamicBank') !== undefined) &&
                    <Col xs={24} span={24}>
                        <RadioGroup
                            label={financialFields.isIslamicBank?.label}
                            disabled
                            value={watch('isIslamicBank')}
                        >
                            <Radio value={1}>Yes</Radio>
                            <Radio value={0}>No</Radio>
                        </RadioGroup>
                    </Col>}
                </Row>

                <Row gutter={16}>
                    {watch('bankName') &&
                    <Col xs={24} md={12} lg={12} span={12}>
                        <SelectInput
                            label={financialFields.bankName?.label}
                            disabled
                            value={watch('bankName')}
                        >
                            {watch('isIslamicBank') === 1 &&
                            <>
                                {islamicBankList.map((itm, idx) => <Option value={itm.key} key={`islamic-bank-name-${idx}`}>{itm.name}</Option>)}
                            </>}

                            {watch('isIslamicBank') === 0 &&
                            <>
                                {nonIslamicBankOptions.map((itm, idx) => <Option value={itm.key} key={`non-islamic-bank-name-${idx}`}>{itm.name}</Option>)}
                            </>}
                        </SelectInput>
                    </Col>}

                    <Col xs={24} md={12} lg={12} span={12}>
                        {watch('bankAccountNumber') &&
                        <TextInput
                            label={financialFields.bankAccountNumber?.label}
                            disabled
                            value={watch('bankAccountNumber')}
                        />}
                    </Col>
                </Row>

                {watch('bankStatement') &&
                <Row gutter={16}>
                    <Col xs={24} md={12} lg={12} span={12}>
                        <SingleFileInput
                            label={financialFields.bankStatement?.label}
                            disabled
                            previewTitle='Bank Statement'
                            selectedFile={watch('bankStatement')}
                        />
                    </Col>
                </Row>}
            </>}

            <Row gutter={16}>
                <Col xs={24} md={12} lg={12} span={12}>
                    <Form.Item
                        className='mb-4 quesTitle'
                        label='Signature'
                        validateStatus={(errors?.signature?.message) ? 'error' : '-'}
                        help={errors?.signature?.message}
                    >
                        <Controller
                            name='signature'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <SignatureInput
                                    onChange={(base64) => {
                                        onChange(base64)
                                    }}
                                />
                            )}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item
                validateStatus={(errors?.signatureAgreement?.message) ? 'error' : '-'}
                help={errors?.signatureAgreement?.message}
            >
                <Controller
                    name='signatureAgreement'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Checkbox
                            ref={ref}
                            style={{ width: '100%' }}
                            onChange={(e) => {
                                onChange((e.target.checked) ? 1 : 0)
                            }}
                            checked={value}
                        >
                            <div style={{ width: '100%' }}>
                                I hereby confirm and agree that the signature is signed by me and I fully understand that it will be affixed to the Bursa Malaysia Central Depository System updating form and Malacca Securities Sdn Bhd internal updating form. By providing this signature, I also agreed to be bound by terms and conditions on the operations of Malacca Securities Sdn Bhd Trading account and terms and conditions of updating a Bursa Malaysia Central Depository Account.
                                <br /><br />
                                I hereby provide my consent expressively to Bursa Malaysia Depository for disclosure of information or documents relating to my affairs and in particular related to my CDS Accounts to Malacca Securities Sdn Bhd and to such agents, service providers and sub-contractors of Malacca Securities Sdn Bhd as informed by Malacca Securities Sdn Bhd. This consent will be valid until it is revoked by me. I release Bursa Malaysia Depository from any loss or liability arising from or in connection with this authorisation except for loss of liability I may suffer as a result of any act, statement and omission that was not done in good faith by Bursa Malaysia Depository.
                            </div>
                        </Checkbox>
                    )}
                />
            </Form.Item>
        </fieldset>
    )
}

SummaryInfoFieldset.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}

SummaryInfoFieldset.defaultProps = {
}

export default SummaryInfoFieldset
