import { useState, useEffect } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import SendProfileUpdateRequestForm from '../SendProfileUpdateRequestForm'
import styles from './SendProfileUpdateRequestFormWrapper.module.scss'

const SendProfileUpdateRequestFormWrapper = props => {
    return (
        <div className={cn(styles.wrapper, props.className)} style={props.style}>
            <h1 className={styles.title}>Updating of Client Particulars</h1>
            <h2 className={styles.subtitle}>Email Verification Request</h2>

            <SendProfileUpdateRequestForm
                className='mt-8'
                onSuccessSubmit={props.onSuccessSubmit}
            />
        </div>
    )
}

SendProfileUpdateRequestFormWrapper.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onSuccessSubmit: PropTypes.func
}

SendProfileUpdateRequestFormWrapper.defaultProps = {
    onSuccessSubmit: () => {}
}

export default SendProfileUpdateRequestFormWrapper
