import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './CustomerLayout.module.scss'
import CustomerHeader from './header'

const CustomerLayout = (props) => {
    return (
        <div className={styles.wrapper}>
            <CustomerHeader />

            <div className={styles.contentWrapper}>
                {props.children}
            </div>
        </div>
    )
}

CustomerLayout.propTypes = {
}

CustomerLayout.defaultProps = {
}

export default CustomerLayout
