import getMockResult from './getMockResult'

const methodsAvailableToMock = [
    'GET', 'POST', 'PUT', 'DELETE'
]

const mockEndpoints = async (mockAdapter, endpoints = {}) => {
    for (const [key, value] of Object.entries(endpoints)) {
        const endpoint = endpoints[key]
        const tmp = key.split(' ')
        const method = tmp[0]
        const path = tmp[1]
        const mockResult = await getMockResult(value)

        if (methodsAvailableToMock.includes(method)) {
            const functionName = 'on' + method[0].toUpperCase() + method.slice(1).toLowerCase()
            mockAdapter[functionName](path).reply(200, mockResult)
        }
    }
}

export default mockEndpoints
