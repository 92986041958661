import { useState, useEffect, useCallback, Profiler } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Spin, Modal } from 'antd'
import qs from 'qs'
import { inject } from 'mobx-react'
import CustomerLayout from '../../../../modules/customer/components/template/layout'
import ProfileUpdateRequestFormWrapper from '../../../../modules/customer/profile/update/request/components/form/wrapper'
import ProfileUpdateRequestStatusPending from '../../../../modules/customer/profile/update/request/components/status/pending'
import ProfileUpdateRequestEKYCVerification from '../../../../modules/customer/profile/update/request/ekyc/components/verification'

const ProfileUpdateRequestPage = props => {
    const location = useLocation()
    const history = useHistory()
    const [verifying, setVerifying] = useState(true)
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    const [isValid, setIsValid] = useState(false)
    const [data, setData] = useState(null)
    const [ekyc, setEkyc] = useState(null)

    useEffect(() => {
    }, [])

    useEffect(() => {
        if (query?.code) {
            verify(query.code)
        }
    }, [query?.code])

    const verify = async (code) => {
        try {
            setVerifying(true)
            const res = await props.services.customerService.verifyCode(code)

            if (res.ok) {
                setIsValid(true)
                setData(res.data)
            } else {
                setIsValid(false)
                Modal.info({
                    title: 'Invalid Link',
                    content: (
                        <p>
                            Looks like the profile update request link you clicked on is invalid.
                        </p>
                    ),
                    onOk: () => {
                        history.push('/')
                    }
                })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setVerifying(false)
        }
    }

    if (!query?.code) {
        history.replace('/')
        return (<div />)
    }

    return (
        <>
            {verifying &&
            <div className='flex flex-col w-full h-screen justify-center content-center place-content-center'>
                <Spin size='large' />
                <p className='text-lg mt-2 text-center text-blue-400'>Verifying ...</p>
            </div>}

            {!verifying && isValid &&
            <CustomerLayout>
                {(!data?.customerProfileUpdateRequest ||
                data?.customerProfileUpdateRequest?.status !== 'PENDING') &&
                <>
                    {!ekyc &&
                    <ProfileUpdateRequestFormWrapper
                        accessToken={data?.accessToken}
                        defaultValues={data?.profile}
                        onSuccessSubmit={(data) => {
                            setEkyc(data?.ekyc)
                        }}
                    />}

                    {ekyc &&
                    <ProfileUpdateRequestEKYCVerification
                        referenceId={ekyc?.referenceId}
                        accountId={ekyc?.accountId}
                        templateId={ekyc?.templateId}
                        inquiryId={ekyc?.inquiryId}
                        accessToken={ekyc?.accessToken}
                        environment={ekyc?.environment}
                        url={ekyc?.ekycUrl}
                        method={ekyc?.method}
                        onComplete={() => {
                            window.location.reload()
                        }}
                    />}
                </>}

                {data?.customerProfileUpdateRequest?.status === 'PENDING' &&
                <ProfileUpdateRequestStatusPending />}
            </CustomerLayout>}
        </>
    )
}

export default inject('store', 'services')(ProfileUpdateRequestPage)
