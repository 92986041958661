import { create } from 'apisauce'
import qs from 'qs'

class HttpClientWrapper {
    httpClient = null
    authToken = null

    constructor (options) {
        this.httpClient = create(options)

        this.httpClient.addRequestTransform((request) => {
            if (this.authToken) {
                request.headers.Authorization = 'Bearer ' + this.authToken
            }

            if (request.method === 'post' && request.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
                request.data = qs.stringify(request.data)
            } else if (request.method === 'put' && request.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
                request.data = qs.stringify(request.data)
            } else if (request.method === 'get' && request.data) {
                request.data = qs.stringify(request.data)
                request.url += '?' + request.data
            } else if (request.method === 'delete' && request.data) {
                request.url += '?' + request.data
            }
        })

        this.post = this.post.bind(this)
        this.get = this.get.bind(this)
    }

    post = async (...args) => {
        return new Promise((resolve) => {
            (async () => {
                const response = await this.httpClient.post(...args)
                resolve(response)
            })()
        })
    }

    put = async (...args) => {
        return new Promise((resolve) => {
            (async () => {
                const response = await this.httpClient.put(...args)
                resolve(response)
            })()
        })
    }

    delete = async (...args) => {
        return new Promise((resolve) => {
            (async () => {
                const response = await this.httpClient.delete(...args)
                resolve(response)
            })()
        })
    }

    get = async (...args) => {
        return new Promise((resolve) => {
            (async () => {
                const response = await this.httpClient.get(...args)
                resolve(response)
            })()
        })
    }

    setAuthToken = (authToken) => {
        this.authToken = authToken
    }
}

export default HttpClientWrapper
