const financialFields = {
    isIslamicBank: {
        label: 'Are you using an Islamic Banking Account?',
        inputType: 'radio'
    },
    bankName: {
        label: 'Bank Name',
        inputType: 'SELECT'
    },
    bankAccountNumber: {
        label: 'Bank Account Number',
        inputType: 'TEXT'
    },
    bankStatement: {
        label: 'Bank Statement',
        inputType: 'FILE'
    }
}

export default financialFields
