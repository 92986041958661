import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import cn from 'classnames'
import mailSentImage from '../../../../assests/images/mail-sent.png'
import CustomerLayout from '../../../../modules/customer/components/template/layout'
import ResendButton from '../../../../modules/customer/profile/update/request/resend/components/button'

const ProfileUpdateRequestEmailSentPage = props => {
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
    }, [location])

    if (!location.state?.email) {
        history.push('/')
        return (<div />)
    }

    return (
        <CustomerLayout>
            <div className={cn('container mx-auto text-center md:w-8/12')}>
                <p className='text-black mb-4'>
                    An email will be sent to your registered email address for you to update your profile information.
                </p>
                <p className='text-black'>
                    An email titled <span className='font-weight-bold'>“MPLUS Online - Profile Update"</span> has been sent to {location.state?.email}. Please check your email Inbox.
                </p>
                <h1 className='oxygen-font-weight-bold text-2xl text-gray-500 mt-8'>
                    Check your inbox in order to update your profile information
                </h1>
                <img
                    className='m-auto'
                    src={mailSentImage}
                    alt='Mail Sent'
                    width={160}
                    height={160}
                />

                <hr className='mb-5' style={{ color: '#C8C8C8' }} />

                <p className='text-lg text-black mb-3 font-weight-bold'>
                    Didn't receive the email?
                </p>
                <p className='text-gray-500 mb-5'>
                    P.S: Sometimes the email may fall into Junk/Spam categories. Don’t forget to check if you could not locate the email in your inbox. If you do not received the email after 1 minute, you may click on the “Resend Email Button”
                </p>

                <ResendButton documentNumber={location.state?.documentNumber} />
            </div>
        </CustomerLayout>
    )
}

export default inject('store')(observer(ProfileUpdateRequestEmailSentPage))
