import { useState, useEffect } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />

const SubmitButton = props => {
    return (
        <button
            type='submit'
            className={cn(props.className, 'btn-primary-full px-4 py-2 rounded font-weight-bold')}
            disabled={props.submitting || props.disabled}
            style={props.style}
            onClick={props.onClick}
        >
            {!props.submitting &&
            <span>{props.label}</span>}

            {props.submitting &&
            <div className='d-flex'>
                <Spin indicator={antIcon} />
                <span className='ml-2 font-weight-bold'>{props.labelSubmitting}</span>
            </div>}
        </button>
    )
}

SubmitButton.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    labelSubmitting: PropTypes.string,
    submitting: PropTypes.bool,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
}

SubmitButton.defaultProps = {
    submitting: false,
    disabled: false,
    labelSubmitting: 'Submitting'
}

export default SubmitButton
