import { useState, useEffect } from 'react'
import { inject } from 'mobx-react'
import Layout from '../modules/application/components/templates/layout'
import StatusNew from '../modules/application/components/status/new'
import StatusEmailVerified from '../modules/application/components/status/email-verified'
import StatusPaid from '../modules/application/components/status/paid'
import StatusVerified from '../modules/application/components/status/verified'
import StatusAmend from '../modules/application/components/status/amend'
import StatusAmendWithDocument from '../modules/application/components/status/amend-with-document'
import StatusRejected from '../modules/application/components/status/rejected'
import StatusApproved from '../modules/application/components/status/approved'

const StatusPage = props => {
    useEffect(() => {
    }, [])

    return (
        <Layout>
            <div className='pb-10'>
                {props.store?.applicationStatus === 'NEW' &&
                <StatusNew fullName={props.store?.fullName} email={props.store?.email} />}

                {props.store?.applicationStatus === 'EMAIL_VERIFIED' &&
                <StatusEmailVerified completedStep={props.store?.completedStep} paymentStatus={props.store?.paymentStatus} />}

                {props.store?.applicationStatus === 'PAID' &&
                <StatusPaid ekyc={props.store?.ekyc} fullName={props.store?.fullName} />}

                {props.store?.applicationStatus === 'VERIFIED' &&
                <StatusVerified fullName={props.store?.fullName} />}

                {props.store?.applicationStatus === 'AMEND' &&
                <StatusAmend />}

                {props.store?.applicationStatus === 'AMEND_WITH_DOCUMENT' &&
                <StatusAmendWithDocument />}

                {props.store?.applicationStatus === 'REJECTED' &&
                <StatusRejected />}

                {props.store?.applicationStatus === 'APPROVED' &&
                <StatusApproved />}

                {((props.store?.applicationStatus === 'VERIFIED' ||
                props.store?.applicationStatus === 'REJECTED' ||
                props.store?.applicationStatus === 'APPROVED') &&
                props.store?.partner && props.store?.partner?.redirectionUrl) &&
                <div className='mx-auto flex mt-4'>
                    <a href={props.store.partner.redirectionUrl} className='btn-primary-outline px-4 py-2 rounded font-weight-bold mx-auto'>
                        Back to {props.store.partner?.fullName}
                    </a>
                </div>}
            </div>
        </Layout>
    )
}

export default inject('store')(StatusPage)
