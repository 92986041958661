import { useState, useEffect } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styles from '../ProfileUpdateRequestStatus.module.scss'

const ProfileUpdateRequestStatusPending = props => {
    useEffect(() => {
    }, [])

    return (
        <div className={cn(styles.wrapper, props.className)} style={props.style}>
            <div className={styles.title}>
                Profile Update Request Submitted
            </div>

            <p className={styles.description}>
                Your request to update your profile has been submitted to us.
            </p>

            <p className={styles.description}>
                If you do require any assistance, you can visit our Website, Facebook Page or have a chat with SITI (our ChatBot).
            </p>

            <p className={styles.description}>
                Thank you once again for choosing M+ Online.
            </p>

            <div className='mt-6'>
                <Link
                    className='btn-primary-full px-4 py-2 rounded font-weight-bold'
                    to='/'
                >
                    Back Home
                </Link>
            </div>
        </div>
    )
}

ProfileUpdateRequestStatusPending.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}

ProfileUpdateRequestStatusPending.defaultProps = {
}

export default ProfileUpdateRequestStatusPending
