import { useMemo, useEffect } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import qs from 'qs'
import styles from '../ApplicationStatus.module.scss'

const ApplicationStatusEmailVerified = props => {
    const location = useLocation()
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })

    const nextStepLink = useMemo(() => {
        let nextStepLink
        switch (props.completedStep) {
            case 1:
                nextStepLink = '/cds-trading-account-registration'
                break
            case 2:
                nextStepLink = '/employment-and-spouse-details'
                break
            case 3:
                nextStepLink = '/financial-profile'
                break
            case 4:
                nextStepLink = '/taxation-and-declarations'
                break
            case 5:
                nextStepLink = '/summary'
                break
        }
        if (query?.exclude && query?.exclude === 'payment') {
            nextStepLink += '?exclude=payment'
        }
        return nextStepLink
    }, [query, props.completedStep])

    useEffect(() => {
    }, [])

    return (
        <div className={cn(styles.wrapper, props.className, '')}>
            {(props.paymentStatus === 'NEW' ||
            props.paymentStatus === 'PENDING') &&
            <>
                <div className={styles.title}>
                    We're halfway there!
                </div>
                <p className={styles.description}>
                    Welcome back to M+ CDS Trading Account Registration Journey, to continue your application please click on the continue button below.
                </p>
            </>}

            {props.paymentStatus === 'FAILED' &&
            <>
                <div className={styles.title}>
                    We're halfway there!
                </div>
                <p className={styles.description}>
                    Payment failed, please retry
                </p>
            </>}

            {props.paymentStatus === 'REJECTED' &&
            <>
                <div className={styles.title}>
                    We're halfway there!
                </div>
                <p className={styles.description}>
                    Your payment has been rejected
                </p>
            </>}

            <Link className='btn-primary-full px-4 py-2 rounded font-weight-bold' to={nextStepLink}>
                Continue
            </Link>
        </div>
    )
}

ApplicationStatusEmailVerified.propTypes = {
    className: PropTypes.string,
    completedStep: PropTypes.number.isRequired,
    paymentStatus: PropTypes.string.isRequired
}

ApplicationStatusEmailVerified.defaultProps = {
}

export default ApplicationStatusEmailVerified
