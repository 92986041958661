import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import DatePicker from './DatePicker'

const DatePickerWithController = React.forwardRef((props, ref) => {
    const [disabled, setDisabled] = useState(props.disabled)
    const { unregister } = useFormContext()

    useEffect(() => {
        setDisabled(props.disabled)
    }, [props.disabled])

    return (
        <>
            {!disabled &&
            <Controller
                name={props.name}
                defaultValue={props.defaultValue}
                render={({ field: { value, onChange, ref } }) => (
                    <DatePicker
                        ref={ref}
                        className={props.className}
                        format={props.format}
                        label={props.label}
                        placeholder={props.placeholder}
                        disabled={disabled}
                        ableToToggleDisabled={props.ableToToggleDisabled}
                        onToggleDisabled={(disabled) => {
                            if (disabled) {
                                setDisabled(true)
                                unregister(props.name)
                            }
                        }}
                        onChange={(e) => {
                            onChange(e)
                            console.log(e)
                            props.onChange(e)
                        }}
                        value={value}
                        error={props.error}
                    />
                )}
            />}

            {disabled &&
            <DatePicker
                label={props.label}
                format={props.format}
                className={props.className}
                disabled={disabled}
                ableToToggleDisabled={props.ableToToggleDisabled}
                onToggleDisabled={(disabled) => {
                    setDisabled(false)
                }}
                value={props.defaultValue}
            />}
        </>
    )
})

DatePickerWithController.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    name: PropTypes.string,
    defaultValue: PropTypes.any,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.object,
    onChange: PropTypes.func,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    ableToToggleDisabled: PropTypes.bool,
    onToggleDisabled: PropTypes.func
}

DatePickerWithController.defaultProps = {
    defaultValue: null,
    onChange: () => {},
    disabled: false,
    ableToToggleDisabled: false,
    onToggleDisabled: () => {}
}

export default DatePickerWithController
