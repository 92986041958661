import { useState, useEffect } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import styles from './GuidelineList.module.scss'

const GuidelineList = props => {
    return (
        <ol className={cn(styles.wrapper, props.className)}>
            {props.data.map((item, index) =>
                <li key={index}>
                    {item}
                </li>
            )}
        </ol>
    )
}

GuidelineList.propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.string)
}

GuidelineList.defaultProps = {
}

export default GuidelineList
