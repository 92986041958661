import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './AdminLayout.module.scss'
import AdminHeader from './header'

const AdminLayout = (props) => {
    return (
        <div className={styles.wrapper}>
            <AdminHeader />

            <div className={styles.contentWrapper}>
                {props.children}
            </div>
        </div>
    )
}

AdminLayout.propTypes = {
}

AdminLayout.defaultProps = {
}

export default AdminLayout
