import React, { useState, useEffect } from 'react'
import styles from './OtpInput.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'
import OtpInput2 from 'react-otp-input'

const OtpInput = React.forwardRef((props, ref) => {
    return (
        <div className={cn(styles.wrapper, props.className)} style={props.style}>
            <OtpInput2
                ref={ref}
                value={props.value}
                onChange={props.onChange}
                numInputs={props.numInputs}
                separator={props.separator}
                isInputNum={props.isInputNum}
                focusStyle={styles.inputFocus}
                placeholder={props.placeholder || '      '}
            />
        </div>
    )
})

OtpInput.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    placeholder: PropTypes.string
}

OtpInput.defaultProps = {
}

export default OtpInput
