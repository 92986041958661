import { useState, useEffect } from 'react'
import { Statistic, message } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import cn from 'classnames'
import Button from '../../../../../../../../components/button'
import styles from './SendEmailUpdateRequestButton.module.scss'

const { Countdown } = Statistic

const SendEmailUpdateRequestButton = props => {
    const [sending, setSending] = useState(false)
    const [toShowCountdown, setToShowCountdown] = useState(false)
    const [sendAt, setSendAt] = useState(null)

    useEffect(() => {
        if (props.store?.sendEmailUpdateRequestAt) {
            const sendAt = moment(props.store.sendEmailUpdateRequestAt)
            const currentTime = moment()
            const duration = moment.duration(currentTime.diff(sendAt))
            if (duration.asMilliseconds() > 15 * 1000 || props.disabled) {
                setToShowCountdown(false)
            } else {
                setToShowCountdown(true)
            }
            setSendAt(moment(props.store.sendEmailUpdateRequestAt))
        }
    }, [props.store?.sendEmailUpdateRequestAt, props.disabled])

    const send = async () => {
        try {
            setSending(true)
            const res = await props.services.customerService.sendEmailUpdateRequest(props.accessToken, props.email)

            if (res.ok) {
                const sendAt = moment()
                props.store?.setSendEmailUpdateRequestAt(sendAt)
                setToShowCountdown(true)
                props.onSuccessSend()
            } else if (res.status === 422) {
                props.onErrorSend(res.data.errors)
            } else {
                message.error('Something went wrong')
            }
        } catch (error) {
            console.error(error)
        } finally {
            setSending(false)
        }
    }

    return (
        <div className={cn(props.className)}>
            {!toShowCountdown &&
            <Button
                label={props.label}
                loading={sending}
                disabled={props.disabled || sending}
                onClick={send}
            />}

            {toShowCountdown &&
            <Countdown
                className={styles.countdown}
                title={props.countdownTitle}
                value={sendAt + (15 * 1000)}
                onFinish={() => setToShowCountdown(false)}
                valueStyle={{
                    fontSize: '14px'
                }}
            />}
        </div>
    )
}

SendEmailUpdateRequestButton.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    accessToken: PropTypes.string,
    email: PropTypes.string,
    onSuccessSend: PropTypes.func,
    onErrorSend: PropTypes.func,
    countdownTitle: PropTypes.string
}

SendEmailUpdateRequestButton.defaultProps = {
    onSuccessSend: () => {},
    onErrorSend: () => {},
    countdownTitle: 'Able to send OTP after'
}

export default inject('store', 'services')(observer(SendEmailUpdateRequestButton))
