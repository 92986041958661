import { useState, useEffect, useCallback, useRef } from 'react'
import { Form, Tabs } from 'antd'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import schema from './schema'
import { inject } from 'mobx-react'
import SubmitButton from '../../../../../../../components/button/submit'
import Button from '../../../../../../../components/button'
import FormError from '../../../../../../../components/form/error'
import PersonalInfoFieldset from '../fieldset/personal'
import FinancialInfoFieldset from '../fieldset/financial'
import SummaryInfoFieldset from '../fieldset/summary/SummaryFieldset'
import personalParticularFields from '../../../../../../../constants/personalParticularFields'
import financialFields from '../../../../../../../constants/financialFields'
import summaryFields from '../../../../../../../constants/summaryFields'

const { TabPane } = Tabs

const ProfileUpdateRequestForm = props => {
    const refFormWrapper = useRef(null)
    const [submitting, setSubmitting] = useState(false)
    const [apiErrorMessages, setApiErrorMessages] = useState([])
    const [currentTabKey, setCurrentTabKey] = useState('personal-particulars')
    const [hasEditedFields, setHasEditedFields] = useState(false)
    const [isEmailVerified, setIsEmailVerified] = useState(false)

    const reactHookFormMethods = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        context: {
            isEmailVerified,
            nationality: props.defaultValues?.nationality
        }
    })

    useEffect(() => {
        // console.log(props.defaultValues)
    }, [])

    useEffect(() => {
        const hasEditedFields = !_.isEmpty(reactHookFormMethods.watch())
        setHasEditedFields(hasEditedFields)
    }, [reactHookFormMethods.watch()])

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            setApiErrorMessages([])

            if (data.isIslamicBank === '1') {
                data.isIslamicBank = true
            } else if (data.isIslamicBank === '0') {
                data.isIslamicBank = false
            }

            const form = new FormData()
            for (const key in data) {
                if (key !== 'bankStatement') {
                    if (data[key] !== undefined) {
                        form.append(key, data[key])
                    }
                } else {
                    form.append('bankStatementPath', data[key])
                }
            }

            const res = await props.services.customerService.submitProfileUpdateRequest(props.accessToken, form)

            if (res.ok) {
                if (props.onSuccessSubmit) {
                    props.onSuccessSubmit(res.data)
                }
            } else if (res.status === 422) {
                if (res.data?.errors) {
                    const nonErrorFieldMessages = []
                    const errors = Object.keys(res.data.errors)
                    const inputFieldNames = Object.keys(reactHookFormMethods.watch())
                    const personalParticularFieldNames = Object.keys(personalParticularFields)
                    const financialFieldNames = Object.keys(financialFields)
                    const summaryFieldNames = Object.keys(summaryFields)

                    errors.forEach((key, index) => {
                        const firstErrorMsg = res.data.errors[key][0]
                        if (inputFieldNames.includes(key)) {
                            if (index === 0) {
                                if (personalParticularFieldNames.includes(key)) {
                                    setCurrentTabKey('personal-particulars')
                                } else if (financialFieldNames.includes(key)) {
                                    setCurrentTabKey('financial-profile')
                                } else if (summaryFieldNames.includes(key)) {
                                    setCurrentTabKey('summary')
                                }

                                if (key !== 'sameAddress' && key !== 'signature') {
                                    reactHookFormMethods.setFocus(key)
                                }
                            }
                            reactHookFormMethods.setError(key, {
                                type: 'manual',
                                message: firstErrorMsg
                            })
                        } else {
                            nonErrorFieldMessages.push(firstErrorMsg)
                        }
                    })

                    setApiErrorMessages(nonErrorFieldMessages)
                    if (nonErrorFieldMessages.length !== 0) refFormWrapper.current.scrollIntoView({ behavior: 'smooth' })
                }
            } else {
                setApiErrorMessages(['Something went wrong'])
                refFormWrapper.current.scrollIntoView({ behavior: 'smooth' })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setSubmitting(false)
        }
    }

    const handleTabChange = async (activeKey) => {
        if (currentTabKey === 'summary') {
            setCurrentTabKey(activeKey)
        } else {
            const isValid = await triggerValidation(currentTabKey)
            if (!isValid) return
            setCurrentTabKey(activeKey)
        }
    }

    const handleBtnNextClick = async () => {
        const isValid = await triggerValidation(currentTabKey)
        if (!isValid) return
        if (currentTabKey === 'personal-particulars') {
            setCurrentTabKey('financial-profile')
        } else if (currentTabKey === 'financial-profile' && hasEditedFields) {
            setCurrentTabKey('summary')
        }
    }

    const handleBtnBackClick = async () => {
        if (currentTabKey === 'financial-profile') {
            setCurrentTabKey('personal-particulars')
        } else if (currentTabKey === 'summary') {
            setCurrentTabKey('financial-profile')
        }
    }

    const triggerValidation = useCallback(async (currentTabKey) => {
        if (currentTabKey === 'personal-particulars') {
            const isValid = await reactHookFormMethods.trigger(
                Object.keys(personalParticularFields),
                { shouldFocus: true }
            )
            return isValid
        } else if (currentTabKey === 'financial-profile') {
            const isValid = await reactHookFormMethods.trigger(
                Object.keys(financialFields),
                { shouldFocus: true }
            )
            return isValid
        }
    })

    return (
        <FormProvider {...reactHookFormMethods}>
            <div ref={refFormWrapper}>
                <Form
                    className={cn(props.className, 'w-full')}
                    layout='vertical'
                    onFinish={reactHookFormMethods.handleSubmit(onSubmit)}
                >
                    {apiErrorMessages.length !== 0 &&
                    <FormError className='mb-3' messages={apiErrorMessages} />}

                    <Tabs
                        defaultActiveKey='personal-particulars'
                        activeKey={currentTabKey}
                        onChange={handleTabChange}
                    >
                        <TabPane className='py-3' tab='Personal Particulars' key='personal-particulars'>
                            <PersonalInfoFieldset
                                accessToken={props.accessToken}
                                onIsEmailVerifiedChange={(isEmailVerified) => {
                                    setIsEmailVerified(isEmailVerified)
                                }}
                                defaultValues={props.defaultValues}
                            />
                        </TabPane>

                        <TabPane className='py-3' tab='Registered Bank Information' key='financial-profile'>
                            <FinancialInfoFieldset
                                defaultValues={props.defaultValues}
                            />
                        </TabPane>

                        <TabPane className='py-3' tab='Summary' key='summary' disabled={!hasEditedFields}>
                            <SummaryInfoFieldset />
                        </TabPane>
                    </Tabs>

                    {currentTabKey !== 'personal-particulars' &&
                    <Button
                        label='Back'
                        type='button'
                        className='mr-2'
                        outline
                        onClick={handleBtnBackClick}
                    />}

                    {currentTabKey !== 'summary' &&
                    <Button
                        label='Next'
                        type='button'
                        disabled={currentTabKey === 'financial-profile' && !hasEditedFields}
                        onClick={handleBtnNextClick}
                    />}

                    {currentTabKey === 'summary' &&
                    <SubmitButton
                        label='Submit'
                        submitting={submitting}
                    />}
                </Form>
            </div>
        </FormProvider>
    )
}

ProfileUpdateRequestForm.propTypes = {
    className: PropTypes.string,
    onSuccessSubmit: PropTypes.func,
    defaultValues: PropTypes.shape({
        email: PropTypes.string
    })
}

ProfileUpdateRequestForm.defaultProps = {
}

export default inject('services')(ProfileUpdateRequestForm)
