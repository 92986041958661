import { notification } from 'antd'
import config from 'config'
import xss from 'xss'
import moment from 'moment'

export const displaySystemNotice = (categories = []) => {
    if (!config.app.systemNotices) return null

    config.app.systemNotices.map((systemNotice, index) => {
        if (!systemNotice?.toDisplay) return null
        if (categories.length > 0 && !categories.includes(systemNotice?.category)) return null

        const currentTime = moment()
        const startAt = (systemNotice.startAt) ? moment.utc(systemNotice.startAt).local() : null
        const endAt = (systemNotice.endAt) ? moment.utc(systemNotice.endAt).local() : null

        if (systemNotice.startAt && systemNotice.endAt) {
            if (!currentTime.isBetween(startAt, endAt, null, [])) return null
        }

        if (systemNotice.startAt && !systemNotice.endAt) {
            if (currentTime.isBefore(startAt)) return null
        }

        if (!systemNotice.startAt && systemNotice.endAt) {
            if (currentTime.isAfter(endAt)) return null
        }

        return (
            notification.info({
                key: `system-notices-${index}`,
                message: systemNotice.title,
                description: <div dangerouslySetInnerHTML={{ __html: xss(systemNotice.description) }} />,
                duration: systemNotice.duration
            })
        )
    })
}

export const closeAllSystemNotice = () => {
    config.app.systemNotices.map((systemNotice, index) =>
        notification.close(`system-notices-${index}`)
    )
}
