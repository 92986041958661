import { useState, useEffect, useRef } from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './CustomerVerificationModal.module.scss'
import CustomerVerificationForm from '../form'
import SendEmailUpdateRequestButton from '../../../email/update/request/send/components/button'

const CustomerVerificationModal = (props) => {
    const refVerificationForm = useRef(null)

    useEffect(() => {
        if (props.visible) {
            refVerificationForm.current?.reset()
        }
    }, [props.visible])

    return (
        <Modal
            visible={props.visible}
            title=''
            footer={null}
            className={styles.wrapper}
            onCancel={props.onCancel}
        >
            <div className={styles.title}>OTP Verification</div>

            <p className={styles.description}>
                A validation-PIN was sent to your email <br /><b>{props.formDefaultValues?.email}</b>. <br />
                Please enter this PIN now to validate your email address
            </p>

            <p className={cn(styles.notes, 'mt-3')}>
                P.S: Note that the OTP verification email may fall into the Junk or Spam Folder, kindly check the folders if the email is not in the inbox. If you do not receive the email after 15 seconds, you may click the "Resend OTP" button.
            </p>

            <CustomerVerificationForm
                ref={refVerificationForm}
                className='mb-5'
                accessToken={props.accessToken}
                defaultValues={props.formDefaultValues}
                onSuccessSubmit={props.onSuccessSubmit}
            />

            <div className='flex items-center justify-center mt-5'>
                <SendEmailUpdateRequestButton
                    label='Resend OTP'
                    countdownTitle='Able to resend OTP after'
                    accessToken={props.accessToken}
                    email={props.formDefaultValues.email}
                    onSuccessSend={props.onSuccessResendEmailUpdateRequest}
                    onErrorSend={props.onErrorResendEmailUpdateRequest}
                />
            </div>
        </Modal>
    )
}

CustomerVerificationModal.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    accessToken: PropTypes.string,
    formDefaultValues: PropTypes.object,
    onSuccessSubmit: PropTypes.func,
    onSuccessResendEmailUpdateRequest: PropTypes.func,
    onErrorResendEmailUpdateRequest: PropTypes.func
}

CustomerVerificationModal.defaultProps = {
    visible: false,
    onSuccessSubmit: () => {}
}

export default CustomerVerificationModal
