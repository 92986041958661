import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import PhoneInput from './PhoneInput'

const PhoneInputWithController = React.forwardRef((props, ref) => {
    const [disabled, setDisabled] = useState(props.disabled)
    const { unregister, setValue } = useFormContext()

    useEffect(() => {
        return () => {
            unregister(props.name)
        }
    }, [])

    useEffect(() => {
        setDisabled(props.disabled)
    }, [props.disabled])

    useEffect(() => {
        if (!disabled) {
            setValue(props.name, props.defaultValue)
        } else {
            unregister(props.name)
        }
    }, [disabled])

    return (
        <>
            {!disabled &&
            <Controller
                name={props.name}
                defaultValue={props.defaultValue}
                render={({ field: { value, onChange, ref } }) => {
                    return (
                        <PhoneInput
                            ref={ref}
                            label={props.label}
                            disabled={disabled}
                            ableToToggleDisabled={props.ableToToggleDisabled}
                            onToggleDisabled={(disabled) => {
                                if (disabled) {
                                    setDisabled(true)
                                    unregister(props.name)
                                }
                            }}
                            onChange={(e) => {
                                if (e) {
                                    onChange({
                                        prefix: e.code?.toString(),
                                        number: e.phone
                                    })
                                }
                                props.onChange(e)
                            }}
                            value={value}
                            error={props.error}
                        />
                    )
                }}
            />}

            {disabled &&
            <PhoneInput
                label={props.label}
                disabled={disabled}
                ableToToggleDisabled={props.ableToToggleDisabled}
                onToggleDisabled={(disabled) => {
                    setDisabled(false)
                }}
                value={props.defaultValue}
                error={props.error}
            />}
        </>
    )
})

PhoneInputWithController.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.string,
    error: PropTypes.object,
    disabled: PropTypes.bool,
    disabledBtnEdit: PropTypes.bool,
    disabledBtnCancel: PropTypes.bool,
    ableToToggleDisabled: PropTypes.bool,
    onToggleDisabled: PropTypes.func,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.object,
    defaultValue: PropTypes.object
}

PhoneInputWithController.defaultProps = {
    disabled: false,
    disableBtnEdit: false,
    disableBtnCancel: false,
    ableToToggleDisabled: false,
    onToggleDisabled: () => {},
    onChange: () => {}
}

export default PhoneInputWithController
