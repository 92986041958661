import { types, flow, getEnv } from 'mobx-state-tree'

export const Step3 = types
    .model('Step3', {
        isIslamicBank: types.maybeNull(types.boolean),
        bankName: types.maybeNull(types.string),
        bankAccountNumber: types.maybeNull(types.string),
        bankStatementPath: types.maybeNull(types.string),
        investmentTerm: types.maybeNull(types.integer),
        riskTolerance: types.maybeNull(types.integer),
        investmentObjective: types.array(types.string),
        investmentExperience: types.maybeNull(types.string),
        brokerAcctList: types.array(types.string),
        brokerAcctOther: types.maybeNull(types.string)
    })
    .views((self) => {
        return {
            get formData () {
                const data = { ...self }
                if (data.investmentObjective) {
                    data.investmentObjective = data.investmentObjective.toJSON()
                }
                if (data.brokerAcctList) {
                    data.brokerAcctList = data.brokerAcctList.toJSON()
                }
                data.isIslamicBank = data.isIslamicBank ? 1 : (data.isIslamicBank === null ? null : 0)
                return data
            }
        }
    })
    .actions((self) => ({
        setData (data) {
            for (const key in data) {
                if (!Object.prototype.hasOwnProperty.call(data, key)) {
                    console.error(`Unavailable key ${key}`)
                } else {
                    self[key] = data[key]
                }
            }
        },
        setIsIslamicBank (b) {
            self.isIslamicBank = b
            self.bankName = null
        },
        setInvestmentExperience (value) {
            if (value === 'NEW_TO_TRADING') {
                self.brokerAcctList = []
                self.brokerAcctOther = null
            }
            self.investmentExperience = value
        },
        submit: flow(function * submit (accessToken, exclude) {
            const data = { ...self }
            if (data.investmentObjective) {
                data.investmentObjective = JSON.stringify(data.investmentObjective.toJSON())
            }
            if (data.brokerAcctList) {
                data.brokerAcctList = JSON.stringify(data.brokerAcctList.toJSON())
            }
            if (data.investmentTerm) {
                let investmentTerm

                switch (data.investmentTerm) {
                    case 1:
                        investmentTerm = 'SHORT_TERM'
                        break
                    case 2:
                        investmentTerm = 'MID_TERM'
                        break
                    case 3:
                        investmentTerm = 'LONG_TERM'
                        break
                }

                data.investmentTerm = investmentTerm
            }
            if (data.riskTolerance) {
                let riskTolerance

                switch (data.riskTolerance) {
                    case 1:
                        riskTolerance = 'CONSERVATIVE'
                        break
                    case 2:
                        riskTolerance = 'MODERATE'
                        break
                    case 3:
                        riskTolerance = 'AGGRESSIVE'
                        break
                }

                data.riskTolerance = riskTolerance
            }
            if (exclude === 'payment') {
                data.exclude = exclude
                delete data.isIslamicBank
                delete data.bankName
                delete data.bankAccountNumber
                delete data.bankStatementPath
            }

            const service = getEnv(self).applicationService
            const res = yield service.completeFinancialProfile(accessToken, data)
            return res
        })
    }))
