import { useState, useEffect } from 'react'
import { Spin, Modal } from 'antd'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'
import { inject } from 'mobx-react'
import Layout from '../modules/application/components/templates/layout'
import DiligenceVerificationFormWrapper from '../modules/diligence/verification/components/form/wrapper'
import DiligenceFormWrapper from '../modules/diligence/components/form/wrapper'

const DiligencePage = props => {
    const location = useLocation()
    const history = useHistory()
    const [verifying, setVerifying] = useState(true)
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    const [code, setCode] = useState(null)
    const [isSecurityVerificationPassed, setIsSecurityVerificationPassed] = useState(false)
    const [isCompleted, setIsCompleted] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const [diligenceFormDefaultValues, setDiligenceFormDefaultValues] = useState(null)

    useEffect(() => {
    }, [])

    useEffect(() => {
        if (query?.code) {
            Modal.destroyAll()
            setIsValid(false)
            setIsCompleted(false)
            setIsSecurityVerificationPassed(false)
            setDiligenceFormDefaultValues(null)
            setCode(query?.code)
            verify(query.code)
        }
    }, [query?.code])

    const verify = async (code) => {
        try {
            setVerifying(true)
            const res = await props.services.diligenceService.getByUUID(code)

            if (res.ok) {
                const payload = res.data
                if (!payload.data) {
                    setIsValid(false)
                    Modal.info({
                        title: 'Invalid URL',
                        content: (
                            <p>
                                Looks like the link you clicked on is invalid.
                            </p>
                        ),
                        onOk: () => {
                            history.push('/')
                        }
                    })
                } else {
                    setIsValid(true)
                    setIsCompleted(payload?.data?.signed)
                }
            } else if (res.problem === 'CLIENT_ERROR') {
                setIsValid(false)
                Modal.info({
                    title: 'Invalid Token',
                    content: (
                        <p>
                            Looks like the diligence link you clicked on is invalid.
                        </p>
                    ),
                    onOk: () => {
                        history.push('/')
                    }
                })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setVerifying(false)
        }
    }

    if (!query?.code) {
        history.replace('/')
        return (<div />)
    }

    return (
        <>
            {verifying &&
            <div className='flex flex-col w-full h-screen justify-center content-center place-content-center'>
                <Spin size='large' />
                <p className='text-lg mt-2 text-center text-blue-400'>Verifying ...</p>
            </div>}

            {!verifying && isValid &&
            <Layout hideBtnNewApplication>
                {!isCompleted && !isSecurityVerificationPassed &&
                <DiligenceVerificationFormWrapper
                    code={query.code}
                    onSuccessSubmit={(payload) => {
                        setDiligenceFormDefaultValues({
                            fullName: payload?.data?.name,
                            cdsNumber: payload?.data?.cdsNumber,
                            nationality: payload?.data?.nationality,
                            documentNumber: payload?.data?.nric
                        })
                        setIsSecurityVerificationPassed(payload?.data?.verification)
                    }}
                />}

                {!isCompleted && isSecurityVerificationPassed &&
                <DiligenceFormWrapper
                    code={query.code}
                    defaultValues={diligenceFormDefaultValues}
                    onSuccessSubmit={(data) => {
                        setIsCompleted(true)
                    }}
                />}

                {isCompleted &&
                <div className='m-auto w-full text-center'>
                    <div className='text-xl font-weight-bold text-light-blue mb-3'>Thank You</div>
                    <p className='text-lg text-gray-400'>You have completed the Mandatory due diligence.</p>
                </div>}
            </Layout>}
        </>
    )
}

export default inject('services')(DiligencePage)
