const personalParticularFields = {
    email: {
        label: 'Email',
        inputType: 'EMAIL'
    },
    fullName: {
        label: 'Full Name (As Per NRIC)',
        inputType: 'TEXT'
    },
    documentNumber: {
        label: 'NRIC/Passport',
        inputType: 'TEXT'
    },
    gender: {
        label: 'Gender',
        inputType: 'TEXT'
    },
    birthDate: {
        label: 'Birth Date',
        inputType: 'DATE_PICKER'
    },
    maritalStatus: {
        label: 'Marital Status',
        inputType: 'SELECT'
    },
    race: {
        label: 'Race',
        inputType: 'SELECT'
    },
    raceOther: {
        label: 'Others',
        inputType: 'TEXT'
    },
    contact: {
        label: 'Mobile Number',
        inputType: 'PHONE'
    },
    address1: {
        label: 'Registered Address (as per NRIC)',
        inputType: 'TEXT'
    },
    address2: {
        label: 'Address Line 2',
        inputType: 'TEXT'
    },
    address3: {
        label: 'Address Line 3',
        inputType: 'TEXT'
    },
    country: {
        label: 'Country',
        inputType: 'SELECT'
    },
    state: {
        label: 'State',
        inputType: 'SELECT'
    },
    city: {
        label: 'City',
        inputType: 'TEXT'
    },
    postcode: {
        label: 'Postcode',
        inputType: 'TEXT'
    },
    sameAddress: {
        label: 'Correspondence/Mailing address is same as address',
        inputType: 'SWITCH'
    },
    mailingAddress1: {
        label: 'Correspondence Address',
        inputType: 'TEXT'
    },
    mailingAddress2: {
        label: 'Correspondence Address Line 2',
        inputType: 'TEXT'
    },
    mailingAddress3: {
        label: 'Correspondence Address Line 3',
        inputType: 'TEXT'
    },
    mailingCountry: {
        label: 'Country',
        inputType: 'SELECT'
    },
    mailingState: {
        label: 'State',
        inputType: 'SELECT'
    },
    mailingCity: {
        label: 'City',
        inputType: 'TEXT'
    },
    mailingPostcode: {
        label: 'Postcode',
        inputType: 'TEXT'
    }
}

export default personalParticularFields
