import { useState, useEffect } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { inject } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import mailSentImage from '../../../../../assests/images/mail-sent.png'
import Button from '../../../../../components/button'
import CreateApplicationForm from '../../../create/components/form'
import ResendEmailButton from '../../../create/email-verification/resend/components/button'
import styles from '../ApplicationStatus.module.scss'

const ApplicationStatusNew = props => {
    const location = useLocation()
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    const [isCreateApplicationModalVisible, setIsCreateApplicationModalVisible] = useState(false)

    useEffect(() => {
    }, [])

    const showCreateApplicationModal = async () => {
        setIsCreateApplicationModalVisible(true)
    }

    const hideCreateApplicationModal = async () => {
        setIsCreateApplicationModalVisible(false)
    }

    return (
        <div className={cn(styles.wrapper, props.className)}>
            <p className='text-black mb-4'>
                Welcome to M+ CDS Trading Account Registration Journey, an email will be sent to your registered email address for email verification purposes. Please ensure the email address input is your preferred email address.
            </p>

            <p className='text-black'>
                An email titled <span className='font-weight-bold'>“MPLUS Online - Account Opening Registration”</span> has been sent to {props?.email}. Please check your email Inbox.
            </p>

            <h1 className='oxygen-font-weight-bold text-2xl text-gray-500 mt-8'>
                Check your inbox to verify your email address
            </h1>

            <img
                className='m-auto'
                src={mailSentImage}
                alt='Mail Sent'
                width={160}
                height={160}
            />

            <Button
                className='mb-5'
                label='Change Email'
                onClick={showCreateApplicationModal}
            />

            <hr className='mb-5' style={{ color: '#C8C8C8' }} />

            <p className='text-lg text-black mb-3 font-weight-bold'>
                Didn't receive the email?
            </p>

            <p className='text-gray-500 mb-5'>
                P.S: Sometimes the email may fall into Junk/Spam categories. Don’t forget to check if you could not locate the email in your inbox. If you do not received the email after 1 minute, you may click on the “Resend Email Button”
            </p>

            <ResendEmailButton
                defaultValues={{
                    email: props.email,
                    isValidateByOTP: false,
                    exclude: query?.exclude
                }}
            />

            <Modal
                title='Change Email'
                visible={isCreateApplicationModalVisible}
                onOk={hideCreateApplicationModal}
                onCancel={hideCreateApplicationModal}
                footer={null}
            >
                <CreateApplicationForm
                    defaultValues={{
                        fullName: props.fullName,
                        email: props.email,
                        emailConfirmation: '',
                        drCode: query?.drCode,
                        partnerId: query?.partnerId,
                        partnerMemberI: query?.partnerMemberId,
                        exclude: query?.exclude
                    }}
                    onSuccessSubmit={(data) => {
                        props.store.setData({
                            fullName: data?.fullName,
                            email: data?.email,
                            applicationStatus: 'NEW',
                            isResumeApplication: false
                        })
                        hideCreateApplicationModal()
                        Modal.success({
                            content: 'You have changed your email successfully, please check your email for verification purposes',
                            okButtonProps: {
                                className: 'btn-primary-full'
                            }
                        })
                    }}
                />
            </Modal>
        </div>
    )
}

ApplicationStatusNew.propTypes = {
    className: PropTypes.string,
    fullName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
}

ApplicationStatusNew.defaultProps = {
}

export default inject('store')(ApplicationStatusNew)
