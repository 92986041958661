export const sendProfileUpdateRequestEndpointPath = '/customer/update-request'
export const submitProfileUpdateRequestEndpointPath = '/customer/submit-update-request'
export const verifyCodeEndpointPath = '/customer/verify-code'
export const verifyEndpointPath = '/customer/verify-otp'
export const sendEmailUpdateRequestEndpointPath = '/customer/update-email-request'
export const getProfileUpdateRequestEkycStatusEndpointPath = '/customer/profile-update-request-by-ctos'

const CustomerService = (api) => ({
    async sendProfileUpdateRequest (documentNumber) {
        const res = await api.post(sendProfileUpdateRequestEndpointPath, {
            documentNumber
        })
        return res
    },

    async resendProfileUpdateRequest (documentNumber) {
        const res = await api.post(sendProfileUpdateRequestEndpointPath, {
            documentNumber
        })
        return res
    },

    async verifyCode (code) {
        const res = await api.post(verifyCodeEndpointPath, {
            code
        })
        return res
    },

    async submitProfileUpdateRequest (accessToken, data) {
        api.setAuthToken(accessToken)
        const res = await api.post(submitProfileUpdateRequestEndpointPath, data, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        return res
    },

    async verify (accessToken, email, otp) {
        api.setAuthToken(accessToken)
        const res = await api.post(verifyEndpointPath, {
            email,
            otp
        })
        return res
    },

    async sendEmailUpdateRequest (accessToken, email) {
        api.setAuthToken(accessToken)
        const res = await api.post(sendEmailUpdateRequestEndpointPath, {
            email
        })
        return res
    },

    async getProfileUpdateRequestEkycStatus (referenceId) {
        const res = await api.post(getProfileUpdateRequestEkycStatusEndpointPath, {
            referenceId
        })
        return res
    }
})

export default CustomerService
