import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import TreeSelectInput from './TreeSelectInput'

const TreeSelectInputWithController = React.forwardRef((props, ref) => {
    const [disabled, setDisabled] = useState(props.disabled)
    const { unregister, setValue } = useFormContext()

    useEffect(() => {
        return () => {
            unregister(props.name)
        }
    }, [])

    useEffect(() => {
        setDisabled(props.disabled)
    }, [props.disabled])

    useEffect(() => {
        if (!disabled) {
            setValue(props.name, props.defaultValue)
        }
    }, [disabled])

    return (
        <>
            {!disabled &&
            <Controller
                name={props.name}
                defaultValue={props.defaultValue}
                render={({ field: { value, onChange, ref } }) => {
                    return (
                        <TreeSelectInput
                            ref={ref}
                            label={props.label}
                            placeholder={props.placeholder}
                            treeData={props.treeData}
                            treeDefaultExpandAll={props.treeDefaultExpandAll}
                            disabled={disabled}
                            ableToToggleDisabled={props.ableToToggleDisabled}
                            onToggleDisabled={(disabled) => {
                                if (disabled) {
                                    setDisabled(true)
                                    unregister(props.name)
                                }
                            }}
                            onChange={(e) => {
                                onChange(e)
                                props.onChange(e)
                            }}
                            value={value}
                            error={props.error}
                        />
                    )
                }}
            />}

            {disabled &&
            <TreeSelectInput
                label={props.label}
                treeData={props.treeData}
                treeDefaultExpandAll={props.treeDefaultExpandAll}
                disabled={disabled}
                ableToToggleDisabled={props.ableToToggleDisabled}
                onToggleDisabled={(disabled) => {
                    setDisabled(false)
                }}
                value={props.defaultValue}
            />}
        </>
    )
})

TreeSelectInputWithController.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    name: PropTypes.string,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.object,
    onChange: PropTypes.func,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    ableToToggleDisabled: PropTypes.bool,
    onToggleDisabled: PropTypes.func
}

TreeSelectInputWithController.defaultProps = {
    defaultValue: null,
    onChange: () => {},
    disabled: false,
    ableToToggleDisabled: false,
    onToggleDisabled: () => {}
}

export default TreeSelectInputWithController
