import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import Checkbox from './Checkbox'

const CheckboxWithController = React.forwardRef((props, ref) => {
    const [disabled, setDisabled] = useState(props.disabled)
    const { unregister } = useFormContext()

    useEffect(() => {
        setDisabled(props.disabled)
    }, [props.disabled])

    return (
        <>
            {!disabled &&
            <Controller
                name={props.name}
                defaultValue={props.defaultValue}
                render={({ field: { value, onChange, ref } }) => (
                    <Checkbox.Group
                        ref={ref}
                        label={props.label}
                        defaultValue={props.defaultValue}
                        disabled={disabled}
                        ableToToggleDisabled={props.ableToToggleDisabled}
                        onToggleDisabled={(disabled) => {
                            if (disabled) {
                                setDisabled(true)
                                unregister(props.name)
                            }
                        }}
                        onChange={(e) => {
                            onChange(e)
                            props.onChange(e)
                        }}
                        value={value}
                        error={props.error}
                    >
                        {props.children}
                    </Checkbox.Group>
                )}
            />}

            {disabled &&
            <Checkbox.Group
                label={props.label}
                disabled={disabled}
                ableToToggleDisabled={props.ableToToggleDisabled}
                onToggleDisabled={(disabled) => {
                    setDisabled(false)
                }}
                value={props.defaultValue}
            >
                {props.children}
            </Checkbox.Group>}
        </>
    )
})

CheckboxWithController.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    defaultValue: PropTypes.array,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.array,
    onChange: PropTypes.func,
    error: PropTypes.object,
    ableToToggleDisabled: PropTypes.bool,
    onToggleDisabled: PropTypes.func
}

CheckboxWithController.defaultProps = {
    defaultValue: [],
    disabled: false,
    options: [],
    value: [],
    onChange: () => {},
    ableToToggleDisabled: false,
    onToggleDisabled: () => {}
}

export default CheckboxWithController
