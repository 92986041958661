import { useState, useEffect } from 'react'
import { Form, Input, Select, Radio, Row, Col } from 'antd'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import { inject } from 'mobx-react'
import FormError from '../../../../components/form/error'
import SubmitButton from '../../../../components/button/submit'
import SignatureInput from '../../../../components/input/signature'
import nationalities from '../../../../models/nationalities'
import countries, { highRiskCountryCodes, nonHighRiskCountries } from '../../../../models/countries'
import schema from './schema'
import focusErrorInput from '../../../../helpers/focusErrorInput'

const { Option } = Select

const DiligenceForm = props => {
    const [form] = Form.useForm()
    const [submitting, setSubmitting] = useState(false)
    const [apiErrorMessages, setApiErrorMessages] = useState([])
    const { handleSubmit, setError, control, reset, watch, setFocus, setValue, getValues, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: props.defaultValues
    })

    useEffect(() => {
    }, [])

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            setApiErrorMessages([])
            const res = await props.services.diligenceService.complete(props.code, data.signature)

            if (res.ok) {
                if (props.onSuccessSubmit) props.onSuccessSubmit(res.data)
            } else if (res.status === 422) {
                if (res.data?.errors) {
                    const inputFields = Object.keys(watch())
                    const errors = res.data.errors
                    const { nonErrorFieldMessages } = focusErrorInput(inputFields, errors, setFocus, setError)
                    setApiErrorMessages(nonErrorFieldMessages)
                }
            } else {
                setApiErrorMessages(['Something went wrong'])
            }
        } catch (error) {
            console.error(error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Form
            className={cn(props.className, 'w-full')}
            layout='vertical'
            onFinish={handleSubmit(onSubmit)}
            form={form}
        >
            {apiErrorMessages.length !== 0 &&
            <FormError className='mb-3' messages={apiErrorMessages} />}

            <div className='text-xl my-5 font-weight-bold'>
                Personal Particulars
            </div>

            <Form.Item
                className='mb-4 quesTitle'
                label='Full Name (As Per NRIC)'
                validateStatus={(errors?.fullName?.message) ? 'error' : '-'}
                help={errors?.fullName?.message}
            >
                <Controller
                    name='fullName'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => {
                        return (
                            <Input
                                ref={ref}
                                disabled
                                placeholder='Full Name'
                                className='input-text-full'
                                onChange={(e) => {
                                    onChange(e.target.value)
                                }}
                                value={value}
                            />
                        )
                    }}
                />
            </Form.Item>

            <Row gutter={16}>
                <Col xs={24} md={12} lg={12} span={12}>
                    <Form.Item
                        className='mb-4 quesTitle'
                        label='Nationality'
                        validateStatus={(errors?.nationality?.message) ? 'error' : '-'}
                        help={errors?.nationality?.message}
                    >
                        <Controller
                            name='nationality'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <Select
                                    ref={ref}
                                    disabled
                                    placeholder='Search to Select'
                                    optionFilterProp='children'
                                    showSearch
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e)
                                        if (e !== 'MY' || e !== 'MYPR') {
                                            setValue('documentExpiry', null)
                                        }
                                        trigger(['documentNumber'])
                                    }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                >
                                    {nationalities.map((itm) => <Option value={itm.code} key={itm.code}>{itm.name}</Option>)}
                                </Select>
                            )}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12} span={12}>
                    <Form.Item
                        className='mb-4 quesTitle'
                        label='NRIC/Passport'
                        validateStatus={(errors?.documentNumber?.message) ? 'error' : '-'}
                        help={errors?.documentNumber?.message}
                    >
                        <Controller
                            name='documentNumber'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <Input
                                    ref={ref}
                                    disabled
                                    placeholder='NRIC/Passport'
                                    className='input-text-full'
                                    onChange={(e) => {
                                        onChange(e.target.value)
                                    }}
                                    value={value}
                                />
                            )}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col xs={24} md={12} lg={12} span={12}>
                    <Form.Item
                        className='mb-4 quesTitle'
                        label='CDS Number'
                        validateStatus={(errors?.cdsNumber?.message) ? 'error' : '-'}
                        help={errors?.cdsNumber?.message}
                    >
                        <Controller
                            name='cdsNumber'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <Input
                                    ref={ref}
                                    disabled
                                    placeholder='CDS Number'
                                    className='input-text-full'
                                    onChange={(e) => {
                                        onChange(e.target.value)
                                    }}
                                    value={value}
                                />
                            )}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item
                className='mb-4 quesTitle'
                label='Are you a tax resident of the United States of America (U.S)?'
                validateStatus={(errors?.isUSTaxResident?.message) ? 'error' : '-'}
                help={errors?.isUSTaxResident?.message}
            >
                <Controller
                    name='isUSTaxResident'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Radio.Group
                            onChange={(e) => {
                                onChange(e.target.value)
                            }}
                            value={value}
                        >
                            <Radio value={0}>No</Radio>
                            <Radio value={1}>Yes</Radio>
                        </Radio.Group>
                    )}
                />
            </Form.Item>

            <Form.Item
                className='mb-4 quesTitle'
                label='Kindly, select the country of residence'
                validateStatus={(errors?.countryOfResidence?.message) ? 'error' : '-'}
                help={errors?.countryOfResidence?.message}
            >
                <Controller
                    name='countryOfResidence'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => {
                        const isHighRiskCountry = highRiskCountryCodes.includes(value)
                        let highRiskCountry = null

                        if (isHighRiskCountry) {
                            highRiskCountry = _.find(countries, { code: value })
                        }

                        return (
                            <Select
                                ref={ref}
                                placeholder='Search to Select'
                                optionFilterProp='children'
                                showSearch
                                onChange={(e) => {
                                    onChange(e)
                                    if (e !== 'MY') {
                                        setValue('feaRule', null)
                                    }
                                }}
                                value={(!isHighRiskCountry) ? value : highRiskCountry?.name}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                            >
                                {nonHighRiskCountries.map((itm) => {
                                    return (
                                        <Option value={itm.code} key={itm.code}>{itm.name}</Option>
                                    )
                                })}
                            </Select>
                        )
                    }}
                />
            </Form.Item>

            {watch('countryOfResidence') === 'MY' &&
            <Form.Item
                className='quesTitle'
                label='Pursuant to the FEA Rules, kindly declare the below'
                validateStatus={(errors?.feaRule?.message) ? 'error' : '-'}
                help={errors?.feaRule?.message}
            >
                <Controller
                    name='feaRule'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Radio.Group
                            onChange={(e) => {
                                onChange(e.target.value)
                            }}
                            value={value}
                        >
                            <Row>
                                <Col xs={24} span={24}>
                                    <Radio value='NO_DOMESTIC_BORROWING'>
                                        I do not have domestic ringgit borrowings and thus allowed to convert any and I undertake to inform MSSB immediately should I subsequently obtain domestic ringgit borrowings or upon request by MSSB
                                    </Radio>
                                </Col>
                            </Row>

                            <Row style={{ marginTop: 10 }}>
                                <Col xs={24} span={24}>
                                    <Radio value='DOMESTIC_BORROWING'>
                                        I have domestic ringgit borrowings and thus are allowed to convert up to RM1,000,000 in aggregate per of my investment abroad which I have/I will have with MSSB and others, are to be taken into account for the purpose of FEA rules. I hereby undertake to inform MSSB immediately once my investment amount has reached the maximum allowable limit.
                                    </Radio>
                                </Col>
                            </Row>
                        </Radio.Group>
                    )}
                />
            </Form.Item>}

            <Form.Item
                className='mb-4 quesTitle'
                label='Signature'
                validateStatus={(errors?.signature?.message) ? 'error' : '-'}
                help={errors?.signature?.message}
            >
                <Controller
                    name='signature'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <SignatureInput
                            onChange={(base64) => {
                                onChange(base64)
                            }}
                        />
                    )}
                />
            </Form.Item>

            <SubmitButton
                className='w-full mt-3'
                label='Submit'
                labelSubmitting='Submitting'
                submitting={submitting}
            />
        </Form>
    )
}

DiligenceForm.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    defaultValues: PropTypes.object,
    code: PropTypes.string.isRequired,
    onSuccessSubmit: PropTypes.func
}

DiligenceForm.defaultProps = {
    onSuccessSubmit: () => {}
}

export default inject('services')(DiligenceForm)
