import { string, object } from 'yup'
import isBase64 from 'is-base64'

const schema = object().shape({
    // fullName: string()
    //     .max(100)
    //     .nullable()
    //     .required('Full name is a required field'),
    // nationality: string()
    //     .nullable()
    //     .required('Nationality is a required field'),
    // documentNumber: string()
    //     .when(['nationality'], (nationality) => {
    //         if (nationality === 'MY' || nationality === 'MYPR') {
    //             return string()
    //                 .nullable()
    //                 .required('NRIC/Passport is a required field')
    //                 .matches(/^\d{12}$/, 'NRIC must be 12 digit number')
    //         } else {
    //             return string()
    //                 .nullable()
    //                 .required('NRIC/Passport is a required field')
    //         }
    //     }),
    // cdsNumber: string()
    //     .nullable()
    //     .required('CDS number is a required field'),
    // contact: object().shape({
    //     prefix: string()
    //         .nullable()
    //         .max(6, 'Contact prefix must be at most 6 characters')
    //         .required('Contact prefix is a required field')
    //         .matches(/^\d+$/, 'Invalid phone code'),
    //     number: string()
    //         .nullable()
    //         .min(7, 'Contact number must be at least 7 characters')
    //         .max(10, 'Contact number must be at most 10 characters')
    //         .required('Contact number is a required field')
    //         .matches(/^\d+$/, 'Invalid contact number')
    // }),
    isUSTaxResident: string()
        .nullable()
        .required('This field is a required field')
        .oneOf(['1', '0'], 'This field is a required field'),
    countryOfResidence: string()
        .nullable()
        .required('Country of residence a required field'),
    feaRule: string()
        .when('countryOfResidence', (countryOfResidence) => {
            if (countryOfResidence === 'MY') {
                return string()
                    .nullable()
                    .required('This is a required field')
            }
        }).nullable(),
    signature: string()
        .nullable()
        .test('is-base64', (value, { createError, path }) => {
            if (isBase64(value, { allowMime: true })) {
                return true
            } else {
                return createError({
                    message: 'Invalid signature',
                    path
                })
            }
        })
        .required('Signature is a required field')
})

export default schema
