import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Row, Col, message } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import { useFormContext } from 'react-hook-form'
import cn from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import { TextInputWithController } from '../../../../../../../../components/input/text'
import SelectInput, { SelectInputWithController } from '../../../../../../../../components/input/select'
import PhoneInputWithController from '../../../../../../../../components/input/phone/PhoneInputWithController'
import { DatePickerWithController } from '../../../../../../../../components/input/datepicker'
import TreeSelectInputWithController from '../../../../../../../../components/input/treeselect/TreeSelectInputWithController'
import { SwitchInputWithController } from '../../../../../../../../components/input/switch'
import { raceList } from '../../../../../../../../models/step1'
import countries, { highRiskCountryCodes, nonHighRiskCountries } from '../../../../../../../../models/countries'
import malaysiaStates from '../../../../../../../../constants/malaysiaStates'
import personalParticularFields from '../../../../../../../../constants/personalParticularFields'
import CustomerVerificationModal from '../../../../../../verification/components/modal'
import focusErrorInput from '../../../../../../../../helpers/focusErrorInput'
import SendEmailUpdateRequestButton from '../../../../../../email/update/request/send/components/button'

const { Option } = SelectInput

const PersonalInfoFieldset = props => {
    const { setValue, setFocus, setError, register, unregister, trigger, watch, formState: { errors } } = useFormContext()
    const [isAllAddressEnabled, setIsAllAddressEnabled] = useState(false)
    const [isAllCorrespondenceAddressEnabled, setIsAllCorrespondenceAddressEnabled] = useState(false)
    const [isVerificationModalVisible, setIsVerificationModalVisible] = useState(false)
    const [isEmailVerified, setIsEmailVerified] = useState(false)
    const [sendingOtp, setSendingOtp] = useState(false)

    useEffect(() => {
    }, [])

    useEffect(() => {
        if (!watch('email')) {
            unregister('otp')
        }
    }, [watch('email')])

    useEffect(() => {
        props.onIsEmailVerifiedChange(isEmailVerified)
    }, [isEmailVerified])

    const handleSuccessSendEmailUpdateRequest = async (email) => {
        setIsVerificationModalVisible(true)
    }

    const handleErrorSendEmailUpdateRequest = async (errors) => {
        const inputFields = ['email']
        const { nonErrorFieldMessages } = focusErrorInput(inputFields, errors, setFocus, setError)
        if (nonErrorFieldMessages.length > 0) {
            message.error(
                <ul className='list-disc mx-2'>
                    {nonErrorFieldMessages.map((message, index) => {
                        return (
                            <li key={index}>{message}</li>
                        )
                    })}
                </ul>
            )
        }
    }

    const handleSuccessVerifyOtp = (data) => {
        setIsEmailVerified(true)
        setIsVerificationModalVisible(false)
        setValue('otp', data.otp)
        setError('email', {
            type: 'manual',
            message: null
        })
    }

    return (
        <fieldset className={cn(props.className)} style={props.style}>
            <TextInputWithController
                name='email'
                label={
                    <div className='flex items-center'>
                        {personalParticularFields.email?.label}

                        {((isEmailVerified && watch('email') !== undefined) || props.defaultValues.email === watch('email')) &&
                        <>
                            <span style={{ marginLeft: 5, color: '#5C94E8' }}>- Verified</span>
                            <CheckCircleFilled style={{ marginLeft: 3, color: '#5C94E8', fontSize: 16 }} />
                        </>}

                        {!isEmailVerified && watch('email') !== undefined && props.defaultValues.email !== watch('email') &&
                        <>
                            <span style={{ marginLeft: 5, color: 'rgba(239, 68, 68)' }}>- Not Verified</span>
                            <CheckCircleFilled style={{ marginLeft: 3, color: 'rgba(239, 68, 68)', fontSize: 16 }} />
                        </>}
                    </div>
                }
                disabled
                ableToToggleDisabled
                defaultValue={props.defaultValues.email}
                error={props.defaultValues.email !== watch('email') ? errors?.email : null}
                onChange={(e) => {
                    if (isEmailVerified) setIsEmailVerified(false)
                }}
                addOn={
                    <SendEmailUpdateRequestButton
                        label='Send OTP'
                        className='pl-2'
                        disabled={!watch('email') || (errors?.email && errors?.email.message !== 'Email is not verified') || isEmailVerified || props.defaultValues.email === watch('email')}
                        accessToken={props.accessToken}
                        email={watch('email')}
                        onSuccessSend={handleSuccessSendEmailUpdateRequest}
                        onErrorSend={handleErrorSendEmailUpdateRequest}
                    />
                }
            />

            {watch('email') &&
            <input {...register('otp')} type='hidden' />}

            <TextInputWithController
                name='fullName'
                label={personalParticularFields.fullName?.label}
                disabled
                ableToToggleDisabled
                defaultValue={props.defaultValues.fullName}
                error={errors?.fullName}
            />

            <Row gutter={16}>
                <Col xs={24} md={12} lg={12} span={12}>
                    <TextInputWithController
                        name='documentNumber'
                        label={personalParticularFields.documentNumber?.label}
                        disabled
                        ableToToggleDisabled
                        defaultValue={props.defaultValues?.documentNumber}
                        error={errors?.documentNumber}
                    />
                </Col>

                {/* <Col xs={24} md={12} lg={12} span={12}>
                    <SelectInputWithController
                        name='gender'
                        label={personalParticularFields.gender?.label}
                        disabled
                        ableToToggleDisabled
                        defaultValue={props.defaultValues?.gender}
                        error={errors?.gender}
                    >
                        <Option value='MALE' key='gender-1'>Male</Option>
                        <Option value='FEMALE' key='gender-2'>Female</Option>
                    </SelectInputWithController>
                </Col> */}
            </Row>

            <Row gutter={16}>
                {/* <Col xs={24} md={12} lg={12} span={12}>
                    <DatePickerWithController
                        name='birthDate'
                        label={personalParticularFields.birthDate?.label}
                        disabled
                        ableToToggleDisabled
                        format='DD-MM-YYYY'
                        defaultValue={moment(props.defaultValues?.birthDate)}
                        error={errors?.birthDate}
                    />
                </Col> */}

                {/* <Col xs={24} md={12} lg={12} span={12}>
                    <SelectInputWithController
                        name='maritalStatus'
                        label={personalParticularFields.maritalStatus?.label}
                        disabled
                        ableToToggleDisabled
                        defaultValue={props.defaultValues?.maritalStatus}
                        error={errors?.gender}
                    >
                        <Option value='SINGLE' key='marital-1'>Single</Option>
                        <Option value='MARRIED' key='marital-2'>Married</Option>
                        <Option value='DIVORCED' key='marital-3'>Divorced</Option>
                        <Option value='WIDOWED' key='marital-4'>Widowed</Option>
                    </SelectInputWithController>
                </Col> */}
            </Row>

            {/* {(props.defaultValues?.nationality === 'MY' || props.defaultValues?.nationality === 'MYPR') &&
            <Row gutter={16}>
                <Col xs={24} md={12} lg={12} span={12}>
                    <TreeSelectInputWithController
                        name='race'
                        label={personalParticularFields.race?.label}
                        disabled
                        ableToToggleDisabled
                        treeData={raceList}
                        placeholder='Select Race'
                        treeDefaultExpandAll
                        defaultValue={props.defaultValues?.race}
                        onChange={(e) => {
                            if (e !== 'OTHERS') {
                                setValue('raceOther', null)
                            }
                        }}
                        error={errors?.race}
                    />
                </Col>

                <Col xs={24} md={12} lg={12} span={12}>
                    <TextInputWithController
                        name='raceOther'
                        label={personalParticularFields.raceOther?.label}
                        disabled={watch('race') !== 'OTHERS'}
                        ableToToggleDisabled
                        disabledBtnEdit={(watch('race') === undefined) ? props.defaultValues?.race !== 'OTHERS' : watch('race') !== 'OTHERS'}
                        disabledBtnCancel={(watch('race') === undefined) ? false : watch('race') === 'OTHERS'}
                        defaultValue={(props.defaultValues?.raceOther) ? props.defaultValues?.raceOther : null}
                        error={errors?.raceOther}
                    />
                </Col>
            </Row>}

            <Row gutter={16}>
                <Col xs={24} md={12} lg={12} span={12}>
                    <PhoneInputWithController
                        name='contact'
                        label={personalParticularFields.contact?.label}
                        disabled
                        ableToToggleDisabled
                        defaultValue={{
                            prefix: props.defaultValues?.contactPrefix,
                            number: props.defaultValues?.contactNumber
                        }}
                        error={errors.contact?.prefix || errors.contact?.number}
                    />
                </Col>
            </Row> */}

            <TextInputWithController
                name='address1'
                label={personalParticularFields.address1?.label}
                disabled={!isAllAddressEnabled}
                ableToToggleDisabled
                onToggleDisabled={(disabled) => {
                    setIsAllAddressEnabled(!disabled)
                }}
                defaultValue={props.defaultValues?.address1}
                error={errors?.address1}
            />

            <TextInputWithController
                name='address2'
                label={personalParticularFields.address2?.label}
                disabled={!isAllAddressEnabled}
                ableToToggleDisabled
                onToggleDisabled={(disabled) => {
                    setIsAllAddressEnabled(!disabled)
                }}
                defaultValue={props.defaultValues?.address2}
                error={errors?.address2}
            />

            <TextInputWithController
                name='address3'
                label={personalParticularFields.address3?.label}
                disabled={!isAllAddressEnabled}
                ableToToggleDisabled
                onToggleDisabled={(disabled) => {
                    setIsAllAddressEnabled(!disabled)
                }}
                defaultValue={props.defaultValues?.address3}
                error={errors?.address3}
            />

            <Row gutter={16}>
                <Col xs={24} md={12} lg={12} span={12}>
                    <SelectInputWithController
                        name='country'
                        label={personalParticularFields.country?.label}
                        disabled={!isAllAddressEnabled}
                        ableToToggleDisabled
                        onToggleDisabled={(disabled) => {
                            setIsAllAddressEnabled(!disabled)
                        }}
                        onChange={() => {
                            trigger(['state', 'city', 'postcode'])
                        }}
                        defaultValue={props.defaultValues?.country}
                        onSetDefaultValue={(value) => {
                            const isHighRiskCountry = highRiskCountryCodes.includes(value)
                            let highRiskCountry = null

                            if (isHighRiskCountry) {
                                highRiskCountry = _.find(countries, { code: value })
                                return highRiskCountry.name
                            } else {
                                return value
                            }
                        }}
                        error={errors?.country}
                    >
                        {nonHighRiskCountries.map((itm) => <Option value={itm.code} key={itm.code}>{itm.name}</Option>)}
                    </SelectInputWithController>
                </Col>

                {((!watch('country')) ? props.defaultValues?.state : watch('country') === 'MY') &&
                <Col xs={24} md={12} lg={12} span={12}>
                    <SelectInputWithController
                        name='state'
                        label={personalParticularFields.state?.label}
                        disabled={watch('country') !== 'MY' || !isAllAddressEnabled}
                        ableToToggleDisabled
                        onToggleDisabled={(disabled) => {
                            setIsAllAddressEnabled(!disabled)
                        }}
                        defaultValue={props.defaultValues?.state}
                        error={errors?.state}
                    >
                        {malaysiaStates.map((itm) => <Option value={itm} key={itm}>{itm}</Option>)}
                    </SelectInputWithController>
                </Col>}
            </Row>

            <Row gutter={16}>
                {((!watch('country')) ? props.defaultValues?.city : watch('country') === 'MY') &&
                <Col xs={24} md={12} lg={12} span={12}>
                    <TextInputWithController
                        name='city'
                        label={personalParticularFields.city?.label}
                        disabled={watch('country') !== 'MY' || !isAllAddressEnabled}
                        ableToToggleDisabled
                        onToggleDisabled={(disabled) => {
                            setIsAllAddressEnabled(!disabled)
                        }}
                        defaultValue={props.defaultValues?.city}
                        error={errors?.city}
                    />
                </Col>}

                {((!watch('country')) ? props.defaultValues?.postcode : watch('country') === 'MY') &&
                <Col xs={24} md={12} lg={12} span={12}>
                    <TextInputWithController
                        name='postcode'
                        label={personalParticularFields.postcode?.label}
                        disabled={watch('country') !== 'MY' || !isAllAddressEnabled}
                        ableToToggleDisabled
                        onToggleDisabled={(disabled) => {
                            setIsAllAddressEnabled(!disabled)
                        }}
                        defaultValue={props.defaultValues?.postcode}
                        error={errors?.postcode}
                    />
                </Col>}
            </Row>

            <Row className='mb-4'>
                <Col xs={24} span={24}>
                    <SwitchInputWithController
                        name='sameAddress'
                        label={personalParticularFields.sameAddress?.label}
                        disabled
                        ableToToggleDisabled
                        onChange={(checked) => {
                            setIsAllCorrespondenceAddressEnabled(!checked)
                        }}
                        checked={props.defaultValues?.sameAddress}
                        error={errors?.sameAddress}
                    />
                </Col>
            </Row>

            {((watch('sameAddress') === undefined) ? !props.defaultValues?.sameAddress : watch('sameAddress') === false) &&
            <>
                <TextInputWithController
                    name='mailingAddress1'
                    label={personalParticularFields.mailingAddress1?.label}
                    disabled={!isAllCorrespondenceAddressEnabled}
                    ableToToggleDisabled
                    onToggleDisabled={(disabled) => {
                        setIsAllCorrespondenceAddressEnabled(!disabled)
                    }}
                    defaultValue={props.defaultValues?.mailingAddress1}
                    error={errors?.mailingAddress1}
                />

                <TextInputWithController
                    name='mailingAddress2'
                    label={personalParticularFields.mailingAddress2?.label}
                    disabled={!isAllCorrespondenceAddressEnabled}
                    ableToToggleDisabled
                    onToggleDisabled={(disabled) => {
                        setIsAllCorrespondenceAddressEnabled(!disabled)
                    }}
                    defaultValue={props.defaultValues?.mailingAddress2}
                    error={errors?.mailingAddress2}
                />

                <TextInputWithController
                    name='mailingAddress3'
                    label={personalParticularFields.mailingAddress3?.label}
                    disabled={!isAllCorrespondenceAddressEnabled}
                    ableToToggleDisabled
                    onToggleDisabled={(disabled) => {
                        setIsAllCorrespondenceAddressEnabled(!disabled)
                    }}
                    defaultValue={props.defaultValues?.mailingAddress3}
                    error={errors?.mailingAddress3}
                />

                <Row gutter={16}>
                    <Col xs={24} md={12} lg={12} span={12}>
                        <SelectInputWithController
                            name='mailingCountry'
                            label={personalParticularFields.mailingCountry?.label}
                            disabled={!isAllCorrespondenceAddressEnabled}
                            ableToToggleDisabled
                            onToggleDisabled={(disabled) => {
                                setIsAllCorrespondenceAddressEnabled(!disabled)
                            }}
                            onChange={() => {
                                trigger(['state', 'city', 'postcode'])
                            }}
                            defaultValue={props.defaultValues?.mailingCountry}
                            onSetDefaultValue={(value) => {
                                const isHighRiskCountry = highRiskCountryCodes.includes(value)
                                let highRiskCountry = null

                                if (isHighRiskCountry) {
                                    highRiskCountry = _.find(countries, { code: value })
                                    return highRiskCountry.name
                                } else {
                                    return value
                                }
                            }}
                            error={errors?.mailingCountry}
                        >
                            {nonHighRiskCountries.map((itm) => <Option value={itm.code} key={itm.code}>{itm.name}</Option>)}
                        </SelectInputWithController>
                    </Col>

                    {((!watch('mailingCountry')) ? props.defaultValues?.mailingState : watch('mailingCountry') === 'MY') &&
                    <Col xs={24} md={12} lg={12} span={12}>
                        <SelectInputWithController
                            name='mailingState'
                            label={personalParticularFields.mailingState?.label}
                            disabled={!isAllCorrespondenceAddressEnabled}
                            ableToToggleDisabled
                            onToggleDisabled={(disabled) => {
                                setIsAllCorrespondenceAddressEnabled(!disabled)
                            }}
                            defaultValue={props.defaultValues?.mailingState}
                            error={errors?.mailingState}
                        >
                            {malaysiaStates.map((itm) => <Option value={itm} key={itm}>{itm}</Option>)}
                        </SelectInputWithController>
                    </Col>}
                </Row>

                <Row gutter={16}>
                    {((!watch('mailingCountry')) ? props.defaultValues?.mailingCity : watch('mailingCountry') === 'MY') &&
                    <Col xs={24} md={12} lg={12} span={12}>
                        <TextInputWithController
                            name='mailingCity'
                            label={personalParticularFields.mailingCity?.label}
                            disabled={!isAllCorrespondenceAddressEnabled}
                            ableToToggleDisabled
                            onToggleDisabled={(disabled) => {
                                setIsAllCorrespondenceAddressEnabled(!disabled)
                            }}
                            defaultValue={props.defaultValues?.mailingCity}
                            error={errors?.mailingCity}
                        />
                    </Col>}

                    {((!watch('mailingCountry')) ? props.defaultValues?.mailingPostcode : watch('mailingCountry') === 'MY') &&
                    <Col xs={24} md={12} lg={12} span={12}>
                        <TextInputWithController
                            name='mailingPostcode'
                            label={personalParticularFields.mailingPostcode?.label}
                            disabled={!isAllCorrespondenceAddressEnabled}
                            ableToToggleDisabled
                            onToggleDisabled={(disabled) => {
                                setIsAllCorrespondenceAddressEnabled(!disabled)
                            }}
                            defaultValue={props.defaultValues?.mailingPostcode}
                            error={errors?.mailingPostcode}
                        />
                    </Col>}
                </Row>
            </>}

            <CustomerVerificationModal
                visible={isVerificationModalVisible}
                accessToken={props.accessToken}
                formDefaultValues={{
                    email: watch('email')
                }}
                onSuccessSubmit={handleSuccessVerifyOtp}
                onCancel={() => setIsVerificationModalVisible(false)}
                onSuccessResendEmailUpdateRequest={handleSuccessSendEmailUpdateRequest}
                onErrorResendEmailUpdateRequest={handleErrorSendEmailUpdateRequest}
            />
        </fieldset>
    )
}

PersonalInfoFieldset.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    accessToken: PropTypes.string,
    defaultValues: PropTypes.object
}

PersonalInfoFieldset.defaultProps = {
    defaultValues: null
}

export default PersonalInfoFieldset
