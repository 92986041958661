const getMockResult = async (handler, request = {}) => {
    if (typeof handler === 'function') {
        let resolver = null
        const p = new Promise((resolve) => {
            resolver = resolve
        })
        const req = request
        const res = {
            status: (statusCode) => {
                return res
            },
            json: (result) => {
                resolver(result)
            },
            send: (result) => {
                resolver(result)
            }
        }
        handler(req, res)
        return await p
    } else {
        return handler
    }
}

export default getMockResult
