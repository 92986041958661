import React from 'react'
import ReactDOM from 'react-dom'
import 'flagpack/dist/flagpack.css'
import './styles/global.scss'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import generateAppContainer from './container'

generateAppContainer.then((appContainer) => {
    ReactDOM.render(
        <BrowserRouter>
            <App container={appContainer} />
        </BrowserRouter>,
        document.getElementById('root')
    )

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals()
})
