import { string, object } from 'yup'
import moment from 'moment'

const schema = object().shape({
    hasBaAccount: string()
        .nullable()
        .required('This field is a required field')
        .oneOf(['1', '0'], 'This field is a required field'),
    hasBaMsecAccount: string()
        .when('hasBaAccount', {
            is: '1',
            then: string()
                .nullable()
                .required('This field is a required field')
                .oneOf(['1', '0'], 'This field is a required field'),
            otherwise: string()
                .nullable()
        }),
    accountType: string()
        .max(100)
        .nullable()
        .required('Account type is a required field')
        .oneOf(['CASH_UPFRONT', 'ISLAMIC_CASH_UPFRONT', 'COLLATERISED'], 'This field is a required field'),
    username: string()
        .min(6)
        .max(10)
        .nullable()
        .required('Username is a required field')
        .matches(/([a-z]+[0-9]|[0-9]+[a-z])/i, 'Username requires at least one number and one letter'),
    hasPreferDR: string()
        .nullable()
        .required('This field is a required field')
        .oneOf(['1', '0'], 'This field is a required field'),
    drCode: string()
        .when('hasPreferDR', {
            is: '1',
            then: string()
                .max(50)
                .nullable()
                .required('DR code is a required field')
                .matches(/^[DR]\d{3}$/, 'Invalid DR Code. Example of valid DR Code: D123'),
            otherwise: string()
                .nullable(true).transform(value => null)
        }),
    mrCode: string()
        .nullable()
        .matches(/^(|MR\d{3}-\d{3}|MD\d{3}-\d{3})$/, 'Invalid MR Code. Example of valid MR Code: MD123-001'),
    referralCode: string()
        .nullable()
        .max(25),
    fullName: string()
        .max(100)
        .nullable()
        .required('Full name is a required field'),
    nationality: string()
        .nullable()
        .required('Nationality is a required field'),
    gender: string()
        .nullable()
        .required('Gender is a required field')
        .oneOf(['MALE', 'FEMALE'], 'Gender is a required field'),
    documentNumber: string()
        .when(['nationality'], (nationality) => {
            if (nationality === 'MY' || nationality === 'MYPR') {
                return string()
                    .nullable()
                    .required('NRIC/Passport is a required field')
                    .matches(/^\d{12}$/, 'NRIC must be 12 digit number')
            } else {
                return string()
                    .nullable()
                    .required('NRIC/Passport is a required field')
            }
        }),
    ekycDocumentType: string()
        .when(['nationality'], (nationality) => {
            if (nationality === 'MY' || nationality === 'MYPR') {
                return string()
                    .nullable()
                    .required('Document type is a required field')
                    .test('ekycDocumentType', (value, { createError, path }) => {
                        if (value === '6') {
                            return createError({
                                message: 'We do not accept MyKas for time being.',
                                path
                            })
                        }
                        return true
                    })
                    .oneOf(['1', '2', '5', '6'], 'Document type must be one of the following values: MyKad, MyTentera & MyPR/PR of Malaysia')
            } else {
                return string()
                    .nullable()
                    .required('Document type is a required field')
                    .oneOf(['3'], 'Document type must be one of the following values: Passport')
            }
        }),
    otherDocumentNumber: string()
        .when(['nationality', 'birthDate'], (nationality, birthDate) => {
            // const isOld = moment(birthDate).isBefore('1976-01-01')
            if (nationality === 'MY') {
                return string()
                    .nullable()
                    // .required('Old NRIC date is a required field')
            }
        }).nullable(),
    documentExpiry: string()
        .when('nationality', (nationality) => {
            if (nationality !== 'MY' && nationality !== 'MYPR') {
                return string()
                    .nullable()
                    .required('Passport expiry date is a required field')
                    .test(
                        'is-valid',
                        'Invalid datetime format',
                        (value, context) => {
                            if (value === null || value === '') {
                                return false
                            }
                            return moment(value).isValid()
                        }
                    )
            }
        }).nullable(),
    uploadedDocumentFrontPath: string()
        .nullable()
        .when('$applicationStatus', (applicationStatus) => {
            if (applicationStatus === 'AMEND_WITH_DOCUMENT') {
                return string()
                    .nullable()
                    .required('Front Photo of NRIC/Passport is a required field')
            }
        }),
    uploadedDocumentBackPath: string()
        .nullable()
        .when('$applicationStatus', (applicationStatus) => {
            if (applicationStatus === 'AMEND_WITH_DOCUMENT') {
                return string()
                    .nullable()
                    .required('Back Photo of NRIC/Passport is a required field')
            }
        }),
    birthDate: string()
        .nullable()
        .required('Birth date is a required field')
        .test(
            'is-valid',
            'Invalid datetime format',
            (value, context) => {
                if (value === null || value === '') {
                    return false
                }
                return moment(value).isValid()
            }
        ),
    maritalStatus: string()
        .nullable()
        .required('Martial status is a required field')
        .oneOf(['SINGLE', 'MARRIED', 'DIVORCED', 'WIDOWED'], 'Martial status is a required field'),
    race: string()
        .when('nationality', (nationality, schema) => {
            if (nationality === 'MY' || nationality === 'MYPR') {
                return string()
                    .nullable()
                    .required('Race is a required field')
            }
        }).nullable(),
    raceOther: string()
        .when('race', (race, schema) => {
            if (race === 'OTHERS') {
                return string()
                    .nullable()
                    .required('Other race is a required field')
            }
        }).nullable(),
    contactNumber: string()
        .nullable()
        .min(7, 'Mobile number must be at least 7 characters')
        .max(11, 'Mobile number must be at most 10 characters')
        .required('Mobile number is a required field')
        .matches(/^\d+$/, 'Invalid mobile number'),
    address1: string()
        .nullable()
        .required('Address is a required field'),
    address2: string()
        .nullable(),
    country: string()
        .nullable()
        .required('Country is a required field'),
    state: string()
        .nullable()
        .when('country', (country, schema) => {
            if (country === 'MY') {
                return string()
                    .nullable()
                    .required('State is a required field')
            }
        }),
    city: string()
        .nullable()
        .when('country', (country, schema) => {
            if (country === 'MY') {
                return string()
                    .nullable()
                    .required('City is a required field')
            }
        }),
    postcode: string()
        .nullable()
        .when('country', (country, schema) => {
            if (country === 'MY') {
                return string()
                    .nullable()
                    .required('Postcode is a required field')
                    .matches(/^\d+$/, 'Postcode only accepts numeric')
            }
        }),
    mailingAddress1: string()
        .nullable()
        .when('$sameAddress', (sameAddress, schema) => {
            if (!sameAddress) {
                return string()
                    .nullable()
                    .required('Mailing address is a required field')
            }
        }),
    mailingAddress2: string()
        .nullable()
        .when('$sameAddress', (sameAddress, schema) => {
            if (!sameAddress) {
                return string()
                    .nullable()
            }
        }),
    mailingCountry: string()
        .nullable()
        .when('$sameAddress', (sameAddress, schema) => {
            if (!sameAddress) {
                return string()
                    .nullable()
                    .required('Mailing country is a required field')
            }
        }),
    mailingState: string()
        .nullable()
        .when(['$sameAddress', 'mailingCountry'], (sameAddress, mailingCountry) => {
            if (mailingCountry === 'MY' && !sameAddress) {
                return string()
                    .nullable()
                    .required('State is a required field')
            }
        }),
    mailingCity: string()
        .nullable()
        .when(['$sameAddress', 'mailingCountry'], (sameAddress, mailingCountry) => {
            if (mailingCountry === 'MY' && !sameAddress) {
                return string()
                    .nullable()
                    .required('Mailing city is a required field')
            }
        }),
    mailingPostcode: string()
        .nullable()
        .when(['$sameAddress', 'mailingCountry'], (sameAddress, mailingCountry) => {
            if (mailingCountry === 'MY' && !sameAddress) {
                return string()
                    .nullable()
                    .required('Mailing postcode is a required field')
                    .matches(/^\d+$/, 'Mailing postcode only accepts numeric')
            }
        })
})

export default schema
