import { string, object, number, array } from 'yup'

const schema = object().shape({
    isIslamicBank: string()
        .when('$hideFinancialProfileFieldset', (hideFinancialProfileFieldset) => {
            if (!hideFinancialProfileFieldset) {
                return string()
                    .required('This field is a required field')
                    .oneOf(['1', '0'], 'This field is a required field')
                    .nullable()
            }
        }).nullable(),
    bankName: string()
        .when('$hideFinancialProfileFieldset', (hideFinancialProfileFieldset) => {
            if (!hideFinancialProfileFieldset) {
                return string()
                    .required('Bank name is a required field')
                    .nullable()
            }
        }).nullable(),
    bankAccountNumber: string()
        .when('$hideFinancialProfileFieldset', (hideFinancialProfileFieldset) => {
            if (!hideFinancialProfileFieldset) {
                return string()
                    .required('Bank account number is a required field')
                    .matches(/^\d+$/, 'Bank account number only accepts numeric')
                    .nullable()
            }
        }).nullable(),
    bankStatementPath: string()
        .when('$hideFinancialProfileFieldset', (hideFinancialProfileFieldset) => {
            if (!hideFinancialProfileFieldset) {
                return string()
                    .required('Bank statement is a required field')
                    .nullable()
            }
        }).nullable(),
    isPersonalAccount: string()
        .when('$hideFinancialProfileFieldset', (hideFinancialProfileFieldset) => {
            if (!hideFinancialProfileFieldset) {
                return string()
                    .required('This field is a required field')
                    .oneOf(['1'], 'This field is a required field')
                    .nullable()
            }
        }).nullable(),
    investmentTerm: number()
        .nullable()
        .required('Investment term is a required field'),
    riskTolerance: number()
        .nullable()
        .required('Risk tolerance number is a required field'),
    investmentObjective: array()
        .min(1, 'Investment objective is a required field')
        .required('Investment objective is a required field'),
    investmentExperience: string()
        .nullable()
        .required('Investment experience is a required field'),
    brokerAcctList: array()
        .when('investmentExperience', (investmentExperience) => {
            if (investmentExperience !== 'NEW_TO_TRADING') {
                return array()
                    .min(1, 'Broker list must have at least 1 items')
                    .required('Broker list is a required field')
            }
        }).nullable(),
    brokerAcctOther: string()
        .when('brokerAcctList', (brokerAcctList) => {
            if (brokerAcctList && brokerAcctList.includes('OTHERS')) {
                return string()
                    .nullable()
                    .required('This is a required field')
            }
        }).nullable()
})

export default schema
