import { useState, useEffect } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import EKYCStatusCompleted from '../../../ekyc/components/status/completed'

const ApplicationStatusVerified = props => {
    useEffect(() => {
    }, [])

    return (
        <div className={cn('container mx-auto text-center', props.className)}>
            <EKYCStatusCompleted fullName={props.fullName} />
        </div>
    )
}

ApplicationStatusVerified.propTypes = {
    className: PropTypes.string,
    fullName: PropTypes.string.isRequired
}

ApplicationStatusVerified.defaultProps = {
}

export default ApplicationStatusVerified
