import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useHistory, useLocation } from 'react-router-dom'
import Layout from '../modules/application/components/templates/layout'
import FormContainer from '../components/form/container'
import ConfirmSummaryForm from '../modules/application/summary/confirm/components/forms'
import StepBar from '../modules/application/components/step-bar'
import { getStepLink } from '../helpers/StepLink'
import qs from 'qs'

const ConfirmSummaryPage = inject('store')(observer(({ store }) => {
    const history = useHistory()
    const location = useLocation()
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })

    const handleSuccessSubmit = () => {
    }

    if (store?.completedStep < 5) {
        const appendString = (query.exclude === 'payment') ? '?exclude=payment' : null
        const link = getStepLink(store.completedStep, appendString)
        history.push(link)
        return (<div />)
    }

    return (
        <Layout>
            <div className='flex flex-col items-center w-full'>
                <h1 className='text-3xl text-5xl tracking-tight mb-4 my-16 text-center'>
                    Summary
                </h1>

                <StepBar
                    current={5} onChange={(e) => {
                        const step = e + 1
                        if (step > store.completedStep) return
                        const appendString = (query.exclude === 'payment') ? '?exclude=payment' : null
                        const link = getStepLink(step, appendString)
                        history.push(link)
                    }}
                />

                <FormContainer>
                    <ConfirmSummaryForm skipPayment={query?.exclude === 'payment'} onSuccessSubmit={handleSuccessSubmit} />
                </FormContainer>
            </div>
        </Layout>
    )
}))

export default ConfirmSummaryPage
