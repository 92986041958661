export const nonIslamicBankList = [
    { key: 'ABB', name: 'Affin Bank Berhad' },
    { key: 'ALB', name: 'Alliance Bank Malaysia Berhad' },
    { key: 'AMBB', name: 'Ambank (M) Berhad' },
    { key: 'BR', name: 'Bank Rakyat' },
    { key: 'B', name: 'Bank Simpanan Nasional' },
    { key: 'CIMB', name: 'CIMB Bank Berhad' },
    { key: 'CB', name: 'Citibank Berhad', hidden: true },
    { key: 'DEU', name: 'Deutsche Bank (Malaysia) Berhad', hidden: true },
    { key: 'HL', name: 'Hong Leong Bank Berhad' },
    { key: 'HSBC', name: 'HSBC Bank Malaysia Berhad' },
    { key: 'MBB', name: 'Malayan Banking Berhad (MAYBANK)' },
    { key: 'OCBC', name: 'OCBC Bank (Malaysia) Berhad' },
    { key: 'PBB', name: 'Public Bank' },
    { key: 'RHB', name: 'RHB Bank' },
    { key: 'SC', name: 'Standard Chartered Bank' },
    { key: 'UOB', name: 'United Overseas Bank (M) Berhad' },
    { key: 'RBS', name: 'Royal Bank of Scotland', hidden: true }
]

export const nonIslamicBankListDev = [
    ...nonIslamicBankList,
    { key: 'SBIA', name: 'SBI Bank A' },
    { key: 'SBIB', name: 'SBI Bank B' },
    { key: 'SBIC', name: 'SBI Bank C' }
]

export const islamicBankList = [
    { key: 'ABBI', name: 'Affin Islamic Bank Berhad' },
    { key: 'ARJ', name: 'Al Rajhi Bank & Investment. Corp. (M) Bhd' },
    { key: 'ALBI', name: 'Alliance Islamic Bank Berhad' },
    { key: 'AMBI', name: 'Ambank Islamic Bank Berhad' },
    { key: 'BI', name: 'Bank Islam Malaysia Berhad' },
    { key: 'BMMB', name: 'Bank Muamalat Malaysia Berhad' },
    { key: 'CIMI', name: 'CIMB Islamic Bank Berhad' },
    { key: 'HLI', name: 'Hong Leong Islamic Bank Berhad' },
    { key: 'HSBI', name: 'HSBC Amanah Malaysia Berhad' },
    { key: 'KFH', name: 'Kuwait Finance House (Malaysia) Berhad' },
    { key: 'MBBI', name: 'Maybank Islamic Berhad' },
    { key: 'OCBI', name: 'OCBC Al-amin Bank Berhad' },
    { key: 'PBBI', name: 'Public Islamic Bank' },
    { key: 'RHBI', name: 'RHB Islamic Bank' },
    { key: 'SCI', name: 'Standard Chartered Saadiq Berhad' }
]

export const bankList = [
    ...nonIslamicBankList,
    ...islamicBankList
]

export const bankListDev = [
    ...nonIslamicBankListDev,
    ...islamicBankList
]

export const brokerList = [
    { key: 'AFFIN_HWANG', name: 'Affin Hwang' },
    { key: 'AMINVEST', name: 'AmInvest' },
    { key: 'CGS_CIMB', name: 'CGS-CIMB' },
    { key: 'HONG_LEONG', name: 'Hong Leong' },
    { key: 'INTER_PACIFIC', name: 'Inter-Pacific' },
    { key: 'KENANGA', name: 'Kenanga' },
    { key: 'MAYBANK', name: 'Maybank' },
    { key: 'PUBLIC_BANK', name: 'Public Bank' },
    { key: 'RAKUTEN', name: 'Rakuten' },
    { key: 'RHB', name: 'RHB' },
    { key: 'UOB_KAY_HIAN', name: 'UOB Kay Hian' }
]
