export const drTooltipTitle = (
    <div>
        <div className='font-weight-bold mb-2'>Dealer Representative</div>
        <div>The default dealer representative will be assigned if you do not have any preferences.</div>
    </div>
)

export const drCodeTooltipTitle = (
    <div>
        <div className='font-weight-bold mb-2'>Dealer Representative or Remisier Code</div>
        <div className='mb-2'>Dealer’s Representative or Remisier is an authorized personnel that assists you in setting up your CDS and Trading Account registration process as well as your trading journey.</div>
        <div className='mb-4'>Example of DR Code: D123 or R123</div>
        <div>Kindly refer to your preferred DR for the code</div>
    </div>
)

export const mrCodeTooltipTitle = (
    <div>
        <div className='font-weight-bold mb-2'>Marketing Representative Code</div>
        <div className='mb-5'>Marketing Representative is an authorized introducer assisting you in setting up your CDS and Trading Account registration process under an authorized Dealer’s Representative or Remisier.</div>
        <div>Example of MR Code: MD123-001 or MR123-001</div>
    </div>
)

export const referralCodeTooltipTitle = (
    <div>
        <div className='font-weight-bold mb-2'>Referral Code</div>
        <div className='mb-5'> An official reference code provided by the Dealer’s Representative or Remisier or Marketing Representative for campaign or promotional purposes</div>
        <div>Example of Reference Code: AB-EVENTS-CDSAccount (Not more than 25 Characters)</div>
    </div>
)
