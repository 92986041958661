import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Statistic } from 'antd'
import moment from 'moment'
import config from 'config'
import { inject } from 'mobx-react'
import cn from 'classnames'
import Button from '../../../../../../../components/button'

const { Countdown } = Statistic

const ResendApplicationResumeOTPButton = props => {
    const [resending, setResending] = useState(false)
    const [isResendBtnVisible, setIsResendBtnVisible] = useState(true)
    const [resendAt, setResendAt] = useState(null)

    useEffect(() => {
        if (props.store?.resendAt) {
            const resendAt = moment(props.store.resendAt)
            const currentTime = moment()
            const duration = moment.duration(currentTime.diff(resendAt))
            if (duration.asMilliseconds() > config.app.ableToResendDuration * 1000) {
                setIsResendBtnVisible(true)
            } else {
                setIsResendBtnVisible(false)
            }
            setResendAt(moment(props.store.resendAt))
        }
    }, [props.store?.resendAt])

    const resendVerificationEmail = async () => {
        try {
            setResending(true)

            const { email, exclude } = props.defaultValues
            const res = await props.services.applicationService.resendOTP(
                email,
                exclude
            )

            if (res.ok) {
                const resendAt = moment()
                props.store?.setResendAt(resendAt)
                setIsResendBtnVisible(false)
                Modal.info({
                    title: 'Resend OTP Successfully',
                    content: (
                        <p>
                            An email has been resent to your registered email address
                        </p>
                    )
                })
            } else if (res.status === 422) {
                if (res.data?.errors) {
                    const messages = []
                    for (const key in res.data.errors) {
                        messages.push(...res.data.errors[key])
                    }
                    Modal.error({
                        title: 'Error',
                        content: (
                            <ul>
                                {messages.map((message, index) =>
                                    <li key={index}>{message}</li>
                                )}
                            </ul>
                        )
                    })
                } else {
                    Modal.error({
                        title: 'Something went wrong',
                        content: (
                            <p>Please try again later</p>
                        )
                    })
                }
            } else {
                Modal.error({
                    title: 'Something went wrong',
                    content: (
                        <p>Please try again later</p>
                    )
                })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setResending(false)
        }
    }

    return (
        <>
            {isResendBtnVisible &&
            <Button
                className={cn('mb-5', props.className)}
                label='Resend Email'
                loading={resending}
                labelLoading='Sending'
                onClick={resendVerificationEmail}
            />}

            {!isResendBtnVisible &&
            <Countdown
                title='Able to resend after'
                value={resendAt + (config.app.ableToResendDuration * 1000)}
                onFinish={() => setIsResendBtnVisible(true)}
            />}
        </>
    )
}

ResendApplicationResumeOTPButton.propTypes = {
    className: PropTypes.string,
    defaultValues: PropTypes.shape({
        email: PropTypes.string,
        exclude: PropTypes.string
    })
}

ResendApplicationResumeOTPButton.defaultProps = {
    defaultValues: {
        email: '',
        exclude: null
    }
}

export default inject('store', 'services')(ResendApplicationResumeOTPButton)
