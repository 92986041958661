import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Form, Upload, Modal } from 'antd'
import { EditTwoTone, CloseCircleTwoTone, PlusOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons'
import cn from 'classnames'
import PropTypes from 'prop-types'
import inputStyles from '../../Input.module.scss'
import styles from './SingleFileInput.module.scss'

const SingleFileInput = React.forwardRef((props, ref) => {
    const [disabled, setDisabled] = useState(props.disabled)
    const [selectedFile, setSelectedFile] = useState(props.selectedFile)
    const [isPreviewImageModalVisible, setIsPreviewImageModalVisible] = useState(false)

    useEffect(() => {
        setDisabled(props.disabled)
    }, [props.disabled])

    useEffect(() => {
        setSelectedFile(props.selectedFile)
    }, [props.selectedFile])

    const handleBtnEditClick = useCallback(() => {
        if (props.disabledBtnEdit) return
        setDisabled(false)
        props.onToggleDisabled(false)
    })

    const handleBtnCancelEditClick = useCallback(() => {
        if (props.disabledBtnCancel) return
        setDisabled(true)
        props.onToggleDisabled(true)
    })

    const handleFileChange = (e) => {
        const { file } = e
        if (file) {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e) {
                file.thumbnailSrc = reader.result

                setSelectedFile({
                    name: file.name,
                    type: file.type,
                    thumbnailSrc: reader.result
                })
            }
        }
        props.onChange(e)
    }

    const SelectFileButton = (props) => {
        return (
            <div>
                <PlusOutlined style={{ fontSize: 80, color: '#D4D4D4' }} />
                <div style={{ marginTop: 8 }}>Choose a file or drag it here</div>
            </div>
        )
    }

    const Thumbnail = (props) => {
        const isImageFile = props.file?.type?.includes('image')

        return (
            <div className={styles.thumbnailWrapper}>
                {isImageFile &&
                <img src={props.file?.thumbnailSrc} />}

                {!isImageFile &&
                <div className={styles.nonImgWrapper}>
                    <div>Format of this file is unable for preview</div>
                    <div className='mt-2'><span className={styles.filename}>{props.file?.name}</span></div>
                </div>}

                <div className={styles.actionWrapper}>
                    <div
                        className={styles.action}
                        onClick={(e) => {
                            e.stopPropagation()
                            if (!isImageFile) return
                            setIsPreviewImageModalVisible(true)
                        }}
                    >
                        <EyeOutlined />
                    </div>
                    <div
                        className={styles.action}
                    >
                        <UploadOutlined />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Form.Item
            className={cn('mb-4 quesTitle', inputStyles.wrapper, styles.wrapper, props.className, { [styles.error]: props.error })}
            style={props.style}
            label={
                (props.label)
                    /* eslint-disable react/jsx-closing-tag-location */
                    ? (<div className='flex justify-between w-full'>
                        <span>{props.label}</span>

                        <div className='flex items-center'>
                            {props.sampleUrl &&
                            <a className='text-blue-500 mr-2' rel='noreferrer' href={props.sampleUrl} target='_blank'>
                                View Sample
                            </a>}

                            {props.ableToToggleDisabled && disabled &&
                            <a
                                type='button'
                                className={inputStyles.btnEdit}
                                disabled={props.disabledBtnEdit}
                                onClick={handleBtnEditClick}
                            >
                                <EditTwoTone className='mr-1' twoToneColor={(props.disabledBtnEdit) ? 'grey' : ''} />
                                Edit
                            </a>}

                            {props.ableToToggleDisabled && !disabled &&
                            <a
                                type='button'
                                className={inputStyles.btnCancel}
                                disabled={props.disabledBtnCancel}
                                onClick={handleBtnCancelEditClick}
                            >
                                <CloseCircleTwoTone twoToneColor={(props.disabledBtnCancel) ? 'grey' : '#ef4444'} className='mr-1' />
                                Cancel
                            </a>}
                        </div>
                    </div>)
                    : null
                    /* eslint-enable react/jsx-closing-tag-location */
            }
            validateStatus={(props.error?.message) ? 'error' : '-'}
            help={props.error?.message}
        >
            <Upload
                disabled={disabled}
                beforeUpload={() => {
                    return false
                }}
                showUploadList={false}
                listType='picture-card'
                accept={props.accept}
                onChange={handleFileChange}
            >
                {selectedFile &&
                <Thumbnail file={selectedFile} />}

                {!selectedFile &&
                <SelectFileButton />}
            </Upload>

            <Modal
                visible={isPreviewImageModalVisible}
                title={props.previewTitle}
                footer={null}
                onCancel={() => setIsPreviewImageModalVisible(false)}
            >
                <img src={selectedFile?.thumbnailSrc} style={{ width: '100' }} />
            </Modal>
        </Form.Item>
    )
})

SingleFileInput.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.string,
    error: PropTypes.object,
    disabled: PropTypes.bool,
    ableToToggleDisabled: PropTypes.bool,
    onToggleDisabled: PropTypes.func,
    sampleUrl: PropTypes.string,
    previewTitle: PropTypes.string,
    selectedFile: PropTypes.object,
    accept: PropTypes.string,
    onChange: PropTypes.func
}

SingleFileInput.defaultProps = {
    disabled: false,
    ableToToggleDisabled: false,
    onToggleDisabled: () => {},
    selectedFile: null,
    onChange: () => {}
}

export default SingleFileInput
