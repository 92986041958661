import { types, flow } from 'mobx-state-tree'
import { Step1 } from './step1'
import { Step2 } from './step2'
import { Step3 } from './step3'
import { Step4 } from './step4'
import { Ekyc } from './ekyc'
import { Partner } from './partner'

export const RootStore = types
    .model('RootStore', {
        initiated: types.optional(types.boolean, false),
        applicationId: types.optional(types.integer, 0),
        accessToken: types.maybeNull(types.string),
        referenceId: types.maybeNull(types.string),
        fullName: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        paymentStatus: types.maybeNull(
            types.enumeration('paymentStatus', [
                'NEW',
                'PENDING',
                'SUCCESS',
                'FAILED',
                'REJECTED',
                'EXCLUDED'
            ])
        ),
        applicationStatus: types.maybeNull(
            types.enumeration('applicationStatus', [
                'NEW',
                'EMAIL_VERIFIED',
                'PAID',
                'VERIFIED',
                'AMEND',
                'AMEND_WITH_DOCUMENT',
                'APPROVED',
                'REJECTED'
            ])
        ),
        ekycStatus: types.maybeNull(
            types.enumeration('ekycStatus', [
                'NEW',
                'PENDING',
                'DOCUMENT_SUBMITTED',
                'SELFIE_SUBMITTED',
                'COMPLETED',
                'FAILED'
            ])
        ),
        isResumeApplication: types.optional(types.boolean, false),
        submitting: types.optional(types.boolean, false),
        completedStep: types.optional(types.integer, 0),
        resendAt: types.maybeNull(types.Date),
        resendProfileUpdateRequestAt: types.maybeNull(types.Date),
        sendEmailUpdateRequestAt: types.maybeNull(types.Date),
        step1: types.maybeNull(Step1),
        step2: types.maybeNull(Step2),
        step3: types.maybeNull(Step3),
        step4: types.maybeNull(Step4),
        ekyc: types.maybeNull(Ekyc),
        partner: types.maybeNull(Partner),
        createdAt: types.maybeNull(types.Date)
    })
    .views((self) => {
        return {
            get isLogin () {
                return self.login
            }
        }
    })
    .actions((self) => ({
        setData (data) {
            for (const key in data) {
                if (!Object.prototype.hasOwnProperty.call(data, key)) {
                    console.error(`Unavailable key ${key}`)
                } else {
                    self[key] = data[key]
                }
            }
        },
        init () {
            self.initiated = true
        },
        setResendAt (value) {
            if (typeof value === 'string') {
                self.resendAt = new Date(value)
            } else {
                self.resendAt = new Date(value)
            }
        },
        setResendProfileUpdateRequestAt (value) {
            if (typeof value === 'string') {
                self.resendProfileUpdateRequestAt = new Date(value)
            } else {
                self.resendProfileUpdateRequestAt = new Date(value)
            }
        },
        setSendEmailUpdateRequestAt (value) {
            self.sendEmailUpdateRequestAt = new Date(value)
        },
        setStep1Data (data) {
            self.step1 = Step1.create({ ...data })
        },
        setStep2Data (data) {
            self.step2 = Step2.create({ ...data })
        },
        setStep3Data (data) {
            self.step3 = Step3.create({ ...data })
        },
        setStep4Data (data) {
            self.step4 = Step4.create({ ...data })
        },
        setEkyc (data) {
            self.ekyc = Ekyc.create({ ...data })
        },
        setPartner (data) {
            self.partner = Partner.create({ ...data })
        },
        reset () {
            self.applicationId = 0
            self.accessToken = null
            self.referenceId = null
            self.fullName = null
            self.email = null
            self.paymentStatus = null
            self.applicationStatus = null
            self.ekycStatus = null
            self.isResumeApplication = false
            self.submitting = false
            self.completedStep = 0
            self.resendAt = null
            self.resendProfileUpdateRequestAt = null
            self.step1 = null
            self.step2 = null
            self.step3 = null
            self.step4 = null
            self.ekyc = null
            self.createdAt = null
        }
    }))

let _store

export const getStore = () => {
    if (!_store) {
        _store = RootStore.create({
            email: null,
            step1: Step1.create({}),
            step2: Step2.create({}),
            step3: Step3.create({}),
            step4: Step4.create({}),
            ekyc: Ekyc.create({})
        })
    }
    return _store
}

export const createStore = (env) => {
    if (!_store) {
        _store = RootStore.create({
            email: null,
            step1: Step1.create({}),
            step2: Step2.create({}),
            step3: Step3.create({}),
            step4: Step4.create({}),
            ekyc: Ekyc.create({})
        }, env)
    }
    return _store
}

export const getExampleStore = () => {
    if (!_store) {
        _store = RootStore.create({
            email: 'asdasd@gmail.com',
            step1: Step1.create({
                drCode: 'amsd1231',
                mrCode: 'MRa12312',
                referralCode: '93883838',
                raceOther: 'Russian',
                race: 'OTHERS',
                fullName: 'Leng Sheng Hong',
                gender: 'MALE',
                nationality: 'MY',
                country: 'MY',
                mailingCountry: 'MY',
                birthDate: new Date('1987-01-12'),
                contactPrefix: '65',
                contactNumber: '149374819',
                maritalStatus: 'SINGLE',
                address1: 'D12-32-1, Some Condo',
                address2: 'Jalan 123/449',
                address3: 'Taman Tun Someone',
                city: 'Puchong',
                state: 'Selangor',
                postcode: '12334'
                // documentExpiry: new Date('2031-11-12')
            }),
            step2: Step2.create({
                officeNumberPrefix: '65',
                officeNumber: '12392023',
                spouseNationality: 'MY',
                spouseContactPrefix: '60',
                spouseContactNumber: '123192323',
                sourceOfInvestmentOther: null,
                sourceOfInvestment: ['SAVINGS', 'BUSINESS_INCOME'],
                employmentType: 'SELF_EMPLOYED',
                yearsOfEmployment: 12
            }),
            step3: Step3.create({
                // isIslamicBank: false,
                investmentObjective: ['SAVINGS'],
                investmentTerm: 2,
                investmentExperience: 'BETWEEN_1_TO_5_YEARS',
                brokerAcctList: ['OTHERS', 'MAYBANK', 'UOB_KAY_HIAN'],
                brokerAcctOther: 'Mango Bank'
            }),
            step4: Step4.create({
                hasForeignTax: true,
                hasTin: false,
                // tinNumber: '1243523',
                reasonForNoTin: 1,
                hasRelatedMsecTrader: true,
                relatedMsecTrader: [
                    {
                        name: 'Hahha',
                        relationship: 'RELATIVES',
                        clientCode: '12394342'
                    }
                ],
                hasRelatedPep: true,
                relatedPep: [
                    {
                        name: 'Najib Razak',
                        relationship: 'SIBLINGS',
                        organisation: '1MDB',
                        occupation: 'President',
                        country: 'MY'
                    }
                ]
            })
        })
    }
    return _store
}
