import { useState, useEffect } from 'react'
import { Spin, Modal } from 'antd'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './CtosModal.module.scss'
import detectIsMobile from '../../../../../../helpers/detectIsMobile'

const CtosModal = (props) => {
    const [loading, setLoading] = useState(true)
    const isMobile = detectIsMobile()

    useEffect(() => {
        const handleMessageEvent = (e) => {
            if (e.origin !== window.location.origin) return
            if (!e.data) return
            if (e.data?.source !== 'btn-close-click') return
            props.onCancel()
        }

        window.addEventListener('message', handleMessageEvent, false)

        return () => {
            window.removeEventListener('message', handleMessageEvent)
        }
    }, [])

    useEffect(() => {
        if (!props.visible) return
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }, [props.visible])

    return (
        <Modal
            visible={props.visible}
            title=''
            footer={null}
            className={cn(props.className, styles.wrapper)}
            onCancel={props.onCancel}
        >
            <div className={styles.title}>CTOS Verification</div>

            <div className={cn(styles.iframeWrapper, { [styles.loading]: loading })}>
                <iframe
                    id='iframe'
                    allow='camera'
                    src={props.url}
                    title='CTOS Verification'
                    width='100%'
                    height='670px'
                    frameBorder={0}
                />

                {loading &&
                <div className={styles.loadingIndicatorWrapper}>
                    <Spin size='large' />
                </div>}
            </div>

            {!loading && !isMobile &&
            <>
                <p className='text-gray-500 text-center mt-5'>Please press on the below button, if you have completed the verification.</p>

                <button
                    type='button'
                    className='btn-primary-full px-4 py-2 rounded font-weight-bold block mt-3 mx-auto'
                    onClick={props.handleDoneVerification}
                >
                    I've Completed Verification
                </button>
            </>}
        </Modal>
    )
}

CtosModal.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    url: PropTypes.string.isRequired,
    handleDoneVerification: PropTypes.func
}

CtosModal.defaultProps = {
    visible: false,
    onCancel: () => {},
    handleDoneVerification: () => {}
}

export default CtosModal
