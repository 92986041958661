import { useState, useEffect } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import DiligenceForm from '../DiligenceForm'
import styles from './DiligenceFormWrapper.module.scss'

const DiligenceFormWrapper = props => {
    return (
        <div className={cn(styles.wrapper, props.className)} style={props.style}>
            <h1 className={styles.title}>Mandatory Due Diligence</h1>

            <DiligenceForm
                className={styles.form}
                code={props.code}
                defaultValues={props.defaultValues}
                onSuccessSubmit={props.onSuccessSubmit}
            />
        </div>
    )
}

DiligenceFormWrapper.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    defaultValues: PropTypes.object,
    code: PropTypes.string.isRequired,
    onSuccessSubmit: PropTypes.func
}

DiligenceFormWrapper.defaultProps = {
    onSuccessSubmit: () => {}
}

export default DiligenceFormWrapper
