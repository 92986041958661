import { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import SendProfileUpdateRequestFormWrapper from '../../../../modules/customer/profile/update/request/send/component/form/wrapper'
import CustomerLayout from '../../../../modules/customer/components/template/layout'

const SendProfileUpdateRequestPage = props => {
    const history = useHistory()

    useEffect(() => {
    }, [])

    const handleSendRequestUpdateProfileSuccess = (data) => {
        history.push({
            pathname: '/profile-update-request-email-sent',
            state: {
                ...data
            }
        })
    }

    return (
        <CustomerLayout>
            <SendProfileUpdateRequestFormWrapper
                className='mt-3'
                onSuccessSubmit={handleSendRequestUpdateProfileSuccess}
            />
        </CustomerLayout>
    )
}

export default SendProfileUpdateRequestPage
