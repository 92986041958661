import { useState, useEffect } from 'react'
import { Spin, Modal } from 'antd'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'
import { inject } from 'mobx-react'

const EmailVerificationPage = props => {
    const location = useLocation()
    const history = useHistory()
    const [verifying, setVerifying] = useState(true)
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })

    useEffect(() => {
        if (query?.code) {
            verify(query.code)
        }
    }, [])

    const verify = async (code) => {
        try {
            setVerifying(true)
            const res = await props.services.applicationService.verify(code)

            if (res.ok) {
                if (props.store?.applicationId !== res.data?.applicationId && props.store?.applicationId !== 0) {
                    props.store.reset()
                }
                props.store?.setData({
                    applicationId: res?.data?.applicationId,
                    referenceId: res?.data?.referenceId,
                    accessToken: res?.data?.accessToken
                })
                if (query?.exclude) {
                    history.push({
                        pathname: '/status',
                        search: '?exclude=payment'
                    })
                } else {
                    history.push('/status')
                }
            } else if (res.problem === 'CLIENT_ERROR') {
                console.log(query)
                Modal.info({
                    title: 'Invalid Token',
                    content: (
                        <p>
                            Looks like the verification link you clicked on is invalid. Go back to home page and resend one.
                        </p>
                    ),
                    onOk: () => {
                        if (query?.exclude) {
                            history.push({
                                pathname: '/',
                                search: '?exclude=payment'
                            })
                        } else {
                            history.push('/')
                        }
                    }
                })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setVerifying(false)
        }
    }

    if (!query?.code) {
        history.replace('/')
        return (<div />)
    }

    return (
        <div className='flex flex-col w-full h-screen justify-center content-center place-content-center'>
            {verifying &&
            <>
                <Spin size='large' />
                <p className='text-lg mt-2 text-center text-blue-400'>Verifying ...</p>
            </>}
        </div>
    )
}

export default inject('store', 'services')(EmailVerificationPage)
