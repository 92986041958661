import countries, { highRiskCountryCodes, nonHighRiskCountries } from './countries'

const nationalities = [...countries]
nationalities.splice(1, 0, {
    code: 'MYPR',
    name: 'P.R. of Malaysia'
})

export const highRiskNationalityCodes = highRiskCountryCodes
export const nonHighRiskNationalities = nationalities.filter((nationality, index) => {
    return !highRiskNationalityCodes.includes(nationality.code)
})

export default nationalities
