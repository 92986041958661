import { useState, useEffect } from 'react'
import { Form, Input } from 'antd'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { inject } from 'mobx-react'
import focusErrorInput from '../../../../../../helpers/focusErrorInput'
import FormError from '../../../../../../components/form/error'
import SubmitButton from '../../../../../../components/button/submit'
import OtpInput from '../../../../../../components/input/otp'
import schema from './schema'

const ApplicationCreatedOtpVerificationForm = props => {
    const [form] = Form.useForm()
    const [submitting, setSubmitting] = useState(false)
    const [apiErrorMessages, setApiErrorMessages] = useState([])
    const { handleSubmit, setError, setValue, control, reset, watch, setFocus, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    })

    useEffect(() => {
        for (const [key, value] of Object.entries(props.defaultValues)) {
            setValue(key, value)
        }
    }, [props.defaultValues])

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            setApiErrorMessages([])
            const res = await props.services.applicationService.verifyByOTP(data.otp, props.defaultValues.email, data.exclude)

            if (res.ok) {
                const payload = res.data
                if (props.onSuccessSubmit) props.onSuccessSubmit(payload)
            } else if (res.status === 422) {
                const inputFields = Object.keys(watch())
                const errors = res.data.errors
                const { nonErrorFieldMessages } = focusErrorInput(inputFields, errors, setFocus, setError, ['email', 'otp'])
                console.log(nonErrorFieldMessages)
                setApiErrorMessages(nonErrorFieldMessages)
            } else {
                setApiErrorMessages(['Something went wrong'])
            }
        } catch (error) {
            console.error(error)
        } finally {
            setSubmitting(false)
            reset()
        }
    }

    return (
        <Form
            className={cn(props.className, 'w-full')}
            style={props.style}
            layout='vertical'
            onFinish={handleSubmit(onSubmit)}
            form={form}
        >
            {apiErrorMessages.length !== 0 &&
            <FormError className='mt-3' messages={apiErrorMessages} />}

            <Form.Item
                className='quesTitle'
                validateStatus={(errors?.otp?.message) ? 'error' : '-'}
                help={<div className={cn('flex items-center justify-center', { 'mt-3': errors?.otp })}>{errors?.otp?.message}</div>}
            >
                <Controller
                    name='otp'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <OtpInput
                            ref={ref}
                            className='flex items-center justify-center mt-10'
                            numInputs={6}
                            value={value}
                            isInputNum
                            onChange={(otp) => {
                                onChange(otp)
                            }}
                        />
                    )}
                />
            </Form.Item>

            <SubmitButton
                className='w-full mt-1'
                label='Verify'
                labelSubmitting='Verifying'
                submitting={submitting}
            />
        </Form>
    )
}

ApplicationCreatedOtpVerificationForm.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onSuccessSubmit: PropTypes.func
}

ApplicationCreatedOtpVerificationForm.defaultProps = {
    onSuccessSubmit: () => {}
}

export default inject('services')(ApplicationCreatedOtpVerificationForm)
