import { useState, useEffect, useRef } from 'react'
import { Checkbox, Col, Form, Input, Radio, Row, Select, Slider, message } from 'antd'
import { inject, observer } from 'mobx-react'
import { nonIslamicBankList, nonIslamicBankListDev, islamicBankList, brokerList } from '../../../../../../models/institutions'
import config from 'config'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import SubmitButton from '../../../../../../components/button/submit'
import Button from '../../../../../../components/button'
import cn from 'classnames'
import schema from './schema'
import _ from 'lodash'
import FormError from '../../../../../../components/form/error'
import FileUploader from '../../../../../../components/file/uploader'
import focusErrorInput from '../../../../../../helpers/focusErrorInput'

const { Option } = Select

const nonIslamicBankOptions = (config.app.isProduction) ? nonIslamicBankList : nonIslamicBankListDev

const CompleteFinancialProfileForm = props => {
    const refFormWrapper = useRef(null)
    const step = props.store.step3
    const isAllFieldsDisabled = (props.store?.applicationStatus !== 'EMAIL_VERIFIED' && props.store?.applicationStatus !== 'AMEND' && props.store?.applicationStatus !== 'AMEND_WITH_DOCUMENT') || props.isLocked
    const [submitting, setSubmitting] = useState(false)
    const [apiErrorMessages, setApiErrorMessages] = useState([])

    const { handleSubmit, setValue, getValues, setFocus, setError, control, trigger, watch, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        context: { hasSpouse: props.store.step1.hasBaAccount, hideFinancialProfileFieldset: props.hideFinancialProfileFieldset },
        defaultValues: {
            ...step.formData,
            isPersonalAccount: (props.store?.completedStep >= 4) ? 1 : 0
        }
    })

    const handleBankStatementBeforeUpload = (file) => {
        const fileFormatOk = file.type === 'image/jpeg' || file.type === 'image/png'
        if (!fileFormatOk) {
            message.error('You can only upload JPG/PNG file!')
        }
        const maxFileSize = (file.type === 'image/jpeg') ? 1 : 0.7 // MB
        const isSizeValid = file.size / 1024 / 1024 < maxFileSize
        const fileSizeErrorMessage = `Document must be smaller than ${maxFileSize}MB!`
        if (!isSizeValid) {
            message.error(fileSizeErrorMessage)
        }
        return fileFormatOk && isSizeValid
    }

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            setApiErrorMessages([])
            const exclude = (props.hideFinancialProfileFieldset) ? 'payment' : null
            const res = await step?.submit(props.store.accessToken, exclude)

            if (res.ok) {
                if (props.onSuccessSubmit) props.onSuccessSubmit()
            } else if (res.status === 422) {
                if (res.data?.errors) {
                    const inputFields = Object.keys(watch())
                    const errors = res.data.errors
                    const excludeFocusKeys = (props.hideFinancialProfileFieldset) ? ['isIslamicBank', 'bankName', 'bankAccountNumber', 'bankStatementPath'] : []
                    const { nonErrorFieldMessages } = focusErrorInput(inputFields, errors, setFocus, setError, excludeFocusKeys)
                    setApiErrorMessages(nonErrorFieldMessages)
                    if (nonErrorFieldMessages.length !== 0) refFormWrapper.current.scrollIntoView({ behavior: 'smooth' })
                }
            } else {
                setApiErrorMessages(['Something went wrong'])
                refFormWrapper.current.scrollIntoView({ behavior: 'smooth' })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <div ref={refFormWrapper}>
            <Form
                className={cn(props.className, 'w-full complete-financial-profile-form')}
                layout='vertical'
                onFinish={handleSubmit(onSubmit)}
            >
                {apiErrorMessages.length !== 0 &&
                <FormError className='mb-5' messages={apiErrorMessages} />}

                <fieldset disabled={isAllFieldsDisabled}>
                    {!props.hideFinancialProfileFieldset &&
                    <>
                        <h2 className='text-2xl mb-5'>
                            Financial Profile
                        </h2>

                        <Row gutter={16}>
                            <Col xs={24} span={24}>
                                <Form.Item
                                    className='quesTitle'
                                    label='Are you using an Islamic Banking Account?'
                                    validateStatus={(errors?.isIslamicBank?.message) ? 'error' : '-'}
                                    help={errors?.isIslamicBank?.message}
                                >
                                    <Controller
                                        name='isIslamicBank'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Radio.Group
                                                name='isIslamicBank'
                                                onChange={(e) => {
                                                    onChange(e.target.value)
                                                    step?.setIsIslamicBank(e.target.value === 1)
                                                    setValue('bankName', null)
                                                }}
                                                value={value}
                                            >
                                                <Radio value={1}>Yes</Radio>
                                                <Radio value={0}>No</Radio>
                                            </Radio.Group>
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col xs={24} md={12} lg={12} span={12}>
                                <Form.Item
                                    className='quesTitle'
                                    label='Bank Name'
                                    validateStatus={(errors?.bankName?.message) ? 'error' : '-'}
                                    help={errors?.bankName?.message}
                                >
                                    <Controller
                                        name='bankName'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => {
                                            let bankHidden = _.find(islamicBankList, { key: value, hidden: true })
                                            if (!step?.isIslamicBank) {
                                                bankHidden = _.find(nonIslamicBankOptions, { key: value, hidden: true })
                                            }

                                            return (
                                                <Select
                                                    ref={ref}
                                                    name='bankName'
                                                    dropdownClassName='bank-name-dropdown'
                                                    disabled={isAllFieldsDisabled}
                                                    placeholder='Search to Select'
                                                    optionFilterProp='children'
                                                    dropdownMatchSelectWidth={266}
                                                    showSearch
                                                    onChange={(e) => {
                                                        onChange(e)
                                                        step?.setData({ bankName: e })
                                                    }}
                                                    value={(!bankHidden) ? value : bankHidden?.name}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                                >
                                                    {step?.isIslamicBank &&
                                                    <>
                                                        {islamicBankList.map((itm, idx) => {
                                                            if (itm.hidden) return null
                                                            return (
                                                                <Option value={itm.key} key={`islamic-bank-name-${idx}`}>{itm.name}</Option>
                                                            )
                                                        })}
                                                    </>}

                                                    {!step?.isIslamicBank &&
                                                    <>
                                                        {nonIslamicBankOptions.map((itm, idx) => {
                                                            if (itm.hidden) return null
                                                            return (
                                                                <Option value={itm.key} key={`non-islamic-bank-name-${idx}`}>{itm.name}</Option>
                                                            )
                                                        })}
                                                    </>}
                                                </Select>
                                            )
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12} lg={12} span={12}>
                                <Form.Item
                                    className='quesTitle'
                                    label='Bank Account Number'
                                    validateStatus={(errors?.bankAccountNumber?.message) ? 'error' : '-'}
                                    help={errors?.bankAccountNumber?.message}
                                >
                                    <Controller
                                        name='bankAccountNumber'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Input
                                                ref={ref}
                                                name='bankAccountNumber'
                                                className='input-text-full'
                                                onChange={(e) => {
                                                    onChange(e.target.value)
                                                    step?.setData({ bankAccountNumber: e.target.value })
                                                }}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} md={12} lg={12} span={12}>
                                <Form.Item
                                    className='quesTitle'
                                    label={
                                        <div className='flex justify-between w-full'>
                                            <span>Bank Statement</span>
                                            <a className='text-blue-500' href='/images/bank-statement-sample.png' target='_blank'>View Sample</a>
                                        </div>
                                    }
                                    validateStatus={(errors?.bankStatementPath?.message) ? 'error' : '-'}
                                    help={errors?.bankStatementPath?.message}
                                >
                                    <Controller
                                        name='bankStatementPath'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <FileUploader
                                                ref={ref}
                                                disabled={isAllFieldsDisabled}
                                                style={{ width: '100%' }}
                                                name='bankStatementPath'
                                                accept='image/png, image/jpeg'
                                                listType='picture-card'
                                                className='bank-statement-uploader'
                                                showUploadList={false}
                                                uploadedDocumentSrc={value}
                                                previewTitle='Bank Statement'
                                                action={`${config.app.apiUrl}/document/upload-bank-statement`}
                                                headers={{ Authorization: `Bearer ${props.store.accessToken}` }}
                                                beforeUpload={handleBankStatementBeforeUpload}
                                                onChange={(info) => {
                                                    const { status } = info.file
                                                    if (status === 'done') {
                                                        const { url } = info.file.response
                                                        onChange(url)
                                                        step?.setData({ bankStatementPath: url })
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    className='w-full'
                                    validateStatus={(errors?.isPersonalAccount?.message) ? 'error' : '-'}
                                    help={errors?.isPersonalAccount?.message}
                                >
                                    <Controller
                                        name='isPersonalAccount'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Checkbox
                                                ref={ref}
                                                name='isPersonalAccount'
                                                style={{ width: '100%' }}
                                                onChange={(e) => {
                                                    onChange((e.target.checked) ? 1 : 0)
                                                }}
                                                checked={value}
                                            >
                                                <div style={{ width: '100%' }}>
                                                    By checking I confirm that the Bank Account provided above is NOT a Joint Account nor a Third Party Account” below the bank statement - Mandatory to tick
                                                </div>
                                            </Checkbox>
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>}

                    <h2 className='text-2xl mb-5 my-4'>
                        Investment Risk Profile
                    </h2>

                    <Row gutter={16}>
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='quesTitle'
                                label='What is your investment term?'
                                validateStatus={(errors?.investmentTerm?.message) ? 'error' : '-'}
                                help={errors?.investmentTerm?.message}
                            >
                                <div className='ml-8 md:ml-0'>
                                    <Controller
                                        name='investmentTerm'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Slider
                                                className='investment-term-slider'
                                                disabled={isAllFieldsDisabled}
                                                style={{ width: '80%', marginLeft: 15 }}
                                                dots
                                                step={1}
                                                min={1}
                                                max={3}
                                                tooltipVisible={false}
                                                marks={{
                                                    1: 'Short',
                                                    2: 'Medium',
                                                    3: 'Long'
                                                }}
                                                onChange={(e) => {
                                                    onChange(e)
                                                    step?.setData({ investmentTerm: e })
                                                }}
                                                value={value}
                                            />
                                        )}
                                    />
                                </div>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='quesTitle'
                                label='What is your risk tolerance?'
                                validateStatus={(errors?.riskTolerance?.message) ? 'error' : '-'}
                                help={errors?.riskTolerance?.message}
                            >
                                <div className='ml-8 md:ml-4'>
                                    <Controller
                                        name='riskTolerance'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Slider
                                                className='risk-tolerance-slider'
                                                disabled={isAllFieldsDisabled}
                                                style={{ width: '80%' }}
                                                dots
                                                step={1}
                                                min={1}
                                                max={3}
                                                tooltipVisible={false}
                                                marks={{
                                                    1: 'Conservative',
                                                    2: 'Moderate',
                                                    3: 'Aggressive'
                                                }}
                                                onChange={(e) => {
                                                    onChange(e)
                                                    step?.setData({ riskTolerance: e })
                                                }}
                                                value={value}
                                            />
                                        )}
                                    />
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='quesTitle'
                                label='What is your investment objective (select all applicable)'
                                validateStatus={(errors?.investmentObjective?.message) ? 'error' : '-'}
                                help={errors?.investmentObjective?.message}
                            >
                                <Controller
                                    name='investmentObjective'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Checkbox.Group
                                            name='investmentObjective'
                                            style={{ width: '100%' }}
                                            onChange={(e) => {
                                                onChange(e)
                                                step?.setData({ investmentObjective: e })
                                            }}
                                            value={value}
                                        >
                                            <Row>
                                                <Col xs={24} md={12} lg={12} span={12}>
                                                    <Checkbox value='CAPITAL_GROWTH'>Capital Growth</Checkbox>
                                                </Col>
                                                <Col xs={24} md={12} lg={12} span={12}>
                                                    <Checkbox value='SAVINGS'>Savings</Checkbox>
                                                </Col>
                                            </Row>
                                            <Row className='md:mt-4'>
                                                <Col xs={24} md={12} lg={12} span={12}>
                                                    <Checkbox value='DIVIDENDS'>Dividends</Checkbox>
                                                </Col>
                                            </Row>
                                        </Checkbox.Group>
                                    )}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} lg={12} span={12}>
                            <Form.Item
                                className='quesTitle'
                                label='Years of experience in the stock investment?'
                                validateStatus={(errors?.investmentExperience?.message) ? 'error' : '-'}
                                help={errors?.investmentExperience?.message}
                            >
                                <Controller
                                    name='investmentExperience'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Radio.Group
                                            name='investmentExperience'
                                            style={{ width: '100%' }}
                                            onChange={(e) => {
                                                onChange(e.target.value)
                                                step?.setInvestmentExperience(e.target.value)
                                                if (e.target.value === 'NEW_TO_TRADING') {
                                                    setValue('brokerAcctList', [])
                                                    setValue('brokerAcctOther', null)
                                                }
                                            }}
                                            value={value}
                                        >
                                            <Row>
                                                <Col xs={24} md={12} lg={12} span={12}>
                                                    <Radio value='NEW_TO_TRADING'>New to Trading</Radio>
                                                </Col>
                                                <Col xs={24} md={12} lg={12} span={12}>
                                                    <Radio value='BELOW_1_YEAR'>Less than 1 year</Radio>
                                                </Col>
                                            </Row>
                                            <Row className='md:pt-2'>
                                                <Col xs={24} md={12} lg={12} span={12}>
                                                    <Radio value='BETWEEN_1_TO_5_YEARS'>1 to 5 years</Radio>
                                                </Col>
                                                <Col xs={24} md={12} lg={12} span={12}>
                                                    <Radio value='BETWEEN_6_TO_10_YEARS'>6 to 10 years</Radio>
                                                </Col>
                                            </Row>
                                            <Row className='md:pt-2'>
                                                <Col xs={24} md={12} lg={12} span={12}>
                                                    <Radio value='ABOVE_10_YEARS'>Above 10 years</Radio>
                                                </Col>
                                            </Row>
                                        </Radio.Group>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {step?.investmentExperience && step?.investmentExperience !== 'NEW_TO_TRADING' &&
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                className='quesTitle'
                                label='Do you have an existing CDS account with another broker?'
                                validateStatus={(errors?.brokerAcctList?.message) ? 'error' : '-'}
                                help={errors?.brokerAcctList?.message}
                            >
                                <Controller
                                    name='brokerAcctList'
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Checkbox.Group
                                            name='brokerAcctList'
                                            style={{ width: '100%' }}
                                            onChange={(e) => {
                                                onChange(e)
                                                if (e.indexOf('OTHERS') === -1) {
                                                    setValue('brokerAcctOther', null)
                                                    step?.setData({ brokerAcctList: e, brokerAcctOther: null })
                                                } else {
                                                    step?.setData({ brokerAcctList: e })
                                                }
                                                trigger('brokerAcctOther')
                                            }}
                                            value={value}
                                        >
                                            <Row>
                                                {brokerList.map((itm, idx) => {
                                                    if (idx < 5) {
                                                        return (
                                                            <Col xs={24} md={4} lg={4} span={4}>
                                                                <Checkbox value={itm.key} key={idx}>{itm.name}</Checkbox>
                                                            </Col>
                                                        )
                                                    } else {
                                                        return <></>
                                                    }
                                                })}
                                            </Row>
                                            <Row className='md:pt-4'>
                                                {brokerList.map((itm, idx) => {
                                                    if (idx >= 5 && idx < 10) {
                                                        return (
                                                            <Col xs={24} md={4} lg={4} span={4}>
                                                                <Checkbox value={itm.key} key={idx}>{itm.name}</Checkbox>
                                                            </Col>
                                                        )
                                                    } else {
                                                        return <></>
                                                    }
                                                })}
                                            </Row>
                                            <Row className='md:pt-4'>
                                                {brokerList.map((itm, idx) => {
                                                    if (idx >= 10) {
                                                        return (
                                                            <Col xs={24} md={4} lg={4} span={4}>
                                                                <Checkbox value={itm.key} key={idx}>{itm.name}</Checkbox>
                                                            </Col>
                                                        )
                                                    } else {
                                                        return <></>
                                                    }
                                                })}

                                                <Col xs={24} md={20} lg={20} span={20}>
                                                    <div className='flex flex-col md:flex-row align-middle mb-2 md:mt-0'>
                                                        <Checkbox className='mb-2' value='OTHERS'>Others</Checkbox>

                                                        <Form.Item
                                                            className='w-full md:-mt-2'
                                                            validateStatus={(errors?.brokerAcctOther?.message) ? 'error' : '-'}
                                                            help={errors?.brokerAcctOther?.message}
                                                        >
                                                            <Controller
                                                                name='brokerAcctOther'
                                                                control={control}
                                                                render={({ field: { value, onChange, ref } }) => (
                                                                    <Input
                                                                        ref={ref}
                                                                        name='brokerAcctOther'
                                                                        className='input-text-full'
                                                                        disabled={step?.brokerAcctList.indexOf('OTHERS') === -1}
                                                                        onChange={(e) => {
                                                                            onChange(e)
                                                                            step?.setData({ brokerAcctOther: e.target.value })
                                                                        }}
                                                                        value={value}
                                                                    />
                                                                )}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Checkbox.Group>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>}

                    {!isAllFieldsDisabled &&
                    <>
                        <SubmitButton
                            className='w-full mt-5 submit-btn'
                            label='Submit'
                            submitting={submitting}
                        />

                        <div className='flex justify-center gray my-3'>
                            By clicking continue I agree that all information provided are accurate.
                        </div>
                    </>}
                </fieldset>

                {isAllFieldsDisabled &&
                <Button
                    className='w-full mt-5'
                    label='Next'
                    onClick={props.onNext}
                />}
            </Form>
        </div>
    )
}

CompleteFinancialProfileForm.propTypes = {
    className: PropTypes.string,
    onSuccessSubmit: PropTypes.func,
    onNext: PropTypes.func,
    isLocked: PropTypes.bool,
    hideFinancialProfileFieldset: PropTypes.bool
}

CompleteFinancialProfileForm.defaultProps = {
    isLocked: false,
    hideFinancialProfileFieldset: false
}

export default inject('store')(observer(CompleteFinancialProfileForm))
