import { useState, useEffect } from 'react'
import { inject } from 'mobx-react'
import { Switch, Route, Link, useParams, useRouteMatch } from 'react-router-dom'
import { applySnapshot } from 'mobx-state-tree'
import _ from 'lodash'
import AsyncStorage from '@callstack/async-storage'
import { Spin } from 'antd'
import AdminLayout from '../../../../modules/admin/components/templates/layout'
import AdminApplicationPersonalInfoPage from './cds-trading-account'
import AdminApplicationEmploymentAndSpousePage from './employment-and-spouse'
import AdminApplicationFinancialProfilePage from './financial-profile'
import AdminApplicationTaxationAndDeclarationPage from './taxation-and-declarations'
import AdminApplicationSummaryPage from './summary'

const AdminApplicationDetailPage = props => {
    const { path } = useRouteMatch()
    const { id } = useParams()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getApplication(props.adminStore.accessToken, id)
    }, [])

    const getApplication = async (accessToken, id) => {
        try {
            setLoading(true)
            const res = await props.services.adminService.getApplicationById(accessToken, id)

            if (res.ok && res.data) {
                const application = res.data.data
                const jsonStr = await AsyncStorage.getItem('rootStore')
                const obj = JSON.parse(jsonStr)

                if (application.createdAt) {
                    application.createdAt = new Date(application.createdAt)
                }
                if (application.formData1) {
                    application.fullName = application.formData1.fullName
                }

                applySnapshot(props.store, {
                    ...application,
                    initiated: true
                })

                // Step 1
                if (application.formData1 && application.completedStep > 1) {
                    const step1 = application.formData1
                    if (step1.birthDate) {
                        step1.birthDate = new Date(`${step1.birthDate}T00:00:00`)
                    }
                    if (step1.documentExpiry) {
                        step1.documentExpiry = new Date(`${step1.documentExpiry}T00:00:00`)
                    }

                    if (!step1.ekycDocumentType) {
                        if (step1.documentExpiry) {
                            step1.ekycDocumentType = 3 // Passport
                        } else {
                            step1.ekycDocumentType = 1 // MyKad
                        }
                    }

                    props.store.setStep1Data(step1)
                } else {
                    if (obj) {
                        if (application.completedStep === 1 && !obj.step1?.fullName) {
                            obj.step1.fullName = application.formData1?.fullName
                        }

                        props.store.setStep1Data(obj.step1)
                    }
                }

                // Step 2
                if (application.formData1 && application.completedStep > 2) {
                    const step2 = { ...application.formData2.employment }
                    for (const [key, value] of Object.entries(application.formData2.spouse)) {
                        step2[`spouse${_.upperFirst(key)}`] = value
                    }
                    if (step2.spouseDocumentExpiry) {
                        step2.spouseDocumentExpiry = new Date(`${step2.spouseDocumentExpiry}T00:00:00`)
                    }
                    if (step2.spouseIsPrMalaysia) {
                        step2.spouseNationality = 'MYPR'
                    }

                    props.store.setStep2Data(step2)
                } else {
                    if (obj) {
                        props.store.setStep2Data(obj.step2)
                    }
                }

                // Step 3
                if (application.formData3 && application.completedStep > 3) {
                    const step3 = { ...application.formData3.customerBank, ...application.formData3.riskProfile }
                    if (step3.investmentTerm) {
                        let investmentTerm

                        switch (step3.investmentTerm) {
                            case 'SHORT_TERM':
                                investmentTerm = 1
                                break
                            case 'MID_TERM':
                                investmentTerm = 2
                                break
                            case 'LONG_TERM':
                                investmentTerm = 3
                                break
                        }

                        step3.investmentTerm = investmentTerm
                    }
                    if (step3.riskTolerance) {
                        let riskTolerance

                        switch (step3.riskTolerance) {
                            case 'CONSERVATIVE':
                                riskTolerance = 1
                                break
                            case 'MODERATE':
                                riskTolerance = 2
                                break
                            case 'AGGRESSIVE':
                                riskTolerance = 3
                                break
                        }

                        step3.riskTolerance = riskTolerance
                    }

                    props.store.setStep3Data(step3)
                } else {
                    if (obj) {
                        props.store.setStep3Data(obj.step3)
                    }
                }

                // Step 4
                if (application.formData4 && application.completedStep > 4) {
                    const step4 = application.formData4.declaration
                    if (step4?.isMalaysiaResident) {
                        step4.countryOfResidence = 'MY'
                    }
                    props.store.setStep4Data(step4)
                } else {
                    if (obj) {
                        props.store.setStep4Data(obj.step4)
                    }
                }

                // Ekyc
                if (application.ekyc) {
                    const ekyc = application.ekyc
                    ekyc.status = application.ekycStatus
                    props.store.setEkyc(ekyc)
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    if (loading) {
        return (
            <div className='flex flex-col w-full h-screen justify-center content-center place-content-center'>
                <Spin size='large' />
            </div>
        )
    }

    return (
        <Switch>
            <Route exact path={path}>
                <AdminLayout>
                    <div className='text-center'>
                        <h1 className='mb-5 text-lg'>Application ID #{id}</h1>
                        <Link className='btn-primary-full px-4 py-2 rounded font-weight-bold' to={`/admin/application/${id}/cds-trading-account`}>
                            View Detail
                        </Link>
                    </div>
                </AdminLayout>
            </Route>
            <Route path={`${path}/cds-trading-account`}>
                <AdminApplicationPersonalInfoPage />
            </Route>
            <Route path={`${path}/employment-and-spouse-details`}>
                <AdminApplicationEmploymentAndSpousePage />
            </Route>
            <Route path={`${path}/financial-profile`}>
                <AdminApplicationFinancialProfilePage />
            </Route>
            <Route path={`${path}/taxation-and-declarations`}>
                <AdminApplicationTaxationAndDeclarationPage />
            </Route>
            <Route path={`${path}/summary`}>
                <AdminApplicationSummaryPage />
            </Route>
        </Switch>
    )
}

export default inject('adminStore', 'store', 'services')(AdminApplicationDetailPage)
