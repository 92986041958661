import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button as AntButton } from 'antd'
import cn from 'classnames'
import SignatureCanvas from 'react-signature-canvas'
import styles from './SignatureInput.module.scss'

const SignatureInput = props => {
    const refCanvas = useRef(null)

    useEffect(() => {
    }, [])

    const clear = () => {
        if (refCanvas.current) refCanvas.current.clear()
        if (props.onClear) props.onClear()
        if (props.onChange) props.onChange(null)
    }

    return (
        <div className={props.className} style={props.style}>
            <div className={styles.canvasWrapper}>
                <SignatureCanvas
                    ref={refCanvas}
                    penColor='black'
                    canvasProps={{ width: 340, height: 300 }}
                    onEnd={(e) => {
                        if (refCanvas.current.isEmpty()) {
                            return
                        }

                        const base64 = refCanvas.current.toDataURL()
                        if (props.onChange) props.onChange(base64)
                    }}
                />
            </div>

            <AntButton style={{ width: 100, marginTop: 10 }} disabled={props.isBtnClearDisabled} onClick={clear}>
                Clear
            </AntButton>
        </div>
    )
}

SignatureInput.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onClear: PropTypes.func,
    isBtnClearDisabled: PropTypes.bool,
    canvasOnEnd: PropTypes.func
}

SignatureInput.defaultProps = {
    isBtnClearDisabled: false,
    onClear: () => {},
    canvasOnEnd: () => {}
}

export default SignatureInput
