import { useState, useEffect } from 'react'
import { Spin, Modal } from 'antd'
import { Switch, Route, useLocation, useHistory, useRouteMatch } from 'react-router-dom'
import qs from 'qs'
import { inject } from 'mobx-react'
import AdminApplicationDetailPage from './detail'
import AdminPrivateRoute from '../../../modules/admin/components/route/private'

const AdminApplicationPage = props => {
    const { path } = useRouteMatch()
    const location = useLocation()
    const history = useHistory()
    const [verifying, setVerifying] = useState(true)
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })

    useEffect(() => {
        if ((!query.token || !query.id) && location.pathname === path) {
            history.push('/')
            return
        }
        if (query?.token) {
            verify(query.token)
        }
    }, [location, query?.token, query?.id])

    const verify = async (token) => {
        try {
            setVerifying(true)
            const res = await props.services.adminService.validateAccessToken(token)

            if (res.ok) {
                props.adminStore.setData({
                    fullName: res.data?.fullName,
                    accessToken: token
                })
                history.push(`/admin/application/${query?.id}`)
            } else if (res.problem === 'CLIENT_ERROR') {
                props.adminStore.setData({
                    fullName: null,
                    accessToken: null
                })
                Modal.info({
                    title: 'Invalid Token',
                    content: (
                        <p>
                            Looks like the token is invalid
                        </p>
                    ),
                    onOk: () => {
                        history.push('/')
                    }
                })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setVerifying(false)
        }
    }

    return (
        <Switch>
            <Route exact path={path}>
                <div className='flex flex-col w-full h-screen justify-center content-center place-content-center'>
                    {verifying &&
                    <>
                        <Spin size='large' />
                        <p className='text-lg mt-2 text-center text-blue-400'>Verifying ...</p>
                    </>}
                </div>
            </Route>
            <AdminPrivateRoute path={`${path}/:id`}>
                <AdminApplicationDetailPage />
            </AdminPrivateRoute>
        </Switch>
    )
}

export default inject('adminStore', 'services')(AdminApplicationPage)
