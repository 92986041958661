import { useState, useEffect } from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './ApplicationResumeOtpVerificationModal.module.scss'
import ApplicationResumeOtpVerificationForm from '../form'
import ResendApplicationResumeOTPButton from '../../resend/components/button'

const ApplicationResumeOtpVerificationModal = (props) => {
    useEffect(() => {
    }, [])

    return (
        <Modal
            visible={props.visible}
            title=''
            footer={null}
            className={styles.wrapper}
            onCancel={props.onCancel}
        >
            <div className={styles.title}>OTP Verification</div>

            <p className={styles.description}>
                A validation-PIN was sent to your email <br /><b>{props.formDefaultValues?.email}</b>. <br />
                Please enter this PIN now to validate your email address
            </p>

            <p className={cn(styles.notes, 'mt-3')}>
                P.S: Note that the OTP verification email may fall into the Junk or Spam Folder, kindly check the folders if the email is not in the inbox. If you do not receive the email after 1 minute, you may click the "Resend Email" button.
            </p>

            <ApplicationResumeOtpVerificationForm
                className='mb-5'
                defaultValues={props.formDefaultValues}
                onSuccessSubmit={props.onSuccessSubmit}
            />

            <div className='flex items-center justify-center mt-5'>
                <ResendApplicationResumeOTPButton
                    defaultValues={{
                        email: props.formDefaultValues?.email,
                        exclude: props.formDefaultValues?.exclude
                    }}
                />
            </div>
        </Modal>
    )
}

ApplicationResumeOtpVerificationModal.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    formDefaultValues: PropTypes.object,
    onSuccessSubmit: PropTypes.func
}

ApplicationResumeOtpVerificationModal.defaultProps = {
    visible: false,
    onSuccessSubmit: () => {}
}

export default ApplicationResumeOtpVerificationModal
