import { string, object, number, array } from 'yup'

const schema = object().shape({
    hasForeignTax: string()
        .nullable()
        .required('This field is a required field')
        .oneOf(['1', '0'], 'This field is a required field'),
    birthCountry: string()
        .when('hasForeignTax', (hasForeignTax) => {
            if (hasForeignTax === '1') {
                return string()
                    .nullable()
                    .required('Birth country is a required field')
            }
        }).nullable(),
    birthCity: string()
        .when('hasForeignTax', (hasForeignTax) => {
            if (hasForeignTax === '1') {
                return string()
                    .nullable()
                    .required('Birth city is a required field')
            }
        }).nullable(),
    taxCountry: string()
        .when('hasForeignTax', (hasForeignTax) => {
            if (hasForeignTax === '1') {
                return string()
                    .nullable()
                    .test('value_not_my', '', function (value) {
                        if (value && value === 'MY') {
                            return this.createError({ message: 'Kindly select Country of Tax Residence outside Malaysia' })
                        }
                        return true
                    })
                    .required('Tax country is a required field')
            }
        }).nullable(),
    taxCity: string()
        .when('hasForeignTax', (hasForeignTax) => {
            if (hasForeignTax === '1') {
                return string()
                    .nullable()
                    .required('Tax city is a required field')
            }
        }).nullable(),
    hasTin: string()
        .when('hasForeignTax', (hasForeignTax) => {
            if (hasForeignTax === '1') {
                return string()
                    .nullable()
                    .required('This field is a required field')
                    .oneOf(['1', '0'], 'This field is a required field')
            }
        }).nullable(),
    tinNumber: string()
        .when('hasTin', (hasTin) => {
            if (hasTin === '1') {
                return string()
                    .nullable()
                    .required('Tin number is a required field')
            }
        }).nullable(),
    reasonForNoTin: string()
        .when('hasTin', (hasTin) => {
            if (hasTin === '0') {
                return string()
                    .nullable()
                    .required('This is a required field')
            }
        }).nullable(),
    reasonForUnableToObtainTin: string()
        .when('reasonForNoTin', (reasonForNoTin) => {
            if (reasonForNoTin === 'UNABLE_OBTAIN_TIN') {
                return string()
                    .nullable()
                    .required('Reason for unable to obtain TIN is a required field')
            }
        }).nullable(),
    countryOfResidence: string()
        .nullable()
        .required('Country of residence a required field'),
    feaRule: string()
        .when('countryOfResidence', (countryOfResidence) => {
            if (countryOfResidence === 'MY') {
                return string()
                    .nullable()
                    .required('This is a required field')
            }
        }).nullable(),
    hasRelatedMsecStaff: string()
        .nullable()
        .required('This field is a required field')
        .oneOf(['1', '0'], 'This field is a required field'),
    relatedMsecStaff: array()
        .required()
        .of(object().shape({
            relationship: string()
                .nullable()
                .required('Relationship is a required field'),
            name: string()
                .nullable()
                .required('Name is a required field')
        })),
    hasRelatedMsecTrader: string()
        .nullable()
        .required('This field is a required field')
        .oneOf(['1', '0'], 'This field is a required field'),
    relatedMsecTrader: array()
        .required()
        .of(object().shape({
            relationship: string()
                .nullable()
                .required('Relationship is a required field'),
            name: string()
                .nullable()
                .required('Name is a required field'),
            clientCode: string()
                .nullable()
                .required('Client code is a required field')
        })),
    hasRelatedPep: string()
        .nullable()
        .required('This field is a required field')
        .oneOf(['1', '0'], 'This field is a required field'),
    relatedPep: array()
        .required()
        .of(object().shape({
            relationship: string()
                .nullable()
                .required('Relationship is a required field'),
            name: string()
                .nullable()
                .required('Name is a required field'),
            occupation: string()
                .nullable()
                .required('Occupation is a required field'),
            organisation: string()
                .nullable()
                .required('Organisation is a required field'),
            country: string()
                .nullable()
                .required('Country is a required field')
        })),
    isAccountHolder: string()
        .nullable()
        .required('This field is a required field')
        .oneOf(['1'], 'This field is a required field')
})

export default schema
