import countryList from 'country-list'

let countries = countryList.getData()
countries.sort(function (a, b) {
    if (a.name < b.name) {
        return -1
    }
    if (a.name > b.name) {
        return 1
    }
    return 0
})
const index = countries.findIndex((country) => {
    return country.code === 'MY'
})
const malaysia = countries[index]
countries.splice(index, 1)
countries.unshift(malaysia)

const removeCountryCodes = ['AX', 'CI', 'CW', 'SX', 'MF', 'TL', 'BL', 'BQ', 'IM', 'JE', 'ME']

countries = countries.filter((country, index) => {
    return !removeCountryCodes.includes(country.code)
})

export const highRiskCountryCodes = [
    'AL', 'BB', 'BF', 'KH', 'KY', 'KP', 'HT', 'IR', 'JM', 'JO', 'ML', 'MT', 'MA',
    'MM', 'NI', 'PK', 'PA', 'PH', 'SN', 'SS', 'SY', 'TR', 'UG', 'AE', 'YE', 'US'
]

export const nonHighRiskCountries = countries.filter((country, index) => {
    return !highRiskCountryCodes.includes(country.code)
})

export default countries
