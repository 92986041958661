import { useState, useEffect, useRef } from 'react'
import { Checkbox, Col, Form, Input, Row, Divider, Modal, Button as AntButton } from 'antd'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory, Link } from 'react-router-dom'
import { EditOutlined } from '@ant-design/icons'
import SubmitButton from '../../../../../../components/button/submit'
import countries from '../../../../../../models/countries'
import { bankList, bankListDev } from '../../../../../../models/institutions'
import AgreementModal from '../../../../../../modules/application/agreement/components/modal'
import SignatureUploader from '../../../../../../modules/application/signature/components/uploader'
import schema from './schema'
import { drCodeTooltipTitle, mrCodeTooltipTitle, referralCodeTooltipTitle } from '../../../../components/tooltip'
import FormError from '../../../../../../components/form/error'
import config from '../../../../../../config'

const bankOptions = (config.app.isProduction) ? bankList : bankListDev

const ConfirmSummaryForm = props => {
    const history = useHistory()
    const refFormWrapper = useRef(null)
    const step = props.store.step4
    const isAllFieldsDisabled = (props.store?.applicationStatus !== 'EMAIL_VERIFIED' && props.store?.applicationStatus !== 'AMEND' && props.store?.applicationStatus !== 'AMEND_WITH_DOCUMENT') || props.isLocked
    const {
        drCode,
        mrCode,
        referralCode,
        username,
        accountType,
        fullName,
        documentNumber,
        contactPrefix,
        contactNumber,
        address1,
        address2,
        address3,
        country,
        city,
        postcode
    } = props.store.step1?.formData || {}
    const { bankName, bankAccountNumber } = props.store.step3?.formData || {}
    const { signaturePath } = props.store.step4?.formData || {}
    const [submitting, setSubmitting] = useState(false)
    const [isAgreementModalVisible, setIsAgreementModalVisible] = useState(false)
    const [apiErrorMessages, setApiErrorMessages] = useState([])

    const { handleSubmit, control, watch, setFocus, setError, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            drCode,
            mrCode,
            referralCode,
            username,
            accountType,
            fullName,
            documentNumber,
            contactPrefix,
            contactNumber,
            address1,
            address2,
            address3,
            country,
            city,
            postcode,
            bankName,
            bankAccountNumber,
            signaturePath,
            email: props.store?.email,
            signatureAgreement: (props.store?.completedStep >= 6 || isAllFieldsDisabled) ? 1 : 0
        }
    })

    useEffect(() => {
    }, [])

    const onSubmit = () => {
        if (props.store?.applicationStatus === 'EMAIL_VERIFIED') {
            setIsAgreementModalVisible(true)
        } else if (props.store?.applicationStatus === 'AMEND' || props.store?.applicationStatus === 'AMEND_WITH_DOCUMENT') {
            resubmit()
        }
    }

    const proceedPayment = async () => {
        try {
            setSubmitting(true)
            const data = {
                exclude: props.skipPayment ? 'payment' : null
            }
            const res = await props.services.paymentService.create(props.store?.accessToken, data)

            if (res.ok) {
                const { payment } = res.data.data
                window.location.href = payment?.fullPaymentUrl
            } else if (res.status === 422) {
                if (res.data?.errors) {
                    const messages = []
                    for (const key in res.data.errors) {
                        messages.push(...res.data.errors[key])
                    }
                    Modal.error({
                        title: 'Error',
                        content: (
                            <ul>
                                {messages.map((message, index) =>
                                    <li key={index}>{message}</li>
                                )}
                            </ul>
                        )
                    })
                }
            } else {
                Modal.error({
                    title: 'Something went wrong',
                    content: (
                        <p>Please try again later</p>
                    )
                })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setSubmitting(false)
        }
    }

    const resubmit = async () => {
        try {
            setSubmitting(true)
            setApiErrorMessages([])
            const res = await props.services.applicationService.submitAmend(props.store.accessToken)

            if (res.ok) {
                history.push('/status')
            } else if (res.status === 422) {
                if (res.data?.errors) {
                    const nonErrorFieldMessages = []
                    const errors = Object.keys(res.data.errors)
                    const inputFields = Object.keys(watch())

                    errors.forEach((key, index) => {
                        const firstErrorMsg = res.data.errors[key][0]
                        if (inputFields.includes(key)) {
                            if (index === 0) {
                                setFocus(key)
                            }
                            setError(key, {
                                type: 'manual',
                                message: firstErrorMsg
                            })
                        } else {
                            nonErrorFieldMessages.push(firstErrorMsg)
                        }
                    })

                    setApiErrorMessages(nonErrorFieldMessages)
                    if (nonErrorFieldMessages.length !== 0) refFormWrapper.current.scrollIntoView({ behavior: 'smooth' })
                }
            } else {
                setApiErrorMessages(['Something went wrong, please try again later'])
                refFormWrapper.current.scrollIntoView({ behavior: 'smooth' })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <div ref={refFormWrapper}>
            <Form
                name='form'
                layout='horizontal'
                className='confirm-summary-form'
                onFinish={handleSubmit(onSubmit)}
            >
                {apiErrorMessages.length !== 0 &&
                <FormError className='mb-5' messages={apiErrorMessages} />}

                <fieldset disabled={isAllFieldsDisabled}>
                    <div className='flex items-center mb-5'>
                        <h2 className='text-2xl'>
                            Dealer Representative and Referrals
                        </h2>

                        {!isAllFieldsDisabled &&
                        <Link to='/cds-trading-account-registration'>
                            <EditOutlined className='text-2xl ml-2' style={{ color: '#5C94E8' }} />
                        </Link>}

                        {isAllFieldsDisabled &&
                        <EditOutlined className='text-2xl ml-2 text-gray-400 cursor-not-allowed' />}
                    </div>

                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                className='quesTitleRow'
                                name='drCode'
                                label='DR Code'
                                labelCol={{ span: 8 }}
                                colon={false}
                                labelAlign='left'
                                tooltip={{
                                    title: drCodeTooltipTitle,
                                    placement: 'bottom'
                                }}
                            >
                                <Controller
                                    name='drCode'
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Input className='input-text-full' style={{ width: '70%' }} value={value} readOnly />
                                    )}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                className='quesTitleRow'
                                name='mrCode'
                                label='MR Code'
                                labelCol={{ span: 8 }}
                                colon={false}
                                labelAlign='left'
                                tooltip={{
                                    title: mrCodeTooltipTitle,
                                    placement: 'bottom'
                                }}
                            >
                                <Controller
                                    name='mrCode'
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Input className='input-text-full' style={{ width: '70%' }} value={value} readOnly />
                                    )}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                className='quesTitleRow'
                                name='referralCode'
                                label='Referral Code'
                                labelCol={{ span: 12 }}
                                colon={false}
                                labelAlign='left'
                                tooltip={{
                                    title: referralCodeTooltipTitle,
                                    placement: 'bottom'
                                }}
                            >
                                <Controller
                                    name='referralCode'
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Input className='input-text-full' value={value} readOnly />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className='flex items-center mb-5 my-8'>
                        <h2 className='text-2xl'>
                            Personal Particulars
                        </h2>

                        {!isAllFieldsDisabled &&
                        <Link to='/cds-trading-account-registration'>
                            <EditOutlined className='text-2xl ml-2' style={{ color: '#5C94E8' }} />
                        </Link>}

                        {isAllFieldsDisabled &&
                        <EditOutlined className='text-2xl ml-2 text-gray-400 cursor-not-allowed' />}
                    </div>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                className='quesTitleRow'
                                name='username'
                                label='User ID'
                                labelCol={{ span: 12 }}
                                labelAlign='left'
                                colon={false}
                            >
                                <Controller
                                    name='username'
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Input className='input-text-full' value={value} readOnly />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                className='quesTitleRow'
                                name='accountType'
                                label='Account Type'
                                labelCol={{ span: 12 }}
                                labelAlign='left'
                                colon={false}
                            >
                                <Controller
                                    name='accountType'
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Input className='input-text-full' value={value} readOnly />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                className='quesTitleRow'
                                name='fullName'
                                label='Name'
                                labelCol={{ span: 12 }}
                                labelAlign='left'
                                colon={false}
                            >
                                <Controller
                                    name='fullName'
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Input className='input-text-full' value={value} readOnly />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                className='quesTitleRow'
                                name='documentNumber'
                                label='NRIC/Passport'
                                labelCol={{ span: 12 }}
                                labelAlign='left'
                                colon={false}
                            >
                                <Controller
                                    name='documentNumber'
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Input className='input-text-full' value={value} readOnly />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                className='quesTitleRow'
                                label='Contact Number'
                                labelCol={{ span: 12 }}
                                labelAlign='left'
                                colon={false}
                            >
                                <Row>
                                    <Col span={4}>
                                        <Controller
                                            name='contactPrefix'
                                            control={control}
                                            render={({ field: { value } }) => (
                                                <Input
                                                    className='input-text-full'
                                                    style={{ width: 60 }}
                                                    value={`+${value}`}
                                                    readOnly
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col span={20}>
                                        <Controller
                                            name='contactNumber'
                                            control={control}
                                            render={({ field: { value } }) => (
                                                <Input className='input-text-full' value={value} readOnly />
                                            )}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                className='quesTitleRow'
                                label='Email'
                                labelCol={{ span: 12 }}
                                labelAlign='left'
                                colon={false}
                            >
                                <Controller
                                    name='email'
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Input className='input-text-full' value={value} readOnly />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                className='quesTitleRow'
                                label='Registered Address (as per NRIC)'
                                name='address1'
                                labelCol={{ span: 24 }}
                                labelAlign='left'
                                colon={false}
                            >
                                <Controller
                                    name='address1'
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Input className='input-text-full' value={value} readOnly />
                                    )}
                                />
                            </Form.Item>

                            <Form.Item className='quesTitle' style={{ marginTop: -10 }} name='address2'>
                                <Controller
                                    name='address2'
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Input className='input-text-full' value={value} readOnly />
                                    )}
                                />
                            </Form.Item>

                            <Form.Item className='quesTitle' style={{ marginTop: -10 }} name='address3'>
                                <Controller
                                    name='address3'
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Input className='input-text-full' value={value} readOnly />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                className='quesTitleRow'
                                label='Country'
                                labelCol={{ span: 24 }}
                                labelAlign='left'
                                colon={false}
                            >
                                <Controller
                                    name='country'
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Input
                                            className='input-text-full' readOnly
                                            value={countries.filter((itm) => itm.code === value)[0]?.name}
                                        />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                className='quesTitleRow'
                                label='City/Town'
                                name='city'
                                labelCol={{ span: 24 }}
                                labelAlign='left'
                                colon={false}
                            >
                                <Controller
                                    name='city'
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Input className='input-text-full' value={value} readOnly />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                className='quesTitleRow'
                                label='Postcode'
                                name='postcode'
                                labelCol={{ span: 24 }}
                                labelAlign='left'
                                colon={false}
                            >
                                <Controller
                                    name='postcode'
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Input className='input-text-full' value={value} readOnly />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className='flex items-center mb-5 my-8'>
                        <h2 className='text-2xl'>
                            Financial Information
                        </h2>

                        {!isAllFieldsDisabled &&
                        <Link to='/financial-profile'>
                            <EditOutlined className='text-2xl ml-2' style={{ color: '#5C94E8' }} />
                        </Link>}

                        {isAllFieldsDisabled &&
                        <EditOutlined className='text-2xl ml-2 text-gray-400 cursor-not-allowed' />}
                    </div>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                className='quesTitleRow'
                                label='Bank Name'
                                labelCol={{ span: 24 }}
                                labelAlign='left'
                                colon={false}
                            >
                                <Controller
                                    name='bankName'
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Input
                                            className='input-text-full' readOnly
                                            value={bankOptions.filter((itm) => itm.key === value)[0]?.name}
                                        />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                className='quesTitleRow'
                                name='bankAccountNumber'
                                label='Bank Account Number'
                                labelCol={{ span: 24 }}
                                labelAlign='left'
                                colon={false}
                            >
                                <Controller
                                    name='bankAccountNumber'
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Input className='input-text-full' value={value} readOnly />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <div className='container flex flex-col md:flex-row items-start'>
                        <Form.Item
                            className='quesTitleRow'
                            label='Signature'
                            labelCol={{ span: 24 }}
                            labelAlign='left'
                            colon={false}
                            validateStatus={(errors?.signaturePath?.message) ? 'error' : '-'}
                            help={errors?.signaturePath?.message}
                        >
                            <Controller
                                name='signaturePath'
                                control={control}
                                render={({ field: { value, onChange, ref } }) => (
                                    <SignatureUploader
                                        className='mb-3 signature-uploader'
                                        uploadedSignatureSrc={value}
                                        isCanvasDisabled={isAllFieldsDisabled}
                                        isUploadBtnDisabled={isAllFieldsDisabled}
                                        isClearBtnDisabled={isAllFieldsDisabled}
                                        onUpload={(uploadedSignature) => {
                                            if (uploadedSignature.url) {
                                                onChange(uploadedSignature.url)
                                            }
                                        }}
                                        onClear={() => {
                                            onChange(null)
                                        }}
                                    />
                                )}
                            />
                        </Form.Item>

                        <Form.Item
                            className='w-full pl-0 md:pl-5'
                            validateStatus={(errors?.signatureAgreement?.message) ? 'error' : '-'}
                            help={errors?.signatureAgreement?.message}
                        >
                            <Controller
                                name='signatureAgreement'
                                control={control}
                                render={({ field: { value, onChange, ref } }) => (
                                    <Checkbox
                                        ref={ref}
                                        name='signatureAgreement'
                                        style={{ width: '100%' }}
                                        onChange={(e) => {
                                            onChange((e.target.checked) ? 1 : 0)
                                            step?.setData({ signatureAgreement: e.target.checked })
                                        }}
                                        checked={value}
                                    >
                                        <div style={{ width: '100%' }}>
                                            I hereby confirm and agree that the signature is signed by me and I fully understand that it will be affixed to the Bursa Malaysia Central Depository System account opening form and Malacca Securities Sdn Bhd Individual trading account application form (client details & product details). By providing this signature, I also agreed to be bound by terms and conditions on the operations of Malacca Securities Sdn Bhd Trading account and terms and conditions of opening a Bursa Malaysia Central Depository Account.
                                            <br /><br />
                                            I hereby provide my consent expressively to Bursa Malaysia Depository for disclosure of information or documents relating to my affairs and in particular related to my CDS Accounts to Malacca Securities Sdn Bhd and to such agents, service providers and sub-contractors of Malacca Securities Sdn Bhd as informed by Malacca Securities Sdn Bhd. This consent will be valid until it is revoked by me. I release Bursa Malaysia Depository from any loss or liability arising from or in connection with this authorisation except for loss of liability I may suffer as a result of any act, statement and omission that was not done in good faith by Bursa Malaysia Depository.
                                        </div>
                                    </Checkbox>
                                )}
                            />
                        </Form.Item>
                    </div>

                    {(props.store?.applicationStatus === 'EMAIL_VERIFIED' && !props.isLocked) &&
                    <>
                        <SubmitButton
                            className='w-full mt-5 submit-btn'
                            label={!props.skipPayment ? 'Proceed to Payment' : 'Proceed'}
                            submitting={submitting}
                        />

                        <div className='flex justify-center text-red text-center my-3'>
                            Kindly ensure that the Bank Account used for payment is under the applicant's name. Joint bank account is not allowed.
                        </div>
                    </>}

                    {((props.store?.applicationStatus === 'AMEND' || props.store?.applicationStatus === 'AMEND_WITH_DOCUMENT') && !props.isLocked) &&
                    <SubmitButton
                        className='w-full mt-5'
                        label='Resubmit'
                        submitting={submitting}
                    />}
                </fieldset>
            </Form>

            <AgreementModal
                className='declaration-modal'
                visible={isAgreementModalVisible}
                handleOk={(e) => {
                    setIsAgreementModalVisible(false)
                    if (e.target.checked) {
                        proceedPayment()
                    }
                }}
                handleCancel={() => setIsAgreementModalVisible(false)}
            />
        </div>
    )
}

ConfirmSummaryForm.propTypes = {
    className: PropTypes.string,
    onSuccessSubmit: PropTypes.func,
    isLocked: PropTypes.bool,
    skipPayment: PropTypes.bool
}

ConfirmSummaryForm.defaultProps = {
    isLocked: false,
    skipPayment: false
}

export default inject('store', 'services')(observer(ConfirmSummaryForm))
