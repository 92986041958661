import { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Divider } from 'antd'
import { applySnapshot } from 'mobx-state-tree'
import qs from 'qs'
import Layout from '../components/templates/layout'
import styles from '../components/templates/layout/Layout.module.scss'
import GuidelineList from '../modules/application/components/guideline/list'
import Checklist from '../modules/application/components/checklist'
import CreateApplicationForm from '../modules/application/create/components/form'
import ResumeApplicationForm from '../modules/application/resume/components/form'
import ApplicationCreatedOtpVerificationModal from '../modules/application/create/otp-verification/components/modal'
import ApplicationResumeOtpVerificationModal from '../modules/application/resume/otp-verification/components/modal'

const HomePage = props => {
    const refApplicationFormWrapper = useRef(null)
    const refResumeFormWrapper = useRef(null)
    const history = useHistory()
    const location = useLocation()
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    const [partner, setPartner] = useState(null)
    const [loading, setLoading] = useState(false)
    const [drCode, setDrCode] = useState(null)
    const [exclude, setExclude] = useState(null)
    const [isValidateByOTP, setIsValidateByOTP] = useState(false)
    const [isApplicationCreatedOtpVerificationModalVisible, setIsApplicationCreatedOtpVerificationModalVisible] = useState(false)
    const [isApplicationResumeOtpVerificationModalVisible, setIsApplicationResumeOtpVerificationModalVisible] = useState(false)

    useEffect(() => {
        if (query?.scrollTo) {
            switch (query.scrollTo) {
                case 'application':
                    refApplicationFormWrapper.current?.scrollIntoView({ behavior: 'smooth' })
                    break
                case 'resume':
                    refResumeFormWrapper.current?.scrollIntoView({ behavior: 'smooth' })
                    break
            }
        }
    }, [query?.scrollTo])

    useEffect(() => {
        if (query?.drCode) {
            setDrCode(query.drCode)
        } else {
            setDrCode(null)
        }
    }, [query?.drCode])

    useEffect(() => {
        if (query?.exclude) {
            setExclude(query.exclude)
        } else {
            setExclude(null)
        }
    }, [query?.exclude])

    useEffect(() => {
        setIsValidateByOTP(query?.mobile?.toLowerCase() === 'true')
    }, [query?.mobile])

    useEffect(async () => {
        if (query.partnerId) {
            try {
                setLoading(true)
                const res = await props.services.partnerService.getById(query.partnerId)

                if (res.ok) {
                    setPartner(res.data)
                } else {
                    setPartner(null)
                }
            } catch (error) {
                console.error('Fail to get partner data', error)
            } finally {
                setLoading(false)
            }
        } else {
            setPartner(null)
        }
    }, [query?.partnerId])

    const handleCreateSuccess = (data) => {
        props.store.reset()
        applySnapshot(props.store, {
            fullName: data?.fullName,
            email: data?.email,
            applicationStatus: 'NEW',
            initiated: true
        })

        if (!isValidateByOTP) {
            history.push({
                pathname: '/email-sent',
                search: location.search
            })
        } else {
            setIsApplicationResumeOtpVerificationModalVisible(true)
        }
    }

    const handleVerifyOTPSuccess = (data) => {
        // Push to status page
        if (props.store?.applicationId !== data?.applicationId && props.store?.applicationId !== 0) {
            props.store.reset()
        }
        props.store?.setData({
            applicationId: data?.applicationId,
            referenceId: data?.referenceId,
            accessToken: data?.accessToken
        })
        history.push('/status')
    }

    const handleRequestResumeSuccess = (data) => {
        props.store.reset()
        applySnapshot(props.store, {
            email: data?.email,
            isResumeApplication: true,
            initiated: true
        })

        if (!isValidateByOTP) {
            history.push({
                pathname: '/email-sent',
                search: location.search
            })
        } else {
            setIsApplicationResumeOtpVerificationModalVisible(true)
        }
    }

    return (
        <Layout>
            <div className={styles.left}>
                <div className={styles.content}>
                    <div className='mb-6'>
                        <h1 className='text-white'>
                            Account Opening
                        </h1>

                        <p className='text-lg'>
                            Simple steps to get started:
                        </p>

                        <GuidelineList
                            className='mt-3'
                            data={[
                                'Provide your registered email & name for Email verification',
                                'Create a username',
                                'Fill in your personal particulars along with the required documents',
                                'Make payment and complete the eKYC Verification'
                            ]}
                        />

                        <p className='mt-5 font-weight-bold text-base'>Note: Remember to fill in the DR Code/MR Code/<br />Reference, if applicable.</p>
                    </div>

                    <div className='mt-3 md:mt-9 mb-9'>
                        {/* <div className='text-lg'>M+ Online Cash Upfront Account</div> */}
                        <div className='oxygen-font-weight-bold text-lg'>Documents required: </div>
                        <Checklist
                            className='md:mt-5 mb-6'
                            data={[
                                'Original NRIC or Passport',
                                'Malaysia Incorporated Bank Account Statement'
                            ]}
                        />

                        <div className='oxygen-font-weight-bold text-lg'>Declarations: </div>
                        <Checklist
                            className='mt-5'
                            data={[
                                'Foreign Exchange Act (FEA)',
                                'Tax Residency (CRS)',
                                'Terms & Conditions for the CDS and Trading Account Opening'
                            ]}
                        />
                    </div>

                    <div>
                        <div className='oxygen-font-weight-bold text-lg'>Existing M+ Online User?</div>
                        <a href='https://www.mplusonline.com.my/' target='_blank' rel='noreferrer' className='text-base hover:text-white hover:underline'>Click here to Login</a>
                        <p className='mt-5 mb-3 text-base'>
                            Speak to our Customer Service if you required any assistance on your existing account
                        </p>
                        <a className='font-weight-bold text-base hover:text-white hover:underline' href='https://mplusonline.com/terms-and-policies' target='_blank' rel='noreferrer'>
                            Trading Account T&C
                        </a>
                    </div>
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.content}>
                    {(partner && partner?.logoUrl && partner?.showLogo) &&
                    <img alt={partner.fullName} src={partner.logoUrl} width='auto' height={50} style={{ height: '60px' }} className='absolute top-3' />}

                    <div className='text-3xl text-light-blue oxygen-font-weight-normal'>
                        Create your username and verify your email
                    </div>

                    <div className='w-full' ref={refApplicationFormWrapper}>
                        <CreateApplicationForm
                            className='mt-7'
                            onSuccessSubmit={handleCreateSuccess}
                            defaultValues={{
                                fullName: '',
                                email: '',
                                emailConfirmation: '',
                                drCode: drCode,
                                partnerId: query?.partnerId,
                                partnerMemberId: query?.partnerMemberId,
                                exclude: exclude,
                                isValidateByOTP
                            }}
                        />
                    </div>

                    <Divider className='mt-10 pt-6'>
                        <div className='text-sm text-light-blue oxygen-font-weight-normal'>
                            Or Resume Account Opening
                        </div>
                    </Divider>

                    <div className='w-full' ref={refResumeFormWrapper}>
                        <ResumeApplicationForm
                            className='mt-3'
                            onSuccessSubmit={handleRequestResumeSuccess}
                            defaultValues={{
                                email: '',
                                isValidateByOTP,
                                exclude
                            }}
                        />
                    </div>
                </div>
            </div>

            <ApplicationCreatedOtpVerificationModal
                visible={isApplicationCreatedOtpVerificationModalVisible}
                formDefaultValues={{
                    email: props.store?.email,
                    exclude: exclude
                }}
                onSuccessSubmit={handleVerifyOTPSuccess}
                onCancel={() => setIsApplicationCreatedOtpVerificationModalVisible(false)}
            />

            <ApplicationResumeOtpVerificationModal
                visible={isApplicationResumeOtpVerificationModalVisible}
                formDefaultValues={{
                    email: props.store?.email,
                    exclude: exclude
                }}
                onSuccessSubmit={handleVerifyOTPSuccess}
                onCancel={() => setIsApplicationResumeOtpVerificationModalVisible(false)}
            />
        </Layout>
    )
}

export default inject('store', 'services')(observer((HomePage)))
