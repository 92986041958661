import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './Layout.module.scss'
import Header from './header'

const Layout = (props) => {
    return (
        <div className={styles.wrapper}>
            <Header />

            <div className={styles.contentWrapper}>
                {props.children}
            </div>
        </div>
    )
}

Layout.propTypes = {
}

Layout.defaultProps = {
}

export default Layout
