import React, { useState, useEffect, useCallback } from 'react'
import { Form, TreeSelect } from 'antd'
import { EditTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import cn from 'classnames'
import PropTypes from 'prop-types'

const CustomTreeSelectInput = React.forwardRef((props, ref) => {
    const [disabled, setDisabled] = useState(props.disabled)

    useEffect(() => {
        setDisabled(props.disabled)
    }, [props.disabled])

    const handleBtnEditClick = useCallback(() => {
        setDisabled(false)
        props.onToggleDisabled(false)
    })

    const handleBtnCancelEditClick = useCallback(() => {
        setDisabled(true)
        props.onToggleDisabled(true)
    })

    return (
        <Form.Item
            className={cn('mb-4 quesTitle', props.className)}
            style={props.style}
            label={
                (props.label)
                    /* eslint-disable react/jsx-closing-tag-location */
                    ? (<div className='flex justify-between w-full'>
                        <span>{props.label}</span>

                        {props.ableToToggleDisabled && disabled &&
                        <a type='button' className='text-blue-500 flex items-center' onClick={handleBtnEditClick}>
                            <EditTwoTone className='mr-1' />
                            Edit
                        </a>}

                        {props.ableToToggleDisabled && !disabled &&
                        <a type='button' className='text-red-500 hover:text-red-500 flex items-center' onClick={handleBtnCancelEditClick}>
                            <CloseCircleTwoTone twoToneColor='#ef4444' className='mr-1' />
                            Cancel
                        </a>}
                    </div>)
                    : null
                    /* eslint-enable react/jsx-closing-tag-location */
            }
            validateStatus={(props.error?.message) ? 'error' : '-'}
            help={props.error?.message}
        >
            <TreeSelect
                disabled={disabled}
                ref={ref}
                placeholder={props.placeholder}
                treeData={props.treeData}
                treeDefaultExpandAll={props.treeDefaultExpandAll}
                onChange={props.onChange}
                value={props.value}
            />
        </Form.Item>
    )
})

CustomTreeSelectInput.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.object,
    onChange: PropTypes.func,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    ableToToggleDisabled: PropTypes.bool,
    onToggleDisabled: PropTypes.func,
    treeData: PropTypes.array,
    treeDefaultExpandAll: PropTypes.bool
}

CustomTreeSelectInput.defaultProps = {
    onChange: () => {},
    disabled: false,
    ableToToggleDisabled: false,
    onToggleDisabled: () => {},
    treeData: [],
    treeDefaultExpandAll: false
}

export default CustomTreeSelectInput
