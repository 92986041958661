import { types, flow, getEnv } from 'mobx-state-tree'
import moment from 'moment'

export const raceList = [
    {
        title: 'Malay',
        value: 'MALAY',
        key: 'MALAY'
    },
    {
        title: 'Chinese',
        value: 'CHINESE',
        key: 'CHINESE'
    },
    {
        title: 'Indian',
        value: 'INDIAN',
        key: 'INDIAN'
    },
    {
        title: 'Bumiputra',
        selectable: false,
        key: 'BUMIPUTRA',
        children: [
            {
                title: 'Bidayuh',
                value: 'BIDAYUH',
                key: 'BIDAYUH'
            },
            {
                title: 'Iban',
                value: 'IBAN',
                key: 'IBAN'
            },
            {
                title: 'Kadazan',
                value: 'KADAZAN',
                key: 'KADAZAN'
            },
            {
                title: 'Murut',
                value: 'MURUT',
                key: 'MURUT'
            },
            {
                title: 'Other Bumiputra',
                value: 'OTHER_BUMIPUTRA',
                key: 'OTHER_BUMIPUTRA'
            }
        ]
    },
    {
        title: 'Others',
        value: 'OTHERS',
        key: 'OTHERS'
    }
]

export const raceListExcludeOtherBumi = [
    {
        title: 'Malay',
        value: 'MALAY',
        key: 'MALAY'
    },
    {
        title: 'Chinese',
        value: 'CHINESE',
        key: 'CHINESE'
    },
    {
        title: 'Indian',
        value: 'INDIAN',
        key: 'INDIAN'
    },
    {
        title: 'Bumiputra',
        selectable: false,
        key: 'BUMIPUTRA',
        children: [
            {
                title: 'Bidayuh',
                value: 'BIDAYUH',
                key: 'BIDAYUH'
            },
            {
                title: 'Iban',
                value: 'IBAN',
                key: 'IBAN'
            },
            {
                title: 'Kadazan',
                value: 'KADAZAN',
                key: 'KADAZAN'
            },
            {
                title: 'Murut',
                value: 'MURUT',
                key: 'MURUT'
            }
        ]
    },
    {
        title: 'Others',
        value: 'OTHERS',
        key: 'OTHERS'
    }
]

const races = raceList.flatMap((itm) => !itm.children ? itm.value : itm.children.map(itm2 => itm2.value))

export const Step1 = types
    .model('Step1', {
        username: types.maybeNull(types.string),
        drCode: types.maybeNull(types.string),
        mrCode: types.maybeNull(types.string),
        referralCode: types.maybeNull(types.string),
        accountType: types.maybeNull(types.string),
        fullName: types.maybeNull(types.string),
        documentNumber: types.maybeNull(types.string),
        ekycDocumentType: types.maybeNull(types.integer),
        otherDocumentNumber: types.maybeNull(types.string),
        documentExpiry: types.maybeNull(types.Date),
        uploadedDocumentFrontPath: types.maybeNull(types.string),
        uploadedDocumentBackPath: types.maybeNull(types.string),
        nationality: types.maybeNull(types.string),
        isPrMalaysia: types.maybeNull(types.boolean),
        contactNumber: types.maybeNull(types.string),
        contactPrefix: types.maybeNull(types.string),
        address1: types.maybeNull(types.string),
        address2: types.maybeNull(types.string),
        address3: types.maybeNull(types.string),
        postcode: types.maybeNull(types.string),
        state: types.maybeNull(types.string),
        city: types.maybeNull(types.string),
        country: types.maybeNull(types.string),
        birthDate: types.maybeNull(types.Date),
        gender: types.maybeNull(types.string),
        raceOther: types.maybeNull(types.string),
        maritalStatus: types.maybeNull(types.string),
        race: types.maybeNull(
            types.enumeration('Race', races)
        ),
        hasPreferDR: types.maybeNull(types.boolean),
        hasBaAccount: types.maybeNull(types.boolean),
        hasBaMsecAccount: types.maybeNull(types.boolean),
        sameAddress: types.maybeNull(types.boolean),
        mailingAddress1: types.maybeNull(types.string),
        mailingAddress2: types.maybeNull(types.string),
        mailingAddress3: types.maybeNull(types.string),
        mailingPostcode: types.maybeNull(types.string),
        mailingState: types.maybeNull(types.string),
        mailingCity: types.maybeNull(types.string),
        mailingCountry: types.maybeNull(types.string)
    })
    .views((self) => {
        return {
            get formData () {
                const data = { ...self }
                if (data.birthDate) {
                    data.birthDate = moment(data.birthDate)
                }
                if (data.documentExpiry) {
                    data.documentExpiry = moment(data.documentExpiry)
                }
                if (data.isPrMalaysia) {
                    data.nationality = 'MYPR'
                }
                data.hasBaAccount = data.hasBaAccount ? 1 : (data.hasBaAccount === null ? null : 0)
                data.hasBaMsecAccount = data.hasBaMsecAccount ? 1 : (data.hasBaMsecAccount === null ? null : 0)
                data.hasPreferDR = data.hasPreferDR ? 1 : (data.hasPreferDR === null ? null : 0)
                return data
            },
            get hasSpouse () {
                return self.maritalStatus === 'MARRIED'
            }
        }
    })
    .actions((self) => ({
        setData (data) {
            for (const key in data) {
                if (!Object.prototype.hasOwnProperty.call(data, key)) {
                    console.error(`Unavailable key ${key}`)
                } else {
                    self[key] = data[key]
                }
            }
        },
        setNationality (value) {
            self.nationality = value
            if (value === 'MY') {
                self.documentExpiry = null
            }
            if (value === 'MYPR') {
                self.isPrMalaysia = true
            } else {
                self.isPrMalaysia = false
            }
        },
        setBirthDate (value) {
            if (typeof value === 'string') {
                self.birthDate = new Date(value)
            } else {
                self.birthDate = new Date(value)
            }
            // const isOld = moment(self.birthDate).isBefore('1976-01-01')
            // if (!isOld) self.otherDocumentNumber = null
        },
        setDocumentExpiry (value) {
            if (typeof value === 'string') {
                self.documentExpiry = new Date(value)
            } else {
                self.documentExpiry = new Date(value)
            }
        },
        setRace (race) {
            self.race = race
            self.raceOther = null
        },
        setRaceOther (race) {
            self.race = 'OTHERS'
            self.raceOther = race
        },
        setHasBaAccount (b) {
            self.hasBaAccount = b
        },
        setHasBaMsecAccount (b) {
            self.hasBaMsecAccount = b
        },
        setHasPreferDR (b) {
            self.hasPreferDR = b
            if (!b) {
                self.drCode = null
                self.mrCode = null
            }
        },
        setSameAddress (same) {
            self.sameAddress = same
        },
        setState (value) {
            self.state = value
        },
        setCountry (value) {
            self.country = value
        },
        setMailingState (value) {
            self.mailingState = value
        },
        setMailingCountry (value) {
            self.mailingCountry = value
        },
        uploadAmendDocument: flow(function * uploadAmendDocument (accessToken, formData) {
            const service = getEnv(self).applicationService
            const res = yield service.uploadAmendDocument(accessToken, formData)
            return res
        }),
        submit: flow(function * submit (accessToken) {
            if (self.country !== 'MY') {
                self.state = null
                self.city = null
                self.postcode = null
            }
            if (self.mailingCountry !== 'MY') {
                self.mailingState = null
                self.mailingCity = null
                self.mailingPostcode = null
            }
            if (self.sameAddress) {
                self.mailingAddress1 = null
                self.mailingAddress2 = null
                self.mailingAddress3 = null
                self.mailingState = null
                self.mailingCity = null
                self.mailingPostcode = null
                self.mailingCountry = null
            }

            const data = {
                ...self,
                birthDate: self.birthDate ? moment(self.birthDate).format('YYYY-MM-DD') : null,
                documentExpiry: self.documentExpiry ? moment(self.documentExpiry).format('YYYY-MM-DD') : null,
                sameAddress: (self.sameAddress !== null) ? self.sameAddress : false
            }

            if (data.nationality === 'MYPR') {
                data.nationality = 'MY'
                data.isPrMalaysia = true
            } else {
                data.isPrMalaysia = false
            }

            const service = getEnv(self).applicationService
            const res = yield service.completePersonalProfile(accessToken, data)
            return res
        })
    }))
