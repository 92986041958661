const focusErrorInput = (inputKeys, errors, setFocusHandler, setErrorHandler, excludeFocusKeys = []) => {
    const nonErrorFieldMessages = []
    const errorKeys = Object.keys(errors)

    let toFocus = false
    errorKeys.forEach((key, index) => {
        const firstErrorMsg = errors[key][0]
        if (inputKeys.includes(key)) {
            if ((index === 0 || toFocus) && !excludeFocusKeys.includes(key)) {
                setFocusHandler(key)
                toFocus = false
            }
            setErrorHandler(key, {
                type: 'manual',
                message: firstErrorMsg
            })
        } else {
            nonErrorFieldMessages.push(firstErrorMsg)
            toFocus = (index === 0)
        }
    })

    return { nonErrorFieldMessages }
}

export default focusErrorInput
