import { useState, useEffect } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import styles from '../ApplicationStatus.module.scss'

const StatusApproved = props => {
    useEffect(() => {
    }, [])

    return (
        <div className={cn(styles.wrapper, props.className)}>
            <div className={styles.title}>
                We’re getting your account ready for you!
            </div>
            <p className={styles.description}>
                You will be notified once the account is ready to use.
            </p>

            <p className={styles.description}>
                Meanwhile, you can click the button below to learn more on how you can fully utilize the M+ Online Platform.
            </p>
            <a className='btn-primary-full px-4 py-2 rounded font-weight-bold' href='https://mplusonline.com/journey-1/' target='_blank' rel='noreferrer'>
                Learn More
            </a>
        </div>
    )
}

StatusApproved.propTypes = {
    className: PropTypes.string
}

StatusApproved.defaultProps = {
}

export default StatusApproved
