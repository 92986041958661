import React, { useState, useEffect, useCallback } from 'react'
import { Checkbox } from 'antd'
import PropTypes from 'prop-types'
import CheckboxGroup from './CheckboxGroup'

const CustomCheckbox = React.forwardRef((props, ref) => {
    return (
        <Checkbox
            className={props.className}
            style={props.style}
            autoFocus={props.autoFocus}
            checked={props.checked}
            defaultChecked={props.defaultChecked}
            disabled={props.disabled}
            indeterminate={props.indeterminate}
            value={props.value}
            onChange={props.onChange}
        >
            {props.children}
        </Checkbox>
    )
})

CustomCheckbox.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    autoFocus: PropTypes.bool,
    checked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    indeterminate: PropTypes.bool,
    onChange: PropTypes.func
}

CustomCheckbox.defaultProps = {
    autoFocus: false,
    checked: false,
    defaultChecked: false,
    disabled: false,
    indeterminate: false,
    onChange: () => {}
}

CustomCheckbox.Group = CheckboxGroup

export default CustomCheckbox
