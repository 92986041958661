import { Button, Checkbox, Modal } from 'antd'
import { useState, useEffect, useRef } from 'react'

const AgreementModal = ({ className, visible, handleOk, handleCancel }) => {
    const refContentWrapper = useRef(null)
    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false)
    const [isAgreed, setIsAgreed] = useState(false)

    useEffect(() => {
        if (refContentWrapper.current) {
            const handleScroll = (event) => {
                const { scrollHeight, scrollTop, clientHeight } = event.target
                const isBottom = (scrollHeight - scrollTop) * 0.95 <= clientHeight
                if (isBottom) {
                    setIsScrolledToEnd(true)
                }
            }

            const { scrollHeight, scrollTop, clientHeight } = refContentWrapper.current
            const isBottom = (scrollHeight - scrollTop) * 0.95 <= clientHeight
            if (isBottom) {
                setIsScrolledToEnd(true)
            }

            refContentWrapper.current?.addEventListener('scroll', handleScroll)
            return () => refContentWrapper.current?.removeEventListener('scroll', handleScroll)
        }
        setIsAgreed(false)
    }, [visible])

    return (
        <Modal
            className={className}
            visible={visible}
            title='DECLARATIONS'
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Checkbox
                    key={1}
                    style={{ textAlign: 'left' }}
                    disabled={!isScrolledToEnd}
                    onChange={handleOk}
                    checked={isAgreed}
                >
                    I confirm and agree to the Declarations for The Operation of CDS Account in Bursa Malaysia and Securities Trading Account (s) in Malacca Securities Sdn Bhd & Terms and Conditions for The Operation of Securities Trading Account (s) in Malacca Securities Sdn Bhd  as stated above. I hereby declare that the information provided is true and correct and will be subjected to Malacca Securities Sdn Bhd's Personal Data Notice. I also understand that I will be contacted for further verification relating to opening and operating of a CDS and trading account with Malacca Securities Sdn Bhd if necessary.
                </Checkbox>
            ]}
            bodyStyle={{ overflowY: 'scroll', height: 'calc(100vh - 280px)', padding: 0 }}
            width='90%'
            style={{
                marginTop: -60
            }}
        >
            <div ref={refContentWrapper} className='' style={{ overflowY: 'scroll', width: '100%', height: '100%', padding: 20 }}>
                <div className='font-weight-bold mb-4'>DECLARATIONS PURSUANT TO THE TERMS AND CONDITIONS FOR THE OPENING AND OPERATION OF CDS ACCOUNT IN BURSA MALAYSIA DEPOSITORY SDN BHD</div>
                <p className='mb-4'>DECLARATION PURSUANT TO THE APPLICATION FOR OPENING OF CDS ACCOUNT DECLARATION BY APPLICANT/DEPOSITOR/AUTHORISED SIGNATORY(IES)/ATTORNEY(S)</p>
                <p className='agreement'>I hereby :-</p>
                <ol className='list-lower-roman pl-3'>
                    <li>
                        Declare that I have the full capacity and authority to make the application, and open and operate a CDS account, and that the information given in this application is true and correct as at the date of this form.
                    </li>
                    <li>
                        Agree to be bound by the Rules of Bursa Malaysia Depository Sdn Bhd ("Depository") and any directions issued by the Depository, as may be amended from time to time.
                    </li>
                    <li>
                        Declare that I am the beneficial owners of all securities credited into this account that is to be opened.
                    </li>
                    <li>
                        Irrevocably consent to the disclosure by the Depository, the issuer and their respective agents to any person, of such of my personal information, as may be necessary or expedient to facilitate the payment of all cash dividends and other cash distributions that may be due to me in respect of the deposited securities in my CDS account, directly into my bank account as stated in this form or as may be updated from time to time, or for any other purpose in connection with the payment of dividends or other cash distributions via such manner.
                    </li>
                    <li>
                        Irrevocably consent to receiving all cash dividends and other cash distributions that may be due to me in respect of the deposited securities in my CDS account via direct credit into my bank account as stated in this form or as may be updated from time to time.
                    </li>
                    <li>
                        Agree to receive, in relation to my deposited securities and where relevant, electronic notifications instead of hardcopy notification, sent using my contact details as stated in this form or as may be updated from time to time, and further consent to the disclosure of my contact details currently in the Depository's records to:
                        <ul className='list-lower-latin pl-7'>
                            <li>
                                Bursa Malaysia Berhad's Group of Companies (the Group) to enable them to communicate capital market-related matters, including new developments and initiatives by the Group, to me; and
                            </li>
                            <li>
                                Facilitate the sending of notices and communications from the issuers and Participating Organisations (POs) e.g eDividend notification, contract notes from POs, and notices/circulars from issuers.
                            </li>
                        </ul>
                    </li>
                </ol>
                <p className='agreement'>
                    I have read and am aware of the personal data notice available at Bursa Malaysia Berhad's website at www.bursamalaysia.com.
                </p>

                <div className='font-weight-bold mt-8 mb-4'>DECLARATION PURSUANT TO TERMS AND CONDITION FOR THE OPERATION OF SECURITIES TRADING ACCOUNT(s) IN MALACCA SECURITIES SDN BHD</div>
                <p className='agreement'>I hereby declare / agree / undertake that:</p>
                <ol className='list-lower-latin pl-7'>
                    <li>
                        I have attained the age of eighteen (18) years and all particulars and information furnished by me in this Online Application Form is true and correct and I have not withheld any material fact or information from Malacca Securities Sdn Bhd ('the Company').
                    </li>
                    <li>
                        I have not been adjudicated or declared a bankrupt and have not committed an act of bankruptcy and have no knowledge of any bankruptcy petition presented against me in any court of competent jurisdiction and I have not been listed as a defaulter by Bursa Malaysia Securities Berhad.
                        <ul className='list-lower-roman pl-7'>
                            <li>
                                Upon the occurrence of any of the events rendering the above declaration in Clauses(a) and (b) becoming inaccurate or untrue, I agree and undertake to immediately cease trading and cease utilizing the services of the Company and shall forthwith notify the Company in writing of the occurrence of such event(s). Without prejudice to the aforementioned, upon the Company having knowledge of the occurrence of any of the such events, the Company shall have the right and discretion to immediately cease my trading and the Company shall have the right to cease the provision of all its/their services or any part thereof. However, all orders carried out for me under any of the Client’s Securities Account(s) (hereinafter defined) and all orders, instructions and communications carried out by the Company prior to the aforesaid cessation shall be valid and enforceable against me.
                            </li>
                        </ul>
                    </li>
                    <li>
                        I understand and acknowledge that the Company do not provide any investment advice, suggestions or recommendations;
                    </li>
                    <li>
                        I understand that my application to open any account and any other services and facilities are subject to the Company's approval as the case may be and the Company is absolutely entitled at it/ their respective discretion to reject my application or impose such conditions and/or restrictions as it / they may deem fit without giving any reasons thereof.
                    </li>
                    <li>
                        I undertake to furnish the Company with such additional particulars, information and declaration as the Company may require at any time and from time to time. I further undertake to promptly notify the Company should there be any changes to the particulars, information or the declaration given to the Company as the case may be.
                    </li>
                    <li>
                        I also authorise the Company to verify the particulars and information provided from any source and in such manner as it/they shall deem fit.
                    </li>
                    <li>
                        I consent to the Company to conduct any check on the credit standing / financial status with any person or institution or credit reporting agency that it/ they may deem necessary at any point of time.
                    </li>
                    <li>
                        I have been advised to seek independent legal advice on the effects and consequences of the terms and conditions of this online Form and the Terms and Conditions for the Operation of Securities Trading Accounts in MSSB (“ Terms “) and any failure to do so shall be at my risk.
                    </li>
                    <li>
                        I have also read and understood the risks in investing in structured warrants
                    </li>
                    <li>
                        I enclose herewith one (1) copy of my New NRIC/Passport/Authority Card duly certified as the true copy.
                    </li>
                    <li>
                        I hereby agree that all payments (including but not limited to sales proceeds, contra gain and credits) due to me shall be auto credited to my trust account maintained with MSSB.
                    </li>
                    <li>
                        I have read and understood the full contents of this online form and the Terms posted at the  Company website www.mplusonline.com.my. I fully accept and agree to be bound by the said TAAF and Terms and agree that the said Terms may be varied and/or supplemented by the Company from time to time. (Please refer to the Company website www.mplusonline.com.my for the Terms and the updated version of the Terms).
                    </li>
                </ol>

                <div className='font-weight-bold mt-8 mb-4'>TERMS AND CONDITIONS FOR THE OPERATION OF SECURITIES TRADING ACCOUNT(S) IN MSSB</div>
                <div className='flex flex-col'>
                    <a className='text-blue-500' href='https://mplus-public.oss-ap-southeast-3.aliyuncs.com/Terms-and-conditions-for-Trading-acct-01012022.docx.pdf' target='_blank' without rel='noreferrer'>
                        https://mplus-public.oss-ap-southeast-3.aliyuncs.com/Terms-and-conditions-for-Trading-acct-01012022.docx.pdf
                    </a>
                    <a className='text-blue-500' href='https://mplus-public.oss-ap-southeast-3.aliyuncs.com/Terms-and-conditions-for-ISBW-trading-01012022.docx.pdf' target='_blank' without rel='noreferrer'>
                        https://mplus-public.oss-ap-southeast-3.aliyuncs.com/Terms-and-conditions-for-ISBW-trading-01012022.docx.pdf
                    </a>
                </div>
            </div>
        </Modal>
    )
}

export default AgreementModal
