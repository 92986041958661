import { useState, useEffect } from 'react'
import { Checkbox, Modal } from 'antd'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './TermModal.module.scss'
import Button from '../../../../../../components/button'

const TermModal = (props) => {
    const [isAgreed, setIsAgreed] = useState(false)
    const [isBtnProceedLoading, setIsBtnProceedLoading] = useState(props.isBtnProceedLoading)

    useEffect(() => {
        setIsAgreed(false)
    }, [props.visible])

    useEffect(() => {
        setIsBtnProceedLoading(props.isBtnProceedLoading)
    }, [props.isBtnProceedLoading])

    const handleCheckboxChange = (e) => {
        setIsAgreed(e.target.checked)
    }

    return (
        <Modal
            visible={props.visible}
            title=''
            footer={null}
            className={cn(props.className, styles.wrapper)}
            onCancel={props.onCancel}
        >
            <div className={styles.title}>Online CDS and Trading Account Application</div>
            <p>Thank you for your interest in opening a CDS Account and Trading Account with M+ Online.</p>
            <p className='mb-5'>Here are some important items to take into account:</p>
            <ul>
                <li><b>The CDS and Trading Account will be processed and maintained by Malacca Securities Sdn Bhd (A Participating Organisation of Bursa Malaysia Securities Sdn Bhd).</b></li>
                <li>This online account opening application is valid for <b>Cash Upfront Account, Islamic Cash Upfront Account and Collateral Account only.</b></li>
                <li>Complete the registration form accurately according to the instructions provided.</li>
                <li>
                    <b>Documents Required:</b>
                    <ul>
                        <li><b>Original NRIC or Passport</b></li>
                        <li><b>Malaysia Incorporated Bank Account Statement</b></li>
                    </ul>
                </li>
                <li>If you have a <b>preferred Dealer Representative or Remisier</b>, kindly select <b>“YES” for “Do you have a preferred Dealer's Representative or Remiser?”</b> and insert the DR Code.</li>
                <li>Please take note that <b>NO Change of Dealer Representative is allowed for the first 6 months</b>, upon successful account opening.</li>
                <li>
                    Complete the RM11.00 payment for the account opening fee.
                    <ul>
                        <li>Kindly ensure that the Bank Account used for payment is under the applicant’s name, joint bank account is not allowed.</li>
                        <li>RM 10.00 - CDS Account Opening Fee.</li>
                        <li>RM 1.00 - Admin charges.</li>
                    </ul>
                </li>
            </ul>
            <p className='mb-5'>The account opening application process may <b>take up to two weeks</b>, to ensure that the KYC process is properly done according to the stipulated regulatory requirements.</p>

            <Checkbox checked={isAgreed} onChange={handleCheckboxChange}>
                I have read and fully understood the above-mentioned requirements and information regarding the application of a CDS Account and M+ Online Trading Account.
            </Checkbox>

            <Button
                label='Proceed'
                labelLoading='Submitting'
                disabled={!isAgreed}
                className='mx-auto my-10 block btn-proceed'
                loading={isBtnProceedLoading}
                onClick={() => props.btnProceedOnClick()}
            />
        </Modal>
    )
}

TermModal.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    visible: PropTypes.bool,
    isBtnProceedLoading: PropTypes.bool,
    btnProceedOnClick: PropTypes.func.isRequired,
    onCancel: PropTypes.func
}

TermModal.defaultProps = {
    visible: false,
    isBtnProceedLoading: false
}

export default TermModal
